import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    Button,
    Box,
    Typography,
    IconButton
} from "@material-ui/core";
import { withRouter } from 'react-router-dom';
import {ReactComponent as MobileClose} from "../../common/images/MobileClose.svg";
import {State, UserType} from "../../stores/AuthStore";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        padding:'10px 20px',
    },
    iconBtnBox:{
        display:'flex',
        justifyContent:'flex-end',
        marginBottom:70
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.7rem',
        fontWeight:500,
        color:'#0d0d0d',
        textAlign:'center',
        marginTop:5,
        marginBottom:60,
    },
    btnStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:600,
        color:'#fff',
        borderRadius:5,
        background:"#bababa",
        padding:12,
        width:'48%',
        "&:hover":{
            background:'#bababa'
        }
    },
    btnStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:600,
        color:'#fff',
        borderRadius:5,
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        padding:12,
        width:'48%',
        "&:hover":{
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    }
});
@inject('authStore', 'orgStore')
@observer
class MobilePasswordChange extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        }
    }
    moveTo = (url) => {
        this.props.history.push(url);
    }
    handleClose = () => {
        this.setState({open:false})
        this.props.authStore.changeLoginState(State.Authenticated);
        if(this.props.authStore.loginUser.type === UserType.Admin) this.moveTo('/');
        else this.moveTo('/mytimeline');

    };
    handleChangePassword =()=> {
        this.setState({open:false})
        this.props.authStore.changeLoginState(State.Authenticated);
        this.moveTo('/profilesettings');
    }
    render() {
        const {classes} = this.props;
        const { Logo } = this.props.orgStore.orgImages;

        return (
            <div className={classes.root}>
                <Box className={classes.iconBtnBox}>
                    <IconButton onClick={this.handleClose}>
                        <MobileClose />
                    </IconButton>
                </Box>

                <Box display='flex' flexDirection='column' alignItems='center'>
                    <img src={Logo} alt="로고" style={{width:145, height:33.27}} />
                    <Typography className={classes.titleText}>
                        <FormattedMessage id={"msg.change_new_password"} />
                        <br/>
                    </Typography>
                    {/*<Typography className={classes.titleText}>새로운 비밀번호로<br /> 변경해주세요.</Typography>*/}
                </Box>

                <Box display="flex" justifyContent="space-between">
                    <Button className={classes.btnStyle1} onClick={this.handleClose}>
                        <FormattedMessage id={"change_skip"} />
                    </Button>
                    <Button className={classes.btnStyle2} onClick={this.handleChangePassword}>
                        <FormattedMessage id={"change_now"} />
                    </Button>
                </Box>

            </div>
        );
    }
};

export default withRouter(withStyles(style)(injectIntl(MobilePasswordChange)));