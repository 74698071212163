import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Box,
    Grid,
} from "@material-ui/core";
import TeamRoomNotice from "./containers/TeamRoomNotice";
import FileSharing from "./containers/FileSharing";
import TeamMembersSideBar from "../../components/TeamMembersSideBar";
import TimeLineTab from "../MyTimeline/containers/TimeLineTab";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {PATH_UTIL} from "../../common/util/path.util";

const style = theme => ({
    root:{
        width:'100%',
        paddingTop:90,
        paddingLeft:28,
        marginLeft:90,
        marginRight:30,
        background:'#fff',
        flexGrow: 1,
        height: window.innerHeight - 175
    }
});

@inject('userLoungeStore', 'topBarStore', 'orgStore')
@observer
class TeamRoom extends Component {
    componentDidMount() {
        const { topBarStore } = this.props;
        if (PATH_UTIL.getPath(this.props.location.pathname) === 'teams') {
            topBarStore.setIsSearchBarRender(true);
        }
    };

    render() {
        const { classes, userLoungeStore, orgStore } = this.props

        return (
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={3}>
                        <TeamRoomNotice />
                        <FileSharing/>
                    </Grid>
                    <Grid item xs={9}>
                        <Box display='flex' justifyContent='flex-end'>
                            <TeamMembersSideBar
                                members={userLoungeStore.selectedTeam !== undefined ? userLoungeStore.selectedTeam.members : []}
                                isTeam={true}
                                configs={orgStore.orgConfigs}
                            />
                        </Box>
                        <TimeLineTab />
                    </Grid>
                </Grid>
            </div>
        );
    };
}

export default withRouter(withStyles(style)(TeamRoom));