import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Box,
    Button,
    Grid,
    Typography
} from "@material-ui/core";
import DropzoneImage from "./DropzoneImage";
import TabsText from "./TabsText";
import TabsLogo from "./TabsLogo";
import TabsColor from "./TabsColor";
import TabletBrowserPreview from "./TabletBrowserPreview";
import {inject, observer} from "mobx-react";
import TabletTimelinePreview from "./TabletTimelinePreview";
import SkeletonPreview from "./SkeletonPreview";
import * as Params from "../../../common/Params";
import MobileConfirmDialog from "../../../common/MobileConfirmDialog";
import {withRouter} from "react-router-dom";
import {PATH_UTIL} from "../../../common/util/path.util";
import {FormattedMessage, injectIntl} from "react-intl";
import TabsTextColor from "./TabsTextColor";

const style = theme => ({
    root:{
        padding:'50px 0 30px',
        "& .MuiButton-root.Mui-disabled":{
            background:'#fff',
            color:'#88889D',
            border:'1px solid #A3A8AF'
        }
    },
    textStyle:{
        width:132,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:800,
        color:'#0d0d0d',
        marginTop:50,
        marginBottom:10
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        color:'#0d0d0d'
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        color:'#666666'
    },
    btnStyle:{
        padding:'8px 53px',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:600,
        color:'#fff',
        "&:hover":{
            background:() =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        },
    },
    btnStyle1:{
        padding:'8px 53px',
        background:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:600,
        color:'#fff',
        marginRight:16,
        "&:hover":{
            background:'#a3a8af'
        }
    },
    previewBoxGrid:{
        display:'flex',
        alignItems:'center'
    },
    previewText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        color:'#0d0d0d',
    },
    previewBox:{
        width:'100%',
        height:'auto',
        marginBottom:10,
    },
    inputStyle: {
        width: 230,
        padding: '6px 10px',
        borderRadius: 4,
        border: '1px solid #b7bcd6',
        background: '#fff',
        marginBottom:49,
        marginRight: 40,
    },
    inputBtnStyle: {
        width: 110,
        padding: '6px 6px',
        borderRadius: 4,
        background: () => theme.configs.MainBtnColor ?  theme.configs.MainBtnColor : theme.configs.MainColor,
        color: theme.configs.Color,
        "&:hover":{
            background:() =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        },
        marginBottom:49,
    },
});




@inject('orgStore', 'progressStore')
@observer
class TabletOrganizationInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading : false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.isLoading !== prevProps.orgStore.getIsOrganizationDataLoading) {
            this.setState({isLoading : prevProps.orgStore.getIsOrganizationDataLoading}, ()=> {
                this.props.progressStore.setIsLoading(this.state.isLoading);
            })
        }

    }

    componentWillUnmount() {
        this.props.orgStore.initialPreviewItems();
        this.props.orgStore.initialImgData();
        this.props.progressStore.setIsLoading(false);
    }

    handleSaveBtn = (e) => {
        e.preventDefault();
        const { intl, orgStore } = this.props;
        if(orgStore.haveConfigs) orgStore.requestUpdateOrgConfig(intl);
        else orgStore.requestSaveOrgConfig(intl);
    }

    handleCancelBtn = (e) => {
        e.preventDefault();
        this.props.orgStore.initialPreviewItems();
        this.props.orgStore.initialImgData();
    }

    handleInputBtn = () => {
        const currentUrl = PATH_UTIL.getOriginURL();
        this.props.orgStore.changeOrgURL(currentUrl);
    }



    render() {
        const { classes, intl, orgStore } = this.props;
        const { mode, hasError, errorMessage, changeHasError,
                previewImages, previewConfigs, isOrganizationDataLoading,
                mainColorSelection, subColorSelection, mainTextColorSelection, subTextColorSelection} = orgStore;
        const isTabletMode = Params.isTabletMode(800);

        return (
            <div className={classes.root}>
                <Grid item xs={12}>
                    <Typography className={classes.textStyle} style={{marginBottom:10,marginTop:0}}>
                        <FormattedMessage id={"organization_name"} />
                    </Typography>
                    <input
                        name="name"
                        type="text"
                        id="name"
                        placeholder={intl.formatMessage({id: "msg.insert_organization_name"})}
                        autoComplete={"off"}
                        value={previewConfigs.OrganizationName || ""}
                        className={classes.inputStyle}
                        onChange={e => orgStore.changeOrgTitle(e.target.value)}
                    />
                    <Typography className={classes.textStyle} style={{marginBottom:10,marginTop:0}}>URL</Typography>
                    <Box display={"flex"}>
                        <input
                            variant={"outlined"}
                            name={"url"}
                            type={"text"}
                            id={"url"}
                            placeholder={intl.formatMessage({id: "msg.insert_url"})}
                            value={previewConfigs.WebviewURL || ""}
                            className={classes.inputStyle}
                            onChange={e => orgStore.changeOrgURL(e.target.value)}
                        />
                        <Button
                            className={classes.inputBtnStyle}
                            onClick={this.handleInputBtn}
                        >
                            <FormattedMessage id="get_url"/>
                        </Button>
                    </Box>
                    <Typography className={classes.textStyle} style={{marginBottom:10,marginTop:0}}>
                        <FormattedMessage id={"admin_email"} />
                    </Typography>
                    <input
                        name="email"
                        type="text"
                        id="email"
                        placeholder={intl.formatMessage({id: "msg.insert_admin_email"})}
                        autoComplete={"off"}
                        value={previewConfigs.AdminEmail || ""}
                        className={classes.inputStyle}
                        onChange={e => orgStore.changeAdminEmail(e.target.value)}
                    />
                    {/* 메인 이미지 설정*/}
                    <Typography className={classes.textStyle} style={{marginBottom:0}}><FormattedMessage id="main_image_setting"/></Typography>
                    <Grid container style={{display:'flex', alignItems:'flex-start',justifyContent:'space-between', flexGrow: 1}}>
                        { isTabletMode ?
                                <Grid item xs={12} style={{marginTop:10}} >
                                    <DropzoneImage/>
                                    <Box style={{paddingRight: 20}}>
                                        <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.main_image_guide_1"/></Typography>
                                        <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.main_image_guide_2"/></Typography>
                                    </Box>
                                </Grid>
                            :
                            <>
                            <Grid item xs={6} style={{marginTop:30}}>
                                <DropzoneImage/>
                                <Box style={{paddingRight: 20}}>
                                    <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.main_image_guide_1"/></Typography>
                                    <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.main_image_guide_2"/></Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={6}>
                                <Typography className={classes.previewText}><FormattedMessage id="preview"/></Typography>
                                {/* 미리보기 Box*/}
                                <Box className={classes.previewBox}>
                                    {
                                        isOrganizationDataLoading === true
                                            ? <SkeletonPreview/>
                                            : <TabletBrowserPreview />
                                    }

                                </Box>
                                <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.preview_guide"/></Typography>
                            </Grid>
                            </>
                        }
                    </Grid>
                    <Grid container>
                        {/* 문구 */}
                        <Grid item xs={6}>
                            <Typography className={classes.textStyle}><FormattedMessage id="text"/></Typography>
                            <TabsText isMobile={this.props.isMobile} isTablet={this.props.isTablet} />
                        </Grid>
                        {/* 문구 색상 */}
                        <Grid item xs={6}>
                            <Typography className={classes.textStyle}><FormattedMessage id="text_color"/></Typography>
                            <TabsTextColor
                                mainColor={previewConfigs.MainTextColor}
                                subColor={previewConfigs.SubTextColor}
                                selectMainColor={orgStore.changeOrgMainTextColor}
                                selectSubColor={orgStore.changeOrgSubTextColor}
                                mainColorSelection={mainTextColorSelection}
                                subColorSelection={subTextColorSelection}
                                isTablet={this.props.isTablet}
                            />
                        </Grid>
                    </Grid>
                    {/* 로고 및 파비콘 */}
                    <Typography className={classes.textStyle}><FormattedMessage id="logo_and_favicon"/></Typography>
                    <TabsLogo isTablet={this.props.isTablet}/>
                    {isTabletMode &&
                        <Grid item xs={12} style={{marginTop: 20}}>
                            <Typography className={classes.previewText}><FormattedMessage id="preview"/></Typography>
                            {/* 미리보기 Box*/}
                            <Box className={classes.previewBox}>
                                {
                                    isOrganizationDataLoading === true
                                        ? <SkeletonPreview/>
                                        : <TabletBrowserPreview />
                                }

                            </Box>
                            <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.preview_guide"/></Typography>
                        </Grid>
                    }
                    {/* 컬러 테마 */}
                    <Typography className={classes.textStyle}><FormattedMessage id="color_theme"/></Typography>
                    <Grid container style={{display:'flex', alignItems:'flex-start',justifyContent:'space-between', flexGrow: 1}}>
                        {isTabletMode ?
                            <>
                                <Grid item xs={12}>
                                    <TabsColor
                                        mainColor={previewConfigs.MainColor}
                                        subColor={previewConfigs.SubColor}
                                        selectMainColor={orgStore.changeOrgMainColor}
                                        selectSubColor={orgStore.changeOrgSubColor}
                                        mainColorSelection={mainColorSelection}
                                        subColorSelection={subColorSelection}
                                        isTablet={this.props.isTablet}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{marginTop: 20}}>
                                    <Typography className={classes.previewText}><FormattedMessage id="preview"/></Typography>
                                    {/* 미리보기 Box*/}
                                    <Box>
                                        {
                                            isOrganizationDataLoading === true
                                                ? <SkeletonPreview/>
                                                : <TabletTimelinePreview />
                                        }
                                    </Box>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={6}>
                                    <TabsColor
                                        mainColor={previewConfigs.MainColor}
                                        subColor={previewConfigs.SubColor}
                                        selectMainColor={orgStore.changeOrgMainColor}
                                        selectSubColor={orgStore.changeOrgSubColor}
                                        mainColorSelection={mainColorSelection}
                                        subColorSelection={subColorSelection}
                                        isTablet={this.props.isTablet}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography className={classes.previewText}><FormattedMessage id="preview"/></Typography>
                                    {/* 미리보기 Box*/}
                                    <Box>
                                        {
                                            isOrganizationDataLoading === true
                                                ? <SkeletonPreview/>
                                                : <TabletTimelinePreview previewConfigs={previewConfigs}
                                                                         previewImages={previewImages}/>
                                        }
                                    </Box>
                                </Grid>
                            </>
                        }
                    </Grid>

                    <Box display="flex" justifyContent="flex-end" style={{margin:'50px auto 0'}}>
                        <Button className={classes.btnStyle1} onClick={this.handleCancelBtn}><FormattedMessage id="cancel"/></Button>
                        <Button
                            className={classes.btnStyle}
                            disabled={mode}
                            onClick={this.handleSaveBtn}
                        >
                            <FormattedMessage id="save"/>
                        </Button>
                    </Box>
                </Grid>
                <MobileConfirmDialog open={hasError} title={intl.formatMessage({id: "error_message"})} msg={errorMessage} changeDialogOpen={changeHasError} />
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(TabletOrganizationInformation)));
