import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Badge,
    Box,
    Drawer,
    IconButton, Tooltip,
    Typography
} from "@material-ui/core";
import {ReactComponent as MobileQuestion} from "../../common/images/MobileQuestion.svg";
import {ReactComponent as MobileClose} from "../../common/images/MobileClose.svg";
import {DATE_UTIL} from "../../common/util/date.util";
import {ROOM_TYPE, ToolTip_Close_Time} from "../../stores/NoticeStore";
import { _ } from "../../stores/NoticeStore";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        "& .MuiBadge-colorSecondary":{
            border:'1px solid #fff',
            background:'#fb4a59',
        },
        "& .MuiBadge-anchorOriginTopRightCircle":{
            top:'21%',
            right:'21%'
        }
    },
    list: {
        width: 250,
    },
    titleBox:{
        background:'#f0f1f1',
        padding:'8px 10px'
    },
    titleText:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:600,
        marginLeft:5
    },
    listBox:{
        paddingTop:30
    },
    iconBtn:{
        padding:5
    },
    listText:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.0rem',
        borderBottom:'1px dashed #a3a8af',
        paddingBottom:5,
        marginBottom:10,
        textAlign:'center'
    },
    listText1:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.1rem',
        textAlign:'center'
    },
});

class MobileNotice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            right: false,
            tooltipOpen : false,
            readNoticeList: [],
        }
    }

    checkedFirst = (prev, next) => {
        if (!prev || !next) {
            return true;
        } else if (next.length === 0) {
            return true;
        } else {
            return;
        }
    }

    handleTooltipClose = () => {
        this.setState({tooltipOpen : false});
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps && this.props.roomNoticeList.length > 0) {
            if (!_.isEqualWith(prevProps.roomNoticeList, this.props.roomNoticeList, this.checkedFirst)){
                if (!this.state.right) {
                    this.setState({tooltipOpen : true});
                    setTimeout(this.handleTooltipClose, ToolTip_Close_Time);
                }
                const roomList = this.props.roomNoticeList.map(notice => notice.roomId);
                this.insertReadNoticeList(this.state.right, roomList);
            } else {
                return;
            }
        } else {
            return;
        }
    }

    componentWillUnmount() {
        this.setState({tooltipOpen : false, readNoticeList : []});
    }

    insertReadNoticeList = (isRead, roomIdList) => {
        if (isRead && roomIdList.length > 0) {
            this.setState({right: isRead, readNoticeList : roomIdList});
        } else {
            return;
        }
    }

    updateReadNoticeState = (isRead, readNoticeList) => {
        const {userId, noticeStateUpdate} = this.props;
        if(!isRead && readNoticeList.length > 0) noticeStateUpdate(userId, readNoticeList);
        else return;
    }

    toggleDrawer = (side, open, noticeList, callback) => () => {
        if(open) {
            this.setState({tooltipOpen : false});
        }
        this.setState({
            [side]: open,
        });
        callback(open, noticeList);
    };

    printDatetime = (newDate) => {
        const datetime = DATE_UTIL.convertTimeZoneToUTC(newDate);
        return DATE_UTIL.getTimeZoneDate(datetime, 'YYYY-M-DD h:mm A');
    }

    render() {
        const { classes, intl, roomNoticeList, isTablet } = this.props;
        const latestNoticeOwnerName = roomNoticeList.length > 0 ? roomNoticeList[0].ownerName : "";
        const latestRoomType = roomNoticeList.length > 0 ? intl.formatMessage({id: ROOM_TYPE[roomNoticeList[0].roomType]}) : "";
        const noticeRoomIdList = roomNoticeList.map(notice => notice.roomId);
        const sideList = (
            <div className={classes.list}>
                <Box display='flex' alignItems='center' justifyContent='space-between' className={classes.titleBox}>
                    <Typography className={classes.titleText}>
                        <FormattedMessage id={"notification"} />
                    </Typography>
                    <IconButton onClick={this.toggleDrawer('right', false, this.state.readNoticeList, this.updateReadNoticeState)}>
                        <MobileClose style={{width:17,height:17}} />
                    </IconButton>
                </Box>
                {
                    roomNoticeList.length > 0
                    ? roomNoticeList.map((obj, index) => {
                        return (
                            <Box className={classes.listBox} key={index}>
                                <Typography className={classes.listText}>{this.printDatetime(obj.createdDatetime)}</Typography>
                                <Typography className={classes.listText1}>
                                    <FormattedMessage
                                        id={"msg.has_new_notification"}
                                        values={{name : obj.ownerName, type : <span style={{fontWeight:800, margin:'0 5px'}}>{intl.formatMessage({id: ROOM_TYPE[obj.roomType]})}</span>}}
                                    />
                                </Typography>
                            </Box>
                        )
                        })
                    :  <Box className={classes.listBox}>
                            <Typography className={classes.listText1}>
                                <FormattedMessage id={"msg.no_new_notification"} />
                            </Typography>
                       </Box>

                }
            </div>
        );

        return (
            <div className={classes.root}>
                <Box style={{padding:'0 5px'}}>
                    <Tooltip
                        PopperProps={{disablePortal: true}}
                        open={this.state.tooltipOpen}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={intl.formatMessage({id:'msg.has_new_notification'}, {name : latestNoticeOwnerName, type : latestRoomType})}
                    >
                        <Badge
                            color="secondary"
                            overlap="circle"
                            badgeContent=" "
                            variant="dot"
                            className={classes.bellBox}
                            onClick={this.toggleDrawer('right', true, noticeRoomIdList, this.insertReadNoticeList)}
                            invisible={roomNoticeList.length === 0}
                        >
                            <MobileQuestion style={isTablet ? {width:28, height:28} : {width:17, height:17, marginTop:-3}}/>
                        </Badge>
                    </Tooltip>
                </Box>

                <Drawer anchor="right" open={this.state.right} onClose={this.toggleDrawer('right', false, this.state.readNoticeList, this.updateReadNoticeState)}>
                    <div>
                        {sideList}
                    </div>
                </Drawer>
            </div>
        )
    }
}

export default withStyles(style)(injectIntl(MobileNotice));