import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Input,
    Typography,
    IconButton,
    Button,
    FormControlLabel,
    Checkbox,
    Popover,
    MenuList,
    MenuItem,
    Select,
    FormControl,
    BottomNavigationAction,
    BottomNavigation
} from "@material-ui/core";
import {ReactComponent as SurveyImgIcon} from "../../../../common/images/SurveyImgIcon.svg";
import {ReactComponent as SurveyCloseIcon} from "../../../../common/images/SurveyCloseIcon.svg";
import {ReactComponent as MoreIcon} from "../../../../common/images/MoreIcon.svg";
import {ReactComponent as SurveyDragIcon} from "../../../../common/images/SurveyDragIcon.svg";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";
import {SurveyItemType} from "../../../../stores/SurveyStore";
import {ToastsStore} from "react-toasts";

const style = theme => ({
    root: {
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width: '100%',
        marginBottom: 40
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        fontWeight: 500,
    },
    contentsBox: {
        width: '100%',
        border: '1px solid #c4c9de',
        borderRadius: 8,
        padding: '11px 20px 23px',
        "&:hover": {
            border: '1px solid #0097ff !important',
            borderTop: '4px solid #0097ff !important',
        },
    },
    buttonBox: {
        "& .MuiBottomNavigation-root": {
            justifyContent: 'flex-start',
            flexWrap: 'wrap',
            height: 'inherit'
        },
        "& .MuiBottomNavigationAction-root.Mui-selected": {
            border: '1px solid #0097ff',
            background: '#d3edff',
            paddingTop: 0
        },
        "& .MuiBottomNavigationAction-label.Mui-selected": {
            fontFamily: 'NanumSquareRoundOTF',
            color: '#0097ff',
            fontWeight: 600,
            fontSize: 14,
        },
        "& .MuiBottomNavigationAction-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            transaction: 'none',
            transitionDelay: 0,
        }
    },
    toggleButtonStyle: {
        maxWidth: 110,
        minWidth: 110,
        height: 40,
        padding: 0,
        background: '#d0d0d0',
        color: 'rgba(0, 0, 0, 0.6)',
        marginRight: 10,
        marginTop: 10,
        borderRadius: 50,
        border: '1px solid #d0d0d0',
        "&:hover": {
            border: '1px solid #0097ff',
            background: '#d3edff',
            color: '#0097ff',
            fontWeight: 600
        }
    },
    imgText: {
        fontSize: 12,
        fontWeight: 400,
        color: '#a3a8af',
        marginTop: 21,
        marginBottom: 15
    },
    contentsBoxIn: {
        "& .MuiInput-underline:after, .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before ": {
            borderBottom: 0
        },
    },
    inputBox: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        borderBottom: '1px solid #c4c9de',
    },
    inputBoxIn: {
        width: '100%',
        paddingLeft: 13,
        "&::placeholder": {
            color: '#a3a8af'
        }
    },
    input: {
        display: 'none',
    },
    iconButtonStyle: {
        padding: 10,
        "&:hover": {
            background: 'transparent'
        }
    },
    titleImgBox: {
        width: 241,
        height: 181,
        overflow: 'hidden',
        margin: 'auto',
    },
    imgBox: {
        width: 167,
        height: 144,
        overflow: 'hidden',
    },
    imgBoxNone: {
        width: 192,
        height: 144,
        overflow: 'hidden',
        display: 'none',
        alignItems: 'center',
    },
    addButton: {
        color: '#0097ff',
        textDecoration: 'underline',
        padding: 0,
        "&:hover": {
            background: 'transparent',
            textDecoration: 'underline',
        }
    },
    actionsBox: {
        marginTop: 30,
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#0d0d0d',
            fontWeight: 600,
        }
    },
    popoverBox: {
        "& .MuiPopover-paper": {
            borderRadius: 7,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)'
        }
    },
    menuText: {
        fontSize: 12,
        color: '#000',
        "&:hover": {
            background: '#d0d0d0'
        }
    },
    formControlBox: {
        width: '100%',
        border: 0,
        borderRadius: 5,
        "& .MuiSelect-select, .MuiSelect-select:focus": {
            background: 'transparent',
        },
        "& .MuiOutlinedInput-input": {
            padding: '10px 11px',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #b7bcd6',
        },
    },
    selectBox: {
        width: '100%',
    },
    optionStyle: {
        color: '#303030'
    },
    image: {
        display: 'flex',
        margin: 'auto',
        cursor: 'pointer'
    },
    imageWidth: {
        width: '100%',
        height:'auto'
    },
    imageHeight: {
        height: '100%',
        width:'auto'
    },
});

@inject('surveyStore')
@observer
class SurveyQuestion extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            explanationOpen: true,
            isFocused: false,
        };
    }

    handleChange = event => {
        this.props.surveyStore.changeSurveyItemRequired(this.props.surveyQuestionId, event.target.checked);
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleExplanationClick = () => {
        this.setState({explanationOpen: true});
        this.setState({
            anchorEl: null,
        });
    };

    handleExplanationClose = () => {
        this.setState({explanationOpen: false});
        this.props.surveyStore.deleteSurveyItemDescription(this.props.surveyQuestionId);
    };

    handleChangeNumber = (event, index, direction) => {
        this.props.surveyStore.changeRatingOptionNumber(event.target.value, index, direction);
    };

    handleChangeLabel = (index, direction, value) => {
        this.props.surveyStore.changeRatingOptionLabel(index, direction, value);
    };

    handleSurveyType = (event, surveyType) => {
        this.props.surveyStore.changeSurveyItemType(this.props.surveyQuestionId, surveyType);
    };

    handleSurveyTitle = (value) => {
        this.props.surveyStore.changeSurveyItemTitle(this.props.surveyQuestionId, value);
    };

    handleSurveyItemDescription = (value) => {
        this.props.surveyStore.changeSurveyItemDescription(this.props.surveyQuestionId, value);
    };

    handleChangeExampleDescription = (e, index, optionIndex) => {
        this.props.surveyStore.changeSurveyItemOptionDescription(index, optionIndex, e.target.value);
    };

    handleDeleteExampleDescription = (index, optionIndex) => {
        this.props.surveyStore.deleteSurveyItemOptionDescription(index, optionIndex);
    };

    handleItemCopy = () => {
        this.setState({
            anchorEl: null,
        });
        this.props.surveyStore.copySurveyItem(this.props.surveyQuestionId);
    };

    handleAddItemOptions = (e, index) => {
        this.props.surveyStore.addSurveyItemOptions(index);
    };

    handleAddEtcItemOptions = (e, index) => {
        const isExist = this.props.surveyStore.surveyItemDataList[index].surveyItemOption.find((item) => item.etc);
        if (!isExist) {
            this.props.surveyStore.addSurveyEtcItemOptions(index);
        } else {
            const {classes, intl} = this.props;
            ToastsStore.info(intl.formatMessage({id: "msg.cannot_insert_etc"}), 1500, classes.toasts);
        }
    };

    handleChangeTitleImage = (index, input) => {
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = event => {
                const previewImage = document.getElementById("preview-image" + index);
                previewImage.onload = (e) => {
                    this.props.surveyStore.changeSurveyItemTitleImageSize(index, e.target.naturalWidth, e.target.naturalHeight);
                }
                this.props.surveyStore.changeSurveyItemTitleImage(index, event.target.result, input.files[0]);
            }
            reader.readAsDataURL(input.files[0]);
        }
    };

    handleDeleteTitleImage = () => {
        const previewImage = document.getElementById("icon-button-file" + this.props.surveyQuestionId);
        previewImage.value = "";
        this.props.surveyStore.deleteSurveyItemTitleImage(this.props.surveyQuestionId);
    };

    handleChangeOptionImage = (input, index, optionIndex) => {
        if (input.files && input.files[0]) {
            const reader = new FileReader();
            reader.onload = event => {
                const previewOptionImage = document.getElementById("preview-item" + this.props.surveyQuestionId + "-option" + optionIndex)
                previewOptionImage.onload = (e) => {
                    this.props.surveyStore.changeSurveyItemOptionImageSize(index, optionIndex, e.target.naturalWidth, e.target.naturalHeight);
                }
                this.props.surveyStore.changeSurveyItemOptionImage(index, optionIndex, event.target.result, input.files[0]);
            }
            reader.readAsDataURL(input.files[0])
        }
    };

    handleDeleteExampleImage = (index, optionIndex) => {
        const previewImage = document.getElementById("icon-button-option" + index + "-file" + optionIndex);
        previewImage.value = "";
        this.props.surveyStore.deleteSurveyItemOptionImage(index, optionIndex);
    };

    changeOpeDeleteDialog = (value) => {
        this.setState({
            anchorEl: null,
        });
        this.props.surveyStore.changeOpeDeleteDialog(value);
    };

    render() {
        const {classes, surveyStore, intl, surveyData} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);
        const checkBox = surveyData.required;
        const description = Boolean(surveyData.contents !== "");
        // console.log("SurveyQuestion surveyStore.surveyItemDataList*", this.props.surveyQuestionId, toJS(surveyStore.surveyItemDataList[this.props.surveyQuestionId]));
        const titleInput = (
            <Box className={classes.inputBox} mt={1}>
                <Input
                    maxLength="10000"
                    value={surveyData.title}
                    placeholder={intl.formatMessage({id: "msg.insert_question_subject"})}
                    className={clsx(classes.textStyle, classes.inputBoxIn)}
                    inputProps={{
                        'aria-label': 'Description',
                    }}
                    onChange={(e) => this.handleSurveyTitle(e.target.value)}
                />

                <>
                    <input accept="image/*" className={classes.input}
                           id={`icon-button-file${this.props.surveyQuestionId}`} type="file"
                           onChange={(e) => this.handleChangeTitleImage(this.props.surveyQuestionId, e.target)}/>
                    <label htmlFor={`icon-button-file${this.props.surveyQuestionId}`}>
                        <IconButton className={classes.iconButtonStyle} aria-label="upload picture" component="span">
                            <SurveyImgIcon/>
                        </IconButton>
                    </label>
                </>
            </Box>
        );

        const explanationInput = (
            <Box className={classes.inputBox} mt={1}>
                <Input
                    maxLength="10000"
                    value={surveyData.contents}
                    placeholder={intl.formatMessage({id: "msg.insert_question_description"})}
                    className={clsx(classes.textStyle, classes.inputBoxIn)}
                    inputProps={{
                        'aria-label': 'Description',
                    }}
                    onChange={(e) => this.handleSurveyItemDescription(e.target.value)}
                />
                <IconButton className={classes.iconButtonStyle} onClick={this.handleExplanationClose}>
                    <SurveyCloseIcon/>
                </IconButton>
            </Box>
        );

        const titleImage = (
                <Box alignItems='flex-start' justifyContent='space-between' mt={2} mb={2} style={(surveyData.surveyItemImage !== undefined && surveyData.surveyItemImage.length > 0) ? {display:'flex'} : {display:'none'}}>
                    {/* 이미지 클릭 -> 이미지 변경 */}
                    {/* 이미지 가로가 241px 보다 클경우 -> img width:'100%' */}
                    {/* 이미지 세로가 181px 보다 클경우 -> img height:'100%' */}
                    {/* 이미지 가로, 세로가 241*181 보다 작을경우 -> 원본사이즈 */}
                    <Box className={classes.titleImgBox} display='flex'>
                        <input accept="image/*" className={classes.input}
                               id={`icon-button-file${this.props.surveyQuestionId}`} type="file"
                               onChange={(e) => this.handleChangeTitleImage(this.props.surveyQuestionId, e.target)}/>
                        <label htmlFor={`icon-button-file${this.props.surveyQuestionId}`} >
                            <Box className={classes.titleImgBox} display='flex'>
                            <img id={`preview-image${this.props.surveyQuestionId}`}
                                 src={(surveyData.surveyItemImage !== undefined && surveyData.surveyItemImage.length > 0) ?
                                       surveyData.surveyItemImage[0].image : undefined}
                                 alt=""
                                 className={clsx(classes.image, surveyData.surveyItemImage !== undefined && surveyData.surveyItemImage.length > 0 && (surveyData.surveyItemImage[0].width >= surveyData.surveyItemImage[0].height && surveyData.surveyItemImage[0].width > 241) && classes.imageWidth, surveyData.surveyItemImage !== undefined && surveyData.surveyItemImage.length > 0 && (surveyData.surveyItemImage[0].height >= surveyData.surveyItemImage[0].width && surveyData.surveyItemImage[0].height > 181) && classes.imageHeight)}
                                 aria-label="upload picture"/>
                            </Box>
                        </label>
                    </Box>
                    <IconButton className={classes.iconButtonStyle} onClick={this.handleDeleteTitleImage}>
                        <SurveyCloseIcon/>
                    </IconButton>
                </Box>
        );
        const exampleInputList =
            surveyData.surveyItemOption.map((option, index) => {
                return (
                    <Box key={index}>
                        <Box display='flex' alignItems='center'>
                            {this.state.isFocused &&
                            <SurveyDragIcon
                                style={{cursor: 'pointer', width: 14, height: 10, marginRight: 10, marginTop: 8}}/>
                            }

                            <Box className={classes.inputBox} mt={1}>
                                <Input
                                    onFocus={()=>this.setState({isFocused : true})}
                                    onBlur={()=>this.setState({isFocused : false})}
                                    maxLength="10000"
                                    value={option.contents}
                                    name={`surveyQuestion-${this.props.surveyQuestionId}-questionItem`}
                                    placeholder={intl.formatMessage({id: "msg.insert_question_example"})}
                                    className={clsx(classes.textStyle, classes.inputBoxIn)}
                                    inputProps={{
                                        'aria-label': 'Description',
                                    }}
                                    onChange={(e) => this.handleChangeExampleDescription(e, this.props.surveyQuestionId, index)}
                                />

                                {/* 이미지 선택 -> <SurveyImgIcon/> display:'none' , 이미지 <SurveyCloseIcon/> 클릭 -> <SurveyImgIcon/> 보여짐 */}
                                {/* 첫번째 보기는 <SurveyCloseIcon/> x , 두번째 보기부터 아이콘 생성 */}
                                <Box display='flex' >
                                    <input accept="image/*" className={classes.input}
                                           id={`icon-button-option${this.props.surveyQuestionId}-file${index}`}
                                           type="file"
                                           onChange={(e) => this.handleChangeOptionImage(e.target, this.props.surveyQuestionId, index)}/>
                                    <label htmlFor={`icon-button-option${this.props.surveyQuestionId}-file${index}`}>
                                        <IconButton className={classes.iconButtonStyle} aria-label="upload picture"
                                                    component="span">
                                            <SurveyImgIcon/>
                                        </IconButton>
                                    </label>
                                    {surveyData.surveyItemOption.length > 1 ?
                                        <IconButton className={classes.iconButtonStyle}
                                                    onClick={() => this.handleDeleteExampleDescription(this.props.surveyQuestionId, index)}>
                                            <SurveyCloseIcon/>
                                        </IconButton>
                                        :
                                        ""
                                    }
                                </Box>
                            </Box>
                        </Box>
                        <Box display='flex' alignItems='flex-start' justifyContent='space-between'  mt={2} mb={2}
                             className={option.surveyItemOptionImage !== undefined && option.surveyItemOptionImage.length > 0 ? '' : classes.imgBoxNone}>
                            {/* 이미지 클릭 -> 이미지 변경 */}
                            {/* 이미지 가로가 192px 보다 클경우 -> img width:'100%' */}
                            {/* 이미지 세로가 144px 보다 클경우 -> img height:'100%' */}
                            {/* 이미지 가로, 세로가 241*181 보다 작을경우 -> 원본사이즈 */}
                            <Box className={classes.imgBox} >
                                <input accept="image/*" className={classes.input}
                                       id={`icon-button-option${this.props.surveyQuestionId}-file${index}`}
                                       type="file"
                                       onChange={(e) => this.handleChangeOptionImage(e.target, this.props.surveyQuestionId, index)}/>
                                <label htmlFor={`icon-button-option${this.props.surveyQuestionId}-file${index}`}>
                                    <Box className={classes.imgBox} display='flex'>
                                    <img id={`preview-item${this.props.surveyQuestionId}-option${index}`}
                                         src={option.surveyItemOptionImage !== undefined && option.surveyItemOptionImage.length > 0 ? option.surveyItemOptionImage[0].image : undefined}
                                         alt=""
                                         className={clsx(classes.image, option.surveyItemOptionImage !== undefined && option.surveyItemOptionImage.length > 0 &&(option.surveyItemOptionImage[0].width >= option.surveyItemOptionImage[0].height && option.surveyItemOptionImage[0].width > 167) && classes.imageWidth, option.surveyItemOptionImage !== undefined && option.surveyItemOptionImage.length > 0 && (option.surveyItemOptionImage[0].height >= option.surveyItemOptionImage[0].width && option.surveyItemOptionImage[0].height > 144) && classes.imageHeight)}
                                         aria-label="upload picture"/>
                                    </Box>
                                </label>
                            </Box>
                            <IconButton className={classes.iconButtonStyle}
                                        onClick={() => this.handleDeleteExampleImage(this.props.surveyQuestionId, index)}>
                                <SurveyCloseIcon/>
                            </IconButton>
                        </Box>
                    </Box>
                )
            });

        const surveyChoice = (
            <Box className={classes.contentsBoxIn}>
                {titleInput}
                {(description || this.state.explanationOpen) && <>{explanationInput}</>}
                {titleImage}

                <DragDropContext
                    onDragEnd={result => {
                        if (result.type !== `ExampleInput-${this.props.surveyQuestionId}`) {
                            return;
                        } else if (!result.destination) {
                            return;
                        } else {
                            const index = parseInt(result.draggableId.split('-')[1]);
                            if (!surveyData.surveyItemOption[index].etc) {
                                if (surveyData.surveyItemOption[result.destination.index].etc) {
                                    const {classes, intl} = this.props;
                                    ToastsStore.info(intl.formatMessage({id: "msg.cannot_move_etc"}), 1500, classes.toasts);
                                } else {
                                    const exampleInput = exampleInputList.splice(index, 1);
                                    exampleInputList.splice(result.destination.index, 0, exampleInput);
                                    surveyStore.changeItemOptionIndex(this.props.surveyQuestionId, index, result.destination.index);
                                }
                            } else {
                                const {classes, intl} = this.props;
                                ToastsStore.info(intl.formatMessage({id: "msg.cannot_move_etc"}), 1500, classes.toasts);
                            }
                        }
                    }}
                >
                    <Droppable
                        droppableId={`exampleInputDroppable-${this.props.surveyQuestionId}`}
                        type={`ExampleInput-${this.props.surveyQuestionId}`}
                    >
                        {(provided, snapshot) => (
                            <div
                                ref={provided.innerRef}
                                style={{backgroundColor: snapshot.isDraggingOver ? '#fff' : '#fff'}}
                            >
                                {exampleInputList.map((exampleInput, i) => (
                                    <Draggable
                                        key={`exampleInputDraggableKey-${i}`}
                                        draggableId={`exampleInputDraggableId-${i}`}
                                        index={i}
                                    >
                                        {(p, s) => (
                                            <div
                                                ref={p.innerRef}
                                                {...p.draggableProps}
                                                style={
                                                    p.draggableProps.style
                                                }
                                            >
                                                <span {...p.dragHandleProps}>
                                                    {exampleInput}
                                                </span>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <Box display='flex' alignItems='center' mt={4} mb={5}>
                    <Button className={clsx(classes.textStyle, classes.addButton)}
                            onClick={(e) => this.handleAddItemOptions(e, this.props.surveyQuestionId)}>+ <FormattedMessage
                        id="add_example"/></Button>
                    <Typography className={classes.textStyle}
                                style={{color: '#000', padding: '0 10px'}}> <FormattedMessage id="or"/> </Typography>
                    <Button className={clsx(classes.textStyle, classes.addButton)}
                            onClick={(e) => this.handleAddEtcItemOptions(e, this.props.surveyQuestionId)}>+ <FormattedMessage
                        id="add_example_etc"/></Button>
                </Box>
            </Box>
        );

        const surveyActions = (
            <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.actionsBox}>
                <FormControlLabel
                    control={<Checkbox value={checkBox} checked={checkBox} color="primary"
                                       onClick={(e) => this.handleChange(e)}/>}
                    label={intl.formatMessage({id: "required"})}/>
                <IconButton style={{padding: 5}}
                            aria-owns={open ? `simple-popper${this.props.surveyQuestionId}` : undefined}
                            onClick={this.handleClick}>
                    <MoreIcon style={{width: 25, height: 25}}/>
                </IconButton>
                <Popover
                    id={`simple-popper${this.props.surveyQuestionId}`}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    className={classes.popoverBox}
                >
                    <MenuList>
                        <MenuItem className={clsx(classes.textStyle, classes.menuText)}
                                  onClick={this.handleExplanationClick}><FormattedMessage id="descriptions"/></MenuItem>
                        <MenuItem className={clsx(classes.textStyle, classes.menuText)}
                                  onClick={this.handleItemCopy}><FormattedMessage id="copy"/></MenuItem>
                        <MenuItem className={clsx(classes.textStyle, classes.menuText)}
                                  onClick={() => this.changeOpeDeleteDialog(true)}><FormattedMessage
                            id="delete"/></MenuItem>

                    </MenuList>
                </Popover>
            </Box>
        );

        return (
            <>
                <Box className={classes.buttonBox} mt={3}>
                    <BottomNavigation value={surveyData.type}
                                      showLabels onChange={this.handleSurveyType}>
                        <BottomNavigationAction value={SurveyItemType.SingleChoice}
                                                label={intl.formatMessage({id: "single_choice"})}
                                                className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>
                        <BottomNavigationAction value={SurveyItemType.MultipleChoice}
                                                label={intl.formatMessage({id: "multiple_choice"})}
                                                className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>
                        <BottomNavigationAction value={SurveyItemType.ShortAnswer}
                                                label={intl.formatMessage({id: "short_answer"})}
                                                className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>
                        <BottomNavigationAction value={SurveyItemType.Rating}
                                                label={intl.formatMessage({id: "score_scale"})}
                                                className={clsx(classes.textStyle, classes.toggleButtonStyle)}
                                                style={{marginRight: 0}}/>
                    </BottomNavigation>
                </Box>

                <Typography className={clsx(classes.textStyle, classes.imgText)}><FormattedMessage
                    id="msg.survey_image_guide"/></Typography>

                {surveyData.type === SurveyItemType.SingleChoice &&
                <>
                    {surveyChoice}
                    {surveyActions}
                </>
                }

                {surveyData.type === SurveyItemType.MultipleChoice &&
                <>
                    {surveyChoice}
                    {surveyActions}
                </>
                }

                {surveyData.type === SurveyItemType.ShortAnswer &&
                <>
                    <Box className={classes.contentsBoxIn}>
                        {titleInput}

                        {this.state.explanationOpen &&
                        <>
                            {explanationInput}
                        </>
                        }

                        {titleImage}
                    </Box>
                    {surveyActions}
                </>
                }

                {surveyData.type === SurveyItemType.Rating &&
                <>
                    <Box className={classes.contentsBoxIn}>
                        {titleInput}
                        {this.state.explanationOpen &&
                        <>
                            {explanationInput}
                        </>
                        }

                        {titleImage}

                        <Box display='flex' alignItems='center'>
                            <FormControl variant={'outlined'} className={classes.formControlBox}>
                                <Select
                                    native
                                    value={surveyData.surveyItemOption[0].number}
                                    onChange={(event) => this.handleChangeNumber(event, this.props.surveyQuestionId, 0)}
                                    className={clsx(classes.textStyle, classes.selectBox)}

                                >
                                    <option value={0} className={clsx(classes.textStyle, classes.optionStyle)}>0
                                    </option>
                                    <option value={1} className={clsx(classes.textStyle, classes.optionStyle)}>1
                                    </option>

                                </Select>
                            </FormControl>

                            <Typography className={classes.textStyle} style={{margin: '0 25px'}}> ~ </Typography>

                            <FormControl variant={'outlined'} className={classes.formControlBox}>
                                <Select
                                    native
                                    value={surveyData.surveyItemOption[surveyData.surveyItemOption.length - 1].number}
                                    onChange={(event) => this.handleChangeNumber(event, this.props.surveyQuestionId, surveyData.surveyItemOption.length - 1)}
                                    className={clsx(classes.textStyle, classes.selectBox)}

                                >
                                    <option value={2} className={clsx(classes.textStyle, classes.optionStyle)}>2
                                    </option>
                                    <option value={3} className={clsx(classes.textStyle, classes.optionStyle)}>3
                                    </option>
                                    <option value={4} className={clsx(classes.textStyle, classes.optionStyle)}>4
                                    </option>
                                    <option value={5} className={clsx(classes.textStyle, classes.optionStyle)}>5
                                    </option>
                                    <option value={6} className={clsx(classes.textStyle, classes.optionStyle)}>6
                                    </option>
                                    <option value={7} className={clsx(classes.textStyle, classes.optionStyle)}>7
                                    </option>
                                    <option value={8} className={clsx(classes.textStyle, classes.optionStyle)}>8
                                    </option>
                                    <option value={9} className={clsx(classes.textStyle, classes.optionStyle)}>9
                                    </option>
                                    <option value={10} className={clsx(classes.textStyle, classes.optionStyle)}>10
                                    </option>
                                </Select>
                            </FormControl>

                        </Box>

                        <Box className={classes.inputBox} mt={1}>
                            <Input
                                value={surveyData.surveyItemOption[0].contents}
                                placeholder={intl.formatMessage({id: "left_label"})}
                                className={clsx(classes.textStyle, classes.inputBoxIn)}
                                onChange={(e) => this.handleChangeLabel(this.props.surveyQuestionId, 0, e.target.value)}
                                inputProps={{
                                    'aria-label': 'Description',
                                }}
                            />
                        </Box>
                        <Box className={classes.inputBox} mt={1}>
                            <Input
                                value={surveyData.surveyItemOption[surveyData.surveyItemOption.length - 1].contents}
                                placeholder={intl.formatMessage({id: "right_label"})}
                                className={clsx(classes.textStyle, classes.inputBoxIn)}
                                onChange={(e) => this.handleChangeLabel(this.props.surveyQuestionId, surveyData.surveyItemOption.length - 1, e.target.value)}
                                inputProps={{
                                    'aria-label': 'Description',
                                }}
                            />
                        </Box>
                    </Box>
                    {surveyActions}
                </>
                }
            </>
        );
    }
}

export default withStyles(style)(injectIntl(SurveyQuestion));