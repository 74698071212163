import React, {useEffect} from 'react';
import {
    Avatar,
    Box,
    Card,
    CardContent,
    IconButton,
    InputBase,
    Paper,
    Typography,
    Grid
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {makeStyles} from "@material-ui/core/styles";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import RefreshIcon from '@material-ui/icons/Refresh';
import {deepPurple} from "@material-ui/core/colors";
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
    root:{
        "& .MuiPaper-root":{
            boxShadow:'none'
        }
    },
    topBox: {
        background:'#d0d0d0',
        padding:'5px 10px 0',
    },
    iconBox: {
        marginTop:-5,
        display:'flex'
    },
    previewBox:{
        width:'100%',
        height:'100%',
        background:'#666',
    },
    imgPaper:{
        maxWidth:373,
        height:218,
        background:'#666',
        margin:'7px 0'
    },
    small: {
        width: 10,
        height: 10,
        marginRight:5
    },
    serviceTitle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:8,
        color:'#0d0d0d',
    },
    textField: {
        fontFamily:'NanumSquareRoundOTF',
        fontSize:10,
        color:'#0d0d0d',
        padding:'0 5px',
        borderRadius: 20,
        background:'#d0d0d0',
        width:'100%'
    },
    tabs : {
        borderRadius: '3px 3px 0 0 ',
        background:'#ffffff',
        padding:'3px 5px 0',
    },
    purple: {
        width: 15,
        height: 15,
        fontSize:10,
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
    },
    logoBar : {
        flexGrow: 1,
        width : '100%',
        background : '#ffffff',
        padding:'0 20px',
        "& .MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorPrimary.MuiPaper-elevation4" :{
            background : '#ffffff',
            boxShadow : '0 0 0 0 rgba(0, 0, 0, 0)',
        },
        "& .MuiPaper-elevation4" : {
            boxShadow : '0 0 0 0 rgba(0, 0, 0, 0)',
        },
        borderTop: '1px solid #d0d0d0',
    },
    card: {
        boxShadow: 'none',
        position: 'relative',
        width:'100%',
        height:'auto',
        overflow:'hidden',
        '& .MuiCardContent-root:last-child' : {
            paddingBottom : 0,
        },
    },
    image:{
        width:'100%',
        maxWidth:'100%',
        height : 'auto',
        minHeight : '315px',
        maxHeight : '460px',
    },
    logoBox:{
        padding:5,
        "&:hover":{
            background:'transparent'
        }
    },
    logoBoxIn:{
        background:'#d0d0d0',
        padding:0
    },
    content: {
        [theme.breakpoints.up('md')]: {
            top:30,
            left:20
        },
        [theme.breakpoints.up('sm')]: {
            top:50,
            left:20
        },
        position: 'absolute',
        // position: 'sticky',
        zIndex: 2,
        top:10,
        // bottom: 200,
        width: '100%',
        whiteSpace : "pre-line",
    },
    contentText:{
        fontSize:18,
        fontFamily:'NanumSquareRoundOTF',
        fontWeight:800,
        marginBottom:10
    },
    contentText1:{
        fontSize:10,
        fontFamily:'NanumSquareRoundOTF',
        fontWeight:500,
    }
}));


function TabletBrowserPreview(props) {
    const classes = useStyles();
    const { setPreviewImages, previewConfigs, previewImages } = props.orgStore;

    useEffect(() => {
        if(Object.values(previewImages).length === 0) setPreviewImages();
        else return null;
    },[previewImages, setPreviewImages]);

    return (
        <React.Fragment>
                    <Box className={classes.root}>
                        <Paper square>
                            <Box display={'flex'} alignItems={'center'} className={classes.topBox}>
                               <Box className={classes.iconBox} style={{marginRight:10}}>
                                   <FiberManualRecordIcon style={{color : '#e03c3c', fontSize:10}}/>
                                   <FiberManualRecordIcon style={{color : '#ffd542', fontSize:10}}/>
                                   <FiberManualRecordIcon style={{color : '#0dc268', fontSize:10}}/>
                               </Box>
                               <Box className={classes.tabs} display={'flex'} alignItems={'center'}>
                                    <Avatar
                                        variant={"rounded"}
                                        src={previewImages.Favicon}
                                        className={classes.small}
                                    />
                                    <Typography variant={'caption'} display={'block'} className={classes.serviceTitle}>{previewConfigs.OrganizationName}</Typography>
                                </Box>
                            </Box>
                            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} style={{padding:5, width:'100%'}}>
                                <Grid container style={{display:'flex', alignItems:'center', marginRight:10}}>
                                    <Grid item xs={2} className={classes.iconBox} >
                                        <ArrowBackIcon style={{fontSize:13}}/>
                                        <ArrowForwardIcon style={{fontSize:13}}/>
                                        <RefreshIcon style={{fontSize:13}}/>
                                    </Grid>
                                    <Grid item xs={10} style={{width :'100%'}}>
                                        <InputBase className={classes.textField} value={`   ${previewConfigs.WebviewURL}`}/>
                                    </Grid>
                                </Grid>

                                <Box display={'flex'} alignItems={'center'}>
                                    <Avatar variant={"circular"} className={classes.purple}>t</Avatar>
                                    <MoreVertIcon style={{fontSize:13}}/>
                                </Box>
                            </Box>
                            <Box className={classes.logoBar}>
                                <IconButton edge={'start'} color={'inherit'} className={classes.logoBox}>
                                    {
                                        previewImages.Logo
                                        ? <img src={previewImages.Logo} alt={'Logo'} variant={'square'} style={{width : 'auto', height: 'auto', maxHeight : 15, maxWidth : 85}} />
                                        :   <Box className={classes.logoBoxIn}>
                                                <Typography style={{fontSize:10}}>Logo</Typography>
                                            </Box>
                                    }


                                </IconButton>
                            </Box>
                            <Box>
                                <Card className={classes.card}>
                                    {
                                        previewImages.Main &&
                                        <img className={classes.image} src={previewImages.Main} alt={'Main'} />

                                    }
                                   <CardContent className={classes.content}>
                                            <Typography component={"p"} className={classes.contentText} style={previewConfigs.MainTextColor ? {color: previewConfigs.MainTextColor} : {color: '#fff'}}>
                                                {
                                                    previewConfigs.MainText
                                                        ? previewConfigs.MainText.split('\n').map((line, index) => {
                                                                return (
                                                                    <span key={index} style={{wordBreak : "break-all"}}>
                                                                        {line}
                                                                        <br/>
                                                                    </span>
                                                                )
                                                            })
                                                        : ""
                                                }
                                            </Typography>
                                            <Typography component={"p"} className={classes.contentText1} style={previewConfigs.SubTextColor ? {color: previewConfigs.SubTextColor} : {color: '#fff'}}>
                                                {
                                                    previewConfigs.SubText
                                                        ? previewConfigs.SubText.split('\n').map((line, index) => {
                                                                return (
                                                                    <span key={index} style={{wordBreak : "break-all"}}>
                                                                        {line}
                                                                        <br/>
                                                                    </span>
                                                                )
                                                            })
                                                        : ""
                                                }
                                            </Typography>
                                   </CardContent>
                                </Card>
                            </Box>
                        </Paper>
                    </Box>
        </React.Fragment>
    )
}

export default inject('orgStore')(observer(TabletBrowserPreview));