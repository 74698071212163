import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
    Button,
    Dialog,
    Slide,
    IconButton,
    Typography,
    Box
} from "@material-ui/core";
import {ReactComponent as EyeMobile} from "../../common/images/EyeMobile.svg";
import {ReactComponent as MarkQuestion} from "../../common/images/MarkQuestion.svg";
import {ReactComponent as MobileClose} from "../../common/images/MobileClose.svg";
import {inject, observer} from "mobx-react";
import MobileConfirmDialog from "../../common/MobileConfirmDialog";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonText: {
        textDecoration: 'underline',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0097ff',
        textAlign: 'left',
        padding: 0,
        minWidth: 0,
        "&:hover": {
            background: 'transparent',
            textDecoration: 'underline'
        }
    },
    dialogTitleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px'
    },
    iconBtn:{
        padding:5
    },
    titleText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    saveBtn:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        "&:hover":{
            background:'transparent'
        }
    },
    textStyle1:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#000',
        fontWeight:600
    },
    inputStyle: {
        width:'100%',
        padding:8,
        borderRadius: 4,
        border: '1px solid #b7bcd6',
        background: '#fff',
        "&::placeholder":{
            fontSize:'1.2rem',
            color:'#aeb3b9'
        },
        "&:focus":{
            outline: 'none !important'
        }

    },
    inputErrorStyle: {
        width:'100%',
        padding:8,
        borderRadius: 4,
        border: '1px solid #ff0000',
        background: '#fff',
        "&::placeholder":{
            fontSize:'1.2rem',
            color:'#aeb3b9'
        },
        "&:focus":{
            outline: 'none !important'
        }
    },
    inputText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.8rem',
        color: '#a3a8af',
        marginRight:4,
        marginLeft: 5
    }
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref}/>
));

// const FullscreenTransition = React.forwardRef((props, ref) => (
//     <Slide direction={"up"} {...props} ref={ref} />
// ));

// const FullscreenExpansion = React.forwardRef((props, ref) => (
//     <Slide direction={"up"} {...props} ref={ref} />
// ));



@inject('userStore', 'authStore')
@observer
class MobileChangePassword extends Component {
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.props.userStore.dialogState();
        this.props.userStore.resetInfo();
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    handleChangePassword = () => {
        const {intl} = this.props;
        this.props.userStore.changePassword(intl, this.props.authStore.loginUser,
        () => this.handleClose()
        );
    }
    handleChangeInputState = (id) => {
        if (document.getElementById(`${id}`).getAttribute('type')!=='text'){
            document.getElementById(`${id}`).setAttribute('type', 'text');
        }else{
            document.getElementById(`${id}`).setAttribute('type', 'password');
        }
    }
    render() {
        const {classes, intl, userStore} = this.props;
        return (
            <div className={classes.root}>
                <Button
                    onClick={this.handleClickOpen}
                    className={classes.buttonText}
                >
                    <FormattedMessage id="change"/>
                </Button>
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.dialogTitleBox}>
                        <Box display='flex' alignItems='center'>
                            <IconButton onClick={this.handleClose} aria-label="Close" className={classes.iconBtn}>
                                <MobileClose/>
                            </IconButton>
                            <Typography className={classes.titleText}><FormattedMessage id="change_password"/></Typography>
                        </Box>
                        <Button className={classes.saveBtn} onClick={this.handleChangePassword}
                                disabled={!userStore.isComplete || userStore.getIsLoading} tabIndex={4}><FormattedMessage id="save"/></Button>
                    </Box>
                    <Box style={{padding:'40px 20px'}}>
                        <Box pb={4}>
                            <Typography className={classes.textStyle1}><FormattedMessage id="current_password"/></Typography>
                            <Box display="flex" alignItems="center">
                                <form style={{width:'100%'}}>
                                    <input
                                        name="password"
                                        type="password"
                                        id="password"
                                        tabIndex={1}
                                        autoFocus={true}
                                        placeholder={intl.formatMessage({id: "msg.insert_current_password"})}
                                        className={classes.inputStyle}
                                        autoComplete={"off"}
                                        onChange={(e) => userStore.changeLoginPassword(e.target.value)}
                                    />
                                </form>
                                <IconButton id="password"
                                            onPointerDown={e => this.handleChangeInputState(e.currentTarget.id)}>
                                    <EyeMobile/>
                                </IconButton>
                            </Box>
                        </Box>

                        <Box pb={4}>
                            <Typography className={classes.textStyle1}><FormattedMessage id="new_password"/></Typography>
                            <Box display="flex" alignItems="center">
                                <form style={{width:'100%'}}>
                                    <input
                                        name="newPassword"
                                        type="password"
                                        id="newPassword"
                                        tabIndex={2}
                                        placeholder={intl.formatMessage({id: "msg.insert_new_password"})}
                                        className={userStore.isNewPassword || userStore.changeInfo.newPassword === "" ? classes.inputStyle : classes.inputErrorStyle}
                                        autoComplete={"off"}
                                        onChange={(e) => userStore.changeNewPassword(e.target.value)}
                                    />
                                </form>
                                <IconButton id="newPassword"
                                            onPointerDown={e => this.handleChangeInputState(e.currentTarget.id)}>
                                    <EyeMobile/>
                                </IconButton>
                            </Box>
                        </Box>

                        <Box pb={1}>
                            <Typography className={classes.textStyle1}><FormattedMessage id="new_password_confirmation"/></Typography>
                            <Box display="flex" alignItems="center">
                                <form style={{width:'100%'}}>
                                    <input
                                        name="againPassword"
                                        type="password"
                                        id="againPassword"
                                        tabIndex={3}
                                        placeholder={intl.formatMessage({id: "msg.insert_new_password_confirmation"})}
                                        className={userStore.isPasswordConfirm ? classes.inputStyle : classes.inputErrorStyle}
                                        autoComplete={"off"}
                                        onChange={(e) => userStore.changeNewPasswordConfirm(e.target.value)}
                                    />
                                </form>
                                <IconButton id="againPassword"
                                            onPointerDown={e => this.handleChangeInputState(e.currentTarget.id)}>
                                    <EyeMobile/>
                                </IconButton>
                            </Box>
                            <Box display="flex" alignItems='center'>
                                <Typography className={classes.inputText}><FormattedMessage id="msg.validation_password"/></Typography>
                                <MarkQuestion/>
                            </Box>
                        </Box>
                    </Box>

                </Dialog>
                <MobileConfirmDialog open={userStore.openConfirmDialog}
                               title={userStore.alertMsg}
                               msg={userStore.errMsg}
                               changeDialogOpen={userStore.changeConfirmDialogOpen}
                />
            </div>
        );
    }
}

MobileChangePassword.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(style)(injectIntl(MobileChangePassword));