import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography, Box, Grid, IconButton, Tooltip, Popper, Paper, ClickAwayListener} from "@material-ui/core";
import TeamMembersSideBar from "../../../components/TeamMembersSideBar";
import {ReactComponent as Enter} from "../../../common/images/Enter.svg";
import LinkView from "./LinkView";
import {DATE_UTIL} from "../../../common/util/date.util";
import {inject, observer} from "mobx-react";
import RoomInformation from "./RoomInformation";
import {UTIL} from "../../../common/util/common.util";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
    },
    //회의box
    conferenceBox:{
        background:'#dff8ed',
        padding:'11px 11px 14px',
        borderRadius:8,
        marginBottom:10
    },
    conferenceBoxOpacity:{
        background:'#dff8ed',
        padding:'11px 11px 14px',
        borderRadius:8,
        marginBottom:10,
        opacity: 0.5
    },
    //학습box
    learningBox:{
        background:'#eae4ff',
        padding:'11px 11px 14px',
        borderRadius:8,
        marginBottom:10
    },
    learningBoxOpacity:{
        background:'#eae4ff',
        padding:'11px 11px 14px',
        borderRadius:8,
        marginBottom:10,
        opacity: 0.5
    },
    timeText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#4f5660',
        fontWeight:800,
        marginRight:10
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:800,
        color:'#0d0d0d',
    },
    teamNameText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        fontWeight:300,
        color:'#0d0d0d',
    },
    conferenceBoxIn:{
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-end'
    },
    iconButton:{
        padding:0,
        marginRight:11,
        "&:hover":{
            background:'transparent'
        }
    },
    iconBtn:{
        padding:0,
        marginTop:10,
        "&:hover":{
            background:'transparent'
        }
    },
    learningIcon1:{
        "& path":{
            stroke:'#664cff'
        },
    },
    endBox:{
        background:'#1a5177',
        width:30,
        padding:'1px 0',
        textAlign:'center',
    },
    endText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:5,
        color:'#fff',
    },
    liveBox:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        background:'#fb4a59',
        // width:30,
        padding:'1px 5px',
        textAlign:'center',
    },
    circle:{
        width:3,
        height:3,
        background:'#fff',
        borderRadius:50,
        marginRight:3
    },
    liveText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:5,
        color:'#fff',
    },
    popper: {
        zIndex: 25,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    paper: {
        padding:0,
        maxWidth: 500,
        overflow: 'auto',
        boxShadow:'0 4px 15px rgba(0, 0, 0, 0.25)',
        borderRadius:10,
    },
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        padding:'4px 4px',
        border:'0.3px solid #000000',
        background:'#fffff5',
        fontFamily:'NanumSquareRoundOTF',
        fontSize: 11,
        color:'#000',
        borderRadius:'0',
        marginLeft:5,
        marginTop:5
    },
}))(Tooltip);

@inject('authStore', 'timelineStore', 'userLoungeStore')
@observer
class DataTimeLineItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            arrow: true,
            arrowRef: null,
            disablePortal: false,
            flip: true,
            placement: 'bottom',
        }
    }

    handleClickEnterance = (event, url) => {
        event.stopPropagation();
        url = url + '&tz=' + DATE_UTIL.getTimeZone() + '&local=' + DATE_UTIL.getLanguage();
        window.open(url);
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    render() {
        const { classes, intl, room, authStore, timelineStore, userLoungeStore } = this.props;
        const { placement, disablePortal, arrow, arrowRef } = this.state;

        const roomState = UTIL.getRoomState(authStore.loginUser.id, room);

        const team = userLoungeStore.selectedTeamId !== 0 ? (userLoungeStore.selectedTeam.id !== undefined ? userLoungeStore.selectedTeam : undefined) : userLoungeStore.teamList.find(t => t.id === room.teamId);
        const member = team !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
        const isLeader = (member !== undefined) && (member.type === 'Leader');

        const detailOpen = timelineStore.getDateTimelineOpen(room.id);
        const linkViewOpen = timelineStore.getDateTimelineLinkViewOpen(room.id);

        const boxStyle = () => {
            if (roomState.isConference) {
                if (roomState.isEnded)
                    return classes.conferenceBoxOpacity;
                else
                    return classes.conferenceBox;
            } else {
                if (roomState.isEnded)
                    return classes.learningBoxOpacity;
                else
                    return classes.learningBox;
            }
        };

        const el = document.getElementById(`dateTimeline-contents-${room.id}`);

        return (
            <ClickAwayListener onClickAway={() => timelineStore.closeDateTimelinePanel(room.id)}>
                <Box>
                    <Box id={`dateTimeline-contents-${room.id}`} display='flex' justifyContent='space-between' className={boxStyle()} onClick={() => timelineStore.openDateTimelineDetail(room.id)}>
                        <Grid item xs={7}>
                            <Box display='flex' pb={1}>
                                <Typography className={classes.timeText}>{DATE_UTIL.getTimeZoneLanguage(room.startDatetime).format('hh:mm A')} ~ {DATE_UTIL.getTimeZoneLanguage(room.endDatetime).format('hh:mm A')}</Typography>
                                {/* 현재 */}
                                {roomState.isCurrent &&
                                <Box className={classes.liveBox}>
                                    <span className={classes.circle}></span> <Typography className={classes.liveText}>LIVE</Typography>
                                </Box>
                                }

                                {/* 종료 */}
                                {roomState.isEnded &&
                                    <Box className={classes.endBox}><Typography className={classes.endText}><FormattedMessage id="end"/></Typography></Box>
                                }
                            </Box>
                            <Typography className={classes.titleText}>{room.name}</Typography>
                            <Typography className={classes.teamNameText}>{room.teamName} | {room.userName} {room.userTitle}</Typography>
                        </Grid>

                        <Grid item xs={5} className={classes.conferenceBoxIn}>
                            <TeamMembersSideBar />

                            <Box display='flex' pt={1}>
                                <Box display='flex'>
                                    {roomState.isEnter &&
                                        <LightTooltip title={intl.formatMessage({id: "enter"})} placement="right" onClick={event => this.handleClickEnterance(event, room.members.find(m => m.userId === authStore.loginUser.id).entranceUrl)}>
                                            <IconButton className={classes.iconButton}>
                                                <Enter style={{width:26}}/>
                                            </IconButton>
                                        </LightTooltip>
                                    }
                                    {(roomState.isMade || isLeader) && room.guests.length > 0 &&
                                        <LinkView linkViewOpen={linkViewOpen} guests={room.guests} handleClickButton={(event) => timelineStore.openDateTimelineLinkView(room.id)} handleClose={timelineStore.closeAllDateTimelineLinkView}/>
                                    }
                                </Box>
                            </Box>
                        </Grid>
                    </Box>
                    <Popper
                        open={detailOpen}
                        anchorEl={el ? el : document.getElementById('root')}
                        placement={placement}
                        disablePortal={disablePortal}
                        className={classes.popper}
                        modifiers={{
                            flip: {
                                enabled: true,
                            },
                            preventOverflow: {
                                enabled: true,
                                boundariesElement: 'scrollParent',
                            },
                            hide: {
                                enabled: false
                            },
                            arrow: {
                                enabled: arrow,
                                element: arrowRef,
                            },
                        }}

                    >
                        {arrow ? <span className={classes.arrow} ref={this.handleArrowRef} /> : null}
                        <Paper className={classes.paper}>
                            {detailOpen ? (
                                <div>
                                    <RoomInformation room={room}/>
                                </div>
                            ) : null }
                        </Paper>
                    </Popper>
                </Box>
            </ClickAwayListener>
        );
    }
}

export default withStyles(style)(injectIntl(DataTimeLineItem));
