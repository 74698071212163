import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Popper,
    Button,
    Paper,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions, Typography, Box, IconButton
} from "@material-ui/core";
import {ReactComponent as EyeIcon} from "../../common/images/EyeIcon.svg";
import {ReactComponent as MarkQuestion} from "../../common/images/MarkQuestion.svg";
import {ReactComponent as Close} from "../../common/images/Close.svg";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: 10,
        maxWidth: 400,
        overflow: 'auto',
        boxShadow: '0 4px 15px 0 rgba(0, 0, 0, 0.4)',
        "& .MuiTypography-h6": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#000',
            fontWeight: 600
        },
        "& .MuiDialogTitle-root": {
            padding: '0 0 10px',
            borderBottom: '1px solid #c0c2c3',
            marginBottom: 20,
        },
        "& .MuiDialogContent-root": {
            padding: 0
        },
        "& .MuiButton-root.Mui-disabled": {
            background: '#fff',
            color: '#88889D',
            border: '1px solid #A3A8AF'
        }
    },
    buttonText: {
        textDecoration: 'underline',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0097ff',
        textAlign: 'left',
        padding: 0,
        minWidth: 0,
        "&:hover": {
            background: 'transparent',
            textDecoration: 'underline'
        }
    },
    popper: {
        zIndex: 1,
        marginLeft: 20,
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    dialogTitleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        fontWeight: 600,
    },
    textStyle1: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        fontWeight: 600,
        width: 120
    },
    inputStyle: {
        width: 230,
        padding: '6px 10px',
        borderRadius: 4,
        border: '1px solid #b7bcd6',
        background: '#fff',
        "&:focus": {
            outline: 'none',
        },
    },
    inputErrorStyle: {
        width: 230,
        padding: '6px 10px',
        borderRadius: 4,
        border: '1px solid #ff0000',
        background: '#fff',
        "&:focus": {
            outline: 'none',
        },
    },
    inputText: {
        marginLeft: 115,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 12,
        color: '#8d909e',
    },
    buttonStyle: {
        width: 130,
        padding: '10px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#fff',
        fontWeight: 600,
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    iconBtn: {
        padding: 5,
        marginLeft: 10
    }
});

@inject('userStore', 'authStore')
@observer
class ChangePassword extends Component {
    state = {
        arrow: true,
        arrowRef: null,
        disablePortal: false,
        flip: true,
        placement: 'right',
    };

    handleClickButton = () => {
        this.props.userStore.dialogState();
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClose = () => {
        this.props.userStore.dialogState();
        this.props.userStore.resetInfo();
    };
    handleChangePassword = () => {
        const {intl} = this.props;
        this.props.userStore.changePassword(intl, this.props.authStore.loginUser);
    }
    handleChangeInputState = (id) => {
        if (document.getElementById(`${id}`).getAttribute('type')!=='text'){
            document.getElementById(`${id}`).setAttribute('type', 'text');
        }else{
            document.getElementById(`${id}`).setAttribute('type', 'password');
        }
    }

    render() {
        const {classes, intl, userStore} = this.props;
        const {placement, disablePortal, flip, arrow, arrowRef} = this.state;
        return (
            <div className={classes.root}>
                <Button
                    buttonRef={node => {
                        this.anchorEl = node;
                    }}
                    onClick={this.handleClickButton}
                    className={classes.buttonText}
                >
                    <FormattedMessage id="change"/>
                </Button>
                <Popper
                    open={userStore.open}
                    anchorEl={this.anchorEl}
                    placement={placement}
                    disablePortal={disablePortal}
                    className={classes.popper}
                    modifiers={{
                        flip: {
                            enabled: flip,
                        },
                        arrow: {
                            enabled: arrow,
                            element: arrowRef,
                        },
                        preventOverflow: {
                            enabled: false,
                            boundariesElement: 'scrollParent',
                        },
                    }}

                >
                    {arrow ? <span className={classes.arrow} ref={this.handleArrowRef}/> : null}
                    <Paper className={classes.paper}>
                        <DialogTitle>
                            <Box display='flex' justifyContent='space-between' alignItems="center">
                                <Typography className={classes.dialogTitleText}><FormattedMessage id="change_password"/></Typography>
                                <IconButton onClick={this.handleClose} style={{padding: 5}}>
                                    <Close style={{width: 20, height: 20}}/>
                                </IconButton>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText component='div'>
                                <Box display="flex" alignItems="center" pb={1}>
                                    <Typography className={classes.textStyle1}><FormattedMessage id="current_password"/></Typography>
                                    <Box display='flex' flexDirection='column'>
                                        <Box display="flex" alignItems="center">
                                            <form>
                                                <input
                                                    name="password"
                                                    type="password"
                                                    tabIndex={1}
                                                    id="password"
                                                    autoFocus={true}
                                                    placeholder={intl.formatMessage({id: "msg.insert_current_password"})}
                                                    className={classes.inputStyle}
                                                    onChange={(e) => userStore.changeLoginPassword(e.target.value)}
                                                />
                                            </form>
                                            <IconButton id="password"

                                                        onClick={e => this.handleChangeInputState(e.currentTarget.id)}
                                                        className={classes.iconBtn}
                                            >
                                                <EyeIcon/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center" pb={1}>
                                    <Typography className={classes.textStyle1}><FormattedMessage id="new_password"/></Typography>
                                    <Box display='flex' flexDirection='column'>
                                        <Box display="flex" alignItems="center">
                                            <form>
                                                <input
                                                    name="newPassword"
                                                    type="password"
                                                    id="newPassword"
                                                    tabIndex={2}
                                                    placeholder={intl.formatMessage({id: "msg.insert_new_password"})}
                                                    className={userStore.isNewPassword || userStore.changeInfo.newPassword === "" ? classes.inputStyle : classes.inputErrorStyle}
                                                    onChange={(e) => userStore.changeNewPassword(e.target.value)}

                                                />
                                            </form>
                                            <IconButton id="newPassword"
                                                        onClick={e => this.handleChangeInputState(e.currentTarget.id)}
                                                        className={classes.iconBtn}
                                            >
                                                <EyeIcon/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box display="flex" alignItems="center" pb={1}>
                                    <Typography className={classes.textStyle1}><FormattedMessage id="new_password_confirmation"/></Typography>
                                    <Box display='flex' flexDirection='column'>
                                        <Box display="flex" alignItems="center">
                                            <form>
                                                <input
                                                    name="againPassword"
                                                    type="password"
                                                    id="againPassword"
                                                    tabIndex={3}
                                                    placeholder={intl.formatMessage({id: "msg.insert_new_password_confirmation"})}
                                                    className={userStore.isPasswordConfirm ? classes.inputStyle : classes.inputErrorStyle}
                                                    onChange={(e) => userStore.changeNewPasswordConfirm(e.target.value)}
                                                />
                                            </form>
                                            <IconButton id="againPassword"
                                                        onClick={e => this.handleChangeInputState(e.currentTarget.id)}
                                                        className={classes.iconBtn}
                                            >
                                                <EyeIcon/>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems='center'>
                                    <Typography className={classes.inputText}><FormattedMessage id="msg.validation_password"/></Typography>
                                    <MarkQuestion style={{marginLeft: 2}}/>
                                </Box>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>

                            <Button className={classes.buttonStyle} onClick={this.handleChangePassword}
                                    disabled={!userStore.isComplete || userStore.getIsLoading} tabIndex={4}>
                                <FormattedMessage id="save"/>
                            </Button>
                        </DialogActions>
                    </Paper>
                </Popper>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(ChangePassword));