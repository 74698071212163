import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Grid,
    Button,
    Box, TextField, IconButton,
} from "@material-ui/core";
import CreateTeamMember from "./CreateTeamMember";
import TeamMemberInformation from "./TeamMemberInformation";
import TeamMemberTreeView from "./TreeView/TeamMemberTreeView";
import TeamMemberTable from "./TeamMemberTable/TeamMemberTable";
import {inject, observer} from "mobx-react";
import {UI, UserType} from "../../../stores/UserStore";
import ConfirmDialog from "../../../common/ConfirmDialog";
import {Clear as ClearIcon, Search as SearchIcon} from "@material-ui/icons";
import DeleteDialog from "../../../common/DeleteDialog";
import {FormattedMessage, injectIntl} from "react-intl";
import MemberExcelModal from "./MemberExcelModal"
import MaterialTable from "material-table";
import dayjs from "dayjs";
import {DATE_UTIL} from "../../../common/util/date.util";
import * as TStore from "../../../stores/TeamStore";


const style = theme => ({
    root:{
        width:'100%',
        background:'#fff',
        paddingTop:100,
        paddingLeft:30,
        marginLeft:90,
        "& .MuiListItem-root.Mui-selected":{
            background:'#d0d0d0',
        },
        "& :focus":{
            outline:'none',
        },
    },
    paper:{
        borderRadius:0,
        boxShadow:'none',
    },
    menuItemBox:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:18,
        fontWeight:600,
        color:'#0d0d0d',
        "&:hover":{
            background:'#d0d0d0'
        }
    },
    btnStyle:{
        width:135,
        padding:'10px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#fff',
        marginRight:20,
        marginTop:30,
        "&:hover":{
            background:() =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    textStyle:{
        width:180,
        // padding:'10px 0',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:13,
        fontWeight:600,
        color:'#fff',
        marginRight:20,
        marginTop:40,
        "& input":{
            fontSize:13,
            letterSpacing:-1,
        },
        "& .MuiInput-underline:after, .MuiInput-underline:hover:not(.Mui-disabled):before":{
            borderBottomColor:'#7d86a9'
        }
    }
});
@inject("teamStore", "authStore", "userStore", "excelStore", "progressStore")
@observer
class TeamMemberManagement extends Component {
    constructor(props) {
        super(...arguments, props);
        this.state = {
            selectedTeamId: 0
        }
    }

    handleSelectTeam = (teamId) => {
        this.setState({selectedTeamId : teamId});
    }

    componentDidMount() {
        const {intl} = this.props;
        const { selectTeamByLeader } = this.props.teamStore;

        if (this.props.authStore.loginUser.type === UserType.Admin) {
            this.props.userStore.memberTreeSelectedTeamId = "";
            this.props.userStore.initUserTablePage();
            this.props.teamStore.getTeamTreeList();
            this.props.userStore.getUserTableListByAll();
            this.props.userStore.initMemberTreeExpandedList();
        } else {
            if (selectTeamByLeader && selectTeamByLeader.id) {
                this.props.userStore.changeUiState(UI.MemberTable, 0);
                this.props.userStore.memberTreeSelectedTeamId = "";
                this.props.userStore.initUserTablePage();
                this.props.userStore.getUserTableListByTeam(intl, selectTeamByLeader.id);
                this.props.teamStore.getTeamTreeList(this.props.authStore.loginUser);
            }
        }
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userStore, progressStore} = this.props;
        progressStore.setIsLoading(userStore.getIsLoading);
    }

    componentWillUnmount() {
        this.props.teamStore.initTeamTablePage();
    }

    keyDownSearchKeyword = (e) => {
        if (e.isComposing || e.keyCode === 229) { return; }
        if (e.keyCode  === 13) {
            this.SearchTeamTableListByKeyword();
        } else if (e.keyCode === 27) {
            this.props.userStore.initUserTableSearchKeyword();
        }
    }

    SearchTeamTableListByKeyword = () => {
        const {intl} = this.props;
        const { memberTreeSelectedTeamId } = this.props.userStore;
        const { selectTeamByLeader } = this.props.teamStore;
        const keyword = this.props.userStore.userTablePage.keyword;
        this.props.userStore.initUserTablePage();
        this.props.userStore.userTablePage.keyword = keyword;

        if ( memberTreeSelectedTeamId === "-1" || memberTreeSelectedTeamId === "" ){
            if ((this.props.authStore.loginUser.type !== UserType.Admin) && selectTeamByLeader && selectTeamByLeader.id) {
                this.props.userStore.getUserTableListByTeam(intl, selectTeamByLeader.id);
            } else {
                this.props.userStore.getUserTableListByAll();
            }
        } else if (memberTreeSelectedTeamId === "-2") {
            this.props.userStore.getUserTableListByNotTeam();
        } else {
            this.props.userStore.getUserTableListByTeam(intl, memberTreeSelectedTeamId);
        }
    }
    removeUser = () =>{
        this.props.userStore.removeUser(this.removeUserCallback);
    }

    removeUserCallback = () => {
        const {intl} = this.props;
        const teamId = this.props.userStore.memberTreeSelectedTeamId;

        if (!teamId || teamId === "-1") {
            this.props.userStore.getUserTableListByAll();
        } else if (teamId === "-2") {
            this.props.userStore.getUserTableListByNotTeam();
        } else {
            this.props.userStore.getUserTableListByTeam(intl, teamId);
        }
        this.props.userStore.changeUiState(UI.MemberTable, 0);
    }

    handleSetDetailTeamTeamNameInTable = (user) =>{
        const {intl} = this.props;

        if(user.teamCount === 0){
            return "-";
        }else if(user.teamCount === 1){
            return `${user.team}(${user.type === TStore.UserType.Leader ? intl.formatMessage({id: "leader"}) : intl.formatMessage({id: "member"}) })`;
        }else if(1 < user.teamCount){
            const count = user.teamCount-1;
            const result = `${user.team}(${user.type ===  TStore.UserType.Leader ? intl.formatMessage({id: "leader"}) : intl.formatMessage({id: "member"})}) ${intl.formatMessage({id: "others"})}${count}`;
            return result;
        }
    }

    handleIsEnabledText = (user) => {
        const {intl} = this.props;
        const {loginUser} = this.props.authStore;
        const {enabled, teamUserEnabled} = user;

        if (loginUser.type === UserType.Admin){
            return enabled ? intl.formatMessage({id: "enabled"}) : intl.formatMessage({id: "disabled"});
        } else if (loginUser.type === UserType.Normal) {
            return teamUserEnabled ? intl.formatMessage({id: "enabled"}) : intl.formatMessage({id: "disabled"});
        }
    }

    handleSetDetailUser = (userId) => {
        this.props.progressStore.setIsLoading(true);
        this.props.userStore.getUserDetail(userId);
    }

    handleAddButtonClick = () => {
        this.props.userStore.changeUiState(UI.Create);
    }

    render() {
        const { intl, excelStore } = this.props;
        const { UiState, changeUserConfirmDialogOpen, userConfirmDialog,
            userDeleteDialog, changeUserDeleteDialogOpen, memberTableList
        } = this.props.userStore;
        const { loginUser } = this.props.authStore;

        const columns = [
            {title: 'id', field: 'id', headerStyle:{wordBreak:"keep-all"}, cellStyle:{wordBreak:"keep-all"}, hidden:true},
            {title: <FormattedMessage id="type"/>, field: 'userType', headerStyle:{wordBreak:"keep-all", textAlign: "center", paddingLeft: 30}, cellStyle:{wordBreak:"keep-all", textAlign: "center"}, render: rowData => (rowData.userType === UserType.Admin ? <FormattedMessage id="management_account"/> : <FormattedMessage id="normal_account"/>)},
            {title: <FormattedMessage id="team_name"/>, field: 'team', headerStyle:{wordBreak:"keep-all", textAlign: "center"}, cellStyle:{wordBreak:"keep-all", textAlign: "center"}, render: rowData => (this.handleSetDetailTeamTeamNameInTable(rowData))},
            {title: <FormattedMessage id="nickname"/>, field: 'name', headerStyle:{wordBreak:"keep-all", textAlign: "center"}, cellStyle:{wordBreak:"keep-all", textAlign: "center"}},
            {title: <FormattedMessage id="title"/>, field: 'title', headerStyle:{wordBreak:"keep-all", textAlign: "center"}, cellStyle:{wordBreak:"keep-all", textAlign: "center"}, render: rowData => (rowData.title ? rowData.title : '-') },
            {title: <FormattedMessage id="registration_date"/>, field: 'createdDatetime', headerStyle:{wordBreak:"keep-all", textAlign: "center"}, cellStyle:{wordBreak:"keep-all", textAlign: "center"}, render: rowData => dayjs(rowData.createdDatetime).format(DATE_UTIL.FORMAT.DEFAULT_FORMAT)},
            {title: <FormattedMessage id="state"/>, field: loginUser.type === UserType.Admin ? 'enabled' : 'teamUserEnabled' , headerStyle:{wordBreak:"keep-all", textAlign: "center"}, cellStyle:{wordBreak:"keep-all", textAlign: "center"}, render: rowData => (this.handleIsEnabledText(rowData))},
        ];

        const action = [{
            icon: 'add',
            tooltip: loginUser.type === UserType.Admin ? <FormattedMessage id="add"/> : <FormattedMessage id="add_member"/>,
            isFreeAction: true,
            onClick: () => this.handleAddButtonClick()
        }];
        if (loginUser.type === UserType.Admin && this.state.selectedTeamId !== 0) {
            action.push({
                icon: 'publish',
                tooltip: <FormattedMessage id="add_excel"/>,
                isFreeAction: true,
                onClick: () => excelStore.setMemberExcelModal(true)
            })
        }
        if (loginUser.type !== UserType.Admin) {
            action.push({
                icon: 'publish',
                tooltip: <FormattedMessage id="add_member_excel"/>,
                isFreeAction: true,
                onClick: () => excelStore.setMemberExcelModal(true)
            })
        }

        return (
            <div>
                <Grid container>
                    {this.props.authStore.loginUser.type ==="Admin" &&
                        <Grid item xs={3}>
                            <TeamMemberTreeView selectTeam={this.handleSelectTeam}/>
                        </Grid>
                    }

                    <Grid item xs={this.props.authStore.loginUser.type ==="Admin" ? 9 : 12}>
                        {UiState.state===UI.MemberTable &&
                            <Box>
                                <MaterialTable
                                    style={{
                                        boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)',
                                        width: "100%",
                                        height: "100%",
                                        paddingLeft: 20
                                    }}
                                    title=""
                                    columns={columns}
                                    data={memberTableList}
                                    options={{
                                        pageSize: 10,
                                        headerStyle: {
                                            wordBreak: "keep-all",
                                            fontFamily: 'NanumSquareRoundOTF',
                                            fontSize: 13,
                                            fontWeight: 600,
                                            color: '#000',
                                        },
                                        cellStyle: {
                                            wordBreak: "keep-all",
                                            fontFamily: 'NanumSquareRoundOTF',
                                            fontSize: 13,
                                            fontWeight: 300,
                                            color: '#000',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden'

                                        },
                                        pageSizeOptions: [10]
                                    }}
                                    localization={{
                                        pagination: {
                                            labelRowsSelect: intl.formatMessage({id: "table_rows_count"}),
                                            labelDisplayedRows: intl.formatMessage({id: "table_bottom_text"}),
                                        },
                                    }}
                                    actions={action}
                                    onRowClick={(e, rowData) => this.handleSetDetailUser(rowData.id)}
                                />
                                {/*<Box style={{paddingLeft: 20}}>*/}
                                {/*    {(loginUser.type === UserType.Admin && this.state.selectedTeamId !== 0) &&*/}
                                {/*        <Button className={classes.btnStyle} onClick={()=> excelStore.setMemberExcelModal(true)}>*/}
                                {/*            {"+ " + intl.formatMessage({id: "add_excel"})}*/}
                                {/*        </Button>*/}
                                {/*    }*/}
                                {/*    {loginUser.type !== UserType.Admin &&*/}
                                {/*        <Button className={classes.btnStyle} onClick={()=> excelStore.setMemberExcelModal(true)}>*/}
                                {/*            {"+ " + intl.formatMessage({id: "add_member_excel"})}*/}
                                {/*        </Button>*/}
                                {/*    }*/}
                                {/*    <div style={{float: 'right'}}>*/}
                                {/*        <TextField value={userTablePage.keyword}*/}
                                {/*                   onChange={changeUserTableSearchKeyword}*/}
                                {/*                   onKeyDown={this.keyDownSearchKeyword}*/}
                                {/*                   className={classes.textStyle}*/}
                                {/*                   placeholder={intl.formatMessage({id: "msg.search_nickname"})}*/}
                                {/*                   InputProps={{*/}
                                {/*                       endAdornment: (*/}
                                {/*                           <Box display='flex' style={{marginRight : -5}}>*/}
                                {/*                               <IconButton position="end"*/}
                                {/*                                           style={{ padding:2 }}*/}
                                {/*                                           onClick={initUserTableSearchKeyword}*/}
                                {/*                                   >*/}
                                {/*                                   <ClearIcon style={{fontSize:17}}/>*/}
                                {/*                               </IconButton>*/}
                                {/*                               <IconButton position="end"*/}
                                {/*                                           style={{ padding:2 }}*/}
                                {/*                                           onClick={this.SearchTeamTableListByKeyword}*/}
                                {/*                               >*/}
                                {/*                                   <SearchIcon style={{fontSize:17}}/>*/}
                                {/*                               </IconButton>*/}
                                {/*                           </Box>*/}
                                {/*                       ),*/}
                                {/*                   }}>*/}
                                {/*        </TextField>*/}
                                {/*        <Button className={classes.btnStyle} style={{marginRight: 30}} onClick={()=>changeUiState(UI.Create)}>*/}
                                {/*            {*/}
                                {/*                loginUser.type ===UserType.Admin ?*/}
                                {/*                    "+ " + intl.formatMessage({id: "add"})*/}
                                {/*                    :*/}
                                {/*                    "+ " + intl.formatMessage({id: "add_member"})*/}
                                {/*            }*/}
                                {/*        </Button>*/}
                                {/*    </div>*/}
                                {/*</Box>*/}
                                {/*<TeamMemberTable />*/}
                            </Box>
                        }
                        {UiState.state===UI.Information && <TeamMemberInformation/>}
                        {UiState.state===UI.Create && <CreateTeamMember/>}

                        <DeleteDialog open={userDeleteDialog.open}
                                      title={userDeleteDialog.title}
                                      msg={userDeleteDialog.msg}
                                      changeDialogOpen={changeUserDeleteDialogOpen}
                                      submit={this.removeUser}
                        />
                        <ConfirmDialog open={userConfirmDialog.open}
                                       title={userConfirmDialog.title}
                                       msg={userConfirmDialog.msg}
                                       changeDialogOpen={(value)=>changeUserConfirmDialogOpen(value)}/>
                    </Grid>
                </Grid>
                <MemberExcelModal selectedTeamId={this.state.selectedTeamId}/>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(TeamMemberManagement));