import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Box,
    Avatar,
    Typography
} from "@material-ui/core";
import MobileTeam from "./containers/MobileTeam";
import MobileTimeLineTab from "./containers/MobileTimeLineTab";
import {UTIL} from "../../common/util/common.util";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import MobileSurvey from "./survey/mobile/MobileSurvey";
import {AuthorityType} from "../../stores/UserLoungeStore";

const style = theme => ({
    root:{
        width:'100%',
        padding:'90px 0 0',
    },
    avatarStyle:{
        fontFamily: 'Montserrat',
        fontSize:'2.5rem',
        fontWeight:800,
        width:65,
        height:65,
        background:'#0097ff'
    },
    userText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:'1.5rem',
        color:'#0d0d0d',
        fontWeight:800,
        marginTop:8
    },
    createRoomBtn:{
        background:'#0097ff',
        color:'#fff',
        boxShadow:'0px 2px 7px rgba(0, 0, 0, 0.45)',
        "&:hover":{
            background:'#0097ff',
            boxShadow:'0px 2px 7px rgba(0, 0, 0, 0.45)',
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
});

@inject('userStore', 'avatarStore', 'topBarStore', 'userLoungeStore', 'authStore')
@observer
class MobileMyTimeline extends Component {
    componentDidMount() {
        const { topBarStore } = this.props;

        if (window.location.pathname === '/' || window.location.pathname === '/mytimeline')
            topBarStore.setIsSearchBarRender(true);
    }

    authority = () => {
        const {userLoungeStore, authStore} = this.props;
        let result = false;
        userLoungeStore.teamList.forEach((team) => {
            const member = team.id !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
            const isLeader = (member !== undefined) && (member.type === 'Leader');
            const isCreateRoom = (member !== undefined) && (member.authorities.find(a => a.authority === AuthorityType.CreateRoom));
            if(isLeader || isCreateRoom || result){
                result = true;
            }
        })
        return result;
    };

    render() {
        const { classes, avatarStore } = this.props;
        const { userInfo } = this.props.userStore;
        const authority = this.authority();

        return (
            <div className={classes.root}>
                <Box style={{padding:'0 20px'}}>
                    <Box display='flex' flexDirection="column" alignItems='center'>
                        {
                            avatarStore.loadAvatarImage ?
                                <Avatar className={classes.avatarStyle}>
                                    <img id="userAvatar" style={{width: '100%', height: '100%'}} src={avatarStore.loadAvatarImage} alt={userInfo.name}/>
                                </Avatar>
                                :
                                <Avatar className={classes.avatarStyle} title={"Home"}>
                                    {UTIL.getFirstCapitalLetter(userInfo.name)}
                                </Avatar>
                        }
                        <Typography className={classes.userText}>{userInfo.name}</Typography>
                    </Box>
                    <MobileTeam history={this.props.history}/>
                    {authority &&
                    <MobileSurvey history={this.props.history}/>
                    }
                </Box>
                <MobileTimeLineTab history={this.props.history} isTablet={this.props.isTablet}/>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(MobileMyTimeline));