import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from "clsx";
import {
    Box,
    Button, Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Slide, TextField,
    Typography
} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {ReactComponent as TopIcon} from "../../../../common/images/TopIcon.svg";
import {inject, observer} from "mobx-react";
import {QuizType} from "../../../../stores/QuizStore";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@material-ui/icons/RadioButtonChecked";


const style = theme => ({
    root: {},
    buttonStyle: {
        width: 110,
        padding: '5px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.15rem',
        fontWeight: 300,
        color: '#fff',
        borderRadius: 7,
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    titleBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderBottom: '1px solid rgba(136,136,157,0.3)',
        padding: '8px 10px 8px 20px',
        "& .MuiMenuItem-root": {
            minHeight: 0
        }
    },
    radioIcon: {
        fontSize: 18,
        color: '#0097ff'
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight: 600
    },
    cancelBtn: {
        background: '#a3a8af !important',
        marginRight: 13,
        "&:hover": {
            background: '#a3a8af !important'
        }
    },
    topButtonStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.15rem',
        color: '#000',
        marginTop: 15
    },
    formControlLabel: {
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.4rem',
            color: '#0d0d0d',
            fontWeight: 400,
        }
    },
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref}/>
));

@inject('quizStore', 'progressStore')
@observer
class MobileQuizPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            anchorEl: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {quizStore, progressStore} = this.props;
        progressStore.setIsLoading(quizStore.getIsLoading)
    };

    handleClickOpen = () => {
        this.setState({dialogOpen: true});
    };
    handleClickClose = () => {
        this.setState({dialogOpen: false});
    };
    handlePopoverClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handlePopoverClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleInsertQuiz = () => {
        const {quizStore, history, intl} = this.props;
        if (!this.props.quizStore.quiz.quizId) {
            quizStore.insertQuiz(intl, history);
        } else {
            quizStore.updateQuiz(intl, history, this.props.quizStore.quiz.quizId);
        }
    };

    render() {
        const {classes, quizStore, intl} = this.props;
        return (
            <div className={classes.root}>
                <Button
                    onClick={this.handleClickOpen}
                    className={classes.buttonStyle}
                    disableRipple
                >
                    <FormattedMessage id="preview"/>
                </Button>
                <Dialog
                    fullScreen
                    open={this.state.dialogOpen}
                    onClose={this.handlePopoverClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.titleBox} id="mobileQuizPreviewTop">
                        <Typography className={classes.textStyle}><FormattedMessage
                            id="preview_quiz"/></Typography>
                    </Box>

                    <Box pl={3} pr={3} pt={2} pb={3}>
                        <Typography className={classes.textStyle} style={{marginBottom: 28}}>
                            {quizStore.quiz.title !== '' ? quizStore.quiz.title : intl.formatMessage({id: "msg.preview_quiz_name"})}
                        </Typography>
                        <Box display='flex' flexDirection='column'>
                            {quizStore.quizBankImageUrl.map((imageDataUrl, index) =>
                                <Box key={index} mb={2}>
                                    {!imageDataUrl.data ?
                                        <img src={imageDataUrl} alt="" style={{width: '100%'}}/>
                                        :
                                        <img src={`data:${quizStore.quiz.quizBankImages.type};base64,${imageDataUrl.data}`} width={"100%"} alt=""/>
                                    }
                                </Box>
                            )}
                            {/* 1. 객관식단답형 */}
                            {quizStore.quiz.type === QuizType.SingleChoice &&
                            <FormControl component="fieldset" className={classes.formControlLabel}>
                                <RadioGroup className={classes.radioBox}>
                                    {quizStore.quiz.quizBankItems.length > 0 ?
                                        quizStore.quiz.quizBankItems.map((item, index) =>
                                            <FormControlLabel key={index} checked={item.correct} label={item.content} control={<Radio
                                                icon={<RadioButtonUncheckedIcon className={classes.radioIcon}
                                                                                style={{color: '#979797'}}/>}
                                                checkedIcon={<RadioButtonCheckedIcon
                                                    className={classes.radioIcon}/>}
                                            />}/>
                                        )
                                        :
                                        <FormattedMessage id="msg.preview_quiz_answer"/>
                                    }
                                </RadioGroup>
                            </FormControl>
                            }

                            {/* 2. 객관식다답형 */}
                            {quizStore.quiz.type === QuizType.MultipleChoice &&
                            <FormControl component="fieldset" className={classes.formControlLabel}>
                                {quizStore.quiz.quizBankItems.length > 0 ?
                                    quizStore.quiz.quizBankItems.map((item, index) =>
                                        <FormControlLabel key={index} checked={item.correct} label={item.content} control={<Checkbox
                                            color="primary"
                                            icon={<CheckBoxOutlineBlankIcon
                                                className={classes.radioIcon}
                                                style={{color: '#979797'}}/>}
                                            checkedIcon={<CheckBoxIcon
                                                className={classes.radioIcon}/>}
                                        />}/>
                                    )
                                    :
                                    <FormattedMessage id="msg.preview_quiz_answer"/>
                                }
                            </FormControl>
                            }


                            {/* 3. 주관식단답형 */}
                            {quizStore.quiz.type === QuizType.ShortAnswer &&
                            <Box style={{marginTop: 28}}>
                                <TextField
                                    label={intl.formatMessage({id: "msg.quiz_short_preview"})}
                                    className={clsx(classes.textStyle, classes.inputBoxIn)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            }

                            {/* 4. 주관식서술형 */}
                            {quizStore.quiz.type === QuizType.Subjective &&
                            <Box style={{marginTop: 28}}>
                                <TextField
                                    label={intl.formatMessage({id: "msg.quiz_subjective_preview"})}
                                    className={clsx(classes.textStyle, classes.inputBoxIn)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Box>
                            }
                        </Box>

                        <Box display="flex" justifyContent='center' alignItems='center' mt={5}>
                            <Button className={clsx(classes.buttonStyle, classes.cancelBtn)}
                                    onClick={this.handleClickClose} disabled={quizStore.isQuizLoading}><FormattedMessage id="rewrite"/></Button>
                            <Button className={classes.buttonStyle} onClick={this.handleInsertQuiz} disabled={quizStore.isQuizLoading}><FormattedMessage id="save"/></Button>
                        </Box>
                        <Box display="flex" justifyContent='center'>
                            <Button startIcon={<TopIcon/>}
                                    className={classes.topButtonStyle}
                                    onClick={() => {
                                        const topEl = document.getElementById("mobileQuizPreviewTop");
                                        topEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                                    }}
                            >
                                <FormattedMessage id="go_to_the_top"/>
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(MobileQuizPreview));