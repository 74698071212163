import {makeAutoObservable} from "mobx";
import dayjs from "dayjs";
import axios from "axios";
import {DATE_UTIL} from "../common/util/date.util";

const logPrefix = ' [AdminRoomStore] ';

const searchingInterval = 1000;

const ErrorCode = {
    TooManyParticipants: "msg.error_too_many_attendees",
    CanNotInsertRoom: "msg.error_create_room",
    CanNotInsertRoomMembers: "msg.error_create_room",
    CanNotRequestCreateRoom: "msg.error_create_room",
    CanNotFoundUser: "msg.error_update_room",
    CanNotRequestUpdateMembers: "msg.error_update_room",
    CanNotRequestUpdateRoom: "msg.error_update_room",
    CanNotUpdateRoomMembers: "msg.error_update_room",
    BadRequest: "msg.error_input_missing",
    NotAllowedDate: "msg.error_not_allow_date",
    CanNotSendEmail: "msg.cannot_send_email",
    Unknown: "msg.error_unknown"
}

export const State = {
    Init: 'Init',
    Pending: 'Pending',
    Failed: 'Failed',
    Done: 'Done',
};

export const TeamType = {
    Root: 'Root',
    Group: 'Group',
    Team: 'Team',
    Member: 'Member'
};

const EmptyInfo = {
    id: '',
    userName: '',
    apiRoomId: '',
    userId: '',
    teamId: '',
    type: 'Education',
    startDatetime: '',
    endDatetime: '',
    meetingTime: '',
    guestNum: '',
    name: '',
    comment: '',
    members: [],
    maxUsers: '',
    isSendEmail: false
};

export default class AdminRoomStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    STATE = State.Init;
    roomInfo = Object.assign({}, EmptyInfo);

    isGetUserListLoading = false;
    isTeamListLoading = false;
    isCreateRoomLoading = false;
    isGetRoomInfoLoading = false;
    isSearchLoading = false;
    isAllTeamListLoading = false;
    isOrganizerListLoading = false;

    openConfirmDialog = false;
    openSuccessConfirmDialog = false;

    count = 0;
    errMsg = '';
    searchKeyword = '';
    lastSearchKeyword = '';
    cancelToken = undefined;
    isSendEmail = false;

    userList = [];
    teamList = [];
    allTeamList = [];
    organizerList = [];

    initRoomInfo = () => {
        this.roomInfo = Object.assign({}, EmptyInfo);
        this.count = 0;
        this.isSendEmail = false;
        this.teamList = [];
        this.allTeamList = [];
        this.organizerList = [];
        this.getAllTeamList();
    }

    initTeamList = () => {
        this.teamList = [];
    }

    setRoomUserId = (value) => {
        this.roomInfo.userId = value;
    }

    setRoomUserName = (value) => {
        this.roomInfo.userName = value;
    }

    setTeamId = (value) => {
        this.roomInfo.teamId = value;
    }

    changeRoomName = (value) => {
        this.roomInfo.name = value;
    }

    changeRoomType = (value) => {
        this.roomInfo.type = value;
    }

    changeComment = (value) => {
        this.roomInfo.comment = value;
    }

    setOrganizer = (userId) => {
        const organizer = this.organizerList.find(o => o.id === parseInt(userId));
        if (organizer) {
            this.setRoomUserId(userId);
            this.setRoomUserName(organizer.name);
        }
    }

    onChangeDate = (value) => {
        let startDateTime = dayjs(value.startDatetime.date).add((value.startDatetime.am === 'AM' ? (value.startDatetime.hour === '12' ? parseInt(value.startDatetime.hour) - 12 : parseInt(value.startDatetime.hour)) : (value.startDatetime.hour === "12" ? parseInt(value.startDatetime.hour) : parseInt(value.startDatetime.hour) + 12)), "hour");
        startDateTime = dayjs(startDateTime).add(value.startDatetime.min, 'minute');
        this.roomInfo.startDatetime = startDateTime;
        let endDateTime = dayjs(startDateTime).add(value.endDatetime.hour, 'hour');
        let endDateMin = value.endDatetime.hour !== '04' ? value.endDatetime.min : '00';
        endDateTime = dayjs(endDateTime).add(endDateMin, 'minute');
        this.roomInfo.endDatetime = endDateTime;
        this.roomInfo.meetingTime = value.endDatetime.hour + ':' + endDateMin;
    }

    changeMembers = (value) => {
        this.roomInfo.members = value;
    }

    changeMembersInMyTeams = (value) => {
        this.roomInfo.members = this.roomInfo.members.concat(value);
    }

    changeGuestNum = (value) => {
        this.roomInfo.guestNum = value;
    }

    changeMaxUsers = (value) => {
        this.count = value;
    }

    changeSendEmail = (value) => {
        this.isSendEmail = value;
    }

    calMeetingTime = () => {
        const time = dayjs(this.roomInfo.endDatetime).diff(dayjs(this.roomInfo.startDatetime), "minute");
        this.roomInfo.meetingTime = this.MakeDateForm(time);
    }

    calLocalDate = () => {
        let convertTimeZoneToUTC = DATE_UTIL.convertTimeZoneToUTC(this.roomInfo.startDatetime);
        const startDate = DATE_UTIL.getTimeZoneDate(convertTimeZoneToUTC, "YYYY-MM-DD HH:mm:ss");
        this.roomInfo.startDatetime = startDate;
        convertTimeZoneToUTC = DATE_UTIL.convertTimeZoneToUTC(this.roomInfo.endDatetime);
        const endDate = DATE_UTIL.getTimeZoneDate(convertTimeZoneToUTC, "YYYY-MM-DD HH:mm:ss");
        this.roomInfo.endDatetime = endDate;
    }

    MakeDateForm = (min) => {
        const days = Math.floor(min / 60 / 24);
        const hours = Math.floor((min - (days * 60 * 24)) / 60);
        const mins = min - (days * 60 * 24) - (hours * 60);
        const hourStr = (hours > 9) ? hours : '0' + hours;
        const minStr = (mins > 9) ? mins : '0' + mins;
        return hourStr + ':' + minStr;
    }

    changeConfirmDialogOpen = () => {
        this.openConfirmDialog = !this.openConfirmDialog;
    }

    changeSuccessConfirmDialogOpen = () => {
        this.openSuccessConfirmDialog = !this.openSuccessConfirmDialog;
    }

    isInputValidation = (intl) => {
        let checkInput = true;

        if (this.roomInfo.teamId === "") {
            checkInput = false;
            this.errMsg = intl.formatMessage({id: "msg.required_team"});
        } else if (this.roomInfo.userId === "") {
            checkInput = false;
            this.errMsg = intl.formatMessage({id: "msg.required_organizer"});
        } else if (this.roomInfo.name === "") {
            checkInput = false;
            this.errMsg = intl.formatMessage({id: "msg.required_topic"});
        } else if (this.roomInfo.startDatetime === "") {
            checkInput = false;
            this.errMsg = intl.formatMessage({id: "msg.required_time"});
        } else if (this.roomInfo.members.length <= 0 && this.roomInfo.guestNum === "") {
            checkInput = false;
            this.errMsg = intl.formatMessage({id: "msg.required_attendees"});
        }
        this.openConfirmDialog = !checkInput;
        return checkInput;
    }

    get getIsLoading() {
        return this.isCreateRoomLoading || this.isGetRoomInfoLoading || this.isTeamListLoading || this.isGetUserListLoading || this.isAllTeamListLoading || this.isOrganizerListLoading;
    }

    searchTeamMembers = (keyword) => {
        this.isSearchLoading = true;
        this.teamList = [];
        this.searchKeyword = keyword;
        if ((this.searchIntervalState === undefined) || (this.searchIntervalState === null)) {
            // console.log(logPrefix, "Starting SearchInterval ...");
            this.searchIntervalState = setInterval(() => this.getTeamMembers(), searchingInterval);
        } else {
            // console.log(logPrefix, "SearchInterval already started ...");
        }
    }

    getTeamMembers = function* getTeamMembers() {
        // console.log(logPrefix, "SearchMembers Start keyword={}", this.searchKeyword);

        if (this.searchKeyword === '') {
            this.lastSearchKeyword = '';
            // console.log(logPrefix, "Clear SearchInterval ...");
            clearInterval(this.searchIntervalState);
            this.searchIntervalState = undefined;
            this.isSearchLoading = false;
        } else {

            if (this.searchKeyword !== this.lastSearchKeyword) {
                try {
                    this.lastSearchKeyword = this.searchKeyword;
                    const teamResults = yield axios.get(
                        this.serverContextPath + `/api/v1/teams/list/${this.lastSearchKeyword}`);
                    // console.log(logPrefix, "SearchMembers teamResults keyword : ", this.lastSearchKeyword, " >> ", teamResults.data);

                    const userResults = yield axios.get(
                        this.serverContextPath + `/api/v1/users/list/${this.roomInfo.teamId}/${this.lastSearchKeyword}`);
                    // console.log(logPrefix, "SearchMembers userResults keyword : ", this.lastSearchKeyword, " >> ", userResults.data);
                    this.teamList = teamResults.data.concat(userResults.data.filter(user => user.id !== this.roomInfo.userId));
                    this.isSearchLoading = false;
                } catch (error) {
                    console.log(logPrefix, "Cannot Search Teams or Users ...", error);
                }
            } else {
                // console.log(logPrefix, "Clear SearchInterval ...");
                clearInterval(this.searchIntervalState);
                this.searchIntervalState = undefined;
                this.lastSearchKeyword = '';
                this.isSearchLoading = false;
            }
        }
    }

    createRoom = function* createRoom(intl, changeMembers) {
        // console.log(logPrefix, "CreateRoom Start ... ");
        this.isCreateRoomLoading = true;
        this.STATE = State.Pending;
        try {
            if (!this.isInputValidation(intl)) {
                // console.log(logPrefix, "CreateRoom InputValidation Fail ... ");
                return false;
            }
            const param = Object.assign({}, this.roomInfo);
            param.startDatetime = DATE_UTIL.getUTCDate(this.roomInfo.startDatetime).format('YYYY-MM-DD HH:mm:ss');
            param.endDatetime = DATE_UTIL.getUTCDate(this.roomInfo.endDatetime).format('YYYY-MM-DD HH:mm:ss');
            param.sendEmail = this.isSendEmail;

            yield axios.post(this.serverContextPath + '/api/v1/rooms', param);

            this.initRoomInfo();
            changeMembers([]);
            this.openSuccessConfirmDialog = true;
        } catch (e) {
            console.log(logPrefix, "CreateRoom Fail : >> ", e);
            this.STATE = State.Failed;
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            this.openConfirmDialog = true;
        } finally {
            this.isCreateRoomLoading = false;
        }
    }

    getAllTeamList = function* getAllTeamList() {
        this.isAllTeamListLoading = true;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/teams?type=simple`);
            this.allTeamList = response.data;
        } catch (e) {
            console.log(logPrefix, "GetAllTeamList Error : >> ", e);
        } finally {
            this.isAllTeamListLoading = false;
        }
    };

    getOrganizerList = function* getOrganizerList(teamId) {
        this.isOrganizerListLoading = true;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/teams/${teamId}/organizers`);
            this.organizerList = response.data;
        } catch (e) {
            console.log(logPrefix, "GetOrganizerList Error : >> ", e);
        } finally {
            this.isOrganizerListLoading = false;
        }
    };
}