import * as tfjs from '@tensorflow/tfjs';               // Don't delete !! It's for bodyPix
import * as bodyPix from "@tensorflow-models/body-pix";
import axios from "axios";
import {
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown,
    grey
} from "@material-ui/core/colors";
import {makeAutoObservable} from "mobx";
export const AvatarWidth = 160;
export const AvatarHeight = 160;
export const BackgroundOption = {
    None: 'None',
    Color: 'Color',
    Image: 'Image',
};

const BackgroundColorNum = 500;
export const BackgroundColor = {
    White: '#ffffff',
    Grey: grey[BackgroundColorNum],
    Black: '#000000',
    Brown: brown[BackgroundColorNum],
    Amber: amber[BackgroundColorNum],
    Yellow: yellow[BackgroundColorNum],
    Orange: orange[BackgroundColorNum],
    DeepOrange: deepOrange[BackgroundColorNum],
    Red: red[BackgroundColorNum],
    Pink: pink[BackgroundColorNum],
    Purple: purple[BackgroundColorNum],
    DeepPurple: deepPurple[BackgroundColorNum],
    Indigo: indigo[BackgroundColorNum],
    Blue: blue[BackgroundColorNum],
    LightBlue: lightBlue[BackgroundColorNum],
    Cyan: cyan[BackgroundColorNum],
    Teal: teal[BackgroundColorNum],
    Green: green[BackgroundColorNum],
    LightGreen: lightGreen[BackgroundColorNum],
    Lime: lime[BackgroundColorNum],
}

const AvatarType = 'image/png';
const LogPrefix = ' [ AvatarStore ] ';

export default class AvatarStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }
    warning = tfjs;
    userId = '';
    deviceBrowserTypes = {};
    videoElementName = '';
    backgroundImageName = '';
    captureCanvasName = '';
    maskedCanvasName = '';
    backgroundCanvasName = '';
    avatarCanvasName = '';
    selectedVideoDeviceId = '';

    optionOpen = false;
    backgroundOption = BackgroundOption.None;
    backgroundColor = undefined;
    backgroundImageExists = false;

    devices = [];

    captured = false;
    creating = false;
    avatarImage = undefined;
    loadAvatarImage = undefined;
    isVideoMediaFoundError = false;

    initialize = (userId, videoElementName, imageElementName, captureCanvasName, backgroundCanvasName, maskedCanvasName, avatarCanvasName) => {
        this.userId = userId;
        this.videoElementName = videoElementName;
        this.backgroundImageName = imageElementName;
        this.captureCanvasName = captureCanvasName;
        this.maskedCanvasName = maskedCanvasName;
        this.backgroundCanvasName = backgroundCanvasName;
        this.avatarCanvasName = avatarCanvasName;
        this.optionOpen = false;
        this.backgroundOption = BackgroundOption.None;
        this.backgroundColor = undefined;
        this.backgroundImageExists = false;
        this.captured = false;
        this.creating = false;
        this.avatarImage = undefined;
        // this.getAvatar(userId)
    }

    dialogState = () => {
        this.open = this.open ? false : true
    }
    setOptionOpen = (open) => {
        // if(this.captured) {
        this.optionOpen = open;
        if (!open) {
            this._displayAvatarImage(this.avatarCanvasName);
            // this.avatarImage = document.getElementById(this.avatarCanvasName);
            // this._uploadAvatarImage(this.avatarCanvasName);
        }
        // }
    }
    setDeviceBrowserTypes = deviceBrowserTypes =>{
        this.deviceBrowserTypes = deviceBrowserTypes
    };
    startVideoStream = mediaStream => {
        this.videoMediaStream = mediaStream;

        const video = document.getElementById('standbyVideo');
        video.srcObject = mediaStream;
        console.log(LogPrefix, 'Start Video stream success', mediaStream);
    }

    stopVideoTrackInStream = () => {
        if (this.videoMediaStream) {
            this.videoMediaStream.getTracks().forEach((track) => track.stop());
            console.log(LogPrefix, 'Stop Video stream success', this.videoMediaStream);
        }
    }

    logMediaStreamTrack = (mediaStream) => {
        mediaStream.getTracks().forEach((track) => {
            console.log(LogPrefix, 'Track', track);
            console.log(LogPrefix, "Track's constraints", track.getConstraints());
            console.log(LogPrefix, "Track's settings", track.getSettings());
        });
    }


    setVideoMediaFoundError = isVideoMediaFoundError => this.isVideoMediaFoundError = isVideoMediaFoundError

    setVideoStream = mediaStream => {
        this.videoMediaStream = mediaStream;
    }
    setDevices = devices => {
        this.devices = devices;
        if (this.videoDevices[0]) this.selectVideoDeviceId(this.videoDevices[0].deviceId);
    }

    initDevices = () => {
        console.log(LogPrefix, 'Enumerating devices...');
        this.selectedVideoDeviceId = '';
        const that = this;
        if (navigator.mediaDevices && navigator.mediaDevices.getSupportedConstraints) {
            const supportedConsts = navigator.mediaDevices.getSupportedConstraints();
            console.log(LogPrefix, 'GetUserMedia supported constraints', supportedConsts);
        }

        this.setVideoMediaFoundError(false);
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && navigator.mediaDevices.enumerateDevices) {
            navigator.mediaDevices.getUserMedia({video: true})
                .then((stream) => {
                    that.setVideoStream(stream);

                    navigator.mediaDevices.enumerateDevices()
                        .then(devices => {
                            console.log(LogPrefix, 'Enumerate devices success', devices);
                            that.setDevices(devices);
                        })
                        .catch(error => {
                            that.setVideoMediaFoundError(true);
                            console.log(LogPrefix, 'Enumerate devices error', error);
                            that.setDevices([]);
                        });
                })
                .catch(error => {
                    that.setVideoMediaFoundError(true);
                    console.log(LogPrefix, 'get user media error', error);
                    that.setDevices([]);
                });
        } else {
            this.setVideoMediaFoundError(true);
        }
    }


    selectVideoDeviceId = deviceId => {
        console.log(LogPrefix, 'Select Video DeviceId', deviceId);
        this.selectedVideoDeviceId = deviceId;

        const that = this;
        console.log(LogPrefix, 'Getting UserMedia...', deviceId);
        navigator.mediaDevices.getUserMedia({video: {deviceId: deviceId, width: 300, height: 180}})
            .then(mediaStream => {
                console.log(LogPrefix, 'Get UserMedia success', mediaStream);
                that.stopVideoTrackInStream();
                that.logMediaStreamTrack(mediaStream);

                that.startVideoStream(mediaStream);
            })
            .catch((error) => {
                console.log(LogPrefix, 'Get UserMedia error', error);

                that.stopVideoTrackInStream();
                console.log(LogPrefix, `Get UserMedia without constraints`);
                navigator.mediaDevices.getUserMedia({video: {deviceId: deviceId}})
                    .then(mediaStream => {
                        console.log(LogPrefix, 'Get UserMedia success without constraints', mediaStream);
                        that.logMediaStreamTrack(mediaStream);

                        that.startVideoStream(mediaStream);
                    })
                    .catch((error) => {
                        console.warn(LogPrefix, 'Get UserMedia error without constraints', error);
                    });
            });
    }

    get videoDevices() {
        return this.devices.filter(d => d.deviceId !== 'default').filter(d => d.kind === 'videoinput').map(d => ({
            deviceId: d.deviceId,
            label: d.label
        }));
    }

    get getIsLoading() {
        return this.creating;
    }
    setCaptured = (captured) => {
        this.captured = captured;
    }

    setBackgroundOption = function* setBackgroundOption(option) {
        this.backgroundOption = option;

        yield this.createAvatar(false);
    };

    setBackgroundColor = function* setBackgroundColor(color) {
        this.backgroundColor = color;

        yield this.createAvatar(false);
    };

    setBackgroundImage = function* () {
        this.backgroundImageExists = true;

        yield this.createAvatar(false);
    };

    createAvatar = function* createAvatar(isCapture) {
        console.log(LogPrefix, `Create avatar : isCapture=${isCapture}`);

        this.creating = true;

        try {
            if (isCapture) {
                this._captureVideo();
                this._copyAvatar(this.captureCanvasName, this.avatarCanvasName);
                this._displayAvatarImage(this.avatarCanvasName);
                // yield this._uploadAvatarImage(this.avatarCanvasName);
                // this.avatarImage = document.getElementById(this.avatarCanvasName);
            }

            if (this.backgroundOption === BackgroundOption.None && !isCapture) {
                this._copyAvatar(this.captureCanvasName, this.avatarCanvasName);

                this._displayAvatarImage(this.avatarCanvasName);
            } else if (this.backgroundOption === BackgroundOption.Color && this.backgroundColor) {
                this._drawBackgroundColor(this.backgroundColor, this.backgroundCanvasName);

                yield this._maskAvatarImage(this.captureCanvasName, this.maskedCanvasName);
                this._drawBackgroundAvatar(this.maskedCanvasName, this.backgroundCanvasName, this.avatarCanvasName);

                this._displayAvatarImage(this.avatarCanvasName);
            } else if (this.backgroundOption === BackgroundOption.Image && this.backgroundImageExists) {

                this._drawBackgroundImage(this.backgroundImageName, this.backgroundCanvasName);

                yield this._maskAvatarImage(this.captureCanvasName, this.maskedCanvasName);
                this._drawBackgroundAvatar(this.maskedCanvasName, this.backgroundCanvasName, this.avatarCanvasName);

                this._displayAvatarImage(this.avatarCanvasName);
            }

            this.creating = false;
        } catch (error) {
            this.creating = false;

            console.warn(LogPrefix, 'Create avatar error', error);
        }
    };

    getAvatar = function* getAvatar(id) {
        try {
            console.log(LogPrefix, 'Getting avatar');
            const result = yield axios.get(this.serverContextPath + `/api/v1/avatars/${id}`);
            this.loadAvatarImage = result.data;
        } catch (error) {
            console.log(LogPrefix, `Get avatar for error`);
        }
    };

    _displayAvatarImage = function* (sourceCanvasName) {
        console.log(LogPrefix, `DisplayAvatarImage: source=${sourceCanvasName}`);

        const sourceCanvas = document.getElementById(sourceCanvasName);
        if (sourceCanvas) {
            const avatarImage = yield sourceCanvas.toDataURL(AvatarType);

            this.avatarImage = avatarImage;
        } else {
            console.warn(LogPrefix, 'DisplayAvatarImage error : Source canvas not exists');
        }
    };

    _uploadAvatarImage = function* () {
        console.log(LogPrefix, `UploadAvatarImage : source=${this.avatarCanvasName}`);

        const sourceCanvas = document.getElementById(this.avatarCanvasName);

        if (sourceCanvas) {
            const avatarImage = sourceCanvas.toDataURL(AvatarType);

            yield axios.post(this.serverContextPath + `/api/v1/avatars/${this.userId}`, avatarImage, {headers: {'Content-Type': 'plain/text'}});

            const result = yield axios.get(this.serverContextPath + `/api/v1/avatars/${this.userId}`);
            this.loadAvatarImage = result.data;

            console.log(LogPrefix, 'UploadAvatarImage success');
        } else {
            console.warn(LogPrefix, 'UploadAvatarImage error : Source canvas not exists');
        }
    };
    _uploadTeamAvatarImage = function* (teamId) {
        console.log(LogPrefix, `UploadAvatarImage : source=${this.avatarCanvasName}`);

        const sourceCanvas = document.getElementById(this.avatarCanvasName);

        if (sourceCanvas) {
            const avatarImage = sourceCanvas.toDataURL(AvatarType);
            yield axios.post(this.serverContextPath + `/api/v1/avatars/team/${teamId}`, avatarImage, {headers: {'Content-Type': 'plain/text'}});
            console.log(LogPrefix, 'UploadAvatarImage success');
        } else {
            console.warn(LogPrefix, 'UploadAvatarImage error : Source canvas not exists');
        }
    };

    _maskAvatarImage = function* maskAvatarImage(sourceCanvasName, maskedCanvasName) {
        console.log(LogPrefix, `MaskAvatarImage from ${sourceCanvasName} to ${maskedCanvasName}`);

        const sourceCanvas = document.getElementById(sourceCanvasName);
        const maskedCanvas = document.getElementById(maskedCanvasName);

        if (sourceCanvas && maskedCanvas) {
            const net = yield bodyPix.load();
            const segmentation = yield net.segmentPerson(sourceCanvas);
            console.log(LogPrefix, 'Bodypix segment person success', segmentation);

            const backgroundMask = bodyPix.toMask(segmentation, {r: 0, g: 0, b: 0, a: 0}, {r: 0, g: 255, b: 0, a: 255});
            console.log(LogPrefix, 'Mask', backgroundMask);

            bodyPix.drawMask(maskedCanvas, sourceCanvas, backgroundMask, 1, 0, false);
            console.log(LogPrefix, 'Draw green mask success');

            console.log(LogPrefix, 'MaskAvatarImage success');
        } else {
            throw new Error('MakeAvatarImage error : Can not found canvas');
        }
    };

    _captureVideo = () => {
        console.log(LogPrefix, 'CaptureVideo');

        const videoElement = document.getElementById(this.videoElementName);
        const captureCanvas = document.getElementById(this.captureCanvasName);

        if (videoElement && captureCanvas) {
            const captureContext = captureCanvas.getContext('2d');

            const videoWidth = videoElement.videoWidth;
            const videoHeight = videoElement.videoHeight;
            const sx = videoWidth > videoHeight ? (videoWidth - videoHeight) / 2 : 0;
            const sy = videoHeight > videoWidth ? (videoHeight - videoWidth) / 2 : 0;
            const sWidth = videoWidth > videoHeight ? videoHeight : videoWidth;
            const sHeight = videoHeight > videoWidth ? videoWidth : videoHeight;

            // console.log(LogPrefix, `Video (width=${videoWidth}, height=${videoHeight})`);
            // console.log(LogPrefix, `Source (x=${sx}, y=${sy}, width=${sWidth}, height=${sHeight})`);
            // console.log(LogPrefix, `Target (x=0, y=0, width=${AvatarWidth}, height=${AvatarHeight})`);
            captureContext.drawImage(videoElement, sx, sy, sWidth, sHeight, 0, 0, AvatarWidth, AvatarHeight);

            this.setCaptured(true);
            console.log(LogPrefix, 'CaptureVideo success');
        } else {
            throw new Error('CaptrueVideo error : Can not found video element or canvas');
        }
    }

    _copyAvatar = (sourceCanvasName, destCanvasName) => {
        console.log(LogPrefix, `CopyAvatar : from ${sourceCanvasName} to ${destCanvasName}`);

        const sourceCanvas = document.getElementById(sourceCanvasName);
        const destCanvas = document.getElementById(destCanvasName);

        if (sourceCanvas && destCanvas) {
            const sourceContext = sourceCanvas.getContext('2d');
            const destContext = destCanvas.getContext('2d');

            const frame = sourceContext.getImageData(0, 0, AvatarWidth, AvatarHeight);
            destContext.putImageData(frame, 0, 0);
        }
    }

    _drawBackgroundAvatar = (maskedCanvasName, backgroundCanvasName, destCanvasName) => {
        console.log(LogPrefix, `DrawBackgroundAvatar : from ${maskedCanvasName} and ${backgroundCanvasName} to ${destCanvasName}`);

        const maskedCanvas = document.getElementById(maskedCanvasName);
        const backgroundCanvs = document.getElementById(backgroundCanvasName);
        const destCanvas = document.getElementById(destCanvasName);

        if (maskedCanvas && backgroundCanvs && destCanvas) {
            const maskedContext = maskedCanvas.getContext('2d');
            const backgroundContext = backgroundCanvs.getContext('2d');
            const destContext = destCanvas.getContext('2d');

            const frame = maskedContext.getImageData(0, 0, AvatarWidth, AvatarHeight);
            this._setMaskToFrame(frame, backgroundContext.getImageData(0, 0, AvatarWidth, AvatarHeight));
            destContext.putImageData(frame, 0, 0);

            console.log(LogPrefix, 'DrawBackgroundAvatar success');
        } else {
            throw new Error(`DrawBackgroundAvatarerror : Can not found canvas`);
        }
    }

    _drawBackgroundColor = (color, destCanvasName) => {
        console.log(LogPrefix, `DrawBackgroundColor : color ${color} to ${destCanvasName}`);

        const destCanvas = document.getElementById(destCanvasName);

        if (destCanvas) {
            const destContext = destCanvas.getContext('2d');

            destContext.fillStyle = color;
            destContext.fillRect(0, 0, AvatarWidth, AvatarHeight);

            console.log(LogPrefix, 'DrawBackgroundColor success');
        } else {
            throw new Error('DrawBackgroundColor error: Can not found canvas');
        }
    }

    _drawBackgroundImage = (imageName, destCanvasName) => {
        console.log(LogPrefix, `DrawBackgroundImage : from ${imageName} to ${destCanvasName}`);

        const imageElement = document.getElementById(imageName);
        const destCanvas = document.getElementById(destCanvasName);

        if (imageElement && destCanvas) {
            const destContext = destCanvas.getContext('2d');

            destContext.clearRect(0, 0, destCanvas.width, destCanvas.height);

            destContext.drawImage(imageElement, 0, 0, AvatarWidth, AvatarHeight);

            console.log(LogPrefix, 'DrawBackgroundImage success');
        } else {
            throw new Error('DrawBackgroundImage error: Can not found image element or canvas');
        }
    }

    _setMaskToFrame = (frame, backFrame) => {
        let l = frame.data.length / 4;

        for (let i = 0; i < l; i++) {
            const r = frame.data[i * 4 + 0];
            const g = frame.data[i * 4 + 1];
            const b = frame.data[i * 4 + 2];
            const a = frame.data[i * 4 + 3];
            if (r === 0 && g === 255 && b === 0 && a === 255) {
                if (backFrame) {
                    frame.data[i * 4 + 0] = backFrame.data[i * 4 + 0];
                    frame.data[i * 4 + 1] = backFrame.data[i * 4 + 1];
                    frame.data[i * 4 + 2] = backFrame.data[i * 4 + 2];
                    frame.data[i * 4 + 3] = backFrame.data[i * 4 + 3];
                } else {
                    frame.data[i * 4 + 3] = 0;
                }
            }
        }
    }
};
