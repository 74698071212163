import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import {Button, DialogActions, DialogContent, IconButton, Typography, Box} from "@material-ui/core";
import {ReactComponent as Close} from "./images/Close.svg";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles({
    dialogBox: {

        "& .MuiDialogContent-root": {
            padding: 0
        },
        "& .MuiDialogActions-root": {
            padding: 16
        }
    },
    dialogTitleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 16,
        color: '#303030',
        fontWeight: 800,
    },
    textStyle: {
        borderTop: '1px solid #bfbfbf',
        borderBottom: '1px solid #bfbfbf',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#303030',
        lineHeight: 2,
        padding: '16px 20px',
        wordBreak: 'keep-all',
    },
    btnStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#fff',
        padding: '8px 12px'
    }
});

export default function DeleteDialog(props) {
    const classes = useStyles();
    const {open, title, msg, changeDialogOpen} = props;

    const handleSubmit = () => {
        const {changeDialogOpen, submit} = props;
        changeDialogOpen(false);
        submit();
    }

    return (
        <Dialog open={open} fullWidth={true} maxWidth={'xs'} className={classes.dialogBox}>
            <DialogTitle>
                <Box className={classes.dialogTitleBox}>
                    <Typography className={classes.titleStyle}>{title}</Typography>

                    <IconButton onClick={() => {
                        changeDialogOpen(false)
                    }} style={{padding: 5}}>
                        <Close style={{width: 20, height: 20}}/>
                    </IconButton>
                </Box>

            </DialogTitle>
            <DialogContent>
                <Typography variant="subtitle1" component="h2" className={classes.textStyle}>
                    {
                        msg.split("\n").map((line, index) => {
                            return (
                                <span key={index}>
                              {line}<br/>
                          </span>);
                        })}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        changeDialogOpen(false);
                    }}
                    className={classes.btnStyle}
                    style={{background: '#a3a8af'}}
                >
                    <FormattedMessage id="cancel"/>
                </Button>
                <Button
                    onClick={handleSubmit}
                    className={classes.btnStyle}
                    style={{background: '#ff0000'}}
                >
                    <FormattedMessage id="delete"/>
                </Button>
            </DialogActions>
        </Dialog>
    );
}