import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    Typography, IconButton
} from "@material-ui/core";
import LoginLogo from "../../common/images/loginLogo.png";
import {ReactComponent as Close} from "../../common/images/Close.svg"
import {inject, observer} from "mobx-react";
import { withRouter } from 'react-router-dom';
import {State, UserType} from '../../stores/AuthStore'
import * as Params from "../../common/Params";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    loginBtn: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 17,
        fontWeight: 800,
        color: '#0d0d0d'
    },
    dialogBox: {
        "& .MuiDialog-paper": {
            borderRadius: 30,
            padding: '15px 40px'
        },
        "& .MuiDialogContent-root": {
            padding: 0
        },
        "& .MuiDialogTitle-root": {
            padding: '0 90px'
        }
    },
    TabletDialogBox: {
        "& .MuiDialog-paper": {
            borderRadius: 30,
            padding: '100px 30px 80px'
        },
        "& .MuiDialogContent-root": {
            padding: 0
        },
        "& .MuiDialogTitle-root": {
            padding: '0 90px'
        }
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        textAlign:'center'
    },
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 27,
        fontWeight: 300,
        color: '#0d0d0d',
        textAlign: 'center',
        marginBottom: 20
    },
    lblStyle: {
        width: 65,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 16,
        fontWeight: 300,
        color: '#0d0d0d',
    },
    textStyle: {
        width: 300,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 15,
        fontWeight: 300,
        padding: '13px 15px',
        borderRadius: 10,
        background: '#ececec',
        border: 0,
        "&::placeholder": {
            color: '#6c6969'
        }
    },
    buttonStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 20,
        color: '#fff',
        fontWeight: 800,
        borderRadius: 10,
        background: '#dc524f',
        padding: '40px 45px',
        "&:hover": {
            background: '#dc524f',
        }
    },
    btnStyle:{
        width:'49%',
        padding:'14px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#fff',
        "&:hover":{
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle1:{
        width:'49%',
        padding:'14px 0',
        background:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#fff',
        marginRight:'2%',
        "&:hover":{
            background:'#a3a8af'
        }
    },
    checkboxText: {
        width: 418,
        paddingLeft: 65,
        "& .MuiTypography-root": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#0d0d0d',
        }
    },
    pwFindText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        textDecoration: 'none'
    },
    errorBox: {
        margin: '5px 0 0px 65px',
    },
    errorText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#ff0000',
        fontWeight: 600,
    }
});

@inject('authStore', 'orgStore')
@observer
class PasswordChangeDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
        }
    }

    moveTo = (url) => {
        this.props.history.push(url);
    }

    render() {
        const {classes, authStore, orgStore } = this.props;
        const handleClose = () => {
            this.setState({open:false})
            authStore.changeLoginState(State.Authenticated);
            if(authStore.loginUser.type === UserType.Admin) this.moveTo('/');
            else this.moveTo('/mytimeline');
        };
        const handleChangePassword =()=> {
            this.setState({open:false})
            authStore.changeLoginState(State.Authenticated);
            this.moveTo('/profilesettings');
        }
        const isTabletMode = Params.isTabletMode(1024);
        return (
            <div>
                {isTabletMode ?
                    <Dialog
                        maxWidth='md'
                        open={this.state.open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        className={classes.TabletDialogBox}
                    >
                        <DialogTitle id="form-dialog-title" className={classes.titleBox}>
                            <img src={LoginLogo} alt="로고"/>
                            <Typography className={classes.titleText} style={{fontSize:20}}>
                                <FormattedMessage id={"msg.change_new_password"} />
                            </Typography>
                        </DialogTitle>
                        <DialogContent>
                            <Box display="flex" justifyContent="center" style={{margin: '50px auto'}}>
                                <Button className={classes.btnStyle1} onClick={handleClose}>
                                    <FormattedMessage id={"change_skip"} />
                                </Button>
                                <Button className={classes.btnStyle} onClick={handleChangePassword}>
                                    <FormattedMessage id={"change_now"} />
                                </Button>
                            </Box>
                        </DialogContent>
                    </Dialog>
                    :
                    <Dialog
                        maxWidth='md'
                        open={this.state.open}
                        onClose={handleClose}
                        aria-labelledby="form-dialog-title"
                        className={classes.dialogBox}
                    >
                        <Box display='flex' justifyContent='flex-end'>
                            <IconButton onClick={handleClose} style={{marginRight: -30}}>
                                <Close/>
                            </IconButton>
                        </Box>
                        <DialogTitle id="form-dialog-title">
                            <Box className={classes.titleBox}>
                                {
                                    orgStore.orgImages.Logo
                                        ? <img src={orgStore.orgImages.Logo} alt="logoImage" style={{width: 'auto', height: 'auto', maxWidth: 174, maxHeight: 80}}/>
                                        : <img src={LoginLogo} alt="logoImage"/>
                                }
                                <Typography className={classes.titleText}>
                                    <FormattedMessage id={"msg.change_new_password"} />
                                </Typography>
                            </Box>
                        </DialogTitle>
                        <DialogContent>
                            <Box display="flex" justifyContent="center" style={{margin:'50px auto'}}>
                                <Button className={classes.btnStyle1} onClick={handleClose}>
                                    <FormattedMessage id={"change_skip"} />
                                </Button>
                                <Button className={classes.btnStyle} onClick={handleChangePassword}>
                                    <FormattedMessage id={"change_now"} />
                                </Button>
                            </Box>
                        </DialogContent>
                    </Dialog>
                }
            </div>
        );
    }
};

export default withRouter(withStyles(style)(PasswordChangeDialog));