import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import Calendar from 'react-calendar';
import '../../common/css/Calendar.css';
import * as dayjs from "dayjs";
import {DATE_UTIL} from "../../common/util/date.util";

const style = theme => ({
    root: {
        // 달력
        "& .react-calendar":{
            "& .saturday": {
                color: 'black',
            }
        },
        "& .react-calendar__navigation__label__labelText":{
            fontFamily:'NanumSquareRoundOTF',
            color:'#000',
            fontSize:15,
            fontWeight:800,
        } ,
        "& .react-calendar__month-view__weekdays__weekday":{
            "& abbr":{
                fontFamily:'NanumSquareRoundOTF',
                fontSize:14,
                color:'#4f5660',
                fontWeight:'normal',
                textDecoration:'none'
            }
        },
        "& .react-calendar__month-view__days__day":{
            // borderRadius:'50%',
            "& abbr":{
                fontFamily:'NanumSquareRoundOTF',
                fontSize:12,
                fontWeight:800,
            }
        },
        "& .react-calendar__tile--now":{
            background: "none",
            color: 'black'
        },
        "& .react-calendar__tile--now:hover": {
            background: '#e6e6e6',
            color: 'black'
        },
        "& .react-calendar__tile--active:hover": {
            background: '#006edc',
            color: 'white'
        },
        "& .today": {
            background: '#a3dfff',
            color:'#fff',
            // borderRadius:"50% !important",
            "& .react-calendar__tile--active": {
                background: '#006edc !important',
                color: 'white !important'
            }
        },
    },
});
class CalendarShape extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: new Date(),
        }
    }

    onChange = (value) =>{
        let convertTimeZone = dayjs(value).tz(DATE_UTIL.getTimeZone(), true)
        this.props.onChangeDate(convertTimeZone)
    }
    // getDateStr = (myDate) => {
    //     return (myDate.getFullYear() + '-' + (myDate.getMonth() + 1) + '-' + myDate.getDate())
    // }
    minDate(){
        let d = new Date(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT))
        let dayOfMonth = d.getDate()
        d.setDate(dayOfMonth -1)
        // return this.getDateStr(d)
        return d;
    }
    // maxDate(){
    //     let d = new Date(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT))
    //     let dayOfMonth = d.getDate()
    //     d.setDate(dayOfMonth + 14)
    //     return d;
    //     // return this.getDateStr(d)
    // }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Calendar
                    locale={DATE_UTIL.getLanguage()}
                    calendarType="US"
                    onChange={(value)=>this.onChange(value)}
                    prev2Label={null}
                    next2Label={null}
                    // maxDate={new Date(this.maxDate())}
                    // minDate={new Date(this.minDate())}
                    maxDetail={"month"}
                    minDetail={"year"}
                    tileDisabled={({date, view}) =>{
                            // const maxDate = this.maxDate();
                            const minDate = this.minDate();
                        if(view === 'month') {
                            // if (date > maxDate) return true;
                            if (date < minDate) return true;
                        }
                    }}
                    tileClassName={({ activeStartDate, date, view }) => {
                        // const isOtherMonth = dayjs(activeStartDate).month() === dayjs(date).month();
                        const isToday = dayjs(date).format(DATE_UTIL.FORMAT.DATE_FORMAT) === DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT);
                        if(view === 'month') {
                            // if (date.getDay() === 6) {
                            //     if (isOtherMonth) return 'saturday'
                            //     else return null;
                            // }
                            if(isToday) return 'today';
                            return null;
                        }
                        // return (view === 'month' && date.getDay() === 6 && isOtherMonth) ? 'saturday' : null;
                    }}
                    formatDay={(locale, date) => dayjs(date).locale(locale).format('D')}
                />
            </div>
        );
    }
}

export default withStyles(style)(CalendarShape);