import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Box,
    Typography,
    IconButton, Button, Dialog, DialogTitle, DialogActions,
} from "@material-ui/core";
import MobileTimelineMoreButton from "./MobileTimelineMoreButton"
import {ReactComponent as SmileyIcon} from "../../../common/images/SmileyIcon.svg";
import {ReactComponent as ClockIcon} from "../../../common/images/ClockIcon.svg";
import MobileTeamMembersSideBar from "../../../components/MobileTeamMembersSideBar";
import {ReactComponent as MobileMeetingLogIcon} from "../../../common/images/MobileMeetingLogIcon.svg";
import {Link, withRouter} from "react-router-dom";
import {ReactComponent as MobileStatisticsIcon} from "../../../common/images/MobileStatisticsIcon.svg";
import * as dayjs from "dayjs";
import {DATE_UTIL} from "../../../common/util/date.util";
import {inject, observer} from "mobx-react";
import {UTIL} from "../../../common/util/common.util";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root: {
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        color:'#0d0d0d',
        fontWeight:800,
        marginBottom:12
    },
    textareaBox:{
        width:'100%',
        maxHeight:150,
        paddingRight:10,
        overflow:'auto',
        marginBottom:12,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        color:'#303030',
        textAlign:'justify',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.1rem',
        color:'#0d0d0d',
        fontWeight:300,
        marginLeft:5
    },
    learningIcon:{
        width:15,
        height:15,
        "& path":{
            fill:'#664cff',
        },
    },
    iconButton:{
        marginTop:5,
        padding:0,
        "& circle":{
            fill:'#ededed'
        }
    },
    buttonStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize: '1.0rem',
        paddingBottom:4,
        paddingLeft: 4,
        backgroundColor: 'rgba(0,0,0,0)',
        color: 'black',
        textDecoration:'underline',
        textDecorationColor: '#c0c2c3'
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize: '1.0rem',
        color:'#0d0d0d',
        fontWeight:300,
        marginLeft:3
    },
    TabletDialogBox: {
        fontFamily:'NanumSquareRoundOTF',
        "& .MuiDialog-paper": {
            borderRadius: 10,
            padding: '60px 10px 14px 10px'
        },
        "& .MuiDialogContent-root": {
            padding: 0
        },
        "& .MuiDialogTitle-root": {
            padding: '0 60px'
        }
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        textAlign:'center',
        whiteSpace:'pre'
    },
    btnStyle:{
        width: 68,
        height: 22,
        padding:'14px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily:'NanumSquareRoundOTF',
        fontSize: '1.0rem',
        fontWeight:600,
        color:'#fff',
        "&:hover":{
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle1:{
        width: 68,
        height: 22,
        padding:'14px 0',
        background:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize: '1.0rem',
        fontWeight:600,
        color:'#fff',
        marginRight:'2%',
        "&:hover":{
            background:'#a3a8af'
        }
    },
    textStyle3:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize: '1.0rem',
        color:'#0d0d0d',
        fontWeight:300,
        marginLeft:3
    },
    titleBox2: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        textAlign:'center',
        whiteSpace:'pre'
    },
});

@inject('authStore', 'userLoungeStore', 'orgStore', 'timelineStore', 'progressStore')
@observer
class MobileRoomInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSendEmailDialog: false,
            confirmSendEmailDialog: false,
            resultMsg: ''
        }
    };

    handleSendEmail = (roomId) => {
        const {intl, authStore, timelineStore, progressStore} = this.props;

        progressStore.setIsLoading(true);
        this.closeSendEmailDialog();
        timelineStore.sendRoomEmail(intl, roomId, authStore.loginUser.id, this.openConfirmSendEmailDialog);
    };

    openSendEmailDialog = () => {
        this.setState({openSendEmailDialog: true})
    };

    closeSendEmailDialog = () => {
        this.setState({openSendEmailDialog: false})
    };

    openConfirmSendEmailDialog = (msg) => {
        const {progressStore} = this.props;

        progressStore.setIsLoading(false);
        this.setState({confirmSendEmailDialog: true, resultMsg: msg})
    };

    closeConfirmSendEmailDialog = () => {
        this.setState({confirmSendEmailDialog: false, resultMsg: ''})
    };

    render() {
        const { classes, intl, history ,authStore, userLoungeStore, room, closeBottom, orgStore } = this.props;

        const roomState = UTIL.getRoomState(authStore.loginUser.id, room);
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);

        const team = userLoungeStore.selectedTeamId !== 0 ? (userLoungeStore.selectedTeam.id !== undefined ? userLoungeStore.selectedTeam : undefined) : userLoungeStore.teamList.find(t => t.id === room.teamId);
        const member = team !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
        const isLeader = (member !== undefined) && (member.type === 'Leader');

        return (
            <div className={classes.root}>
                <Box>
                    {
                        ((room.userId === authStore.loginUser.id) && !roomState.isLive) &&
                        <Box display='flex' justifyContent='flex-end'>
                            <MobileTimelineMoreButton room={room} closeBottom={closeBottom}/>
                        </Box>
                    }
                    <Typography className={classes.titleText}>
                        {room.name}
                    </Typography>
                </Box>
                <Box>
                    <Typography className={classes.textareaBox}>
                        {room.comment}
                    </Typography>
                    <Box>
                        <Box display='flex' alignItems='center'>
                            {room.type === 'Conference' ? <SmileyIcon style={{width:15,height:15}}/> : <SmileyIcon className={classes.learningIcon}/>}
                            <Typography className={classes.textStyle1}>{room.teamName} | {room.userName} {room.userTitle}</Typography>
                        </Box>
                        <Box display='flex' alignItems='center' pb={2} pt={1}>
                            {room.type === 'Conference' ? <ClockIcon style={{width:15,height:15}}/> : <ClockIcon className={classes.learningIcon}/>}
                            <Typography className={classes.textStyle1} style={{color:'#4f5660',fontWeight:800}}>{DATE_UTIL.getTimeZoneLanguage(room.startDatetime).format('A')} {dayjs(room.startDatetime).format('HH:mm')} ~ {DATE_UTIL.getTimeZoneLanguage(room.endDatetime).format('A')} {dayjs(room.endDatetime).format('HH:mm')}</Typography>
                        </Box>
                        <MobileTeamMembersSideBar members={room.members} isTeam={false} configs={orgStore.orgConfigs}/>
                        {/*시간이 지난뒤 회의일경우*/}
                        {(dayjs(room.endDatetime).isBefore(currentDatetime) && roomState.isConference) &&
                            <Link to="/MeetingMinutes" className={classes.link}>
                                <IconButton className={classes.iconButton}>
                                    <MobileMeetingLogIcon/>
                                </IconButton>
                            </Link>
                        }
                        {/*시간이 지난뒤 학습일경우*/}
                        {(dayjs(room.endDatetime).isBefore(currentDatetime) && !roomState.isConference && room.statistics && (isLeader || (room.userId === authStore.loginUser.id))) &&
                            <IconButton className={classes.iconButton} onClick={() => history.push(`/rooms/${room.id}/statistics`)}>
                                <MobileStatisticsIcon/>
                            </IconButton>
                        }
                    </Box>
                    {room.userId === authStore.loginUser.id &&
                        <Button className={classes.buttonStyle} onClick={this.openSendEmailDialog}><FormattedMessage id="send_invitation_email"/> ></Button>
                    }
                    {(room.userId === authStore.loginUser.id && room.sendMailDatetime) &&
                        <Typography className={classes.textStyle3}>(<FormattedMessage id="recently_send"/>: {DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(room.sendMailDatetime), `YYYY${intl.formatMessage({id: "calendar_year"})} MMM D${intl.formatMessage({id: "calendar_day"})} hh:mm A`)})</Typography>
                    }
                </Box>
                <Dialog
                    maxWidth='xs'
                    open={this.state.openSendEmailDialog}
                    onClose={this.closeSendEmailDialog}
                    aria-labelledby="form-dialog-title"
                    className={classes.TabletDialogBox}
                >
                    <DialogTitle id="form-dialog-title" className={classes.titleBox2}>
                        <Typography className={classes.titleText} style={{fontSize:'1.4rem'}}>
                            <FormattedMessage id={"msg.confirm_send_invitation_email"} />
                        </Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button className={classes.btnStyle1} onClick={this.closeSendEmailDialog}>
                            <FormattedMessage id={"cancel"} />
                        </Button>
                        <Button className={classes.btnStyle} onClick={() => this.handleSendEmail(room.id)}>
                            <FormattedMessage id={"send"} />
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    maxWidth='xs'
                    open={this.state.confirmSendEmailDialog}
                    onClose={this.closeConfirmSendEmailDialog}
                    aria-labelledby="form-dialog-title"
                    className={classes.TabletDialogBox}
                >
                    <DialogTitle id="form-dialog-title" className={classes.titleBox2}>
                        <Typography className={classes.titleText} style={{fontSize:'1.4rem'}}>
                            {this.state.resultMsg}
                        </Typography>
                    </DialogTitle>
                    <DialogActions>
                        <Button className={classes.btnStyle} onClick={this.closeConfirmSendEmailDialog}>
                            <FormattedMessage id={"ok"} />
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(MobileRoomInformation)));