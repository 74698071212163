import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TablePagination,
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";
import * as TStore from "../../../../stores/TeamStore";
import {UserType, State} from "../../../../stores/UserStore";
import {DATE_UTIL} from "../../../../common/util/date.util";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        padding:'20px 20px',
        background:'#fff',
        height:'100%',
        "& .MuiPaper-elevation1":{
            boxShadow:'none'
        },
        "& .MuiPaper-rounded":{
            borderRadius:0
        },
        "& .MuiTableCell-root":{
            borderBottom:0
        }
    },
    tabletRoot:{
        [theme.breakpoints.down('md')]: {
            "& .MuiTablePagination-caption":{
                fontSize:12
            },
            "& .MuiIconButton-root":{
                padding:0
            }
        },
        padding:'20px 0',
        background:'#fff',
        height:'100%',
        "& .MuiPaper-elevation1":{
            boxShadow:'none'
        },
        "& .MuiPaper-rounded":{
            borderRadius:0
        },
        "& .MuiTableCell-root":{
            borderBottom:0
        }
    },
    paperBox:{
        background:'transparent',
    },
    cellText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#7d86a9',
        fontWeight:600
    },
    tabletCellText:{
        [theme.breakpoints.up('md')]: {
            fontSize:13,
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:11,
        color:'#7d86a9',
        fontWeight:600
    },
    cellText1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#202020',
        maxWidth: 140,
        background:'#f8f8f8',
        fontWeight:600,
        textOverflow: "ellipsis",
        overflow:"hidden",
        whiteSpace: 'nowrap',
    },
    tabletCellText1:{
        [theme.breakpoints.up('md')]: {
            fontSize:13,
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:11,
        color:'#202020',
        maxWidth: 90,
        background:'#f8f8f8',
        fontWeight:600,
        textOverflow: "ellipsis",
        overflow:"hidden",
        whiteSpace: 'nowrap',
    },
    tableRowBox:{
        background:'#fff',
        borderBottom:'1px solid rgba(224, 224, 224, 1)'
    },
    table: {
        background:'transparent',
        border:'0'
    },
    link:{
        color:'#57a4ff',
    }
});


@inject("authStore", "userStore", "teamStore", "progressStore")
@observer
class TeamMemberTable extends Component {
    componentDidMount() {

    }
    componentWillUnmount() {
        // this.props.userStore.memberTableList = [];
    }
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userStore, progressStore} = this.props;
        progressStore.setIsLoading(userStore.getIsLoading);
    }

    handleChangePage = (event, newPage) => {
        const {intl} = this.props;
        this.props.userStore.userTablePage.page = newPage;
        if(this.props.authStore.loginUser.type === UserType.Admin){
            const {memberTreeSelectedTeamId} = this.props.userStore;
            if(!memberTreeSelectedTeamId || memberTreeSelectedTeamId === "-1"){
                this.props.userStore.getUserTableListByAll();
            }else if(memberTreeSelectedTeamId === "-2"){
                this.props.userStore.getUserTableListByNotTeam();
            }else{
                    this.props.userStore.getUserTableListByTeam(intl, memberTreeSelectedTeamId);
            }
        }else{
            this.props.userStore.getUserTableListByTeam(intl, this.props.teamStore.selectTeamByLeader.id);
        }

    }

    handleChangeRowsPerPage = (event) => {
        const {intl} = this.props;
        this.props.userStore.userTablePage.page = 0;
        this.props.userStore.userTablePage.rowsPerPage = parseInt(event.target.value, 10);
        if(this.props.authStore.loginUser.type === UserType.Admin){
            const {memberTreeSelectedTeamId} = this.props.userStore;
            if(!memberTreeSelectedTeamId || memberTreeSelectedTeamId === "-1"){
                this.props.userStore.getUserTableListByAll();
            }else if(memberTreeSelectedTeamId === "-2"){
                this.props.userStore.getUserTableListByNotTeam();
            }else{
                this.props.userStore.getUserTableListByTeam(intl, memberTreeSelectedTeamId);
            }
        }else{
            this.props.userStore.getUserTableListByTeam(intl, this.props.teamStore.selectTeamByLeader.id);
        }

    }
    handleSetDetailUser = (user) => {
        this.props.progressStore.setIsLoading(true);
        this.props.userStore.getUserDetail(user.id);
    }

    handleSetDetailTeamTeamNameInTable = (user) =>{
        const {intl} = this.props;

        if(user.teamCount === 0){
            return "-";
        }else if(user.teamCount === 1){
            return `${user.team}(${user.type === TStore.UserType.Leader ? intl.formatMessage({id: "leader"}) : intl.formatMessage({id: "member"}) })`;
        }else if(1 < user.teamCount){
            const count = user.teamCount-1;
            const result = `${user.team}(${user.type ===  TStore.UserType.Leader ? intl.formatMessage({id: "leader"}) : intl.formatMessage({id: "member"})}) ${intl.formatMessage({id: "others"})}${count}`;
            return result;
        }
    }

    handleIsEnabledText = (user) => {
        const {intl} = this.props;
        const {loginUser} = this.props.authStore;
        const {enabled, teamUserEnabled} = user;

        if (loginUser.type === UserType.Admin){
            return enabled ? intl.formatMessage({id: "enabled"}) : intl.formatMessage({id: "disabled"});
        } else if (loginUser.type === UserType.Normal) {
            return teamUserEnabled ? intl.formatMessage({id: "enabled"}) : intl.formatMessage({id: "disabled"});
        }
    }
    render() {
        const { classes, intl, isTablet } = this.props;
        const { userLoadingState, memberTableList, userTablePage } = this.props.userStore;

        return (
            <div className={isTablet ? classes.tabletRoot : classes.root}>
                <TableContainer component={Paper} className={classes.paperBox}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" className={isTablet ? classes.tabletCellText : classes.cellText}><FormattedMessage id="type"/></TableCell>
                                <TableCell align="center" className={isTablet ? classes.tabletCellText : classes.cellText}><FormattedMessage id="team_name"/>(<FormattedMessage id="role"/>)</TableCell>
                                <TableCell align="center" className={isTablet ? classes.tabletCellText : classes.cellText}><FormattedMessage id="nickname"/></TableCell>
                                <TableCell align="center" className={isTablet ? classes.tabletCellText : classes.cellText}><FormattedMessage id="title"/></TableCell>
                                <TableCell align="center" className={isTablet ? classes.tabletCellText : classes.cellText}><FormattedMessage id="registration_date"/></TableCell>
                                <TableCell align="center" className={isTablet ? classes.tabletCellText : classes.cellText}><FormattedMessage id="state"/></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {memberTableList.length > 0 ? (
                                memberTableList.map((user, index) => (
                                <TableRow key={user.id} className={classes.tableRowBox}>
                                    <TableCell component="th"
                                               scope="row"
                                               align="center"
                                               className={isTablet ? classes.tabletCellText1 : classes.cellText1}>
                                        {user.userType === UserType.Admin ? <FormattedMessage id="management_account"/> : <FormattedMessage id="normal_account"/>}
                                    </TableCell>
                                    <TableCell align="center"
                                               className={isTablet ? classes.tabletCellText1 : classes.cellText1}>
                                        {this.handleSetDetailTeamTeamNameInTable(user)}
                                    </TableCell>
                                    <TableCell align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1} style={{color:'#57a4ff',textDecoration:'underline',cursor:'pointer'}}
                                               onClick={() =>this.handleSetDetailUser(user)}>
                                        {user.name}
                                    </TableCell>
                                    <TableCell align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1}>{user.title ? user.title : "-"}</TableCell>
                                    <TableCell align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1}>{dayjs(user.createdDatetime).format(DATE_UTIL.FORMAT.DEFAULT_FORMAT)}</TableCell>
                                    <TableCell align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1}>
                                        {
                                            this.handleIsEnabledText(user)
                                        }
                                    </TableCell>
                                </TableRow>
                            ))
                            ):(
                                userLoadingState === State.Pending ? (
                                    <TableRow className={classes.tableRowBox}>
                                        <TableCell colSpan={6} align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1}>
                                            <FormattedMessage id="msg.wait"/>
                                        </TableCell>
                                    </TableRow>
                                ):(
                                <TableRow className={classes.tableRowBox}>
                                    <TableCell colSpan={6} align="center" className={isTablet ? classes.tabletCellText1 : classes.cellText1}>
                                        <FormattedMessage id="msg.no_data"/>
                                    </TableCell>
                                </TableRow>
                            ))}

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 15, 20]}
                    labelRowsPerPage={intl.formatMessage({id: "rows_per_page"})}
                    labelDisplayedRows={({ from, to, count }) => intl.formatMessage({id: "table_bottom_text"}, {"count": count, "from": from, "to": to})}
                    component="div"
                    count={userTablePage.total}
                    rowsPerPage={userTablePage.rowsPerPage}
                    page={userTablePage.page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(TeamMemberTable));

