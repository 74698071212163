import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Box,
    Button,
    Grid,
    Typography
} from "@material-ui/core";
import DropzoneImage from "./DropzoneImage";
import TabsText from "./TabsText";
import TabsLogo from "./TabsLogo";
import TabsColor from "./TabsColor";
import TabsTextColor from "./TabsTextColor";
import BrowserPreview from "./BrowserPreview";
import {inject, observer} from "mobx-react";
import TimelinePreview from "./TimelinePreview";
import SkeletonPreview from "./SkeletonPreview";
import AlertDialog from "./AlertDialog";
import {withRouter} from "react-router-dom";
import {PATH_UTIL} from "../../../common/util/path.util";
import {FormattedMessage, injectIntl} from "react-intl";
import {CONFIG_SELECTIONS} from "../../../stores/OrgStore";

const style = theme => ({
    root:{
        padding:'50px 20px',
        "& .MuiButton-root.Mui-disabled":{
            background:'#fff',
            color:'#88889D',
            border:'1px solid #A3A8AF'
        }
    },
    textStyle:{
        width:130,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:'600',
        color:'#0d0d0d'
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#0d0d0d'
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#666666'
    },
    btnStyle:{
        width:135,
        padding:'8px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#fff',
        "&:hover":{
            background:() =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        },
    },
    btnStyle1:{
        width:135,
        padding:'8px 0',
        background:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#fff',
        marginRight:27,
        "&:hover":{
            background:'#a3a8af'
        }
    },
    previewText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#0d0d0d',
        marginTop:65
    },
    previewText1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#0d0d0d',
        marginTop:440
    },
    inputStyle: {
        width: 230,
        padding: '6px 10px',
        borderRadius: 4,
        border: '1px solid #b7bcd6',
        background: '#fff',
        marginBottom: 49,
        marginRight: 40
    },
    inputBtnStyle: {
        width: 110,
        padding: '6px 6px',
        borderRadius: 4,
        background: () => theme.configs.MainBtnColor ?  theme.configs.MainBtnColor : theme.configs.MainColor,
        color: theme.configs.Color,
        "&:hover":{
            background:() =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        },
        marginBottom:49
    },
});




@inject('orgStore', 'progressStore', 'authStore')
@observer
class OrganizationInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading : false,
            textColorSelectionType : props.orgStore.mainTextColorSelection ? CONFIG_SELECTIONS.Simple : CONFIG_SELECTIONS.Direct
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevState.isLoading !== prevProps.orgStore.getIsOrganizationDataLoading) {
            this.setState({isLoading : prevProps.orgStore.getIsOrganizationDataLoading}, ()=> {
                this.props.progressStore.setIsLoading(this.state.isLoading);
            })
        }

    }

    componentWillUnmount() {
        this.props.orgStore.initialPreviewItems();
        this.props.orgStore.initialImgData();
        this.props.progressStore.setIsLoading(false);
    }

    handleSaveBtn = (e) => {
        e.preventDefault();
        const { intl, orgStore } = this.props;
        if(orgStore.haveConfigs) orgStore.requestUpdateOrgConfig(intl);
        else orgStore.requestSaveOrgConfig(intl);
    }

    handleCancelBtn = (e) => {
        e.preventDefault();
        this.props.orgStore.initialPreviewItems();
        this.props.orgStore.initialImgData();
    }

    handleInputBtn = () => {
        const currentUrl = PATH_UTIL.getOriginURL();
        this.props.orgStore.changeOrgURL(currentUrl);
    }

    changeTextColorSelectionType = (type) => {
        this.setState({ textColorSelectionType: type });
    }


    render() {
        const { classes, intl, orgStore } = this.props;
        const { mode, hasError, errorMessage, changeHasError,
                previewConfigs, isOrganizationDataLoading,
                mainColorSelection, subColorSelection, mainTextColorSelection, subTextColorSelection } = orgStore;
        return (
            <div className={classes.root}>
                <Grid container spacing={1}>
                    <Grid item xs={2}>
                        <Typography className={classes.textStyle}><FormattedMessage id="organization_name"/></Typography>
                        <Typography className={classes.textStyle} style={{marginTop:60,marginBottom:62}}>URL</Typography>
                        <Typography className={classes.textStyle} style={{marginTop:60,marginBottom:62}}><FormattedMessage id="admin_email"/></Typography>
                        <Typography className={classes.textStyle} style={{marginTop:20,marginBottom:180}}><FormattedMessage id="main_image_setting"/></Typography>
                        <Typography className={classes.textStyle} style={{marginTop:170,marginBottom:170}}><FormattedMessage id="text"/></Typography>
                        <Typography className={classes.textStyle} style={this.state.textColorSelectionType === CONFIG_SELECTIONS.Simple ? {marginBottom: ""} : {marginBottom: 360}}><FormattedMessage id="text_color"/></Typography>
                        <Typography className={classes.textStyle} style={this.state.textColorSelectionType === CONFIG_SELECTIONS.Simple ? {marginTop: 190, marginBottom:270} : {marginTop: 190, marginBottom:270}}><FormattedMessage id="logo_and_favicon"/></Typography>
                        <Typography className={classes.textStyle}><FormattedMessage id="color_theme"/></Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <input
                            variant="outlined"
                            name="name"
                            type="text"
                            id="name"
                            placeholder={intl.formatMessage({id: "msg.insert_organization_name"})}
                            value={previewConfigs.OrganizationName || ""}
                            className={classes.inputStyle}
                            onChange={e => orgStore.changeOrgTitle(e.target.value)}
                        />
                        <Box display={"flex"}>
                            <input
                                variant={"outlined"}
                                name={"url"}
                                type={"text"}
                                id={"url"}
                                placeholder={intl.formatMessage({id: "msg.insert_url"})}
                                value={previewConfigs.WebviewURL || ""}
                                className={classes.inputStyle}
                                onChange={e => orgStore.changeOrgURL(e.target.value)}
                            />
                            <Button className={classes.inputBtnStyle} onClick={this.handleInputBtn} >
                                <FormattedMessage id="get_url"/>
                            </Button>
                        </Box>
                        <input
                            variant="outlined"
                            name="email"
                            type="text"
                            id="email"
                            placeholder={intl.formatMessage({id: "msg.insert_admin_email"})}
                            value={previewConfigs.AdminEmail || ""}
                            className={classes.inputStyle}
                            onChange={e => orgStore.changeAdminEmail(e.target.value)}
                        />
                        {/* 메인 이미지 설정 */}
                        <Box>
                            <DropzoneImage/>
                            <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.main_image_guide_1"/></Typography>
                            <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.main_image_guide_2"/></Typography>
                        </Box>
                        {/* 문구 */}
                        <TabsText/>
                        {/* 문구 색상 */}
                        <TabsTextColor style={this.state.textColorSelectionType === CONFIG_SELECTIONS.Simple ? {marginBottom: ""} : {marginBottom: 100}}
                            mainColor={previewConfigs.MainTextColor}
                            subColor={previewConfigs.SubTextColor}
                            selectMainColor={orgStore.changeOrgMainTextColor}
                            selectSubColor={orgStore.changeOrgSubTextColor}
                            mainColorSelection={mainTextColorSelection}
                            subColorSelection={subTextColorSelection}
                            changeTextColorSelectionType={this.changeTextColorSelectionType}
                        />
                        {/* 로고 및 파비콘 */}
                        <TabsLogo/>
                        {/* 컬러 테마 */}
                        <TabsColor
                            mainColor={previewConfigs.MainColor}
                            subColor={previewConfigs.SubColor}
                            selectMainColor={orgStore.changeOrgMainColor}
                            selectSubColor={orgStore.changeOrgSubColor}
                            mainColorSelection={mainColorSelection}
                            subColorSelection={subColorSelection}
                        />

                        <Box display="flex" justifyContent="flex-end" style={{margin:'100px 10% 100px 0'}}>
                            <Button className={classes.btnStyle1} onClick={this.handleCancelBtn}><FormattedMessage id="cancel"/></Button>
                            <Button
                                className={classes.btnStyle}
                                disabled={mode}
                                onClick={this.handleSaveBtn}
                            >
                                <FormattedMessage id="save"/>
                            </Button>
                        </Box>
                    </Grid>
                    <Grid item xs={5}>
                        <Box>
                            <Typography className={classes.previewText}><FormattedMessage id="preview"/></Typography>
                            {/* 미리보기 Box*/}
                            <Box>
                                {
                                    isOrganizationDataLoading === true
                                        ? <SkeletonPreview />
                                        : <BrowserPreview />
                                }

                            </Box>
                            <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.preview_guide"/></Typography>
                        </Box>
                        <Box pt={10}>
                            <Typography className={classes.previewText1}><FormattedMessage id="preview"/></Typography>
                            <Box>
                                {
                                    isOrganizationDataLoading === true
                                    ? <SkeletonPreview />
                                    : <TimelinePreview />
                                }
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <AlertDialog open={hasError} title={intl.formatMessage({id: "error_message"})} msg={errorMessage} changeDialogOpen={changeHasError} submit={changeHasError}/>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(OrganizationInformation)));