import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import DeviceChart from "./DeviceChart";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:16,
        fontWeight:600,
        color:'#0d0d0d',
    },
});

@inject('statisticsStore')
@observer
class Device extends Component {
    render() {
        const { classes, intl } = this.props;
        const { userDeviceList } = this.props.statisticsStore;

        const chartData = [];
        userDeviceList.forEach((ud) => {
            const deviceType = ud.deviceType === "etc" ? intl.formatMessage({id: "desktop"}) : ud.deviceType === "ios" ? "ios" : ud.deviceType === "android" ? intl.formatMessage({id: "android"}) : "Unknown";
            const color = ud.deviceType === "etc" ? '#0047ac' : ud.deviceType === "ios" ? '#b7defe' : '#0076de';
            chartData.push({"name": deviceType, "color": color, "count": ud.userCount, "users": ud.users});
        })

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}><FormattedMessage id="device_status"/></Typography>
                <DeviceChart data={chartData} />
            </div>
        );
    };
}

export default withStyles(style)(injectIntl(Device));