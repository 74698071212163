import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Tabs,
    Tab,
    Box,
    Typography,
} from "@material-ui/core";
import DropzoneLogo from "./DropzoneLogo";
import {CONFIG_SELECTIONS} from "../../../stores/OrgStore";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTab-textColorInherit.Mui-selected":{
            color:'#202020',
            fontWeight:'bold'
        },
        "& .MuiTab-root":{
            minWidth:69,
            minHeight:10
        },
        "& .MuiTabs-root":{
            minHeight:10
        },
        "&  .MuiTabs-indicator":{
            height:3,
        }
    },
    textareaStyle:{
        width:"90%",
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#8d909e',
        border:'1px solid #b7bcd6',
        padding:'11px 5px',
        borderRadius:4,
        resize:'none',
        marginTop:24,
        "&::placeholder":{
            color:'#8d909e'
        }
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#666666'
    }
}));

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth:'100%',
            width:'100%',
            backgroundColor:'#1676ff',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#7d86a9',
        fontSize:12,
        fontFamily:'NanumSquareRoundOTF',
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <Box
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>
                {children}
            </Box>
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function TabsLogo(props) {
    const intl = useIntl();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { isTablet } = props;
    const [type, setType] = React.useState(CONFIG_SELECTIONS.Logo);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(newValue === 0) setType(CONFIG_SELECTIONS.Logo);
        if(newValue === 1) setType(CONFIG_SELECTIONS.Favicon);
    };

    return (
        <div className={classes.root} style={{marginTop:isTablet ? 20 : 32 }}>
            <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example" style={{borderBottom: '1px solid #dddddd',width:138}}>
                <StyledTab label={intl.formatMessage({id: "logo"})} />
                <StyledTab label={intl.formatMessage({id: "favicon"})} />
            </StyledTabs>

            <TabPanel value={value} index={0}>
                <DropzoneLogo type={type}/>
                <Typography className={classes.textStyle}>
                    * <FormattedMessage id="msg.logo_and_favicon_guide_1"/><br/>
                    * <FormattedMessage id="msg.logo_upload_guide"/><br/>
                    * <FormattedMessage id="msg.logo_and_favicon_guide_2"/>
                </Typography>
            </TabPanel>
        </div>
    );
}

