import React, {Component} from 'react';
import {Link, withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {
    Typography,
    Box,
    IconButton,
    Tooltip
} from "@material-ui/core";
import {TimelineOppositeContent} from "@material-ui/lab";
import { ReactComponent as MeetingLogIcon} from "../../../common/images/MeetingLogIcon.svg";
import { ReactComponent as StatisticsIcon} from "../../../common/images/StatisticsIcon.svg";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {DATE_UTIL} from "../../../common/util/date.util";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

dayjs.extend(utc);
dayjs.extend(timezone);

const style = theme => ({
    root:{
        marginTop:50,
        "& .MuiTimeline-root":{
            paddingLeft:40,
            paddingRight:0
        },
        "& .MuiTimelineOppositeContent-root":{
            flex:0,
            padding:0,
            margin:0,
        },
        "& .MuiTimelineContent-root":{
            paddingTop:0,
            paddingLeft:0
        },
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
        width:40,
        height:40,
        marginBottom:8,
    },
    endBox:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:10,
        color:'#fff',
        background:'#1a5177',
        width:40,
        padding:'2px 0',
        textAlign:'center',
        marginTop:4,
        marginBottom:20
    },
    iconButton:{
        padding:0,
        marginBottom:8,
        "&:hover":{
            background:'transparent'
        }
    },
    timeText:{
        width:80,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        fontWeight:800,
        color:'#a3a8af',
        textAlign:'left'
    },
    liveBox:{
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        background:'#fb4a59',
        width:40,
        padding:'2px 0',
        textAlign:'center',
        marginTop:4
    },
    circle:{
        width:3,
        height:3,
        background:'#fff',
        borderRadius:50,
        marginRight:5
    },
    liveText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:10,
        color:'#fff',
    },
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        padding:'4px 4px',
        border:'0.3px solid #000000',
        background:'#fffff5',
        fontFamily:'NanumSquareRoundOTF',
        fontSize: 11,
        color:'#000',
        borderRadius:'0',
        marginLeft:5,
        marginTop:5
    },
}))(Tooltip);

@inject('authStore', 'userLoungeStore')
@observer
class TimeLineItem extends Component {
    render() {
        const { classes, intl, history, authStore, userLoungeStore, isFirst, room, isFirstByDate } = this.props;

        const team = userLoungeStore.selectedTeamId !== 0 ? (userLoungeStore.selectedTeam.id !== undefined ? userLoungeStore.selectedTeam : undefined) : userLoungeStore.teamList.find(t => t.id === room.teamId);
        const member = team !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
        const isLeader = (member !== undefined) && (member.type === 'Leader');

        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);

        return (
            <TimelineOppositeContent style={{marginTop:-8}}>
                <Typography className={classes.timeText}>
                    {isFirst ?
                        isFirstByDate ?
                            dayjs(room.startDatetime).format(DATE_UTIL.FORMAT.AMPM_FORMAT)
                            :
                            dayjs(room.startDatetime).format('hh:mm A')
                            :
                        ''
                    }
                </Typography>

                {/* (지난시간) */}
                {dayjs(room.endDatetime).isBefore(currentDatetime) &&
                    <>
                        <Box className={classes.endBox}>
                            <FormattedMessage id="end"/>
                        </Box>
                        <Box display='flex' flexDirection='column'>
                        {room.type === 'Conference' ?
                            <LightTooltip title={intl.formatMessage({id: "view_minutes"})} placement="right">
                                <Link to="/meetingminutes" className={classes.link}>
                                    <IconButton className={classes.iconButton}>
                                        <MeetingLogIcon/>
                                    </IconButton>
                                </Link>
                            </LightTooltip>
                            :
                            room.statistics && (isLeader || (room.userId === authStore.loginUser.id)) ?
                                <LightTooltip title={intl.formatMessage({id: "view_statistics"})} placement="right">
                                    <IconButton className={classes.iconButton} onClick={() => history.push(`/rooms/${room.id}/statistics`)}>
                                        <StatisticsIcon/>
                                    </IconButton>
                                </LightTooltip>
                                :
                                ''
                        }
                        </Box>
                    </>
                }

                {/* (현재) */}
                {(dayjs(currentDatetime).isBetween(room.startDatetime, room.endDatetime) || dayjs(currentDatetime).isSame(room.startDatetime) || dayjs(currentDatetime).isSame(room.endDatetime)) &&
                <Box className={classes.liveBox}>
                    <span className={classes.circle}></span> <Typography className={classes.liveText}>LIVE</Typography>
                </Box>
                }
            </TimelineOppositeContent>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(TimeLineItem)));
