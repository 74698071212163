import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import {
    Dialog,
    Slide,
    IconButton,
    Typography,
    Box, Tooltip
} from "@material-ui/core";
import {ReactComponent as MobileClose} from "../../../common/images/MobileClose.svg";
import {ReactComponent as MobileTeamArrow} from "../../../common/images/MobileTeamArrow.svg";
import {ReactComponent as LeaderIcon} from "../../../common/images/LeaderIcon.svg";
import MobileTeamMembersSideBar from "../../../components/MobileTeamMembersSideBar";
import {inject, observer} from "mobx-react";
import {ReactComponent as MyTeamSettingIcon} from "../../../common/images/MyTeamSettingIcon.svg";
import * as Params from "../../../common/Params";
import ConfirmDialog from "../../../common/ConfirmDialog";
import {withRouter} from "react-router-dom";
import {ManagementUrlCategoryType} from "../../../stores/OrgStore";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonBox: {
        width:'100%',
        border:'1px solid #c0c2c3',
        padding:'10px 11px',
        borderRadius:5,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        marginTop:20
    },
    buttonText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    titleBox:{
        display:'flex',
        justifyContent:'flex-start',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px',
        marginBottom:20
    },
    titleText:{
        width:'100%',
        textAlign:'left',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    iconBtn:{
        padding:5
    },
    teamBox:{
        borderRadius:16,
        padding:'19px 18px',
        background:'#f5f5f5',
        margin:'0 20px 20px'
    },
    teamText1:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:800
    },
    teamText2:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        fontWeight:600,
        textAlign:'justify',
        margin:'11px 0 15px',
    },
    teamText3:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.1rem',
        color: '#0d0d0d',
        fontWeight:500,
    },
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref} />
))

@inject('authStore', 'userLoungeStore', 'timelineStore', 'teamStore', 'progressStore', 'orgStore')
@observer
class MobileTeam extends Component {
    state = {
        teamListOpen: false,
    };

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userLoungeStore, timelineStore, progressStore} = this.props;
        progressStore.setIsLoading(userLoungeStore.getIsLoading || timelineStore.getIsLoading)
    }

    handleClickOpen = () => {
        this.setState({ teamListOpen: true });
    };

    handleClose = () => {
        this.setState({ teamListOpen: false });
    };

    getTeamLeaderList = (team) => {
        let leaderNameList = [];
        const leaderList = team.members.filter(m => (m.type === 'Leader'));
        leaderList.forEach(l => {
            leaderNameList.push(l.name + (l.title !== null ? (' ' + l.title) : ''));
        })
        return leaderNameList.join(', ');
    };

    getIsLeader = (team) => {
        const {authStore} = this.props;

        const leaderList = team.members.filter(m => m.type === 'Leader');
        return !!leaderList.find(l => l.userId === authStore.loginUser.id);
    };

    moveManagement = (e, team) => {
        const {intl} = this.props;
        const {setConfirmDialog} = this.props.authStore;

        e.stopPropagation();
        const isMobile = Params.isMobileMode(600);
        console.log("isMobile : ", isMobile);
        if(isMobile){
            setConfirmDialog(intl.formatMessage({id: "msg.use_desktop_for_team_management"}), intl.formatMessage({id: "team_leader_function"}));
            return;
        }
        this.props.teamStore.changeSelectTeamByLeader(team);
        this.props.history.push(`/management/${ManagementUrlCategoryType.Team}`);
    };

    handleTeamClick = (teamId) => {
        const {history} = this.props;
        history.push(`/teams/${teamId}`);
    };

    render() {
        const { classes, intl, userLoungeStore, orgStore } = this.props;
        const { confirmDialog, changeConfirmDialogOpen } = this.props.authStore;

        return (
            <div className={classes.root}>
                <Box onClick={this.handleClickOpen} className={classes.buttonBox}>
                    <Typography className={classes.buttonText}><FormattedMessage id="affiliation_team"/></Typography>
                    <MobileTeamArrow />
                </Box>
                <Dialog
                    fullScreen
                    open={this.state.teamListOpen}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.titleBox}>
                        <IconButton onClick={this.handleClose} aria-label="Close" className={classes.iconBtn}>
                            <MobileClose/>
                        </IconButton>
                        <Typography className={classes.titleText}><FormattedMessage id="affiliation_team"/></Typography>
                    </Box>

                    {
                        userLoungeStore.teamList.length > 0 ?
                            userLoungeStore.teamList.map((team) => {
                                return (
                                    <div key={team.id} style={{cursor: 'pointer'}} onClick={() => {this.handleTeamClick(team.id);}}>
                                        <Box className={classes.teamBox}>
                                            <Typography className={classes.teamText1}>{team.name}</Typography>
                                            <Typography className={classes.teamText2}>
                                                {team.comment}
                                            </Typography>
                                            <Box display='flex' alignItems='center' pb={2}>
                                                <LeaderIcon style={{marginRight:5}}/>
                                                <Typography className={classes.teamText3}><FormattedMessage id="team_leader"/> : {this.getTeamLeaderList(team)}</Typography>
                                            </Box>
                                            <Box display="flex" flexDirection={"row"} justifyContent="space-between" alignItems='center'>
                                                <Box display="flex" flexBasis={0}>
                                                    <MobileTeamMembersSideBar members={team.members} isTeam={true} configs={orgStore.orgConfigs}/>
                                                </Box>
                                                {
                                                    this.getIsLeader(team) &&
                                                    <Box display="flex" flexBasis={1}>
                                                        <Tooltip title={intl.formatMessage({id: "team_management"})} arrow>
                                                            <IconButton onClick={(e)=>this.moveManagement(e, team)} style={{padding:0}}>
                                                                <MyTeamSettingIcon/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                    </div>
                                )
                            })
                            :
                            <Box className={classes.teamBox}>
                                <Typography className={classes.teamText1}><FormattedMessage id="msg.no_team"/></Typography>
                            </Box>
                    }
                </Dialog>
                <ConfirmDialog open={confirmDialog.open}
                               title={confirmDialog.title}
                               msg={confirmDialog.msg}
                               changeDialogOpen={(value)=>changeConfirmDialogOpen(value)}/>
            </div>
        );
    }
}

MobileTeam.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(style)(injectIntl(MobileTeam)));