import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {Drawer, Avatar, Box, Typography, IconButton} from "@material-ui/core";
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import {ReactComponent as Users} from "../common/images/Users.svg";
import {ReactComponent as Close} from "../common/images/Close.svg";
import {UTIL} from "../common/util/common.util"
import {ReactComponent as LeaderIcon} from "../common/images/LeaderIcon.svg";

const useStyles = makeStyles((theme) => ({
    root:{
    },
    drawerBox:{
        zIndex:'9999 !important',
        "& .MuiBackdrop-root": {
            background:'transparent'
        },
        "& .MuiDrawer-paper":{
            height: "90%",
            marginTop:65,
            boxShadow:'0 4px 7px 0 rgba(0, 0, 0, 0.25)',
        }
    },
    list: {
        width: 264,
        height:'100%',
        background:'#fff',
    },
    avatarStyle1:{
        cursor:'pointer',
        display:'inline-block',
        "& .MuiAvatar-circle":{
            width:27,
            height:27,
            background : '#fff',
            fontFamily:'NanumSquareRoundOTF',
            fontSize:12,
            color:'#fff',
            fontWeight:800,
        },
        "& .MuiAvatar-circle:nth-child(6)":{
            width:27,
            height:27,
            background : '#fff',
            fontFamily:'NanumSquareRoundOTF',
            fontSize:12,
            color:'#0d0d0d',
            fontWeight:800,
            zIndex:'1000 !important'
        },
        // "& .MuiAvatar-colorDefault":{
        //     zIndex:'999 !important',
        // }
    },
    titleBox:{
        background:'#f0f1f1',
        padding:'10px 16px'
    },
    fullList: {
        width: 'auto',
        height:'100%',
        background:'#fff'
    },
    titleText:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:10,
        fontWeight:800,
        marginLeft:5
    },
    listAvatar:{
        width:40,
        height:40,
    },
    listAvatarStyle:{
        fontFamily:'Montserrat',
        fontSize:23,
        fontWeight:800,
        width:40,
        height:40,
        color:'#fff',
        background: () => theme.configs.SubColor ? theme.configs.SubColor : '#0097ff',
    },
    listBox:{
        padding:'20px 16px'
    },

    listText:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:800
    },
    listText1:{
        color:'#747f8d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:11,
        fontWeight:800
    },
}));


export default function TeamMembersSideBar(props){
    const classes = useStyles();
    const [state, setState] = React.useState({
        right: false,
    });

    const isTeam = props.isTeam;
    let leaders = [];
    let members = [];
    let memberList = [];

    if (props.members !== undefined) {
        if (isTeam) {
            leaders = props.members.filter(m => m.type === 'Leader');
            members = props.members.filter(m => m.type === 'Member');

            sortByName(leaders);
            sortByName(members);

            memberList = leaders.concat(members);
        } else {
            memberList = props.members;
        }
    }

    function sortByName(list) {
        list.sort((a, b) => a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
    }

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
        if (event.stopPropagation) event.stopPropagation();
    };

    const handleClose = (event) => {
        event.stopPropagation();
        setState({open: false,});
    };

    const list = (anchor) => (
        <div onClick={event => event.stopPropagation()}
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
            <Box display='flex' alignItems='center' justifyContent='space-between' className={classes.titleBox}>
                <Box display='flex' alignItems='center'>
                    <Users/>
                    <Typography className={classes.titleText}>({memberList.length})</Typography>
                </Box>

                <IconButton onClick={handleClose} >
                    <Close style={{width:20,height:20}}/>
                </IconButton>
            </Box>
            <Box className={classes.listBox}>
                {
                    memberList.map((member, index) => {
                        return (
                            <Box display='flex' alignItems='center' pb={2} key={index}>
                                {
                                    member.image ?
                                        <Avatar style={{width: 40, height: 40}}>
                                            <img id="userAvatar" style={{width: '100%', height: '100%'}} src={member.image} alt={isTeam ? member.name : member.userName} title={isTeam ? member.name : member.userName}/>
                                        </Avatar>
                                        :
                                        <Avatar
                                            className={classes.listAvatarStyle}
                                            title={member.name}>{UTIL.getFirstCapitalLetter(isTeam ? member.name : member.userName)}
                                        </Avatar>
                                }
                                <Box pl={1} display='flex' flexDirection='column'>
                                    {member.type === 'Leader' && <LeaderIcon style={{width:12,height:12}}/>}
                                    <Typography className={classes.listText}>{isTeam ? member.name : member.userName} {isTeam ? member.title : member.userTitle}</Typography>
                                </Box>
                            </Box>
                        );
                    })
                }
            </Box>
        </div>
    );

    return (
        <div className={classes.root}>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Box className={classes.avatarStyle1}>
                        <AvatarGroup max={6} spacing={6} onClick={toggleDrawer(anchor, true)}>
                            {memberList.map((member, index) => {
                                return <Avatar key={index} src={member.image} style={{zIndex : index, background : props.configs.SubColor, color : props.configs.Color}} children={!member.image && UTIL.getFirstCapitalLetter(isTeam ? member.name : member.userName)}/>
                            })}
                        </AvatarGroup>
                    </Box>
                    <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} className={classes.drawerBox}>
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
};