import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Typography,
    Box,
    MenuItem,
    MenuList,
    IconButton,
    FormControlLabel,
    RadioGroup,
    Radio,
    Button,
    Grid, ClickAwayListener
} from "@material-ui/core";
import { ReactComponent as DeleteIcon} from "../../../common/images/DeleteIcon.svg"
import {ReactComponent as CheckedBox} from "../../../common/images/CheckedBox.svg";
import {ReactComponent as UnCheckedBox} from "../../../common/images/UnCheckedBox.svg";
import {ReactComponent as MarkQuestion} from "../../../common/images/MarkQuestion.svg";
import {ReactComponent as WarningIcon} from "../../../common/images/WarningIcon.svg"
import ResetPassword from "./ResetPassword";
import {inject, observer} from "mobx-react";
import {UserType} from "../../../stores/UserStore";
import CheckIcon from "@material-ui/icons/Check";
import {validateName} from "../../../common/Validation";
import {FormattedMessage, injectIntl} from "react-intl";
import {UI} from "../../../stores/UserStore";

const style = theme => ({
    root:{
        width:'80%',
        background:'#fff',
        padding:'50px 30px',
        "& .MuiOutlinedInput-input":{
            padding:'8px 12px'
        },
        "& .MuiListItem-root.Mui-selected":{
            background:'#d0d0d0',
        },
        "& :focus":{
            outline:'none',
        },
    },
    tableBox:{
        width:'100%',
        borderTop:'1px solid #dddddd',
        borderCollapse:'collapse',
        "& th":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:14,
            color:'#0d0d0d',
            fontWeight:600,
            background:'#f5f5f5',
            width:150,
            padding:20,
            textAlign:'left'
        },
        "& td":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:14,
            color:'#0d0d0d',
            padding:20,
            marginLeft:14,
        },
        "& tr":{
            borderBottom:'1px solid #dddddd',
        },
    },
    gridBox:{
        "& .MuiButton-root.Mui-disabled":{
            background:'#fff',
            color:'#88889D',
            border:'1px solid #A3A8AF'
        }
    },
    avatarStyle:{
        width:120,
        height:120,
        border:'2px dashed #dbdbdb',
        marginBottom:50
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#0d0d0d',
        fontWeight:600,
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#0d0d0d',
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#666666',
    },
    textStyle2_err:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#ff0000'
    },
    textCheckStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#0097ff'
    },
    inputStyle:{
        width:'100%',
        padding:'8px 10px',
        borderRadius:4,
        border:'1px solid #b7bcd6',
        background:'#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#0d0d0d'
    },
    inputStyle_err:{
        width:'80%',
        padding:'8px 10px',
        borderRadius:4,
        border:'1px solid red',
        background:'#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#0d0d0d'
    },
    emailInputStyle:{
        width:'47%',
        padding:'8px 10px',
        borderRadius:4,
        border:'1px solid #b7bcd6',
        background:'#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#0d0d0d'
    },
    emailInputStyle_err:{
        width:'47%',
        padding:'8px 10px',
        borderRadius:4,
        border:'1px solid red',
        background:'#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#0d0d0d'
    },
    formControlBox:{
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
            border:'1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiSelect-select:focus":{
            background:'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
            border:'1px solid rgba(0, 0, 0, 0.23)',
        }
    },
    selectBox: {
        width: 240,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 12,
        height:34
    },
    selectBox_err: {
        width: 240,
        border:'1px solid red',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 12,
        height:34
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 12,
        color:'#303030'
    },
    textareaStyle:{
        width:"80%",
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#8d909e',
        border:'1px solid #b7bcd6',
        padding:'10px',
        borderRadius:4,
        resize:'none',
        "&::placeholder":{
            color:'#8d909e'
        },
        "&:focus":{
            outline:'none',
        },
    },
    teamBox:{
        width:"80%",
        height:140,
        border:'1px solid #b7bcd6',
        padding:'0 8px',
        marginBottom:14,
        marginTop:5,
        "& .MuiListItem-button":{
            background:'transparent'
        },
        overflowY:'scroll',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    listStyle:{
        width:'100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 12,
        color:'#0d0d0d',
        padding:5
    },
    spanStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 12,
        color:'#7d86a9',
        marginLeft:5
    },
    checkboxStyle:{
        "& .MuiTypography-root":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 12,
            color:'#0d0d0d',
        },
        marginLeft:20,
    },
    checkboxStyle1:{
        "& .MuiTypography-root":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 12,
            color:'#0d0d0d',
            marginRight:50
        },
    },
    btnStyle:{
        width:135,
        padding:'8px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#fff',
        "&:hover":{
            background:() =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },

    btnStyle1:{
        width:135,
        padding:'8px 0',
        background:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#fff',
        marginRight:16,
        "&:hover":{
            background:'#a3a8af'
        }
    },

    passwordBtn:{
        color:'#57a4ff',
        textDecoration:'underline',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        "&:hover":{
            background:'transparent',
            textDecoration:'underline',
        }
    },
    iconBtn:{
        width:25,
        height:25,
        padding:5,
        marginLeft:5
    },
    gridBoxIn:{
        width:'80%',
    },

    teamDropdown:{
        position: 'relative',
    },
    dropdown: {
        width:'70%',
        position: 'absolute',
        top: 40,
        right: 0,
        left: 0,
        zIndex: 1,
        border:'1px solid #b7bcd6',
        padding:'8px 0',
        background:'#fff'
    },
    dropdownText:{
        fontSize: 14,
        fontFamily:'NanumSquareRoundOTF',
        color:'#0d0d0d',
        cursor:'pointer',
        padding:'5px 18px',
        "&:hover":{
            background:'#f5f5f5'
        }
    },
    inputStyle1:{
        width:'70%',
        padding:10,
        borderRadius:6,
        background:'#f2f3f7',
        border:0,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#b6b6b6'
    },
});

@inject('userStore', 'teamStore', 'avatarStore', 'authStore', 'progressStore')
@observer
class TeamMemberInformation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userNameCheckState: true,
            userNameCheckMsg: "",
            userEmailCheckState: true,
            userEmailCheckMsg: "",
            userTypeCheckState: true,
            userTypeCheckMsg: "",

            teamSearchBar : false,
            teamSelectIndex : 0,
        }
    }

    componentDidMount() {
        const {intl} = this.props;
        const userNameCheckMsg = intl.formatMessage({id: "msg.validation_nickname"});
        this.setState({userNameCheckMsg : userNameCheckMsg});
    }

    componentWillUnmount() {
        this.props.userStore.initSelectedActiveTeam();
        this.props.userStore.changeModifyState(false);
        this.props.userStore.detailUser = {}
        this.props.userStore.detailTeamUserList = [];
        this.props.teamStore.initSearchTeamList();

    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userStore, progressStore} = this.props;
        progressStore.setIsLoading(userStore.getIsLoading)
    }

    initState = () => {
        const {intl} = this.props;
        const userNameCheckMsg =intl.formatMessage({id: "msg.validation_nickname"});

        this.setState({
            userNameCheckState: true,
            userNameCheckMsg: userNameCheckMsg,
            userEmailCheckState: true,
            userEmailCheckMsg: "",
            userTypeCheckState: true,
            userTypeCheckMsg: "",

            teamSearchBar : false,
            teamSelectIndex : 0,
        })
    }


    handleModifyUser = () => {
        const {intl} = this.props;
        const {detailUser, setUserConfirmDialog} = this.props.userStore;

        if(detailUser.title !=="" && !validateName(detailUser.title)){
            const title = intl.formatMessage({id: "modify_member"});
            const msg = intl.formatMessage({id: "msg.title_incorrect"});
            setUserConfirmDialog(msg, title);
            return;
        }
        this.props.userStore.modifyUser(intl);
    }

    handleCheckNameState = (value, msg) => {
        this.setState({
            userNameCheckState: value,
            userNameCheckMsg: msg,
        });
    }
    handleCheckEmailState = (value, msg) => {
        this.setState({
            userEmailCheckState: value,
            userEmailCheckMsg: msg,
        });
    }
    handleCheckTypeState = (value, msg) => {
        this.setState({
            userTypeCheckState: value,
            userTypeCheckMsg: msg,
        });
    }
    changeDetailUserType = (e) => {
        const {intl} = this.props;
        this.props.userStore.changeDetailUserType(intl, e, this.handleCheckTypeState);
    }
    changeDetailUserEmail = (e) => {
        const {intl} = this.props;
        this.props.userStore.changeDetailUserEmail(intl, e, this.handleCheckEmailState);

    }
    handleCheckEmail = () => {
        const {intl} = this.props;
        this.props.userStore.checkUserEmail(intl, this.handleCheckEmailState, "modify");
    }
    handleCheckEmailKeyDown = (e) => {
        if (e.isComposing || e.keyCode === 229) return;
        if (e.keyCode === 13) {
            this.handleCheckEmail();
        }
    }
    changeDetailUserName = (e) => {
        const {intl} = this.props;
        this.props.userStore.changeDetailUserName(intl, e.target.value, this.handleCheckNameState);
    }
    handleCheckName = () => {
        const {intl} = this.props;
        this.props.userStore.checkUserName(intl, this.handleCheckNameState, "modify");
    }
    handleCheckNameKeyDown = (e) => {
        if (e.isComposing || e.keyCode === 229) return;
        if (e.keyCode === 13) {
            this.handleCheckName();
        }
    }
    handleCancelModyUser = (e) => {
        this.props.userStore.cancelModyUser();
        this.props.teamStore.initSearchTeamList();
        this.initState();
    }



    handleAddDetailTeamUser = (teamId) => {
        const team = this.props.teamStore.teamTreeListForTeamMember.find(team => team.id === teamId);
        if (team) {
            this.props.userStore.addDetailTeamUser(team);
        }
    }

    handleAddDetailTeamUserByKeyDown = (e, list) =>{
        console.log("start handleAddNewTeamUser keyCode=", e.keyCode);
        if (e.isComposing || e.keyCode === 229) { return; }
        if (e.keyCode  === 13) {
            const teamSelectIndex = this.state.teamSelectIndex;
            this.handleAddDetailTeamUser(list[teamSelectIndex].id);
        } else if (e.keyCode  === 38 && this.state.teamSelectIndex > 0) {
            const index = this.state.teamSelectIndex - 1;
            console.log("index : ", index);
            this.setState({teamSelectIndex : index});
        } else if (e.keyCode  === 40 && this.state.teamSelectIndex < list.length-1) {
            const index = this.state.teamSelectIndex + 1;
            console.log("index : ", index);
            this.setState({teamSelectIndex : index});
        } else if (e.keyCode  === 27) {
            this.setState({
                teamSearchBar : false,
            });
        }
    }
    handleClickAway = () => {
        this.setState({teamSearchBar : false});
    };

    handleSearchBar = (event, state) => {
        console.log(" SearchBar selected ");
        this.setState({
            [event.target.name] : state,
            teamSelectIndex : 0
        });
    }
    hoverTeamLabel = (index) => {
        this.setState({teamSelectIndex : index});
    }
    handleTeamKey = (e) => {
        this.props.teamStore.searchTeamList(e.target.value);
    }
    handleRemoveUser = (e) =>{
        const {intl} = this.props;

        this.props.userStore.changeUserDeleteDialogTitle(intl.formatMessage({id: "delete_account"}));
        this.props.userStore.changeUserDeleteDialogMsg(intl.formatMessage({id: "msg.delete_account"}));
        this.props.userStore.changeUserDeleteDialogOpen(true);
    }

    handleChangeDetailUserEnabled = (e) => {
        const { type } = this.props.authStore.loginUser;
        if (type === UserType.Admin) {
            this.props.userStore.changeDetailUserEnabled(e);
        } else {
            const teamId = this.props.teamStore.selectTeamByLeader.id;
            this.props.userStore.changeDetailTeamUserEnable(e, teamId);

        }
    }

    moveTeamMemberTable = () => {
        const {userStore} = this.props;
        userStore.changeUiState(UI.MemberTable);
    }

    // 리더 권한
    //
    // selectActiveTeam = (team) => {
    //     if (this.props.userStore.selectedActiveTeam.teamId === team.teamId) {
    //         this.props.userStore.initSelectedActiveTeam();
    //     } else {
    //         this.props.userStore.changeSelectedActiveTeam(team);
    //     }
    // }
    //
    // setMemberTypeBySelectedActiveTeam = () => {
    //     this.props.userStore.changeMemberType("mody");
    // }

    render() {
        const {classes, intl} = this.props;
        const {modifyState, detailUser, detailTeamUserList,
            changeModifyState, changeDetailUserTitle, removeDetailTeamUser,
            // selectedActiveTeam, 리더 권한
        } = this.props.userStore;
        const {teamTreeListForTeamMember, selectTeamByLeader, searchKeyword} = this.props.teamStore;
        const {loginUser} = this.props.authStore;
        const notTeamList = teamTreeListForTeamMember.filter(team => detailTeamUserList.findIndex(newTeam => newTeam.teamId === team.id) === -1);
        const teamUserEnabled = detailTeamUserList.find(teamUser => teamUser.teamId === this.props.teamStore.selectTeamByLeader.id);

        return (
            <Box className={classes.root}>
                {modifyState === false ?
                    <table className={classes.tableBox}>
                        <tbody>
                        {loginUser.type === UserType.Admin &&
                        <tr>
                            <th><FormattedMessage id="type"/></th>
                            <td>
                                <Typography className={classes.textStyle1}>{detailUser.type === UserType.Admin ? <FormattedMessage id="management_account"/> : <FormattedMessage id="normal_account"/>}</Typography>
                            </td>
                        </tr>
                        }
                        <tr>
                            <th><FormattedMessage id="email"/></th>
                            <td>{detailUser.email}</td>
                        </tr>

                        <tr>
                            <th><FormattedMessage id="nickname"/></th>
                            <td>{detailUser.name}</td>
                        </tr>

                        <tr>
                            <th><FormattedMessage id="password"/></th>
                            <td><ResetPassword/></td>
                        </tr>

                        <tr>
                            <th><FormattedMessage id="title"/></th>
                            <td><Typography className={classes.textStyle1}>{detailUser.title}</Typography></td>
                        </tr>

                        <tr>
                            <th><FormattedMessage id="affiliation_team"/></th>
                            <td>
                                {loginUser.type === UserType.Normal ?
                                    <Typography className={classes.textStyle1}>{selectTeamByLeader.name}</Typography>

                                    :
                                    <Typography className={classes.textStyle1}>
                                        {
                                            detailTeamUserList.map((teamUser, index) => {
                                               return teamUser.teamName
                                            }).join(", ")
                                        }
                                    </Typography>
                                }
                            </td>
                        </tr>
                        <tr>
                            <th><FormattedMessage id="state"/></th>
                            <td>
                                    {
                                        loginUser.type === UserType.Admin ?
                                            detailUser.enabled ? <FormattedMessage id="enabled"/> : <FormattedMessage id="disabled"/>
                                            :
                                            detailTeamUserList && detailTeamUserList.find(teamUser => teamUser.teamId === this.props.teamStore.selectTeamByLeader.id) &&
                                            detailTeamUserList.find(teamUser => teamUser.teamId === this.props.teamStore.selectTeamByLeader.id).enabled ?
                                                <FormattedMessage id="enabled"/> : <FormattedMessage id="disabled"/>
                                    }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    :
                    <>
                        {loginUser.type === UserType.Admin &&
                        <Box display='flex' alignItems='center' pb={3}>
                            <Grid item xs={2}>
                                <Typography className={classes.textStyle}><FormattedMessage id="type"/><span
                                    style={{color: '#ff0000', marginLeft: 5}}>*</span></Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Box className={classes.gridBox}>
                                    <Typography
                                        className={classes.textStyle1}>{detailUser.type === UserType.Admin ? <FormattedMessage id="management_account"/> : <FormattedMessage id="normal_account"/>}</Typography>
                                </Box>
                            </Grid>
                        </Box>
                        }

                        <Box display='flex' alignItems='center' pb={3}>
                            <Grid item xs={2}>
                                <Typography className={classes.textStyle}>
                                    <FormattedMessage id="email"/>
                                    <span style={{color: '#ff0000', marginLeft: 5}}>*</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Box className={classes.gridBox}>
                                    <Box display='flex' alignItems='center'>
                                        <Box display='flex' alignItems='center' style={{width: '100%'}}>
                                            <input
                                                value={detailUser.email.slice(0, detailUser.email.indexOf("@"))}
                                                name="email"
                                                type="text"
                                                id="email"
                                                style={{height: 34}}
                                                placeholder={intl.formatMessage({id: "msg.insert_email"})}
                                                onChange={this.changeDetailUserEmail}
                                                className={this.state.userEmailCheckState ? classes.emailInputStyle : classes.emailInputStyle_err}
                                            />
                                            <Typography style={{width: '6%', textAlign: 'center'}}>@</Typography>
                                            <input
                                                value={detailUser.email.slice(detailUser.email.indexOf("@") + 1, detailUser.email.length)}
                                                name="emailAddress"
                                                type="text"
                                                id="emailAddress"
                                                placeholder={intl.formatMessage({id: "direct_input"})}
                                                style={{height: 34}}
                                                onChange={this.changeDetailUserEmail}
                                                onKeyDown={this.handleCheckEmailKeyDown}
                                                className={this.state.userEmailCheckState ? classes.emailInputStyle : classes.emailInputStyle_err}
                                            />
                                        </Box>
                                        <IconButton onClick={this.handleCheckEmail} className={classes.iconBtn}>
                                            <CheckIcon/>
                                        </IconButton>
                                    </Box>
                                </Box>
                                <Box display="flex" alignItems='center' pt={1}>
                                    {!this.state.userEmailCheckState && <WarningIcon style={{width:13, height:13, marginRight:5}}/> }
                                    <Typography className={this.state.userEmailCheckState ? classes.textCheckStyle : classes.textStyle2_err}>
                                        {this.state.userEmailCheckMsg}
                                    </Typography>
                                </Box>

                            </Grid>
                        </Box>

                        <Box display='flex' alignItems='center' pb={3}>
                            <Grid item xs={2}>
                                <Typography className={classes.textStyle}>
                                    <FormattedMessage id="nickname"/>
                                    <span style={{color: '#ff0000', marginLeft: 5}}>*</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Box className={classes.gridBox}>
                                    <Box display='flex' alignItems='center'>
                                        <input
                                            value={detailUser.name}
                                            name="nickName"
                                            type="text"
                                            id="nickName"
                                            placeholder={intl.formatMessage({id: "msg.insert_nickname"})}
                                            className={this.state.userNameCheckState ? classes.inputStyle : classes.inputStyle_err}
                                            onChange={this.changeDetailUserName}
                                            onKeyDown={this.handleCheckNameKeyDown}
                                        />
                                        <IconButton onClick={this.handleCheckName} className={classes.iconBtn}>
                                            <CheckIcon/>
                                        </IconButton>
                                    </Box>
                                    {!this.state.userNameCheckState &&
                                    <Box display="flex" alignItems='center' pt={1}>
                                        {this.state.userNameCheckState ? null : <WarningIcon style={{width:13, height:13, marginRight:5}}/>}
                                        <Typography className={this.state.userNameCheckState ? classes.textCheckStyle : classes.textStyle2_err}>
                                            {this.state.userNameCheckMsg}
                                        </Typography>
                                    </Box>
                                    }
                                    <Box display="flex" alignItems='center' pt={1}>
                                        <Typography className={classes.textStyle2}>
                                            <FormattedMessage id="msg.validation_nickname"/>
                                        </Typography>
                                        <MarkQuestion style={{marginLeft: 5}}/>
                                    </Box>
                                </Box>
                            </Grid>
                        </Box>

                        <Box display='flex' alignItems='center' pb={3}>
                            <Grid item xs={2}>
                                <Typography className={classes.textStyle}>
                                    <FormattedMessage id="title"/>
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Box className={classes.gridBox}>
                                    <Box>
                                        <Box display='flex' alignItems='center'>
                                            <input
                                                value={detailUser.title ? detailUser.title : ""}
                                                name="title"
                                                type="text"
                                                id="title"
                                                placeholder={intl.formatMessage({id: "msg.insert_title"})}
                                                onChange={changeDetailUserTitle}
                                                className={classes.inputStyle}
                                            />
                                            <span style={{width:35,height:30}}></span>
                                        </Box>
                                        <Box display="flex" pt={1}>
                                            <Typography className={classes.textStyle2}>
                                                <FormattedMessage id="msg.validation_nickname"/>
                                            </Typography>
                                            <MarkQuestion style={{marginTop: 2, marginLeft: 5}}/>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Box>

                        <Box display='flex' alignItems='center' pb={3}>
                            <Grid item xs={2}>
                                <Typography className={classes.textStyle}><FormattedMessage id="affiliation_team"/></Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Box className={classes.gridBox}>
                                    {loginUser.type === UserType.Normal ?
                                        <Typography className={classes.textStyle1}>{selectTeamByLeader.name}</Typography>
                                        :
                                        <Box>
                                            <Box display='flex'>
                                                <Box className={classes.teamBox}>
                                                    <Box display='flex' >
                                                        <MenuList style={{width:'100%'}}>
                                                            {detailTeamUserList.map( (team,index) => {
                                                                return(
                                                                    <Box key={team.teamId} display='flex' justifyContent='space-between'>
                                                                        <MenuItem
                                                                            key={index.toString()}
                                                                            // selected={selectedActiveTeam.teamId === team.teamId}
                                                                            // onClick={() => this.selectActiveTeam(team)}
                                                                            className={classes.listStyle}>
                                                                            {team.teamName}
                                                                        </MenuItem>
                                                                        <IconButton onClick={() => removeDetailTeamUser(team.teamId)}>
                                                                            <DeleteIcon />
                                                                        </IconButton>
                                                                    </Box>
                                                                )
                                                            })}
                                                        </MenuList>
                                                    </Box>
                                                </Box>
                                                {/* 리더 권한 */}
                                                {/*<Box style={{width: '20%', textAlign: 'center', marginTop: 10}}>*/}
                                                {/*    <FormGroup className={classes.checkboxStyle}>*/}
                                                {/*        <FormControlLabel*/}
                                                {/*            control={<Checkbox*/}
                                                {/*                icon={<UnCheckedBox/>}*/}
                                                {/*                checkedIcon={<CheckedBox/>}*/}
                                                {/*                checked={selectedActiveTeam.type === TeamUserType.Leader}*/}
                                                {/*                onChange={this.setMemberTypeBySelectedActiveTeam}*/}
                                                {/*                disabled={ !selectedActiveTeam || selectedActiveTeam.teamId === 0}*/}
                                                {/*                name="Leader"*/}
                                                {/*            />}*/}
                                                {/*            label={"리더"}*/}
                                                {/*        />*/}
                                                {/*    </FormGroup>*/}
                                                {/*</Box>*/}
                                            </Box>
                                            <ClickAwayListener onClickAway={this.handleClickAway}>
                                                <Box className={classes.teamDropdown}>
                                                    <input
                                                        value={searchKeyword}
                                                        onChange={this.handleTeamKey}
                                                        onKeyDown={(event) => this.handleAddDetailTeamUserByKeyDown(event, notTeamList)}
                                                        onSelect={(event) => this.handleSearchBar(event,true)}
                                                        disabled={detailUser.type === UserType.Admin}
                                                        name="teamSearchBar"
                                                        type="text"
                                                        id="teamSearchBar"
                                                        placeholder={
                                                            detailUser.type === UserType.Normal ?
                                                                intl.formatMessage({id: "msg.insert_or_select_team_name"})
                                                                :
                                                                intl.formatMessage({id: "msg.cannot_join_team"})
                                                        }
                                                        className={classes.inputStyle1}
                                                    />
                                                    {this.state.teamSearchBar ? (
                                                        <Box className={classes.dropdown}>
                                                            {notTeamList.length > 0 ?
                                                                notTeamList.map((team,index) => {
                                                                    if(index === this.state.teamSelectIndex)
                                                                        return (
                                                                            <Typography key={index.toString()}
                                                                                        className={classes.dropdownText}
                                                                                        style={{ background:'#f5f5f5'}}
                                                                                        onMouseEnter={()=> this.hoverTeamLabel(index)}
                                                                                        onClick={() => this.handleAddDetailTeamUser(team.id)}
                                                                            >
                                                                                {team.name}
                                                                            </Typography>
                                                                        )
                                                                    else return (
                                                                        <Typography key={index.toString()}
                                                                                    className={classes.dropdownText}
                                                                                    onMouseEnter={()=> this.hoverTeamLabel(index)}
                                                                                    onClick={() => this.handleAddDetailTeamUser(team.id)}
                                                                        >
                                                                            {team.name}
                                                                        </Typography>
                                                                    )
                                                                }): (
                                                                    <Typography key={"0"}
                                                                                className={classes.dropdownText}>
                                                                        <FormattedMessage id="msg.no_addable_team"/>
                                                                    </Typography>
                                                                )}
                                                        </Box>
                                                    ) : null}
                                                </Box>
                                            </ClickAwayListener>
                                        </Box>
                                    }
                                </Box>
                            </Grid>
                        </Box>


                        <Box display='flex' alignItems='center' pb={3}>
                            <Grid item xs={2}>
                                <Typography className={classes.textStyle}>
                                    <FormattedMessage id="state"/>
                                    <span style={{color: '#ff0000', marginLeft: 5}}>*</span>
                                </Typography>
                            </Grid>
                            <Grid item xs={10}>
                                <Box className={classes.gridBox} style={{marginBottom: 0}}>
                                    <RadioGroup
                                        row
                                        className={classes.checkboxStyle1}
                                        aria-label="useSelection"
                                        name="useSelection1"
                                        value={
                                                loginUser.type === UserType.Admin ?
                                                    detailUser ? detailUser.enabled : ""
                                                    :
                                                    teamUserEnabled ? teamUserEnabled.enabled : ""
                                            }
                                        onChange={this.handleChangeDetailUserEnabled}
                                    >
                                        <FormControlLabel
                                            control={<Radio icon={<UnCheckedBox/>} checkedIcon={<CheckedBox/>}/>}
                                            value={true}
                                            label={intl.formatMessage({id: "enabled"})}
                                        />
                                        <FormControlLabel
                                            control={<Radio icon={<UnCheckedBox/>} checkedIcon={<CheckedBox/>}/>}
                                            value={false}
                                            label={intl.formatMessage({id: "disabled"})}
                                        />
                                    </RadioGroup>
                                </Box>
                            </Grid>
                        </Box>
                    </>
                }


                {modifyState === false ?
                    <Box pt={8} style={{width: "100%"}}>
                        <Button className={classes.btnStyle} onClick={() => this.moveTeamMemberTable()}>
                            <FormattedMessage id="previous_list"/>
                        </Button>
                        <Button className={classes.btnStyle} style={{float: 'right'}} onClick={() => changeModifyState(true)}>
                            <FormattedMessage id="edit"/>
                        </Button>
                        {loginUser.type === UserType.Admin &&
                        <Button className={classes.btnStyle1} style={{float: 'right'}} onClick={this.handleRemoveUser}>
                            <FormattedMessage id="delete"/>
                        </Button>
                        }
                    </Box>
                    :
                    <Box pt={8} style={{width: "100%"}}>
                        <Button className={classes.btnStyle} onClick={() => this.moveTeamMemberTable()}>
                            <FormattedMessage id="previous_list"/>
                        </Button>
                        <Button className={classes.btnStyle}
                                style={{float: 'right'}}
                                onClick={this.handleModifyUser}
                                disabled={
                                    !this.state.userEmailCheckState
                                    || !this.state.userNameCheckState
                                    || !this.state.userTypeCheckState
                                }
                        ><FormattedMessage id="save"/></Button>
                        <Button className={classes.btnStyle1} style={{float: 'right'}} onClick={this.handleCancelModyUser}>
                            <FormattedMessage id="cancel"/>
                        </Button>
                    </Box>
                }

            </Box>
        );
    }
}
export default withStyles(style)(injectIntl(TeamMemberInformation));

