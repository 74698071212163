import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Typography,
    Box,
    Dialog,
    IconButton,
    Slide
} from "@material-ui/core";
import {ReactComponent as MobileTeamArrow} from "../../../common/images/MobileTeamArrow.svg";
import {ReactComponent as MobileClose} from "../../../common/images/MobileClose.svg";
import MobileAnnouncement from "./MobileAnnouncement";
import {observer} from "mobx-react";
import MobileTeamRoomNoticeBody from "./MobileTeamRoomNoticeBody";
import {FormattedMessage} from "react-intl";


const style = theme => ({
    root: {
        flexGrow: 1,
    },
    buttonBox: {
        width:'100%',
        border:'1px solid #c0c2c3',
        padding:'10px 11px',
        borderRadius:5,
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        marginTop:20
    },
    buttonText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px',
        marginBottom:20
    },
    titleText:{
        width:'100%',
        textAlign:'center',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    iconBtn:{
        padding:5
    },
    teamBox:{
        borderRadius:10,
        padding:'19px 18px',
        background:'#f5f5f5',
        margin:'0 20px 20px'
    },
    teamText1:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#000',
        fontWeight:800,
        textAlign:'justify'
    },
});

export const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref} />
))

@observer
class MobileTeamRoomNotice extends Component {
    state = {
        open: false,
    };

    handleClickOpen = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.root}>
                <Box onClick={this.handleClickOpen} className={classes.buttonBox}>
                    <Typography className={classes.buttonText}><FormattedMessage id="notice"/></Typography>
                    <MobileTeamArrow />
                </Box>
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.titleBox}>
                        <Box display='flex' alignItems='center'>
                            <IconButton onClick={this.handleClose} aria-label="Close" className={classes.iconBtn}>
                                <MobileClose/>
                            </IconButton>
                            <Typography className={classes.titleText}><FormattedMessage id="notice"/></Typography>
                        </Box>

                        <MobileAnnouncement/>
                    </Box>
                    <MobileTeamRoomNoticeBody />
                </Dialog>
            </div>
        );
    }
}

export default withStyles(style)(MobileTeamRoomNotice);