import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Tabs,
    Tab,
    Box,
    TextareaAutosize
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTab-textColorInherit.Mui-selected":{
            color:'#202020',
            fontWeight:'bold'
        },
        "& .MuiTab-root":{
            minWidth:69,
            minHeight:10
        },
        "& .MuiTabs-root":{
            minHeight:10
        },
        "&  .MuiTabs-indicator":{
            height:3,
        }
    },
    textareaStyle:{
        width:"90%",
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#8d909e',
        border:'1px solid #b7bcd6',
        padding:'11px 5px',
        borderRadius:4,
        resize:'none',
        marginTop:24,
        "&::placeholder":{
            color:'#8d909e'
        },
        "&:focus":{
            outline:'none',
        },
        overflowY:'scroll',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    TabletTextareaStyle:{
        [theme.breakpoints.down('xs')]: {
            width:"100%",
        },
        width:"70%",
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#8d909e',
        border:'1px solid #b7bcd6',
        padding:'11px 5px',
        borderRadius:4,
        resize:'none',
        marginTop:24,
        "&::placeholder":{
            color:'#8d909e'
        },
        "&:focus":{
            outline:'none',
        },
        overflowY:'scroll',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    }
}));

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth:80,
            width:'100%',
            backgroundColor:'#1676ff',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#7d86a9',
        fontSize:12,
        fontFamily:'NanumSquareRoundOTF',
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function TabsText(props) {
    const intl = useIntl();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { isTablet } = props;
    const { changeOrgMainText, changeOrgSubText, previewConfigs } = props.orgStore;
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div className={classes.root} style={{marginTop:isTablet ? 20 :30 }}>
            <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example" style={{borderBottom: '1px solid #dddddd',width:138}}>
                <StyledTab label={intl.formatMessage({id: "main_text"})} />
                <StyledTab label={intl.formatMessage({id: "sub_text"})} />
            </StyledTabs>

            <TabPanel value={value} index={0}>
                {isTablet ?
                    <TextareaAutosize
                        rows={6}
                        rowsMax={6}
                        aria-label="maximum height"
                        placeholder={intl.formatMessage({id: "msg.insert_text"})}
                        className={classes.TabletTextareaStyle}
                        value={previewConfigs.MainText}
                        onChange={(e) => changeOrgMainText(intl, e.target.value)}
                    />
                    :
                    <TextareaAutosize
                        rows={4}
                        rowsMax={4}
                        aria-label="maximum height"
                        placeholder={intl.formatMessage({id: "msg.insert_text"})}
                        className={classes.textareaStyle}
                        value={previewConfigs.MainText}
                        onChange={(e) => changeOrgMainText(intl, e.target.value)}
                    />
                }
            </TabPanel>
            <TabPanel value={value} index={1}>
                {isTablet ?
                    <TextareaAutosize
                        rows={6}
                        rowsMax={6}
                        aria-label="maximum height"
                        placeholder={intl.formatMessage({id: "msg.insert_text"})}
                        value={previewConfigs.SubText}
                        className={classes.TabletTextareaStyle}
                        onChange={(e)=>changeOrgSubText(intl, e.target.value)}
                    />
                    :
                    <TextareaAutosize
                        rows={4}
                        rowsMax={4}
                        aria-label="maximum height"
                        placeholder={intl.formatMessage({id: "msg.insert_text"})}
                        value={previewConfigs.SubText}
                        className={classes.textareaStyle}
                        onChange={(e)=>changeOrgSubText(intl, e.target.value)}
                    />
                }
            </TabPanel>
        </div>
    );
}
export default inject('orgStore')(observer(TabsText));