import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Button,
    Typography,
    Grid,
    Box,
} from "@material-ui/core";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
        padding:'70px 20px 20px',
        width: '100%',
    },
    titleStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:800,
        color:'#0d0d0d',
        marginBottom:25
    },
    tableBox:{
        width:'100%',
        borderTop:'1px solid #a3a8af',
        borderCollapse:'collapse',
        "& th":{
            background:'#f5f5f5',
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'0.9rem',
            fontWeight:600,
            color:'#0d0d0d',
            width:80,
            padding:'10px 0 10px 6px',
            textAlign:'left'
        },
        "& td":{
            padding:'10px 0 10px 6px',
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'0.9rem',
            color:'#0d0d0d',
            marginLeft:14,
        },
        "& tr":{
            borderBottom:'1px solid #a3a8af',
        },
    },
    btnStyle:{
        width:'100%',
        padding:'9px 0',
        background:'#0097ff',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:600,
        color:'#fff',
        marginBottom:40,
        borderRadius:5,
        "&:hover":{
            background:'#0097ff'
        }
    },
});

class MobileMeetingMinutes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modified:false,
        }
    }

    handleClick = () => {
        this.setState({ modified: true });
    }

    render() {
        const { classes } = this.props;
        // const { open, placement, disablePortal, flip, arrow, arrowRef } = this.state;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleStyle}>회의록</Typography>
                <Grid item xs={12}>
                    <table className={classes.tableBox}>
                        <tbody>
                            <tr>
                                <th >회의일시</th>
                                <td>2021년 2월 25일 10:15 AM</td>
                            </tr>
                            <tr>
                                <th className={classes.textStyle1}>회의록 작성자</th>
                                <td>서비스기획팀 한수연 대리</td>
                            </tr>
                            <tr>
                                <th>참석자</th>
                                <td>김병건 대리, 윤세라 부장, 박소은 주임, 길영수 차장, 임유진 주임, 한수연 대리</td>

                            </tr>
                            <tr>
                                <th>회의 안건</th>
                                <td >00사이트 리뉴얼 기획 회의</td>
                            </tr>
                            <tr>
                                <th>회의 내용</th>
                                <td>
                                    1. 회의 안건<br/>
                                    - 내용
                                </td>
                            </tr>

                            <tr>
                                <th>확정 내용</th>
                                <td>
                                    - 내용
                                </td>
                            </tr>

                            <tr>
                                <th>다음 회의 일정</th>
                                <td>2021년 2월 27일 03:00 PM</td>
                            </tr>
                        </tbody>
                    </table>


                    <Box pt={2}>
                        <Button className={classes.btnStyle}><FormattedMessage id="ok"/></Button>
                    </Box>
                </Grid>
            </div>
        );
    }
}

export default withStyles(style)(MobileMeetingMinutes);
