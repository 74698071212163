import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Avatar,
    List,
    ListItem,
} from "@material-ui/core";
import {withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {UTIL} from "../common/util/common.util";
import {UserType} from "../stores/AuthStore";
import {PATH_UTIL} from "../common/util/path.util";
import {ReactComponent as Home} from "../common/images/House.svg";

const style = theme => ({
    root:{
        width:85,
        height:'100%',
        flexShrink: 0,
        position:'fixed',
        background:'#d7d7d7',
        boxShadow:'inset 0 4px 15px 0 rgba(0, 0, 0, 0.1)',
        overflow:'auto',
        overflowX:'hidden',
        "& .MuiListItem-root":{
            paddingTop:10,
            paddingBottom:10
        },
        "& .MuiListItem-root.Mui-selected":{
            background: () => theme.configs.MainColor,
                    // ? theme.configs.MainColor
                    // : '#fff',
            borderLeft: () => '4px solid ' +  theme.configs.SubColor,
                // theme.configs.SubColor
                //     ? '4px solid ' +  theme.configs.SubColor
                //     : '4px solid #0097ff',
            boxShadow:'inset 0 4px 15px 0 rgba(0, 0, 0, 0.1)',
        },
        "& .MuiListItem-root.Mui-selected:hover":{
            background: () => theme.configs.MainColor,
                    // ? theme.configs.MainColor
                    // : '#fff',
            borderLeft: () => '4px solid ' +  theme.configs.SubColor,
                // theme.configs.SubColor
                //     ? '4px solid ' +  theme.configs.SubColor
                //     : '4px solid #0097ff',
        },
        "&::-webkit-scrollbar": {
            width:'6px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#a8a8a8',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    avatarStyle:{
        fontFamily: 'Montserrat',
        fontSize:22,
        fontWeight:800,
        width:45,
        height:45,
        background: () => theme.configs.SubColor
                // ? theme.configs.SubColor
                // : '#0097ff'
    },
    avatarStyle1:{
        width:45,
        height:45,
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
});

@inject('authStore', 'userStore', 'userLoungeStore', 'avatarStore', 'timelineStore', 'surveyStore', 'quizStore')
@observer
class SideBar extends Component {
    componentDidMount() {
        const {authStore, userLoungeStore} = this.props;
        userLoungeStore.getSimpleTeamList(authStore.loginUser.id);
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {history, authStore, userLoungeStore, timelineStore, surveyStore, quizStore} = this.props;
        if ((this.props.location.pathname !== prevProps.location.pathname) && (prevProps.location.pathname !== '/')) {
            const path = PATH_UTIL.getPath(this.props.location.pathname);
            const teamId = PATH_UTIL.getTeamId(this.props.location.pathname);

            if ((path === 'mytimeline') || (path === 'teams')) {
                if (userLoungeStore.selectedTeamId !== teamId) {
                    userLoungeStore.changeSelectedTeamId(teamId);
                }
                userLoungeStore.changeSelectedTeam(authStore.loginUser.id, teamId, timelineStore, history);
                surveyStore.getSurveyListByUserId(authStore.loginUser);
                quizStore.getQuizListByUserId(authStore.loginUser);
            }
        }
    };

    handleTeamClick = (teamId) => {
        const {history, authStore} = this.props;
        if (teamId === 0) {
            if (authStore.loginUser.type === UserType.Admin) {
                history.push('/management/organization')
            } else {
                history.push('/mytimeline');
            }
        } else {
            history.push(`/teams/${teamId}`);
        }
    };

    render() {
        const { classes, authStore, userLoungeStore } = this.props;

        return (
            <div className={classes.root}>
                <List component="nav" aria-label="main mailbox folders" style={{paddingTop:0}}>
                    <ListItem
                        button
                        selected={authStore.loginUser.type === 'Admin' ? true : userLoungeStore.selectedTeamId === 0}
                        onClick={(event) => {this.handleTeamClick(0);}}
                    >
                        <Avatar className={classes.avatarStyle} title={"Home"}>
                            <Home style={{width: '70%', height: '70%'}}/>
                        </Avatar>
                        {/*{*/}
                        {/*    avatarStore.loadAvatarImage ?*/}
                        {/*        <Avatar className={classes.avatarStyle}>*/}
                        {/*            <img id="userAvatar" style={{width: '100%', height: '100%'}} src={avatarStore.loadAvatarImage} alt={authStore.loginUser.name} title={"Home"}/>*/}
                        {/*        </Avatar>*/}
                        {/*        :*/}
                        {/*        <Avatar className={classes.avatarStyle} title={"Home"}>*/}
                        {/*            {UTIL.getFirstCapitalLetter(userInfo.name)}*/}
                        {/*        </Avatar>*/}
                        {/*}*/}
                    </ListItem>
                    {
                        userLoungeStore.simpleTeamList.map((team) => {
                            return (
                                <ListItem
                                    key={team.id}
                                    button
                                    selected={userLoungeStore.selectedTeamId === team.id}
                                    onClick={(event) => {this.handleTeamClick(team.id);}}
                                >
                                    {
                                        team.image ?
                                            <Avatar style={{width: 48, height: 48}}>
                                                <img id="teamAvatar" style={{width: '100%', height: '100%'}} src={team.image} alt={team.name} title={team.name}/>
                                            </Avatar>
                                            :
                                            <Avatar className={classes.avatarStyle} title={team.name}>
                                                {UTIL.getFirstCapitalLetter(team.name)}
                                            </Avatar>
                                    }
                                </ListItem>
                            );
                        })
                    }
                </List>
            </div>
        );
    };
}

export default withRouter(withStyles(style)(SideBar));