import React from "react";
import {BrowserRouter as Router, Route, Switch, Redirect, withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import Box from "@material-ui/core/Box";
import {withStyles} from "@material-ui/core/styles";
import {CssBaseline} from "@material-ui/core";

import axios from "axios";

import TopBar from "./views/TopBar/TopBar";
import MobileTopBar from "./views/TopBar/MobileTopBar";

import MainTopBar from "./views/Home/MainTopBar";
import DefaultHome from "./views/Home/Home";
import DefaultMobileHome from "./views/Home/MobileHome";
import MobileSignIn from "./views/Home/MobileSignIn";
import * as store from "./stores/AuthStore";
import SideBar from "./components/SideBar";
import ServiceCenter from "./views/ServiceCenter/ServiceCenter";
import MobileServiceCenter from "./views/ServiceCenter/MobileServiceCenter";
import MeetingMinutes from "./views/MeetingMinutes/MeetingMinutes";
import MobileMeetingMinutes from "./views/MeetingMinutes/MobileMeetingMinutes";
import OrganizationManagement from "./views/OrganizationManagement/OrganizationManagement";
import MyTimeline from "./views/MyTimeline/MyTimeline";
import MobileMyTimeline from "./views/MyTimeline/MobileMyTimeline";
import TeamRoom from "./views/TeamRoom/TeamRoom";
import MobileTeamRoom from "./views/TeamRoom/MobileTeamRoom";
import CreateRoom from "./views/CreateRoom/CreateRoom";
import MobileCreateRoom from "./views/CreateRoom/MobileCreateRoom";

import ProfileSettings from "./views/ProfileSettings/ProfileSettings";
import MobileProfileSettings from "./views/ProfileSettings/MobileProfileSettings";
import Statistics from "./views/Statistics/Statistics";
import MobileStatistics from "./views/Statistics/MobileStatistics";
import {LocalStorageTokenKey, State, UserType} from "./stores/AuthStore";
import ModifyRoom from "./views/CreateRoom/ModifyRoom";
import MobileModifyRoom from "./views/CreateRoom/MobileModifyRoom";
import * as Params from "./common/Params"
import TabletOrganizationManagement from "./views/OrganizationManagement/TabletOrganizationManagement";
import MobilePasswordChange from "./views/Home/MobilePasswordChange";
import {IntlProvider} from "react-intl";
import SurveyCreate from "./views/MyTimeline/survey/SurveyCreate";
import SurveyModify from "./views/MyTimeline/survey/SurveyModify";
import UserSurveyDetail from "./views/Statistics/memberActivity/UserSurveyDetail";
import MobileUserSurveyDetail from "./views/Statistics/memberActivity/MobileUserSurveyDetail";
import {DOMAIN} from './domain';
import QuizCreate from "./views/MyTimeline/survey/quiz/QuizCreate";
import QuizModify from "./views/MyTimeline/survey/quiz/QuizModify";
// import ScrollToTop from "./components/ScrollToTop";
// import TeamManagement from "./views/OrganizationManagement/TeamManagement/TeamManagement";
// import TeamMemberManagement from "./views/OrganizationManagement/TeamMemberManagement/TeamMemberManagement";

const style = () => ({
    root: {
        background:'#fff !important',
    }
});

@inject('authStore', 'orgStore',  'teamStore', 'localeStore')
@observer
class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mobileOpen: false,
            isMobile: false,
            isTablet: false,
        };
    }

    componentDidMount() {

        const resize = () => {
            const isMobile = Params.isMobileMode(600);
            const isTablet = Params.isTabletMode(1200);
            this.setState({
                isMobile: isMobile,
                isTablet: isMobile ? false : isTablet ? isTablet : false,
            });
        };

        resize();
        window.onresize = resize;

        const axiosRequestInterceptors = (config) => {
            const token = localStorage.getItem(store.LocalStorageTokenKey);

            if (token) {
                config.headers['X-Auth-Token'] = token;
            }

            return config;
        };

        const axiosRequestErrorHandler = (error) => {
            return Promise.reject(error);
        };

        const axiosResponseInterceptor = (response) => {
            if (response.status === 403) {
                this.props.authStore.invalidateLogin();
            }

            return response;
        };

        const axiosResponseErrorHandler = (error) => {
            if ((error.response) && (error.response.status === 403)) {
                this.props.authStore.invalidateLogin();
            }

            return Promise.reject(error);
        };

        console.log("========== RGate App componentDidMount ==========");
        axios.interceptors.request.use(axiosRequestInterceptors, axiosRequestErrorHandler);
        axios.interceptors.response.use(axiosResponseInterceptor, axiosResponseErrorHandler);

        this.props.authStore.checkLogin();

    }

    render() {
        const { classes, orgStore, localeStore } = this.props;
        const configs = {...orgStore.orgConfigs}
        const {loginState, loginUser} = this.props.authStore;
        const isMobile = this.state.isMobile;
        const isTablet = this.state.isTablet;
        const locale = localeStore.locale;
        const localeMessage = localeStore.localeMessage;
        const domain = DOMAIN[window.location.hostname];
        const Home = domain !== undefined ? domain.home : DefaultHome;
        const MobileHome = domain !== undefined ? domain.mobileHome : DefaultMobileHome;

        return (
            <IntlProvider messages={localeMessage} locale={locale} defaultLocale={'en'} >
                <div className={classes.root}>
                    <CssBaseline/>
                    <Router>
                        {loginState === State.Authenticated ?
                            <Box style={{display:'flex'}}>
                                {
                                    (isMobile || isTablet)
                                        ?
                                        <MobileTopBar configs={configs} isMobile={isMobile} isTablet={isTablet}/>
                                        :
                                        <>
                                            <TopBar />
                                            <SideBar />
                                        </>
                                }
                                {
                                    loginUser.type === UserType.Admin ?
                                        <Switch>
                                            <Route exact path="/profilesettings" render={() => isMobile ? <MobileProfileSettings isMobile={true} isTablet={false}/> : isTablet ? <MobileProfileSettings isMobile={false} isTablet={true}/> : <ProfileSettings isMobile={false} isTablet={false}/> } />
                                            <Route exact path="/servicecenter" render={() => isMobile ? <MobileServiceCenter isMobile={true} isTablet={false}/> : isTablet ? <MobileServiceCenter isMobile={false} isTablet={true}/> : <ServiceCenter isMobile={false} isTablet={false} />} />
                                            <Route exact path={["/management/:category", "/management/:category/:id"]}  render={() => (isTablet || isMobile) ? <TabletOrganizationManagement isMobile={false} isTablet={true}/> : <OrganizationManagement isMobile={false} isTablet={false} />} />
                                            <Redirect path="/" to="/management/organization" />
                                        </Switch>
                                        :
                                        <Switch>
                                            <Route exact path="/mytimeline" render={() => isMobile ? <MobileMyTimeline isMobile={true} isTablet={false}/> : isTablet ? <MobileMyTimeline isMobile={false} isTablet={true} /> : <MyTimeline isMobile={false} isTablet={false} />} />
                                            <Route exact path="/meetingminutes" render={() => isMobile ? <MobileMeetingMinutes isMobile={true} isTablet={false}/> : isTablet ? <MobileMeetingMinutes isMobile={false} isTablet={true}/> : <MeetingMinutes isMobile={false} isTablet={false}/>} />
                                            <Route exact path="/createroom" render={() => isMobile ? <MobileCreateRoom isMobile={true} isTablet={false}/> : isTablet ? <MobileCreateRoom isMobile={false} isTablet={true}/> : <CreateRoom isMobile={false} isTablet={false}/>} />
                                            <Route exact path="/survey/create" render={() => <SurveyCreate/>} />
                                            <Route exact path="/quiz/create" render={() => <QuizCreate/>} />
                                            <Route exact path="/survey/modify/:surveyId" render={() => <SurveyModify/>} />
                                            <Route exact path="/quiz/modify/:quizId" render={() => <QuizModify/>} />
                                            <Route exact path="/modifyroom" render={() => isMobile ? <MobileModifyRoom isMobile={true} isTablet={false}/>: isTablet ? <MobileModifyRoom isMobile={false} isTablet={true}/> : <ModifyRoom isMobile={false} isTablet={false}/>} />
                                            <Route exact path="/teams/:id" render={() => isMobile ? <MobileTeamRoom isMobile={true} isTablet={false}/> : isTablet ? <MobileTeamRoom isMobile={false} isTablet={true}/> : <TeamRoom isMobile={false} isTablet={false}/>} />
                                            <Route exact path="/servicecenter" render={() => isMobile ? <MobileServiceCenter isMobile={true} isTablet={false}/> : isTablet ? <MobileServiceCenter isMobile={false} isTablet={true}/> : <ServiceCenter isMobile={false} isTablet={false}/>} />
                                            <Route exact path="/profilesettings" render={() => isMobile ? <MobileProfileSettings isMobile={true} isTablet={false}/> : isTablet ? <MobileProfileSettings isMobile={false} isTablet={true}/> : <ProfileSettings isMobile={false} isTablet={false}/>} />
                                            <Route exact path={["/management/:category", "/management/:category/:id"]} render={() => isTablet ? <TabletOrganizationManagement isMobile={false} isTablet={true}/> : <OrganizationManagement/>} />
                                            <Route exact path="/rooms/:id/statistics" render={() => isMobile ? <MobileStatistics isMobile={true} isTablet={false} /> : isTablet ? <MobileStatistics isMobile={false} isTablet={true} /> : <Statistics isMobile={false} isTablet={false}/>} />
                                            <Route exact path="/rooms/:roomId/users/:userId/statistics/" render={() => isMobile ? <MobileUserSurveyDetail isMobile={true} isTablet={false} /> : isTablet ? <UserSurveyDetail isMobile={false} isTablet={true} /> : <UserSurveyDetail isMobile={false} isTablet={false} />} />
                                            <Redirect path={"/"} to="/mytimeline" />
                                        </Switch>
                                }
                            </Box>
                            :
                            <Box>
                                {isMobile ?
                                    <Switch>
                                        <Route exact path="/mobilesignin" render={()=> <MobileSignIn />} />
                                        <Route exact path="/mobilepasswordchange" render={()=> <MobilePasswordChange />} />
                                        {/*<Route exact path="/survey/modify/:surveyId" render={() => <SurveyModify/>} />*/}
                                        <Route exact path="/" render={()=> <MobileHome />} />
                                        {loginState === State.NotAuthenticated && !localStorage.getItem(LocalStorageTokenKey) && <Redirect path={"*"} to={"/"} /> }
                                    </Switch>
                                    :
                                    <>
                                        {domain === undefined &&
                                            <MainTopBar isMobile={this.state.isMobile} isTablet={this.state.isTablet} />
                                        }

                                        <Switch>
                                            <Route exact path="/" render={() =><Home isMobile={this.state.isMobile} isTablet={this.state.isTablet} configs={configs}/>} />
                                            {loginState === State.NotAuthenticated && !localStorage.getItem(LocalStorageTokenKey) && <Redirect path={"*"} to={"/"} /> }
                                        </Switch>

                                    </>
                                }
                            </Box>
                        }
                    </Router>
                </div>
            </IntlProvider>
        );
    }
};

export default withRouter(withStyles(style)(App));
