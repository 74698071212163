import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import QuestionsChart from "./QuestionsChart";
import {observer} from "mobx-react";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
        height:'100%'
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:16,
        fontWeight:600,
        color:'#0d0d0d',
    },
});

@observer
class Questions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        };
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}><FormattedMessage id="presentation_and_question_count"/></Typography>
                <QuestionsChart />
            </div>
        );
    };
}

export default withStyles(style)(Questions);