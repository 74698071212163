import {makeAutoObservable, toJS} from "mobx";
import axios from "axios";
import _ from 'lodash';

const logPrefix = ' [QuizStore] ';

export const QuizType = {
    SingleChoice: 'SingleChoice',
    MultipleChoice: 'MultipleChoice',
    ShortAnswer: 'ShortAnswer',
    Subjective: 'Subjective'
};

const ErrorCode = {
    CanNotFoundUser: "msg.error_update_room",
    CanNotFoundQuiz: "msg.error_update_room",
    CanNotInsertQuiz: "msg.error_update_room",
    DataMakeFail: "msg.error_update_room",
    CanNotInsertData: "msg.error_update_room",
    CanNotDeleteQuiz: "msg.error_update_room",
    BadRequest: "msg.error_input_missing",
    Unknown: "msg.error_unknown"
};

const EmptyQuiz = {
    quizId: '',
    userId: '',
    title: '',
    type: QuizType.SingleChoice,
    quizBankItems: [],
    quizBankImages: {},
};

const EmptyQuizItems = {
    itemId: '',
    correct: '',
    content: '',
};

export default class QuizStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    };

    /** Data */
    quiz = Object.assign({}, EmptyQuiz);
    quizBankItems = Object.assign({}, EmptyQuizItems);
    quizList = [];
    quizBankImages = [];
    quizBankImageUrl = [];
    quizHistory = [];
    selectedQuizId = '';

    /** Loading */
    isQuizLoading = false;
    isAddQuizLoading = false;

    /** Dialog */
    openConfirmDialog = false;
    openDeleteDialog = false;
    mobileQuizDialog = false;
    errMsg = '';

    initQuiz = (loginUser, quizId) => {
        // console.log(logPrefix, "InitQuiz");
        this.quiz = Object.assign({}, EmptyQuiz);
        this.quizBankItems = Object.assign({}, EmptyQuizItems);
        // this.quizBankImages = Object.assign({}, EmptyQuizImage);
        this.quizHistory = [];
        this.quizBankImages = [];
        this.quizBankImageUrl = [];
        this.isQuizLoading = false;
        this.isAddQuizLoading = false;

        this.openConfirmDialog = false;
        this.openDeleteDialog = false;
        this.errMsg = '';
        this.setUserId(loginUser);
        if(quizId){
            this.getQuizHistory(quizId);
            this.getQuizDetailByQuizId(quizId);
        }
    };

    get getIsLoading() {
        return this.isQuizLoading || this.isAddQuizLoading;
    };

    mobileQuizDialogOpen = (loginUser, quizId) => {
        if(quizId){
            this.initQuiz(loginUser, quizId)
        } else {
            this.initQuiz(loginUser)
        }
        this.mobileQuizDialog = true;
    }

    mobileQuizDialogClose = () => {
        this.mobileQuizDialog = false;
    }

    changeConfirmDialogOpen = () => {
        this.openConfirmDialog = !this.openConfirmDialog
    };

    changeOpeDeleteDialog = () => {
        this.openDeleteDialog = !this.openDeleteDialog;
    };

    setUserId = (loginUser) => {
        this.quiz.userId = loginUser.id;
    };

    changeQuizType = (type) => {
        this.quiz.type = type
        this.quiz.quizBankItems = [];
    };

    changeQuizTitle = (title) => {
        this.quiz.title = title
    };

    changeQuizImage = (imageData, image) => {
        // console.log(logPrefix, "ChangeQuizImage Start ... image={}, imageData={}", image, imageData);
        this.quizBankImages = [];
        this.quizBankImageUrl = [];
        this.quizBankImages.push(image);
        this.quizBankImageUrl.push(imageData);
        // console.log(logPrefix, "ChangeQuizImage Result={}", this.quizBankImages);
    };

    deleteQuizImage = (index) => {
        if(this.quiz.quizBankImages !== null){
            this.quiz.quizBankImages = null;
        }
        this.quizBankImages.splice(index, 1);
        this.quizBankImageUrl.splice(index, 1);
        // console.log(logPrefix, "DeleteQuizImage Result={}", this.quizBankImages);
    };

    addAnswerOptions = (option) => {
        // console.log(logPrefix, "option={}", option);
        const newAnswer = _.cloneDeep(this.quizBankItems);
        newAnswer.content = option.answerTitle;
        newAnswer.correct = option.switch;
        this.quiz.quizBankItems.push(newAnswer);
        this.quiz.quizBankItems.forEach((item,index) => item.itemId = (index + 1));
        // console.log(logPrefix, "ChangeAnswerOptions Result : >> ", this.quiz.quizBankItems);
    };

    deleteAnswerOption = (index) => {
        this.quiz.quizBankItems.splice(index, 1);
        this.quiz.quizBankItems.forEach((item, index) => item.quizItemId = (index + 1));
    };

    isInputValidation = (intl) => {
        // console.log("this.quiz.quizBankItems", this.quiz.quizBankItems);

        let checkInput = true;

        if (this.quiz.title === "") {

            checkInput = false;
            this.errMsg = intl.formatMessage({id: "msg.required_subject"});

        } else if (!this.isSubjective && !this.quiz.quizBankItems.some(item => item.correct)) {

            checkInput = false;
            this.errMsg = "객관식 퀴즈에는 하나 이상의 정답이 있어야 합니다.";

        }

        this.openConfirmDialog = !checkInput;
        return checkInput;
    };

    get isSubjective() {
        return this.quiz.type === QuizType.ShortAnswer || this.quiz.type === QuizType.Subjective;
    };

    * getQuizListByUserId(loginUser) {
        // console.log(logPrefix,"GetQuizListByUserId Start... userId={}", loginUser.id);
        this.isQuizLoading = true;

        try {

            const response = yield axios.get(this.serverContextPath + `/api/v1/quiz/${loginUser.id}`);
            // console.log(logPrefix, "GetQuizListByUserId Success... response={}", response.data);
            this.quizList = response.data;

        } catch (e) {

            console.log(logPrefix, "GetQuizListByUserId Fail : >> ", e);

        } finally {

            this.isQuizLoading = false;

        }
    };

    * getQuizDetailByQuizId(quizId) {
        // console.log(logPrefix,"GetQuizDetailByQuizId Start... quizId={}", quizId);
        this.isQuizLoading = true;

        try {

            const response = yield axios.get(this.serverContextPath + `/api/v1/quiz/detail/${quizId}`);
            // console.log(logPrefix, "GetQuizDetailByQuizId Success... response={}", response);
            this.quiz = response.data;
            if(response.data.quizBankImages){
                this.quizBankImageUrl.push(response.data.quizBankImages.quizImageBinaries[0]);
            }

        } catch (e) {

            console.log(logPrefix, "GetQuizDetailByQuizId Fail : >> ", e);

        } finally {

            this.isQuizLoading = false;

        }
    };

    * getQuizHistory(quizId, callback) {
        // console.log(logPrefix,"GetQuizHistory Start... quizId={}", quizId);
        this.isQuizLoading = true;

        try {

            const response = yield axios.get(this.serverContextPath + `/api/v1/quiz/histories/${quizId}`);
            // console.log(logPrefix, "GetQuizHistory Success... response={}", response.data);
            this.quizHistory = response.data;

        } catch (e) {

            console.log(logPrefix, "GetQuizHistory Fail : >> ", e);

        } finally {

            this.isQuizLoading = false;
            callback&& callback();

        }
    };

    * insertQuiz(intl, history) {
        // console.log(logPrefix,"InsertQuiz Start...",this.quiz);
        this.isQuizLoading = true;

        try {
            if (!this.isInputValidation(intl)) {
                return false;
            }
            const files = toJS(this.quizBankImages);
            const requestQuiz = new Blob([JSON.stringify({
                quizId: this.quiz.quizId,
                userId: this.quiz.userId,
                title: this.quiz.title,
                type: this.quiz.type,
                quizBankItems: this.quiz.quizBankItems,
            })], {
                type: 'application/json;charset=utf-8'
            });
            const param = new FormData();
            param.append('requestQuiz', requestQuiz);
            files.forEach(file => param.append("quizBankImages", file));

            const response = yield axios.post(this.serverContextPath + '/api/v1/quiz', param);
            history.push('/')
            // console.log(logPrefix, "InsertQuiz Success... response={}", response.data);
            this.quizList = response.data;

        } catch (e) {

            console.log(logPrefix, "InsertQuiz Fail : >> ", e);
            // this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            // this.openConfirmDialog = true;

        } finally {

            this.isQuizLoading = false;

        }
    };

    * updateQuiz(intl, history) {
        // console.log(logPrefix,"UpdateSurvey Start...");
        this.isQuizLoading = true;

        try {

            if (!this.isInputValidation(intl)) {
                return false;
            }

            const files = toJS(this.quizBankImages);
            const requestQuiz = new Blob([JSON.stringify({
                quizId: this.quiz.quizId,
                userId: this.quiz.userId,
                title: this.quiz.title,
                type: this.quiz.type,
                quizBankItems: this.quiz.quizBankItems,
                quizBankImages: this.quiz.quizBankImages,
            })], {
                type: 'application/json;charset=utf-8'
            });
            const param = new FormData();
            param.append('requestQuiz', requestQuiz);
            files.forEach(file => param.append("quizBankImages", file));

            const response = yield axios.put(this.serverContextPath + `/api/v1/quiz`, param);
            // console.log(logPrefix, "UpdateQuiz Success... response={}", response.data);
            this.quizList = response.data;
            history.push('/');

        } catch (e) {

            console.log(logPrefix, "insertSurveyItem Fail : >> ", e);
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            this.openConfirmDialog = true;

        } finally {

            this.isQuizLoading = false;

        }
    };

    * copyQuiz(intl, quizId, loginUser) {
        // console.log(logPrefix,"CopySurvey Start... quizId={}, loginUser", quizId, loginUser);
        this.isQuizLoading = true;

        try {

            yield axios.post(this.serverContextPath + `/api/v1/quiz/${quizId}/copy/${loginUser.id}`);
            // console.log(logPrefix, "CopySurvey Success");
            this.getQuizListByUserId(loginUser);

        } catch (e) {

            console.log(logPrefix, "CopyQuiz Fail : >> ", e);
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            this.openConfirmDialog = true;

        } finally {

            this.isQuizLoading = false;

        }
    };

    * deleteQuiz(intl, loginUser) {
        // console.log(logPrefix,"DeleteQuiz Start... quizId={}",this.selectedQuizId);
        this.isQuizLoading = true;

        try {

            yield axios.delete(this.serverContextPath + `/api/v1/quiz/${this.selectedQuizId}`);
            // console.log(logPrefix, "DeleteQuiz Success...");
            this.getQuizListByUserId(loginUser);

        } catch (e) {

            console.log(logPrefix, "DeleteQuiz Fail : >> ", e);
            this.errMsg = intl.formatMessage({id: ErrorCode[e.response.data.code]});
            this.openConfirmDialog = true;

        } finally {

            this.isQuizLoading = false;

        }
    };
}