import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Grid,
    Button,
    Select,
    FormControl,
    TextareaAutosize,
    TextField, ClickAwayListener, FormControlLabel, Checkbox,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import GuestParticipation from "./containers/GuestParticipation";
import SelectSchedule from "../../components/Calendar/SelectSchedule";
import {inject, observer} from "mobx-react";
import ConfirmDialog from "../../common/ConfirmDialog";
import {withRouter} from "react-router-dom";
import {TeamType} from "../../stores/RoomStore";
import {FormattedMessage, injectIntl} from "react-intl";
import {ReactComponent as UnCheckedBox} from "../../common/images/UnCheckedBox.svg";
import {ReactComponent as CheckedBox} from "../../common/images/CheckedBox.svg";

const style = (theme) => ({
    root: {
        paddingTop: 100,
        paddingLeft: 70,
        marginLeft: 90,
        width: '100%',

    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 24,
        fontWeight: 800,
        color: '#0d0d0d',
        marginBottom: 55
    },
    labelText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        fontWeight: 600,
        color: '#0d0d0d',
        width: 180,
    },
    formControlBox: {
        width: '100%',
        background: '#fff',
        border: 0,
        borderRadius: 5,
        "& .MuiSelect-select": {
            background: 'transparent',
        },
        "& .MuiOutlinedInput-input": {
            padding: '11px 6px',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiSelect-select:focus": {
            background: 'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        }
    },
    selectBox: {
        width: 240,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#303030'
    },
    inputStyle: {
        width: '100%',
        padding: '11px 5px',
        borderRadius: 5,
        border: '1px solid #b7bcd6',
        background: '#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        "&::placeholder": {
            color: '#0d0d0d'
        },
        "&:focus": {
            outline: 'none',
        },
    },
    textareaStyle: {
        width: "100%",
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        border: '1px solid #b7bcd6',
        padding: '10px',
        borderRadius: 4,
        overflowY: 'auto',
        resize: 'none',
        "&::placeholder": {
            color: '#0d0d0d'
        },
        "&:focus": {
            outline: 'none',
        },
        "&::-webkit-scrollbar": {
            width: '5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background: 'transparent',
            marginTop: 10,
        },
    },
    autocompleteBox: {
        width: '100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        "& .MuiAutocomplete-root": {
            padding: 0,
        },
        "& .MuiAutocomplete-input": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#0d0d0d',
        },
        "& .MuiChip-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 12,
            color: '#0d0d0d'
        },
        "& .MuiAutocomplete-popper": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 12,
            color: '#0d0d0d',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiSelect-select:focus": {
            background: 'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        }
    },
    option: {
        fontSize: 14,
        fontFamily: 'NanumSquareRoundOTF',
        color: '#303030'
    },
    buttonStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 12,
        border: '1px solid #b7bcd6',
        marginTop: 9,
        padding: '8px 14px'
    },
    teamDropdown: {
        position: 'relative',
    },
    dropdown: {
        position: 'absolute',
        top: 50,
        right: 0,
        left: 0,
        zIndex: 1,
        border: '1px solid #b7bcd6',
        padding: '8px 0',
        background: '#fff'
    },
    dropdownText: {
        fontSize: 14,
        fontFamily: 'NanumSquareRoundOTF',
        color: '#303030',
        cursor: 'pointer',
        padding: '5px 18px',
        "&:hover": {
            background: '#f5f5f5'
        }
    },
    selectedDropdownText: {
        fontSize: 14,
        fontFamily: 'NanumSquareRoundOTF',
        color: '#303030',
        cursor: 'pointer',
        padding: '5px 18px',
        opacity: 0.2,
    },
    btnStyle: {
        padding: '9px 38px',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        fontWeight: 800,
        color: '#fff',
        marginBottom: 50,
        borderRadius: 7,
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle1: {
        padding: '9px 38px',
        background: '#a3a8af',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        fontWeight: 800,
        color: '#fff',
        marginBottom: 50,
        marginRight: 10,
        borderRadius: 7,
        "&:hover": {
            background: '#a3a8af'
        }
    },
    checkBox: {
        width: '100%',
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#0d0d0d',
            fontWeight: 600
        }
    },
});

@inject('roomStore', 'authStore', 'userLoungeStore', 'teamStore', 'userStore', 'progressStore', 'timelineStore', 'topBarStore')
@observer
class CreateRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            members: [],
        }
    }

    componentDidMount() {
        const {history, userLoungeStore, topBarStore, roomStore, authStore} = this.props;
        if (window.location.pathname === '/createroom') {
            topBarStore.setIsSearchBarRender(false);
        }
        if ((userLoungeStore.selectedTeamId === 0) && (history !== undefined)) {
            history.push('/');
        } else {
            roomStore.initRoomInfo(authStore.loginUser, userLoungeStore.selectedTeamId);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {roomStore, progressStore, userStore, teamStore} = this.props;
        progressStore.setIsLoading(roomStore.getIsLoading || userStore.getIsLoading || teamStore.getIsLoading)
    }

    handleCancel = () => {
        const {userLoungeStore} = this.props;

        if (userLoungeStore.selectedTeamId !== 0) {
            this.props.history.push(`/teams/${userLoungeStore.selectedTeamId}`);
        } else {
            this.props.history.push(`/mytimeline`);
        }
    }

    handleCreateRoom = () => {
        const {intl, roomStore, timelineStore, userLoungeStore} = this.props;
        roomStore.createRoom(intl, this.props.history, timelineStore, userLoungeStore);
    };

    handleSendEmailChecked = (checked) => {
        const {roomStore} = this.props;
        roomStore.changeSendEmail(checked);
    };

    render() {
        const {classes, intl} = this.props;
        const {roomStore} = this.props;

        return (
            <div className={classes.root}>
                <Typography
                    className={classes.titleStyle}><FormattedMessage id="on_room_create"/></Typography>
                <Grid item sm={11} md={9} lg={7} xl={5}>
                    <Box display='flex' alignItems='center' pb={4}>
                        <Typography className={classes.labelText}><FormattedMessage id="room_type"/><span
                            style={{color: '#ff0000', marginLeft: 5}}>*</span></Typography>
                        <FormControl variant={'outlined'} className={classes.formControlBox}>
                            <Select
                                native
                                value={roomStore.roomInfo.type}
                                onChange={e => roomStore.changeRoomType(e.target.value)}
                                className={classes.selectBox}

                            >
                                <option value='Education'
                                        className={classes.optionStyle}>{intl.formatMessage({id: "education"})}</option>
                                {/*<option value='Conference' className={classes.optionStyle}>{UTIL.getIntlFormatMessageString("conference")}</option>*/}

                            </Select>
                        </FormControl>
                    </Box>

                    <Box display='flex' alignItems='center' pb={4}>
                        <Typography className={classes.labelText}><FormattedMessage id="topics"/><span
                            style={{color: '#ff0000', marginLeft: 5}}>*</span></Typography>
                        <input
                            name="title"
                            type="text"
                            id="title"
                            maxLength="50"
                            placeholder={intl.formatMessage({id: "msg.insert_room_topic"})}
                            onChange={e => roomStore.changeRoomName(e.target.value)}
                            value={roomStore.roomInfo.name}
                            className={classes.inputStyle}
                        />
                    </Box>

                    <Box display='flex' pb={4}>
                        <Typography
                            className={classes.labelText}><FormattedMessage id="descriptions"/></Typography>
                        <TextareaAutosize
                            rows={9}
                            rowsMax={9}
                            aria-label="maximum height"
                            placeholder={intl.formatMessage({id: "msg.insert_room_description"})}
                            onChange={e => roomStore.changeComment(e.target.value)}
                            value={roomStore.roomInfo.comment}
                            className={classes.textareaStyle}
                        />
                    </Box>

                    <Box display='flex' alignItems='center' pb={4}>
                        <Typography
                            className={classes.labelText}><FormattedMessage id="select_reservation_date"/><span
                            style={{color: '#ff0000', marginLeft: 5}}>*</span></Typography>
                        <Box style={{width: '100%'}}>
                            <SelectSchedule/>
                        </Box>

                    </Box>

                    {/* 보안 */}
                    <GuestParticipation/>

                    <Box display='flex' alignItems='center' pb={4}>
                        <Typography className={classes.labelText}><FormattedMessage id="attendees"/><span
                            style={{color: '#ff0000', marginLeft: 5}}>*</span>
                            <br/>
                            {/*<span style={roomStore.calMaxUsers <= 0 ? {color: 'red'} : {color: 'black'}}>*/}
                            {/*(참석가능 인원 : {roomStore.calMaxUsers} {UTIL.getIntlFormatMessageString("person")})*/}
                            {/*</span>*/}
                        </Typography>

                        <Box display='flex' flexDirection='column' style={{width: '100%'}}>
                            <Box className={classes.autocompleteBox}>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    classes={{
                                        option: classes.option,
                                    }}
                                    value={this.state.members}
                                    loading={roomStore.isSearchLoading}
                                    noOptionsText={intl.formatMessage({id: "no_search_result"})}
                                    loadingText={intl.formatMessage({id: "searching"})}
                                    options={roomStore.teamList}
                                    getOptionDisabled={(option) => {
                                        if (option.type === TeamType.Team) {
                                            return roomStore.roomInfo.members.filter(value => value.id === option.id && value.type === TeamType.Team).length > 0
                                        } else {
                                            return roomStore.roomInfo.members.filter(value => value.id === option.id && value.type !== TeamType.Team).length > 0
                                        }
                                    }
                                    }
                                    getOptionLabel={(option) => {
                                        if (option.type === TeamType.Team) {
                                            return `#${option.name}`
                                        } else {
                                            return `@${option.name}`
                                        }
                                    }}
                                    groupBy={(option) => option.type}
                                    filterSelectedOptions
                                    size='small'
                                    disablePortal={true}
                                    freeSolo
                                    onChange={(e, value, option) => {
                                        if (option !== "create-option") {
                                            this.setState({members: value})
                                            roomStore.changeMembers(value)
                                        }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label=""
                                            placeholder={roomStore.roomInfo.members.length > 0 ? "" : intl.formatMessage({id: "msg.insert_room_attendees"})}
                                            onChange={(e) => {
                                                roomStore.searchTeamMembers(e.target.value);
                                            }}
                                        />
                                    )}
                                />
                            </Box>

                            <ClickAwayListener onClickAway={roomStore.handleClickAway}>
                                <Box className={classes.teamDropdown}>
                                    <Button className={classes.buttonStyle}
                                            onClick={roomStore.handleChangeDropDownButton}>
                                        <FormattedMessage id="import_from_registered_team"/>
                                    </Button>
                                    {roomStore.teamDropDownButtonOpen ? (
                                        <Box className={classes.dropdown}>
                                            {roomStore.myTeamList.map(option =>
                                                <Typography key={option.id}
                                                            onClick={() => {
                                                                if (roomStore.roomInfo.members.find(data => (data.name === option.name) && (data.id === option.id)) === undefined) {
                                                                    this.setState({members: this.state.members.concat(option)})
                                                                    roomStore.changeMembersInMyTeams(option)
                                                                }
                                                            }
                                                            }
                                                            className={roomStore.roomInfo.members.find(data => (data.name === option.name) && (data.id === option.id)) === undefined ? classes.dropdownText : classes.selectedDropdownText}>#{option.name}</Typography>
                                            )}
                                        </Box>
                                    ) : null}
                                </Box>
                            </ClickAwayListener>
                        </Box>
                    </Box>
                    <Box display='flex' alignItems='center' pb={4}>
                        <Typography className={classes.labelText}><FormattedMessage id="email"/></Typography>
                        <Box display='flex' flexDirection="column" className={classes.checkBox}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={<UnCheckedBox/>}
                                        checkedIcon={<CheckedBox/>}
                                        checked={roomStore.isSendEmail}
                                        onChange={e => this.handleSendEmailChecked(e.target.checked)}
                                    />
                                }
                                label={intl.formatMessage({id: "send_invitation_email"})}
                                style={{width: 200}}
                            />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent='flex-end' pt={8} pb={8}>
                        <Button className={classes.btnStyle1} onClick={this.handleCancel}
                                disabled={roomStore.getIsLoading}><FormattedMessage id="cancel"/></Button>
                        <Button className={classes.btnStyle} disabled={roomStore.getIsLoading}
                                onClick={this.handleCreateRoom}><FormattedMessage id="save"/></Button>
                    </Box>
                    <ConfirmDialog open={roomStore.openConfirmDialog}
                                   title={intl.formatMessage({id: "msg.input_incorrect"})}
                                   msg={roomStore.errMsg}
                                   changeDialogOpen={roomStore.changeConfirmDialogOpen}
                    />
                </Grid>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(CreateRoom)));