import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Input,
    Typography
} from "@material-ui/core";
import {inject, observer} from "mobx-react";

const style = theme => ({
    root: {
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width: '100%',
        border: '1px solid #c4c9de',
        borderRadius: 8,
        marginBottom: 40,
        padding: '30px 20px 25px',
        "& .MuiInput-underline:after, .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before ": {
            borderBottom: 0
        },
        "& label + .MuiInput-formControl": {
            marginTop: 0
        }
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 18,
        color: '#a3a8af',
        fontWeight: 600,
    },
    explanationText: {
        fontSize: 12,
        fontWeight: 400,
        marginTop: 12
    },
    inputBoxIn: {
        width: '100%',
        fontSize: 14,
        color: '#0d0d0d',
        fontWeight: 500,
        borderBottom: '1px solid #979797',
        paddingLeft: 13,
        "&::placeholder": {
            color: '#a3a8af'
        }
    },
    titleImgBox: {
        width: 241,
        height: 181,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center'
    },
});

@inject('statisticsStore')
@observer
class SurveyShortAnswer extends Component {
    render() {
        const {classes, statisticsStore, item} = this.props;
        const shortAnswer = statisticsStore.getUserSurveyShortAnswer(item);

        return (
            <div className={classes.root}>
                {item.title !== "" &&
                    item.required ?
                        <Typography className={classes.textStyle} style={{color: '#0d0d0d'}}>{item.title}<span style={{color: '#FF0000'}}>*</span></Typography>
                        :
                        <Typography className={classes.textStyle} style={{color: '#0d0d0d'}}>{item.title}</Typography>

                }
                {item.contents !== "" &&
                    <>
                        <Typography className={clsx(classes.textStyle, classes.explanationText)} style={{color:'#0d0d0d'}}>{item.contents}</Typography>
                    </>
                }
                {item.images.length > 0 &&
                    item.images.map((images, i) => (
                        <Box key={item.surveyItemId + i} className={classes.titleImgBox} mb={2}>
                            <img src={images.image} alt="" style={{width: '100%'}}/>
                        </Box>
                    ))
                }
                <Box style={{marginTop: 28}}>
                    <Input
                        defaultValue={shortAnswer}
                        className={clsx(classes.textStyle, classes.inputBoxIn)}
                        inputProps={{
                            'aria-label': 'Description',
                        }}
                        readOnly={true}
                    />
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(SurveyShortAnswer);