import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Popper,
    Paper,
    IconButton,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList
} from "@material-ui/core";

import {ReactComponent as MoreIcon} from "../../../common/images/MoreIcon.svg";
import {MobXProviderContext, observer} from "mobx-react";
import {DATE_UTIL} from "../../../common/util/date.util";
import * as dayjs from "dayjs";
import {Link, withRouter} from "react-router-dom";
import DeleteDialog from "../../../common/DeleteDialog";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        "& .MuiPaper-root":{
            boxShadow:'0 2px 4px 0 rgba(0, 0, 0, 0.25)',
            border:'solid 0.5px #d4d4d6',
            borderRadius:7,
        },
        "& .MuiMenuItem-root":{
            paddingLeft:10,
            paddingRight:10
        }
    },
    moreIcon:{
        padding:8,
        "&:hover":{
            background:'#ededed'
        }
    },
    listText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 12,
        color: '#000',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
}));

function MoreButton(props) {
    const { timelineStore, roomStore, authStore, userLoungeStore } = React.useContext(MobXProviderContext);
    const intl = useIntl();
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };

    const handleModifyButton = () => {
        setOpen(false);
        // roomStore.modifyRoom(props.room.id)
    };

    const handleRemoveButton = () => {
        handleToggle();
        roomStore.deleteRoom(intl, props.history, props.room.id, authStore.loginUser.id, timelineStore, userLoungeStore);
    };

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <div className={classes.root}>
            <div>
                <IconButton
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    className={classes.moreIcon}
                >
                    <MoreIcon />
                </IconButton>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                                        {dayjs(currentDatetime).isBefore(props.room.startDatetime) &&
                                        <Link to={{pathname: '/modifyroom', roomId: props.room.id, teamId: props.room.teamId}} className={classes.link} onClick={() => handleModifyButton}>
                                            <MenuItem className={classes.listText} ><FormattedMessage id="modify"/></MenuItem>
                                         </Link>
                                        }
                                        <MenuItem className={classes.listText} onClick={() => timelineStore.changeOpenMoreDeleteDialog(true)}><FormattedMessage id="delete"/></MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </div>
            <DeleteDialog open={timelineStore.openMoreDeleteDialog}
                          title={intl.formatMessage({id: "delete"})}
                          msg={intl.formatMessage({id: "msg.check_delete"})}
                          changeDialogOpen={timelineStore.changeOpenMoreDeleteDialog}
                          submit={handleRemoveButton}
            />
        </div>
    );
}
export default withRouter(observer(MoreButton));