import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Paper,
    Typography,
    Box,
    IconButton,
    Tooltip,
    ClickAwayListener,
    Popper
} from "@material-ui/core";
import {TimelineContent} from "@material-ui/lab";
import { ReactComponent as ArrowLineConference} from "../../../common/images/ArrowLineConference.svg";
import { ReactComponent as ConferenceIcon} from "../../../common/images/ConferenceIcon.svg";
import { ReactComponent as ArrowLineLearning} from "../../../common/images/ArrowLineLearning.svg";
import { ReactComponent as LearningIcon} from "../../../common/images/LearningIcon.svg";
import { ReactComponent as SmileyIcon} from "../../../common/images/SmileyIcon.svg";
import { ReactComponent as ClockIcon} from "../../../common/images/ClockIcon.svg";
import { ReactComponent as Enter} from "../../../common/images/Enter.svg";
import { ReactComponent as CaretCircle} from "../../../common/images/CaretCircle.svg";
import TeamMembersSideBar from "../../../components/TeamMembersSideBar";
import LinkView from "./LinkView";
import RoomInformation from "./RoomInformation";
import {DATE_UTIL} from "../../../common/util/date.util";
import dayjs from "dayjs";
import {inject, observer} from "mobx-react";
import {UTIL} from "../../../common/util/common.util";
import {FormattedMessage, injectIntl} from "react-intl";
import SettingsIcon from '@material-ui/icons/Settings';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import DeleteDialog from "../../../common/DeleteDialog";
import {withRouter} from "react-router-dom";

const style = theme => ({
    root:{
        marginTop:50,
        "& .MuiTimeline-root":{
            paddingLeft:40,
            paddingRight:0
        },
        "& .MuiTimelineOppositeContent-root":{
            flex:0,
            padding:0
        },
        "& .MuiTimelineContent-root":{
            paddingTop:0,
            paddingLeft:0
        },
    },
    // 시간이 지났을경우
    lineContentBoxOpacity:{
        position:'relative',
        opacity:0.5,
        borderTop:'1px dotted #c0c2c3',
    },

    iconButton:{
        padding:0,
        marginBottom:8,
        "&:hover":{
            background:'transparent'
        }
    },
    lineContentBox:{
        position:'relative',
        borderTop:'1px dotted #c0c2c3',
    },
    // 회의일경우
    lineContentBoxIn:{
        position:'relative',
        display:'flex',
        justifyContent:'space-between',
        width:'45%',
        background:'#dff8ed',
        borderRadius:10,
        padding:22,
        boxShadow:'none',
        marginLeft:20,
        marginTop:-18,
        borderTop:'2px solid #00bc6a',
        zIndex:10
    },
    //회의이면서 일정이 여러개일때
    lineContentBoxIn2:{
        position:'absolute',
        top:3,
        left:22,
        width:'45%',
        height:'100%',
        content:'',
        padding:22,
        background:'#d1f1e3',
        borderRadius:10,
        boxShadow:'none',
        zIndex:5
    },
    //학습일경우
    lineContentBoxIn1:{
        position:'relative',
        display:'flex',
        justifyContent:'space-between',
        width:'45%',
        background:'#eae4ff',
        borderRadius:10,
        padding:22,
        boxShadow:'none',
        marginLeft:20,
        marginTop:-18,
        borderTop:'2px solid #664cff',
        zIndex:10,
    },
    //힉습이면서 일정이 여러개일때
    lineContentBoxIn3:{
        position:'absolute',
        top:3,
        left:22,
        width:'45%',
        height:'100%',
        content:'',
        padding:22,
        background:'#D9D2EF',
        borderRadius:10,
        boxShadow:'none',
        zIndex:5
    },
    learningIcon:{
        "& path":{
            fill:'#664cff',
        },
    },
    learningIcon1:{
        "& path":{
            stroke:'#664cff'
        },
    },
    ArrowLineBox:{
        position:'relative',
        marginTop:-15,
        zIndex:20
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:800,
        color:'#0d0d0d',
        textAlign:'left',
        maxWidth: 200,
        whiteSpace:'nowrap',
        textOverflow: 'ellipsis',
        overflow:'hidden'
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        fontWeight:600,
        color:'#0d0d0d',
        margin:'6px 0 9px',
        maxWidth: 200,
        whiteSpace:'nowrap',
        textOverflow: 'ellipsis',
        overflow:'hidden'
    },
    textStyle3:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#0d0d0d',
        fontWeight:400,
        marginLeft:3
    },
    countBox:{
        display:'inline-block',
        position:'absolute',
        top:-20,
        right:-16,
        borderRadius:5,
        background:'#fb4a59',
        padding:'5px 10px'
    },
    countText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#fff',
        fontWeight:600
    },
    iconBtn:{
        padding:0,
    },
    popper: {
        zIndex: 25,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    paper: {
        padding:0,
        maxWidth: 500,
        overflow: 'auto',
        boxShadow:'0 4px 15px rgba(0, 0, 0, 0.25)',
        borderRadius:10,
    },
    avatarStyle:{
        display:'inline-block',
        "& .MuiAvatar-circle":{
            width:27,
            height:27,
            background:'#fff',
            fontFamily:'NanumSquareRoundOTF',
            fontSize:12,
            color:'#0d0d0d',
            fontWeight:800,
        },
        "& .MuiAvatar-colorDefault":{
            zIndex:'999 !important',
        }
    },
    imageText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        fontWeight:700,
        color:'#fff',
        padding:'2px 4px'
    },
    imageTextBox:{
        position:'absolute',
        alignItems:'center',
        padding:'0 2px',
        top:'2%',
        left:40,
        display:'flex',
        border:'2px solid #fff',
        borderRadius:8,
    }
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        padding:'4px 4px',
        border:'0.3px solid #000000',
        background:'#fffff5',
        fontFamily:'NanumSquareRoundOTF',
        fontSize: 11,
        color:'#000',
        borderRadius:'0',
        marginLeft:5,
        marginTop:5
    },
}))(Tooltip);

@inject('authStore', 'userLoungeStore', 'timelineStore', 'orgStore', 'roomStore')
@observer
class TimeLineItemContent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            arrow: true,
            arrowRef: null,
            disablePortal: false,
            flip: true,
            placement: 'bottom',
        };
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClickEnterance = (event, url) => {
        event.stopPropagation();
        url = url + '&tz=' + DATE_UTIL.getTimeZone() + '&local=' + DATE_UTIL.getLanguage();
        window.open(url);
    };

    handleModifyButton = (event, roomId, teamId) => {
        const {history} = this.props;

        event.stopPropagation();
        history.push({pathname: "/modifyroom", roomId: roomId, teamId: teamId});
    };

    handleRemoveButton = (event) => {
        const {timelineStore} = this.props;

        event.stopPropagation();
        timelineStore.changeOpenDeleteDialog(true)
    };

    handleRemoveRoom = () => {
        const {authStore, roomStore, timelineStore, userLoungeStore, intl, history, room} = this.props;
        roomStore.deleteRoom(intl, history, room.id, authStore.loginUser.id, timelineStore, userLoungeStore);
    };

    render() {
        const { classes, intl, userLoungeStore, timelineStore, authStore, orgStore, room, isMulti, roomCount, isExpand } = this.props;
        const { placement, disablePortal, arrow, arrowRef } = this.state;

        const roomState = UTIL.getRoomState(authStore.loginUser.id, room);

        const team = userLoungeStore.selectedTeamId !== 0 ? (userLoungeStore.selectedTeam.id !== undefined ? userLoungeStore.selectedTeam : undefined) : userLoungeStore.teamList.find(t => t.id === room.teamId);
        const member = team !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
        const isLeader = (member !== undefined) && (member.type === 'Leader');

        const detailOpen = timelineStore.getTimelineOpen(room.id);
        const linkViewOpen = timelineStore.getTimelineLinkViewOpen(room.id);

        const anchorEl = document.getElementById(`timeline-contents-${room.id}`);
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);

        const isGuestButton = (roomState.isMade || isLeader) && room.guests.length > 0;
        const isModifyButton = (room.userId === authStore.loginUser.id) && !roomState.isLive && dayjs(currentDatetime).isBefore(room.startDatetime);
        const isDeleteButton = (room.userId === authStore.loginUser.id) && !roomState.isLive;

        return (
            <>
                <ClickAwayListener onClickAway={() => timelineStore.closeTimelinePanel(room.id)}>
                    <TimelineContent className={dayjs(room.endDatetime).isBefore(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT)) ? classes.lineContentBoxOpacity : classes.lineContentBox}>

                        <Box className={classes.ArrowLineBox}>
                            {
                                roomState.isConference ?
                                    <div>
                                        <ArrowLineConference/>
                                        <div className={classes.imageTextBox} style={{background:'#00bc6a'}}>
                                            <ConferenceIcon/>
                                            <Typography className={classes.imageText}><FormattedMessage id="conference"/></Typography>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <ArrowLineLearning/>
                                        <div className={classes.imageTextBox} style={{background:'#664cff'}}>
                                            <LearningIcon/>
                                            <Typography className={classes.imageText}><FormattedMessage id="education"/></Typography>
                                        </div>
                                    </div>
                            }
                        </Box>

                        <Paper id={`timeline-contents-${room.id}`} className={roomState.isConference ? classes.lineContentBoxIn : classes.lineContentBoxIn1} onClick={() => timelineStore.openTimelineDetail(room.id)}>
                            {isMulti &&
                                <Box className={classes.countBox}>
                                    <Typography className={classes.countText}>{roomCount}</Typography>
                                </Box>
                            }

                            <Box>
                                <Typography className={classes.textStyle}>{room.name}</Typography>
                                <Typography className={classes.textStyle2}>{room.comment}</Typography>
                                <Box display='flex' alignItems='center'>
                                    <SmileyIcon className={roomState.isConference ? '' : classes.learningIcon} />
                                    <Typography className={classes.textStyle3}>{room.teamName} | {room.userName} {room.userTitle}</Typography>
                                </Box>
                                <Box display='flex' alignItems='center' pb={1} pt={1}>
                                    <ClockIcon className={roomState.isConference ? '' : classes.learningIcon} />
                                    <Typography className={classes.textStyle3} style={{color:'#4f5660',fontWeight:800}}>{DATE_UTIL.getTimeZoneLanguage(room.startDatetime).format('A')} {dayjs(room.startDatetime).format('HH:mm')} ~ {DATE_UTIL.getTimeZoneLanguage(room.endDatetime).format('A')} {dayjs(room.endDatetime).format('HH:mm')}</Typography>
                                </Box>

                                <TeamMembersSideBar members={room.members} isTeam={false} configs={orgStore.orgConfigs}/>
                            </Box>

                            <Box display='flex' flexDirection='column' justifyContent='space-between' alignItems='flex-end'>
                                <Box display='flex' flexDirection='column'>
                                    {roomState.isEnter &&
                                        <LightTooltip title={intl.formatMessage({id: "enter"})} placement="right" onClick={event => this.handleClickEnterance(event, room.members.find(m => m.userId === authStore.loginUser.id).entranceUrl)}>
                                            <IconButton className={classes.iconButton}>
                                                <Enter style={{width:26}}/>
                                            </IconButton>
                                        </LightTooltip>
                                    }
                                    {isGuestButton &&
                                        <LinkView linkViewOpen={linkViewOpen} guests={room.guests} handleClickButton={(event) => timelineStore.openTimelineLinkView(room.id)} handleClose={timelineStore.closeAllTimelineLinkView}/>
                                    }
                                </Box>
                                <Box display='flex' flexDirection='column-reverse'>
                                    {isDeleteButton &&
                                        <LightTooltip title={intl.formatMessage({id: "delete"})} placement="right" onClick={(event) => this.handleRemoveButton(event)}>
                                            <IconButton className={classes.iconButton}>
                                                <DeleteOutlineIcon style={{width:26}}/>
                                            </IconButton>
                                        </LightTooltip>
                                    }
                                    {isModifyButton &&
                                        <LightTooltip title={intl.formatMessage({id: "modify"})} placement="right" onClick={(event) => this.handleModifyButton(event, room.id, room.teamId)}>
                                            <IconButton className={classes.iconButton}>
                                                <SettingsIcon style={{width:26}}/>
                                            </IconButton>
                                        </LightTooltip>
                                    }
                                </Box>

                                {isMulti ?
                                    isExpand ?
                                        <IconButton className={classes.iconBtn} onClick={this.props.handleClickExpand}>
                                            <CaretCircle className={roomState.isConference ? '' : classes.learningIcon1} />
                                        </IconButton>
                                        :
                                        <IconButton className={classes.iconBtn} onClick={this.props.handleClickExpand}>
                                            <CaretCircle className={roomState.isConference ? '' : classes.learningIcon1} style={{transform:'rotate(180deg)'}}/>
                                        </IconButton>
                                    :
                                    isExpand ?
                                        <IconButton className={classes.iconBtn} onClick={this.props.handleClickExpand}>
                                            <CaretCircle className={roomState.isConference ? '' : classes.learningIcon1} />
                                        </IconButton>
                                        :
                                        null
                                }
                            </Box>
                        </Paper>

                        <Popper
                            open={detailOpen}
                            anchorEl={anchorEl !== null ? anchorEl : document.body}
                            placement={placement}
                            disablePortal={disablePortal}
                            className={classes.popper}
                            modifiers={{
                                flip: {
                                    enabled: true,
                                },
                                preventOverflow: {
                                    enabled: true,
                                    boundariesElement: 'scrollParent',
                                },
                                hide: {
                                    enabled: false
                                },
                                arrow: {
                                    enabled: arrow,
                                    element: arrowRef,
                                },
                            }}

                        >
                            {arrow ? <span className={classes.arrow} ref={this.handleArrowRef} /> : null}
                            <Paper className={classes.paper}>
                                {detailOpen ? (
                                    <div>
                                        <RoomInformation room={room}/>
                                    </div>
                                ) : null }
                            </Paper>
                        </Popper>

                        {(isMulti && !isExpand) && <Paper className={roomState.isConference ? classes.lineContentBoxIn2 : classes.lineContentBoxIn3} />}
                    </TimelineContent>
                </ClickAwayListener>
                <DeleteDialog open={timelineStore.openDeleteDialog}
                              title={intl.formatMessage({id: "delete"})}
                              msg={intl.formatMessage({id: "msg.check_delete"})}
                              changeDialogOpen={timelineStore.changeOpenDeleteDialog}
                              submit={this.handleRemoveRoom}
                />
            </>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(TimeLineItemContent)));
