import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Paper} from "@material-ui/core";
import {Timeline} from "@material-ui/lab";
import TimeLineItem from "./TimelineItem";
import {inject, observer} from "mobx-react";
import TimeLineNoSchedule from "./TimeLineNoSchedule";
import * as dayjs from "dayjs";
import {DATE_UTIL} from "../../../common/util/date.util";
import {withRouter} from "react-router-dom";

const style = theme => ({
    root:{
        marginTop:50,
        paddingRight:20,
        overflowX:'hidden',
        "& .MuiTimeline-root":{
            paddingLeft:40,
            paddingRight:0,
        },
        "& .MuiTimelineOppositeContent-root":{
            flex:0,
            padding:0,
            margin:0,
        },
        "& .MuiTimelineContent-root":{
            paddingTop:0,
            paddingLeft:0,
        },
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
});


@inject('authStore', 'userLoungeStore', 'timelineStore', 'progressStore')
@observer
class TimeLine extends Component {
    constructor(props){
        super(props);
        this.state = {
            scrollTop: 0
        };
    };

    componentDidMount() {
        const { authStore, userLoungeStore} = this.props;

        document.getElementById('timelineList').addEventListener("scroll", () => this.changeTimelineDate(), true);
        document.getElementById('timelineList').addEventListener("scroll", () => this.infiniteScroll(userLoungeStore.selectedTeamId, authStore.loginUser.id), true);
    };

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userLoungeStore, timelineStore, progressStore} = this.props;
        progressStore.setIsLoading(userLoungeStore.getIsLoading || timelineStore.getIsLoading)
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {timelineStore} = this.props;

        if (timelineStore.topRoomId !== undefined) {
            const el = document.getElementById(timelineStore.topRoomId);
            if (el) {
                el.scrollIntoView({block: "start", behavior : 'auto'});
            }
            timelineStore.changeTopRoomId(undefined);
        }
    };

    changeTimelineDate = () => {
        const {timelineStore} = this.props;

        const targetElement = document.getElementById('timelineList');
        const scrollTop = targetElement ? parseInt(targetElement.scrollTop.toString()) : 0;
        timelineStore.closeAllTimelinePanel();
        timelineStore.changeScrollTop(scrollTop);

        loop:
        for(let i = 0; i < timelineStore.roomListGroupByStartTime.length; i++) {
            for (let j = 0; j < timelineStore.roomListGroupByStartTime[i].roomList.length; j++) {
                if (document.getElementById(timelineStore.roomListGroupByStartTime[i].roomList[j].id) !== null) {
                    let top = document.getElementById(timelineStore.roomListGroupByStartTime[i].roomList[j].id).getBoundingClientRect().top;
                    let bottom = document.getElementById(timelineStore.roomListGroupByStartTime[i].roomList[j].id).getBoundingClientRect().bottom;

                    if (this.state.scrollTop < scrollTop) {
                        if (top - 35 < scrollTop < top - 25) {
                            timelineStore.changeTimelineDate(timelineStore.roomListGroupByStartTime[i].roomList[j].startDatetime);
                            this.setState({
                                scrollTop: scrollTop
                            });
                            break loop;
                        }
                    } else {
                        if (bottom - 280 < scrollTop < bottom - 270) {
                            timelineStore.changeTimelineDate(timelineStore.roomListGroupByStartTime[i].roomList[j].startDatetime);
                            this.setState({
                                scrollTop: scrollTop
                            });
                            break loop;
                        }
                    }
                }
            }
        }
    };

    infiniteScroll = (teamId,userId) => {
        const {timelineStore, progressStore} = this.props;

        const targetElement = document.getElementById('timelineList');
        let scrollTop = targetElement ? targetElement.scrollTop : 0;
        let clientHeight = targetElement ? targetElement.clientHeight : 0;
        let scrollHeight = targetElement ? targetElement.scrollHeight : 0;
        if (timelineStore.roomListGroupByStartTime.length > 0) {
            if (scrollTop + clientHeight >= scrollHeight) {
                progressStore.setIsLoading(true)
                timelineStore.getRoomListGroupByStartTime(teamId, userId, 'future', 0)
            }
            if (scrollTop === 0) {
                if (timelineStore.roomListGroupByStartTime[0].roomList.length > 0) {
                    progressStore.setIsLoading(true)
                    const topRoomId = timelineStore.roomListGroupByStartTime[0].roomList[0].id;
                    timelineStore.getRoomListGroupByStartTime(teamId, userId, 'past', 0, topRoomId);
                }
            }
        }
    };

    render() {
        const { classes, history, timelineStore } = this.props;

        return (
            <div id='timelineList' className={classes.root} style={{overflowY: 'auto', height: window.innerHeight - 350}}>
                <Paper elevation={0} className={classes.paperBox}>
                    <Timeline >
                        {
                            timelineStore.roomListGroupByStartTime.length > 0 ?
                                timelineStore.roomListGroupByStartTime.map((roomData, index) => {
                                    const sameDateList = timelineStore.roomListGroupByStartTime.filter(t => dayjs(t.startDatetime).format(DATE_UTIL.FORMAT.DATE_FORMAT) === dayjs(roomData.startDatetime).format(DATE_UTIL.FORMAT.DATE_FORMAT));
                                    return (
                                        <TimeLineItem
                                            key={index}
                                            history={history}
                                            isFirst={true}
                                            isFirstByDate={sameDateList.findIndex(t => t.startDatetime === roomData.startDatetime) === 0}
                                            roomData={roomData}
                                            isLast={roomData.isLast}
                                        />
                                    );
                                })
                                :
                                <div style={{paddingLeft: 32}}>
                                    <TimeLineNoSchedule/>
                                </div>
                        }
                    </Timeline>
                </Paper>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(TimeLine));
