import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Popper,
    Paper,
    Typography,
    Box,
    IconButton,
    ClickAwayListener,
    Tooltip
} from "@material-ui/core";
import {ReactComponent as InvitationLinkIcon} from "../../../common/images/InvitationLinkIcon.svg";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {FormattedMessage, injectIntl} from "react-intl";


const style = theme => ({
    root: {
        flexGrow: 1,
    },
    iconButton:{
        padding:0,
        // marginBottom:8,
        "&:hover":{
            background:'transparent'
        }
    },
    popper: {
        zIndex: 30,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    paper: {
        padding:'10px 10px 18px 0',
        maxWidth: 450,
        // overflow: 'auto',
        boxShadow:'0 4px 15px 0 rgba(0, 0, 0, 0.4)',
        // border:'1px solid #cdced0',
        borderRadius:10,
    },
    textareaBox:{
        padding:'10px 28px',
        height:150,
        overflowY:'auto',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#000000',
        fontSize:14,
        fontWeight:800,
        marginBottom:12
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#303030',
        fontSize:13,
        width:80,
    },
    linkUnderline:{
        width:'100%',
        fontFamily:'NanumSquareRoundOTF',
        color:'#303030',
        fontSize:13,
        textDecoration:'underline',
        wordBreak:'break-all',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        wordWrap:'normal',
        overflow:'hidden',
        cursor:'pointer',
    },
    toastContainer:{
        top: '20px !important'
    },
    toasts:{
        fontSize: 12,
        color: '#fff !important',
        backgroundColor: 'rgba(97, 97, 97, 0.9) !important',
        fontFamily:'NanumSquareRoundOTF',
        minHeight: 35
    }
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        padding:'4px 4px',
        border:'0.3px solid #000000',
        background:'#fffff5',
        fontFamily:'NanumSquareRoundOTF',
        fontSize: 11,
        color:'#000',
        borderRadius:'0',
        marginLeft:5,
        marginTop:5
    },
}))(Tooltip);


class LinkView extends Component {
    state = {
        arrow: true,
        arrowRef: null,
        disablePortal: false,
        flip: true,
        placement: 'right',
    };

    handleClickButton = (event) => {
        event.stopPropagation();

        if(this.props.handleClickButton) {
            this.props.handleClickButton();
        }
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClickUrl() {
        const { classes, intl } = this.props;
        ToastsStore.info(intl.formatMessage({id: "msg.success_url_copy"}), 1500, classes.toasts);
    };

    render() {
        const { classes, intl, linkViewOpen, guests } = this.props;
        const { placement, disablePortal, flip, arrow, arrowRef } = this.state;

        return (
            <div className={classes.root} onClick={(event) => event.stopPropagation()}>
                <LightTooltip title={intl.formatMessage({id: "view_invitation_link"})} placement="bottom">
                    <IconButton
                        className={classes.iconButton}
                        buttonRef={node => {this.anchorEl = node;}}
                        onClick={this.handleClickButton}
                    >
                        <InvitationLinkIcon style={{width:26}}/>
                    </IconButton>
                </LightTooltip>
                <Popper
                    open={linkViewOpen}
                    anchorEl={this.anchorEl}
                    placement={placement}
                    disablePortal={disablePortal}
                    className={classes.popper}
                    modifiers={{
                        flip: {
                            enabled: flip,
                        },
                        preventOverflow: {
                            enabled: false,
                            boundariesElement:'scrollParent',
                        },
                        hide: {
                            enabled: false
                        },
                        arrow: {
                            enabled: arrow,
                            element: arrowRef,
                        },
                    }}

                >
                    {arrow ? <span className={classes.arrow} ref={this.handleArrowRef} /> : null}
                    <Paper className={classes.paper}>
                        <ClickAwayListener onClickAway={this.props.handleClose}>
                            <Box>
                            {linkViewOpen ? (
                                <Box className={classes.textareaBox}>
                                    <Typography className={classes.textStyle1}><FormattedMessage id="invitation_url"/></Typography>
                                        {
                                            guests.map((guest, index) => {
                                                return (
                                                    <LightTooltip title={intl.formatMessage({id: "msg.click_copy_url"})} placement="bottom" key={index}>
                                                        <Box display='flex' alignItems='center' style={{marginBottom:6}}>
                                                            <Typography className={classes.textStyle2}>{guest.name}</Typography>
                                                            <CopyToClipboard text={guest.entranceUrl} onCopy={() => this.handleClickUrl()}>
                                                                <Typography className={classes.linkUnderline}>{guest.entranceUrl}</Typography>
                                                            </CopyToClipboard>
                                                        </Box>
                                                    </LightTooltip>
                                                )
                                            })
                                        }
                                    <ToastsContainer className={classes.toastContainer} position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground/>
                                </Box>
                            ) : null}
                            </Box>
                        </ClickAwayListener>
                    </Paper>
                </Popper>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(LinkView));