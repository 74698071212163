import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Typography,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Input
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Sample1 from "../../../../common/images/Sample1.jpg";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root: {
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width: '100%',
        border: '1px solid #c4c9de',
        borderRadius: 8,
        marginBottom: 40,
        padding: '30px 20px 25px'
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 18,
        color: '#a3a8af',
        fontWeight: 600,
    },
    explanationText: {
        fontSize: 12,
        fontWeight: 400,
        marginTop: 12
    },
    formControl: {
        width: '100%',
        "& .MuiInput-underline:after, .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before ": {
            borderBottom: 0
        },
        "& label + .MuiInput-formControl": {
            marginTop: 0
        }
    },
    formControlLabelDefault: {
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#a3a8af',
            fontWeight: 400,
        }
    },
    formControlLabel: {
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#0d0d0d',
            fontWeight: 400,
        }
    },
    radioIcon: {
        fontSize: 18,
        color: '#0097ff'
    },
    inputBoxIn: {
        fontSize: 14,
        color: '#0d0d0d',
        fontWeight: 500,
        borderBottom: '1px solid #979797',
        marginLeft: 25,
    },
    titleImgBox: {
        width: 241,
        height: 181,
        overflow: 'hidden',
        margin: 'auto',
        display:'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    imgBox: {
        width: 167,
        marginLeft: '25px',
        height: 144,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    image: {
        display: 'flex',
        margin: 'auto',
        cursor: 'pointer',
        justifyContent:'center'
    },
    imageWidth: {
        width: '100%',
        height:'auto'
    },
    imageHeight: {
        height: '100%',
        width:'auto'
    },
});

@inject('surveyStore')
@observer
class PreviewMultipleChoice extends Component {

    render() {
        const {classes, intl, surveyQuestion} = this.props;
        return (
            <div className={classes.root}>
                <Typography className={classes.textStyle} style={surveyQuestion.title !== "" ? {color: '#0d0d0d'} : {}}>
                    {surveyQuestion.title !== "" ?
                        surveyQuestion.title
                        :
                        <FormattedMessage id="msg.preview_survey_subject"/>
                    }
                    {surveyQuestion.required &&
                    <span style={{color: '#FF0000'}}>*</span>
                    }
                </Typography>

                <Typography className={clsx(classes.textStyle, classes.explanationText)}
                            style={surveyQuestion.contents !== "" ? {color: '#0d0d0d'} : {}}>
                    {surveyQuestion.contents !== "" ?
                        surveyQuestion.contents
                        :
                        <FormattedMessage id="msg.preview_survey_description"/>
                    }
                </Typography>

                {surveyQuestion.surveyItemImage.length > 0 ?
                    surveyQuestion.surveyItemImage[0].image === "" ?
                        <Box className={classes.titleImgBox} mb={2}>
                            <img src={Sample1} alt="" style={{width: '100%'}}/>
                        </Box>
                        :
                        <Box className={classes.titleImgBox} mb={2}>
                            <img src={surveyQuestion.surveyItemImage[0].image} alt=""
                                 className={clsx(classes.image, (surveyQuestion.surveyItemImage[0] !== undefined && surveyQuestion.surveyItemImage[0].width >= surveyQuestion.surveyItemImage[0].height && surveyQuestion.surveyItemImage[0].width > 241) && classes.imageWidth, (surveyQuestion.surveyItemImage[0] !== undefined && surveyQuestion.surveyItemImage[0].height >= surveyQuestion.surveyItemImage[0].width && surveyQuestion.surveyItemImage[0].height > 181) && classes.imageHeight)}
                            />
                        </Box>
                    :
                    <>
                    </>
                }

                <Box style={{marginTop: 28}}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup
                            row
                            aria-label="imageExample"
                            name="imageExample"
                            className={classes.group}
                            // value={this.state.value}
                            // onChange={this.handleChange}
                        >
                            {/* 이미지o : 50%, 이미지x : 100%*/}
                            {surveyQuestion.surveyItemOption.map((option, index) => {
                                return (
                                    option.etc ?
                                        <Box display='flex' flexDirection='column'
                                             style={option.surveyItemOptionImage.length > 0 && option.surveyItemOptionImage !== undefined ? {width: '50%'} : {width: '100%'}}
                                             key={option.number}>
                                            <FormControlLabel
                                                value={index}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        icon={<CheckBoxOutlineBlankIcon className={classes.radioIcon}
                                                                                        style={{color: '#979797'}}/>}
                                                        checkedIcon={<CheckBoxIcon className={classes.radioIcon}/>}
                                                    />
                                                }
                                                label={option.contents === "" ? intl.formatMessage({id: "etc"}) : option.contents}
                                                className={classes.formControlLabel}
                                            />
                                            <Input
                                                placeholder=""
                                                className={clsx(classes.textStyle, classes.inputBoxIn)}
                                                inputProps={{
                                                    'aria-label': 'Description',
                                                }}
                                            />
                                            {option.surveyItemOptionImage.length > 0 && option.surveyItemOptionImage !== undefined ?
                                                option.surveyItemOptionImage[0].image === "" ?
                                                    <Box className={classes.imgBox} mt={1}>
                                                        <img src={Sample1} alt="" style={{width: '100%'}}/>
                                                    </Box>
                                                    :
                                                    <Box className={classes.imgBox} mt={1}>
                                                        <img
                                                            src={option.surveyItemOptionImage[0].image}
                                                            alt=""
                                                            className={clsx(classes.image, (option.surveyItemOptionImage[0] !== undefined && option.surveyItemOptionImage[0].width >= option.surveyItemOptionImage[0].height && option.surveyItemOptionImage[0].width > 167) && classes.imageWidth, (option.surveyItemOptionImage[0] !== undefined && option.surveyItemOptionImage[0].height >= option.surveyItemOptionImage[0].width && option.surveyItemOptionImage[0].height > 144) && classes.imageHeight)}
                                                        />
                                                    </Box>
                                                :
                                                <></>
                                            }
                                        </Box>
                                        :
                                        <Box
                                            style={option.surveyItemOptionImage.length > 0 && option.surveyItemOptionImage !== undefined ? {width: '50%'} : {width: '100%'}}
                                            key={option.number}>
                                            <FormControlLabel
                                                value={index}
                                                control={
                                                    <Checkbox
                                                        color="primary"
                                                        icon={<CheckBoxOutlineBlankIcon className={classes.radioIcon}
                                                                                        style={{color: '#979797'}}/>}
                                                        checkedIcon={<CheckBoxIcon className={classes.radioIcon}/>}
                                                    />
                                                }
                                                label={option.contents === "" ? intl.formatMessage({id: "msg.preview_survey_example"}) : option.contents}
                                                className={classes.formControlLabel}
                                            />
                                            {option.surveyItemOptionImage.length > 0 && option.surveyItemOptionImage !== undefined ?
                                                option.surveyItemOptionImage[0].image === "" ?
                                                    <Box className={classes.imgBox} mt={1}>
                                                        <img src={Sample1} alt="" style={{width: '100%'}}/>
                                                    </Box>
                                                    :
                                                    <Box className={classes.imgBox} mt={1}>
                                                        <img
                                                            src={option.surveyItemOptionImage[0].image}
                                                            className={clsx(classes.image, (option.surveyItemOptionImage[0] !== undefined && option.surveyItemOptionImage[0].width >= option.surveyItemOptionImage[0].height && option.surveyItemOptionImage[0].width > 167) && classes.imageWidth, (option.surveyItemOptionImage[0] !== undefined && option.surveyItemOptionImage[0].height >= option.surveyItemOptionImage[0].width && option.surveyItemOptionImage[0].height > 144) && classes.imageHeight)}
                                                            alt="" />
                                                    </Box>
                                                :
                                                <></>
                                            }
                                        </Box>
                                )
                            })}
                        </FormGroup>
                    </FormControl>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(PreviewMultipleChoice));