import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Typography,
    Box
} from "@material-ui/core";
import {ReactComponent as InvitationLinkIcon} from "../../../common/images/InvitationLinkIcon.svg";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {ToastsContainer, ToastsStore, ToastsContainerPosition} from 'react-toasts';
import {FormattedMessage, injectIntl} from "react-intl";


const style = theme => ({
    root: {
    },
    iconButton:{
        padding:0,
        "&:hover":{
            background:'transparent'
        }
    },
    dialogBox:{
        "& .MuiDialogTitle-root":{
            padding:'30px 0 10px'
        },
        "& .MuiPaper-rounded":{
            borderRadius:10
        },
        "& .MuiDialog-paper":{
            boxShadow:"none",
            margin:20
        },
        "& .MuiDialogContent-root":{
            padding:'8px 15px 20px'
        }
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        color:'#000',
        fontWeight:600,
        textAlign:'center'
    },
    dialogContentBox:{

    },
    dialogContentBoxIn:{
        display:'flex',
        marginBottom:10
    },
    textStyle:{
        width:'20%',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.1rem',
        color:'#000',
    },
    textStyle1:{
        width:'80%',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.1rem',
        color:'#000',
        textDecoration:'underline',
        wordBreak:'break-all',
        textOverflow:'ellipsis',
        whiteSpace:'nowrap',
        wordWrap:'normal',
        overflow:'hidden'
    },
    toastContainer:{
        top: '20px !important'
    },
    toasts:{
        fontSize: '1.2rem',
        color: '#fff !important',
        backgroundColor: 'rgba(97, 97, 97, 0.9) !important',
        fontFamily:'NanumSquareRoundOTF',
        minHeight: 35
    }
});


class MobileLinkView extends Component {
    state = {
        linkViewOpen: false,
    };

    handleClickOpen = (event) => {
        event.stopPropagation();
        this.setState({ linkViewOpen: true });
    };

    handleClose = (event) => {
        event.stopPropagation();
        this.setState({ linkViewOpen: false });
    };

    handleClickUrl(){
        const { classes, intl } = this.props;
        ToastsStore.info(intl.formatMessage({id: "msg.success_url_copy"}), 1500, classes.toasts);
    };

    render() {
        const { classes, guests } = this.props;

        return (
            <div className={classes.root} >
                <div
                    className={classes.iconButton}
                    onClick={this.handleClickOpen}
                >
                    <InvitationLinkIcon style={{width:26, verticalAlign:'middle'}}/>
                </div>
                <Dialog
                    open={this.state.linkViewOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.dialogBox}
                >
                    <DialogTitle id="alert-dialog-title" >
                        <Typography className={classes.titleText}><FormattedMessage id="invitation_url"/></Typography>
                    </DialogTitle>
                    <DialogContent className={classes.dialogContentBox}>
                        {
                            guests.map((guest, index) => {
                                return(
                                    <Box key={index} className={classes.dialogContentBoxIn}>
                                        <Typography className={classes.textStyle}>{guest.name}</Typography>
                                        <CopyToClipboard text={guest.entranceUrl}
                                                        onCopy={() => this.handleClickUrl()}
                                        >
                                            <Typography className={classes.textStyle1} >{guest.entranceUrl}</Typography>
                                        </CopyToClipboard>
                                    </Box>
                                )
                            })
                        }
                        <ToastsContainer className={classes.toastContainer} position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground/>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(MobileLinkView));