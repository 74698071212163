import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Typography,
    Button, MenuItem, Popover, MenuList
} from "@material-ui/core";
import {ReactComponent as PollCreatePlus} from "../../../common/images/PollCreatePlus.svg";
import Paragraph from "./container/Paragraph";
import PreviewParagraph from "./container/PreviewParagraph";
import {FormattedMessage, injectIntl} from "react-intl";
import {inject, observer} from "mobx-react";
import SurveyQuestion from "./container/SurveyQuestion";
import PreviewShortAnswer from "./container/PreviewShortAnswer";
import PreviewSingleChoice from "./container/PreviewSingleChoice";
import PreviewMultipleChoice from "./container/PreviewMultipleChoice";
import PreviewRating from "./container/PreviewRating";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import ParagraphAdd from "./container/ParagraphAdd";
import PreviewParagraphAdd from "./container/PreviewParagraphAdd";
import {ReactComponent as SurveyDragIcon} from "../../../common/images/SurveyDragIcon.svg";
import {SurveyItemType} from "../../../stores/SurveyStore";
import DeleteDialog from "../../../common/DeleteDialog";
import {ToastsContainer, ToastsContainerPosition, ToastsStore} from "react-toasts";

const style = theme => ({
    root: {
        width: '100%',
        display: 'flex',
        paddingRight: 55,
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        fontWeight: 600,
    },
    titleStyle: {
        fontSize: 24,
        fontWeight: 800,
    },
    contentsBox: {
        width: '50%',
        paddingRight: 75
    },
    typoStyle1: {
        fontSize: 12,
        color: '#a3a8af'
    },
    btnStyle: {
        color: '#0097ff',
        textDecoration: 'underline',
        fontWeight: 500,
        "&:hover": {
            background: 'transparent',
            textDecoration: 'underline',
        }
    },
    popoverBox: {
        "& .MuiPopover-paper": {
            borderRadius: 7,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)'
        }
    },
    menuText: {
        fontSize: 12,
        color: '#000',
        fontWeight: 500,
        "&:hover": {
            background: '#d0d0d0'
        }
    },
    buttonStyle: {
        background: 'rgba(20, 0, 254, 0.5)',
        borderRadius: 5,
        padding: '9px 16px',
        color: '#fff',
        fontWeight: 300,
        marginRight: 16,
        "&:hover": {
            background: 'rgba(20, 0, 254, 0.5)',
        }
    },
    buttonStyle2: {
        background: 'transparent',
        border: '1px solid #8a80fe',
        borderRadius: 5,
        padding: '9px 16px',
        color: '#1400fe',
        fontWeight: 300,
        marginRight: 16,
        "&:hover": {
            background: 'transparent',
        }
    },
    plusIcon: {
        "& path": {
            fill: '#1400FE'
        }
    },

    surveyQuestion: {
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width: '100%',
        marginBottom: 40
    },
    surveyQuestionContentsBox: {
        width: '100%',
        border: '1px solid #c4c9de',
        borderRadius: 8,
        padding: '11px 20px 23px',
        "&:hover": {
            border: '1px solid #0097ff !important',
            borderTop: '4px solid #0097ff !important',
        },
    },
});

@inject('surveyStore', 'progressStore')
@observer
class SurveyCreateContents extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            selectedSurveyQuestionIndex: -1,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {surveyStore, progressStore} = this.props;
        progressStore.setIsLoading(surveyStore.getIsLoading)
        if (prevState.selectedSurveyQuestionIndex !== this.state.selectedSurveyQuestionIndex) {
            this.props.surveyStore.surveyItemDataList.forEach((v, i) => {
                const el = document.getElementById(`surveyQuestionBox-${i}`);
                if (i !== this.state.selectedSurveyQuestionIndex) {
                    el.setAttribute("border", '1px solid #c4c9de');
                    el.setAttribute("border-top", '1px solid #c4c9de');
                    el.style.border = '1px solid #c4c9de';
                    el.style.borderTop = '1px solid #c4c9de';
                }
            });
        }
    }

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handleClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    initSelectedSurveyQuestionIndex = () => {
        this.setState({selectedSurveyQuestionIndex: -1});
    };

    setSelectedSurveyQuestionIndex = (e, selectedSurveyQuestionIndex) => {
        e.stopPropagation();
        this.setState({selectedSurveyQuestionIndex: selectedSurveyQuestionIndex});
    };

    handleAddSurveyItem = () => {
        this.props.surveyStore.addSurveyItems();
    };

    handleAddSurveyParagraph = () => {
        this.props.surveyStore.addSurveyItems(SurveyItemType.Context);
    };

    handleDeleteItem = () => {
        this.props.surveyStore.deleteSurveyItems(this.state.selectedSurveyQuestionIndex);
    };

    render() {
        const {classes, surveyStore, intl, history} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);
        return (
            <div className={classes.root} style={{minHeight: 'calc(100vh - 192px)'}}
                 onClick={this.initSelectedSurveyQuestionIndex}>
                <Box className={classes.contentsBox} style={{borderRight: '1px solid #dbdbdb'}}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' style={{margin: '60px 0 50px'}} >
                        <Typography className={clsx(classes.textStyle, classes.titleStyle)}><FormattedMessage
                            id="new_survey"/></Typography>
                        {surveyStore.surveyHistory.length > 0 &&
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Typography className={clsx(classes.textStyle, classes.typoStyle1)}><FormattedMessage
                                id="usage_history"/></Typography>
                            <Button
                                aria-owns={open ? 'simple-popper' : undefined}
                                onClick={this.handleClick}
                                className={clsx(classes.textStyle, classes.btnStyle)}
                            >
                                {surveyStore.surveyHistory.length}
                            </Button>
                            <Popover
                                id="simple-popper"
                                open={open}
                                anchorEl={anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                    vertical: 'center',
                                    horizontal: 'right',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                className={classes.popoverBox}
                            >

                                <MenuList>
                                    {surveyStore.surveyHistory.map(h => <MenuItem key={h.historyId}
                                        className={clsx(classes.textStyle, classes.menuText)} onClick={() => history.push(`/rooms/${h.roomId}/statistics`)}>{h.roomName}</MenuItem>)}
                                </MenuList>
                            </Popover>
                        </Box>
                        }
                    </Box>
                    <Box>
                        <Paragraph surveyQuestionInit={this.initSelectedSurveyQuestionIndex}/>

                        {surveyStore.surveyItemDataList.length > 0 &&
                        <DragDropContext
                            onDragEnd={result => {
                                if (result.type !== 'Survey') {
                                    return;
                                } else if (!result.destination) {
                                    return;
                                } else {
                                    const index = parseInt(result.draggableId.split('-')[1]);
                                    this.initSelectedSurveyQuestionIndex();
                                    surveyStore.changeItemIndex(index, result.destination.index);
                                }
                            }}
                        >
                            <Droppable
                                droppableId="surveyQuestionDroppable"
                                type="Survey"
                            >
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        style={{backgroundColor: snapshot.isDraggingOver ? '#fff' : '#fff'}}
                                    >

                                        {surveyStore.surveyItemDataList.map((surveyQuestion, i) => (

                                            <Draggable
                                                key={`surveyQuestionDraggableKey-${i}`}
                                                draggableId={`surveyQuestionDraggableId-${i}`}
                                                index={i}
                                            >
                                                {(p, s) => (
                                                    <div
                                                        ref={p.innerRef}
                                                        {...p.draggableProps}
                                                        style={
                                                            p.draggableProps.style
                                                        }
                                                    >
                                                        <div className={classes.surveyQuestion}>
                                                            <Box id={`surveyQuestionBox-${i}`}
                                                                 className={classes.surveyQuestionContentsBox}
                                                                 onClick={e => {
                                                                     this.setSelectedSurveyQuestionIndex(e, i);

                                                                     const el = document.getElementById(`surveyQuestionBox-${i}`);
                                                                     el.setAttribute("border", '1px solid #0097ff');
                                                                     el.setAttribute("border-top", '4px solid #0097ff');
                                                                     el.style.border = '1px solid #0097ff';
                                                                     el.style.borderTop = '4px solid #0097ff';
                                                                 }}>
                                                                <Box
                                                                    display={i === this.state.selectedSurveyQuestionIndex ? 'flex' : 'none'}
                                                                    justifyContent='center'>
                                                                        <span {...p.dragHandleProps}>
                                                                            <SurveyDragIcon
                                                                                style={{cursor: 'pointer'}}/>
                                                                        </span>
                                                                </Box>
                                                                {/*{surveyQuestion}*/}
                                                                {surveyQuestion.type !== SurveyItemType.Context ?
                                                                    <SurveyQuestion surveyData={surveyQuestion}
                                                                        surveyQuestionId={i}
                                                                    />
                                                                    :
                                                                    <ParagraphAdd surveyData={surveyQuestion}
                                                                        surveyQuestionId={i}/>
                                                                }
                                                            </Box>
                                                        </div>
                                                    </div>
                                                )}
                                            </Draggable>

                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                        }

                        <Box display="flex" alignItems='center' mb={1} onClick={this.initSelectedSurveyQuestionIndex}>
                            <Button className={clsx(classes.textStyle, classes.buttonStyle)}
                                    onClick={this.handleAddSurveyItem}><PollCreatePlus
                                style={{marginRight: 8}}/><FormattedMessage id="add_question"/></Button>
                            <Button className={clsx(classes.textStyle, classes.buttonStyle2)}
                                    onClick={this.handleAddSurveyParagraph}><PollCreatePlus className={classes.plusIcon}
                                                                                            style={{marginRight: 8}}/>
                                                                                            <FormattedMessage
                                id="add_paragraph"/></Button>
                        </Box>
                    </Box>

                </Box>

                {/*미리보기*/}
                <Box className={classes.contentsBox} style={{paddingLeft: 75}}
                     onClick={this.initSelectedSurveyQuestionIndex}>
                    <Box display='flex' justifyContent='space-between' alignItems='center'
                         style={{margin: '60px 0 50px'}}>
                        <Typography className={clsx(classes.textStyle, classes.titleStyle)}><FormattedMessage
                            id="preview_survey"/></Typography>
                        <Typography className={clsx(classes.textStyle, classes.typoStyle1)}
                                    style={{color: '#ff0000'}}>* <FormattedMessage id="required_items"/></Typography>
                    </Box>
                    <Box>
                        <PreviewParagraph/>
                        {surveyStore.surveyItemDataList.length > 0 &&
                        surveyStore.surveyItemDataList.map((surveyQuestion, i) => {
                            if (surveyQuestion.type === SurveyItemType.SingleChoice) {
                                return <PreviewSingleChoice key={i} index={i} surveyQuestion={surveyQuestion}/>
                            } else if (surveyQuestion.type === SurveyItemType.MultipleChoice) {
                                return <PreviewMultipleChoice key={i} index={i} surveyQuestion={surveyQuestion}/>
                            } else if (surveyQuestion.type === SurveyItemType.ShortAnswer) {
                                return <PreviewShortAnswer key={i} index={i} surveyQuestion={surveyQuestion}/>
                            } else if (surveyQuestion.type === SurveyItemType.Rating) {
                                return <PreviewRating key={i} index={i} surveyQuestion={surveyQuestion}/>
                            } else {
                                return <PreviewParagraphAdd key={i} index={i} surveyQuestion={surveyQuestion}/>
                            }
                        })
                        }
                    </Box>
                </Box>
                <DeleteDialog open={surveyStore.openDeleteDialog}
                              title={intl.formatMessage({id: "delete"})}
                              msg={intl.formatMessage({id: "msg.check_delete"})}
                              changeDialogOpen={surveyStore.changeOpeDeleteDialog}
                              submit={this.handleDeleteItem}
                />
                <ToastsContainer className={classes.toastContainer} position={ToastsContainerPosition.TOP_CENTER} store={ToastsStore} lightBackground />
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(SurveyCreateContents));