import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {inject, observer} from "mobx-react";
import {AvatarHeight, AvatarWidth} from "../../../stores/AvatarStore";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {Avatar} from "@material-ui/core";
import {BackgroundOption} from "../../../stores/AvatarStore";
import {flow} from "mobx";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root: {
        "& .MuiDropzoneArea-root":{
            width:120,
            minHeight:120,
            border:'2px dashed #dbdbdb',
            marginBottom:10,
            borderRadius:'50%',
        },
        "& .MuiDropzoneArea-text":{
            position :  "absolute",
            width : "100%",
            top:'48%',
            textAlign :"center",
            fontFamily:'NanumSquareRoundOTF',
            fontSize:10,
            color:'#303030'
        },
        "& .MuiDropzoneArea-icon": {
            position :  "absolute",
            top: "13%",
            marginLeft:-25,
        },
        "& :focus":{
            outline:'none',
        },
    },
});
const LogPrefix = '[MakeProfile] ';
@inject('teamStore', "avatarStore", "authStore")
@observer
class TeamDropzoneImage extends Component {
    constructor(props){
        super(props);
        this.state = {
            files: []
        };
    }
    handleChange(files){
        this.setState({
            files: files
        });
    }



    componentDidMount() {
        this.setAvatarAll();
    }

    componentWillUnmount() {
        this.init();
    }

    setAvatarAll = flow(function* testAll(){
        yield this.init();
        yield this.props.avatarStore.setOptionOpen(true); // 배경설정 클릭시
        yield this.props.avatarStore.setBackgroundOption(BackgroundOption.Image); // 파일선택 select

    })
    init = () =>{
        this.props.avatarStore.initialize(
            this.props.authStore.loginUser.id,
            'standbyVideo',
            'backgroundImage',
            'captureCanvas',
            'backgroundCanvas',
            'maskedCanvas',
            'avatarCanvas');
    }

    handleChangeAvatarBackgroundFile = (event) =>{
        this.props.handleAvatarState(true);
        const inputElement = document.getElementById('background-button-file');
        if(!inputElement.files.length >0){
            return;
        }
        if (inputElement) {
            const reader = new FileReader();
            reader.onload = (e) => {
                console.log(LogPrefix, 'FileReader onload', e);
                const imageElement = document.getElementById('backgroundImage');
                imageElement.src = e.target.result;
            }
            reader.onloadend = (e) => {
                console.log(LogPrefix, 'FileReader onloadend', e);
                this.props.avatarStore.setBackgroundImage();
            }

            reader.readAsDataURL(inputElement.files[0]);
        } else {
            console.log(LogPrefix, "Can't found input element");
        }
    };
    render() {
        const { classes, avatarStore, flag } = this.props;
        const { detailTeam, actionState} = this.props.teamStore;

        return (
            <div className={classes.root}>
                <Box display="flex">
                    <Avatar ref={this.avatarRef} style={{width: 80, height: 80}}>
                        {avatarStore.avatarImage ?
                            <img id="backgroundImage" src={avatarStore.avatarImage}
                                 style={{width: 80, height: 80}} alt={""}/>
                            :
                            flag === "modify" ?
                                <img id="userImg" src={detailTeam.image}
                                     style={{width: 80, height: 80}} alt={""}/>
                                :
                                <Avatar className={classes.avatarStyle1} title={"Home"}>
                                    {"img"}
                                </Avatar>
                        }
                    </Avatar>
                    <Box display="flex" flexDirection="row" justifyContent="center"
                         alignItems="center" style={{margin: '10px auto'}}>
                        <input id="background-button-file"
                               type="file"
                               accept="image/*"
                               style={{display: 'none'}}
                               onChange={this.handleChangeAvatarBackgroundFile}
                        />
                        <label htmlFor="background-button-file">
                            <Button variant="outlined" color="primary" component="span">
                                {actionState.modified ?
                                    <FormattedMessage id="change_image"/>
                                    :
                                    <FormattedMessage id="add_image"/>
                                }

                            </Button>
                        </label>
                    </Box>
                </Box>

                <img id="backgroundImage"
                     style={{display : "none", width: AvatarWidth, height: AvatarHeight}}
                     alt={""}/>
                <canvas id="captureCanvas" width={AvatarWidth} height={AvatarHeight}
                        style={{
                            display: 'none',
                            width: AvatarWidth,
                            height: AvatarHeight
                        }}/>
                <canvas id="backgroundCanvas" width={AvatarWidth} height={AvatarHeight}
                        style={{
                            display: 'none',
                            width: AvatarWidth,
                            height: AvatarHeight
                        }}/>
                <canvas id="maskedCanvas" width={AvatarWidth} height={AvatarHeight}
                        style={{
                            display: 'none',
                            width: AvatarWidth,
                            height: AvatarHeight
                        }}/>
                <canvas id="avatarCanvas" width={AvatarWidth} height={AvatarHeight}
                        style={{
                            display: 'none',
                            width: AvatarWidth,
                            height: AvatarHeight
                        }}/>
            </div>

        );
    }
}

export default withStyles(style)(TeamDropzoneImage);