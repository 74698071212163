import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Paper,
    Typography,
    Box,
    IconButton,
    LinearProgress,
    Grid
} from "@material-ui/core";
import { ReactComponent as FileArrowDown } from "../../../common/images/FileArrowDown.svg";
import {ReactComponent as UploadCheck} from "../../../common/images/UploadCheck.svg";
import {ReactComponent as Close} from "../../../common/images/Close.svg";
import {inject, observer} from "mobx-react";
import {UploadState} from "../../../stores/UserLoungeStore"
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
        width:'80%',
        position:'absolute',
        top:60,
        right:0,
        zIndex:1000,

    },
    uploadBox: {
        position: 'relative',
        borderRadius:10,
        padding:'19px 8px',
        background:'linear-gradient(to bottom, #5e5e5e, #353535)',
        boxShadow:'0 4px 40px 0 rgba(40, 43, 48, 0.15)',
    },
    tri:{
        width:0,
        height:0,
        borderTop:'15px solid none',
        borderBottom:'10px solid #5e5e5e',
        borderRight:'15px solid transparent',
        borderLeft:'15px solid  transparent',
        marginRight:80
    },
    titleStyle:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#fff',
        fontSize:14,
        fontWeight:800,
        textAlign:'center'
    },
    uploadBoxIn:{
        background:'#fff',
        borderRadius:7,
        boxShadow:'inset 0 3px 6px 0 rgba(0, 0, 0, 0.11), 0 4px 8px 0 rgba(40, 43, 48, 0.2)',
        padding:12,
        marginTop:7
    },
    fileUploadBox:{
      marginBottom:9
    },
    fileNameText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#6b6b6b',
        fontSize:12,
        marginLeft:8,
        paddingRight:20
    },
    fileIcon:{
        "& path":{
            fill:'#6b6b6b'
        }
    },


    // 파일 업로드 완료
    fileNameText1:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#cfcfcf',
        fontSize:12,
        marginLeft:8
    },
    fileIcon1:{
        "& path":{
            fill:'#cfcfcf'
        }
    },


    downloadText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#5fc068',
        fontSize:12,
        textAlign:'center'
    },
    failedText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#f63963',
        fontSize:12,
        textAlign:'center'
    },
    downloadBtn:{
        display:'flex',
        alignItems:'center',
        justifyContent:'flex-end'
    },
    deleteIcon:{
        padding:5
    },
});

@inject('authStore', 'userLoungeStore')
@observer
class FileUpload extends Component {
    render() {
        const { classes, uploadFileList, authStore, userLoungeStore } = this.props;

        const BorderLinearProgress = withStyles((theme) => ({
            root: {
                height:4,
                borderRadius:10,
            },
            colorPrimary: {
                backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
            },
            bar: {
                borderRadius:10,
                backgroundColor:'#5fc068',
            },
        }))(LinearProgress);

        return (
            <div className={classes.root}>
                <Box display='flex' justifyContent="flex-end">
                    <span className={classes.tri}/>
                </Box>
                <Paper className={classes.uploadBox}>
                    <Typography className={classes.titleStyle}>파일 업로드</Typography>
                    <Box className={classes.uploadBoxIn}>
                        {
                            uploadFileList.map((fileInfo, index) => {
                                return (
                                    <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.fileUploadBox} key={index}>
                                        <Grid item xs={8}>
                                            <Box display='flex' alignItems='center'>
                                                <Grid item xs={1}>
                                                    <FileArrowDown className={fileInfo.state !== UploadState.Progress ? classes.fileIcon1 : classes.fileIcon}/>
                                                </Grid>
                                                <Grid item xs={11}>
                                                    <Typography noWrap className={fileInfo.state !== UploadState.Progress ? classes.fileNameText1 : classes.fileNameText}>{fileInfo.file.name}</Typography>
                                                </Grid>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Box className={classes.downloadBtn}>
                                                {
                                                    (fileInfo.state !== UploadState.Progress) ?
                                                        <React.Fragment>
                                                            <Grid item xs={10}>
                                                                <Typography className={fileInfo.state === UploadState.Failed || fileInfo.state === UploadState.Canceled ? classes.failedText : classes.downloadText}>{fileInfo.state === UploadState.Downloaded ? <FormattedMessage id="complete"/> : fileInfo.state === UploadState.Pending ? <FormattedMessage id="wait"/> : fileInfo.state === UploadState.Canceled ? <FormattedMessage id="cancel"/> : <FormattedMessage id="fail"/>}</Typography>
                                                            </Grid>
                                                            {
                                                                fileInfo.state === UploadState.Pending ?
                                                                    <Grid item xs={2}>
                                                                        <IconButton className={classes.deleteIcon} onClick={() => userLoungeStore.cancelAddTeamFile(authStore.loginUser.id, fileInfo)}>
                                                                            <Close style={{width:10,height:10}}/>
                                                                        </IconButton>
                                                                    </Grid>
                                                                    :
                                                                    <Grid item xs={2}>
                                                                        <UploadCheck style={{marginLeft:3}}/>
                                                                    </Grid>
                                                            }
                                                        </React.Fragment>
                                                        :
                                                        <React.Fragment>
                                                            <Grid item xs={10}>
                                                                <BorderLinearProgress variant="indeterminate" />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <IconButton className={classes.deleteIcon} onClick={() => userLoungeStore.cancelAddTeamFile(authStore.loginUser.id, fileInfo)}>
                                                                    <Close style={{width:10,height:10}}/>
                                                                </IconButton>
                                                            </Grid>
                                                        </React.Fragment>
                                                }
                                            </Box>
                                        </Grid>
                                    </Box>
                                );
                            })
                        }
                    </Box>
                </Paper>
            </div>
        );
    };
}

export default withStyles(style)(FileUpload);