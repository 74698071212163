import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    Typography,
    Box,
    IconButton,
    Button,
    FormControlLabel,
    Checkbox, Grid
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {ReactComponent as MobileClose} from "../../common/images/MobileClose.svg"
import {ReactComponent as WarningIcon} from "../../common/images/WarningIcon.svg";
import {inject, observer} from "mobx-react";
import {State} from "../../stores/AuthStore";
import { withRouter } from "react-router-dom";
import * as Params from "../../common/Params";
import ConfirmDialog from "../../common/ConfirmDialog";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        padding:'10px 20px',
        "& .MuiButton-root.Mui-disabled":{
            background:'#bababa',
            color:'#fff'
        }
    },
    iconBtnBox:{
        display:'flex',
        justifyContent:'flex-end',
        marginBottom:70
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.7rem',
        fontWeight:500,
        color:'#0d0d0d',
        marginBottom:42
    },
    textStyle:{
        width:'100%',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:500,
        padding:'11px 9px',
        borderRadius:5,
        border:'1px solid #c0c2c3',
        color:'#0d0d0d',
        "&::placeholder":{
            color:'#a3a8af',
            fontSize:'1.0rem',
        },
        "&:focus":{
            outline: 'none !important'
        }
    },
    checkboxText:{
        "& .MuiCheckbox-root":{
            paddingRight:3
        },
        "& .MuiCheckbox-colorSecondary.Mui-checked":{
            color:'#0e4da4',
        },
        "& .MuiTypography-root":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:'1.0rem',
            color:'#0d0d0d',
            fontWeight:300
        },
    },
    buttonStyle:{
        width:'100%',
        padding:10,
        borderRadius:5,
        background: () =>
            theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:600,
        color:'#fff',
        marginTop:54,
        "&:hover":{
            background:() =>
                theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor,
        }
    },
    errorBox:{
        marginTop:9,
    },
    errorText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'0.9rem',
        color:'#ff0000',
        fontWeight:600,
        marginLeft:3
    }
});

@inject('authStore', 'orgStore')
@observer
class MobileSignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rememberNickState: '',
        }
    }
    handleLogin = () => {
        const {intl} = this.props;
        const isMobile = Params.isMobileMode(600);
        this.props.authStore.doLogin(intl, isMobile, ()=> {
            if (this.props.authStore.loginState === State.Progress) {
                this.moveTo('/mobilepasswordchange');
            } else {
                this.moveTo('/');
            }
        });

    }

    moveTo = (url) => {
        this.props.history.push(url);
    }

    handleBack = () => {
        this.props.history.go(-1);
    }
    alertReset = () => {
        this.props.authStore.loginState = State.Pending;
    }

    render() {
        const { classes, intl, authStore, orgStore} = this.props;
        const { Logo } = {...orgStore.orgImages};
        const { confirmDialog, changeConfirmDialogOpen } = this.props.authStore;
        return (
            <div className={classes.root}>
                <Box className={classes.iconBtnBox}>
                    <IconButton onClick={this.handleBack}>
                        <MobileClose />
                    </IconButton>
                </Box>

                <Box display='flex' flexDirection='column' alignItems='center'>
                    <Box style={{width:155}}>
                        <img src={Logo} alt="logo" style={{width:'100%'}} />
                    </Box>

                    <Typography className={classes.titleText}>
                        <FormattedMessage id={"sign_in"} />
                    </Typography>
                </Box>
                <Box style={{marginBottom:11}}>
                    <input
                        name="id"
                        type="email"
                        id="id"
                        autoFocus={true}
                        tabIndex={1}
                        placeholder={authStore.isLoginByNickname ? intl.formatMessage({id : "msg.insert_nickname"}) : intl.formatMessage({id : "msg.insert_email"})}
                        value={authStore.login.email}
                        style={authStore.loginState !== State.Failed ? {border:'1px solid #c0c2c3'} : {border: '1px solid #ff000'}}
                        onChange={(event) => {
                            authStore.changeLoginEmail(event.target.value)
                            this.alertReset();
                            }
                        }
                        className={classes.textStyle}
                    />
                </Box>
                <Box>
                    <input
                        name="password"
                        type="password"
                        id="password"
                        tabIndex={2}
                        placeholder={intl.formatMessage({id: "msg.validation_password"})}
                        style={authStore.loginState !== State.Failed ? {border:'1px solid #c0c2c3'} : {border: '1px solid #ff000'}}
                        className={classes.textStyle}
                        onChange={(event) => {
                            authStore.changeLoginPassword(event.target.value)
                            this.alertReset();
                        }}
                    />
                </Box>

                {/* 사용자 정보가 없을경우 */}
                {authStore.loginState === State.Failed &&
                <Box display="flex" alignItems="center" className={classes.errorBox}>
                    <WarningIcon style={{width:11, height:11}}/>
                    <Typography className={classes.errorText}>{authStore.errorMsg}</Typography>
                </Box>
                }
                <Grid container>
                    <Grid item xs={5}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon style={{fontSize:'1.5rem', color:'#c4c4c4'}}/>}
                                    checkedIcon={<CheckBoxIcon style={{fontSize:'1.5rem'}}/>}
                                    name="checkedId"
                                    tabIndex={3}
                                    checked={authStore.isRememberNickState}
                                />
                            }
                            label={intl.formatMessage({id: "save_id"})}
                            className={classes.checkboxText}
                            onChange={() => authStore.changeRememberNickState()}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon style={{fontSize:'1.5rem', color:'#c4c4c4'}}/>}
                                    checkedIcon={<CheckBoxIcon style={{fontSize:'1.5rem'}}/>}
                                    name="checkedId"
                                    tabIndex={3}
                                    checked={authStore.isLoginByNickname}
                                />
                            }
                            label={intl.formatMessage({id: "sign_in_by_nickname"})}
                            className={classes.checkboxText}
                            onChange={() => authStore.changeIsLoginByNickname()}
                        />
                    </Grid>
                </Grid>

                <Button className={classes.buttonStyle} onClick={this.handleLogin} disabled={authStore.login.email === '' && authStore.login.password === ''} tabIndex={4}>
                    <FormattedMessage id={"start"} />
                </Button>
                <ConfirmDialog open={confirmDialog.open}
                               title={confirmDialog.title}
                               msg={confirmDialog.msg}
                               changeDialogOpen={(value)=>changeConfirmDialogOpen(value)}/>
            </div>
        );
    }
};

export default withRouter(withStyles(style)(injectIntl(MobileSignIn)));

