import {makeAutoObservable, toJS} from "mobx";
import axios from "axios";
import dayjs from "dayjs";
import {DATE_UTIL} from "../common/util/date.util";

const logPrefix = ' [ TeamStore ] ' ;

const searchingInterval = 1000;

export const State = {
    Init: 'Init',
    Pending: 'Pending',
    Failed: 'Failed',
    Done: 'Done'
};
export const TeamType = {
    Root: 'Root',
    Group: 'Group',
    Team: 'Team',
};
export const UserType = {
    Leader: 'Leader',
    Member: 'Member',
};

export const LocalStorageTokenKey = '_BASKITOP_AUTHENTICATION_TOKEN_';
const ErrorCode = {
    CanNotFoundTeam: "msg.cannot_found_team",
    BadRequest: "msg.missing_required_value",
    CanNotInsertTeam: "msg.cannot_create_team",
    CanNotInsertTeamUser: "msg.cannot_insert_member",
    CanNotInsertTeamUserAuthority: "msg.cannot_grant_authority",
    CanNotUpdateTeam: "msg.cannot_modify_team.",
    CanNotDeleteTeam: "msg.cannot_delete_team",
    CanNotDeleteTeamUser: "msg.cannot_delete_member",
    CanNotDeleteTeamUserAuthority: "msg.cannot_withdraw_authority",
    Unknown: "msg.unknown_error"
}
const EmptyTeam = {
    id: 0,
    parentTeamId: undefined,
    name: '',
    comment: "",
    type: '',
    maxUsers: 20,
    image: '',
    enabled: true,
    createdDatetime: "",
    updatedDatetime: "",
    childTeam: [],
};
const EmptyDialog = {
    open: false,
    title: "",
    msg: "",
};
const EmptyPage = {
    page: 0,
    rowsPerPage: 10,
    total: 0,
    keyword : "",
};

const EmptyActionState = {
    modified: false,
};

export const UI = {
    TeamTable: 'TeamTable',
    Information: 'Information',
    Create: 'Create',
};
export default class TeamStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    isProgress = false;
    isGetTeamUserListLoading = false;
    teamLoadingState = State.Init;

    UiState = UI.TeamTable;
    actionState = {...EmptyActionState};
    teamTablePage = {...EmptyPage};
    teamConfirmDialog = {...EmptyDialog}
    teamDeleteDialog = {...EmptyDialog};

    teamTreeExpandedList = [];
    teamTreeSelectedTeamId = "";
    teamTreeMenuDivOpenList = [];

    selectTeamByLeader = {};
    selectedUserIdBySearchKeyword = 0;

    myTeamList = [];


    teamTableList = [];
    teamTreeList = [];
    parentTeamList = [];

    teamCreatingState = false;
    newTeam = {...EmptyTeam};
    newTeamSimple = {...EmptyTeam};
    newMemberList = [];
    newAuthorityList = [];

    teamModifyingState = false;
    modyTeam = {...EmptyTeam};
    parentTeam = {...EmptyTeam};

    deleteTeamId = 0;

    orgDetailTeamImage = "";
    detailTeam = {...EmptyTeam};
    orgDetailTeam = {};
    detailTeamUser = [];
    orgDetailTeamUser = [];
    detailTeamUserAuthority = [];
    orgDetailTeamUserAuthority = [];
    insertDetailTeamUserList = [];
    deleteDetailTeamUserList = [];
    insertDetailTeamUserAuthorityList = [];
    deleteDetailTeamUserAuthorityList = [];
    teamUserListForUsersBelongedToTeam = [];
    teamTreeListForTeamMember = [];


    get getIsLoading() {
        return this.isProgress || this.isGetTeamUserListLoading;
    }

    changeUiState = (state) => {
        this.UiState = state;
    }

    changeTeamTableSearchKeyword = (e) => {
        this.teamTablePage.keyword = e.target.value;
    }
    initTeamTableSearchKeyword = () => {
        this.teamTablePage.keyword = "";
    }
    initTeamTablePage = () => {
        this.teamTablePage = {...EmptyPage};
    }


    changeTeamDeleteDialogOpen = (open) => {
        this.teamDeleteDialog.open = open;
    }
    changeTeamDeleteDialogTitle = (title) => {
        this.teamDeleteDialog.title = title;
    }
    changeTeamDeleteDialogMsg = (msg) => {
        this.teamDeleteDialog.msg = msg;
    }

    changeTeamConfirmDialogOpen = (open) => {
        if (open) {
            this.teamConfirmDialog.open = open;
        } else {
            this.teamConfirmDialog = {...EmptyDialog};
        }
    }
    changeTeamConfirmDialogTitle = (title) => {
        this.teamConfirmDialog.title = title;
    }
    changeTeamConfirmDialogMsg = (msg) => {
        this.teamConfirmDialog.msg = msg;
    }

    setTeamConfirmDialog = (msg, title) => {
        this.changeTeamConfirmDialogOpen(true);
        this.changeTeamConfirmDialogTitle(title);
        this.changeTeamConfirmDialogMsg(msg);
    }
    setTeamConfirmDialogByError = (intl, err, title) => {
        const errValidation = ErrorCode[err.code];
        let msg = "";
        if (errValidation) {
            msg = intl.formatMessage({id: errValidation});
        } else {
            msg = intl.formatMessage({id: ErrorCode.Unknown});
        }
        this.changeTeamConfirmDialogOpen(true);
        this.changeTeamConfirmDialogTitle(title);
        this.changeTeamConfirmDialogMsg(msg);
    }

    changeTeamTreeExpandedList = (event, nodes) => {
        const node = nodes.find(node => node === "-1");
        if (!node) {
            this.teamTreeExpandedList = [];
        } else {
            this.teamTreeExpandedList = nodes;
        }
    };

    changeTeamTreeExpendListByCustom = (node) => {
        const isIt = this.teamTreeExpandedList.find(expand => expand === node);
        if (!isIt) {
            this.teamTreeExpandedList = [...this.teamTreeExpandedList, node];
        }
    }
    initTeamTreeExpandedList = () => {
        this.teamTreeExpandedList = [];
    }

    changeTeamTreeMenuDivOpenList = (node) => {
        const isIt = this.teamTreeMenuDivOpenList === node;
        if (!isIt) {
            this.teamTreeMenuDivOpenList = node;
        } else {
            this.teamTreeMenuDivOpenList = "null";
        }
    }

    changeTeamTreeSelectedTeamId = (node) => {
        const isIt = this.teamTreeSelectedTeamId === node;
        if (!isIt) {
            this.teamTreeSelectedTeamId = node;
        }
    }
    initTeamTreeSelectedTeamId = () => {
        this.teamTreeSelectedTeamId = "";
    }

    initTreeAction = async () => {
        await this.addTeamCancelSimple();
        await this.addTeamCancel();
        await this.modifyTeamCancelSimple();
        await this.modifyTeamCancel();
    }

    changeSelectTeamByLeader = (value) => {
        this.selectTeamByLeader = value;
    }

    initSelectTeamByLeader = () => {
        this.selectTeamByLeader = {};
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    getTeamSort = (teamList) => {
        let sortedTeamList = teamList.filter(e => !e.parentTeamId);
        sortedTeamList = sortedTeamList.map(team => {
            let childTeam = teamList.filter(e => e.parentTeamId === team.id);
            return {
                ...team,
                childTeam: childTeam
            }
        });
        this.teamTreeList = sortedTeamList;
        this.totalTeamList = sortedTeamList;
        this.parentTeamList = teamList.filter(team => team.type === TeamType.Group);
    }
    addTeamSimple = (team, type) => {
        this.teamCreatingState = true;
        this.newTeamSimple.parentTeamId = team.id;
        this.newTeamSimple.type = type;
        const teamIndex = this.teamTreeList.findIndex(t => t.id === team.id);
        if (team.id) {
            this.teamTreeList[teamIndex].childTeam.push(this.newTeamSimple);
        } else {
            this.teamTreeList.push(this.newTeamSimple);
        }
    }
    addTeam = () => {
        this.addTeamCancelSimple();
        this.teamCreatingState = true;
        this.newTeam.type = TeamType.Team;
    }
    changeNewTeamSimpleName = (e) => {
        this.newTeamSimple.name = e.target.value;
    }
    changeNewTeamName = (e) => {
        this.newTeam.name = e.target.value;
    }
    changeNewTeamParentTeamId = (value) => {
        this.newTeam.parentTeamId = value;
    }
    changeNewTeamComment = (e) => {
        if (e.target.value.length <= 1000) {
            this.newTeam.comment = e.target.value;
        }
    }
    changeNewTeamMaxUsers = (e) => {
        let maxUsers = e.target.value;
        if (maxUsers.length > 1 && maxUsers[0] === "0") {
            maxUsers = maxUsers.substring(1, 2);
        }
        if (parseInt(maxUsers) < 0) {
            return;
        }
        this.newTeam.maxUsers = maxUsers;
    }
    changeNewTeamEnabled = (e) => {
        if (e.target.value === "true") {
            this.newTeam.enabled = true;
        } else {
            this.newTeam.enabled = false;
        }
    }
    changeSelectedUserIdBySearchKeyword = (userId) => {
        this.selectedUserIdBySearchKeyword = userId;
    }
    addNewMember = (newMember) => {
        const isAlready = this.newMemberList.findIndex(member => member.userId === newMember.userId);
        if (isAlready !== -1) {
            this.newMemberList.splice(isAlready, 1);
        }
        this.newMemberList.push(newMember);
    }
    removeNewMember = (member) => {
        this.selectedUserIdBySearchKeyword = 0;
        const idx = this.newMemberList.findIndex(newMember => newMember.userId === member.userId);
        this.newMemberList.splice(idx, 1);
    }
    addNewAuthority = (type) => {
        const newAuthority = {
            userId: this.selectedUserIdBySearchKeyword,
            authority: type,
        };
        this.newAuthorityList.push(newAuthority);
    }
    removeNewAuthority = (type) => {
        const idx = this.newAuthorityList.findIndex(auth => auth.userId === this.selectedUserIdBySearchKeyword && auth.authority === type);
        this.newAuthorityList.splice(idx, 1);
    }
    addTeamCancel = () => {
        this.teamCreatingState = false;
        this.selectedUserIdBySearchKeyword = 0;
        this.newTeam = {...EmptyTeam};
        this.newMemberList = [];
        this.newAuthorityList = [];
    }
    addTeamCancelSimple = () => {
        if (!this.teamCreatingState) { return; }

        this.teamCreatingState = false;
        if (this.newTeamSimple.parentTeamId) {
            const teamIndex = this.teamTreeList.findIndex(t => t.id === this.newTeamSimple.parentTeamId);
            const idx = this.teamTreeList[teamIndex].childTeam.findIndex(child => child.id === this.newTeamSimple.id);
            if (idx !== -1) {
                this.teamTreeList[teamIndex].childTeam.splice(idx, 1);
            }
        } else {
            const idx = this.teamTreeList.findIndex(e => e.id === this.newTeamSimple.id);
            if (idx !== -1) {
                this.teamTreeList.splice(idx, 1);
            }
        }
        this.newTeamSimple = {...EmptyTeam};
    }

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    changeModifiedState = (state) => {
        this.actionState.modified = state;
    }

    changeModyTeamSimple = (team) => {
        this.teamModifyingState = true;
        if (team.parentTeamId) this.modyTeam = this.teamTreeList.find(e => e.id === team.parentTeamId).childTeam.find(e => e.id === team.id);
        else this.modyTeam = this.teamTreeList.find(e => e.id === team.id);
        this.modyTeam.lastName = this.modyTeam.name;
        this.modyTeam.isMody = true;
    }
    setDetailTeam = (teamUser) => {
        this.initModifyTeam();
        this.detailTeam = teamUser;
        this.orgDetailTeam = toJS(teamUser);

        this.detailTeamUser = teamUser.members;
        this.detailTeamUser.forEach(user => {
            user.authorities.forEach(auth => {
                this.detailTeamUserAuthority.push(auth);
            })
        });
        this.orgDetailTeamUser = toJS(this.detailTeamUser);
        this.orgDetailTeamUserAuthority = toJS(this.detailTeamUserAuthority);
    }

    changeModyTeamName = (e) => {
        this.modyTeam.name = e.target.value;
    }

    addDetailTeamUser = (newMember) => {
        const isAlready = this.detailTeamUser.findIndex(teamUser => teamUser.userId === newMember.userId);
        if (isAlready !== -1) {
            this.detailTeamUser.splice(isAlready, 1);
        }
        this.detailTeamUser.push(newMember);
    }

    removeDetailTeamUser = (member) => {
        this.selectedUserIdBySearchKeyword = 0;
        const idx = this.detailTeamUser.findIndex(newMember => newMember.userId === member.userId);
        this.detailTeamUser.splice(idx, 1);
        while (true) {
            const authIdx = this.detailTeamUserAuthority.findIndex(auth => auth.userId === member.userId);
            if (authIdx !== -1) {
                this.detailTeamUserAuthority.splice(authIdx, 1);
            } else {
                break;
            }
        }
    }
    addDetailTeamUserAuthority = (type) => {
        const newAuthority = {
            teamId: this.detailTeam.id,
            userId: this.selectedUserIdBySearchKeyword,
            authority: type,
        }
        this.detailTeamUserAuthority.push(newAuthority);
    }
    removeDetailTeamUserAuthority = (type) => {
        const idx = this.detailTeamUserAuthority.findIndex(auth => auth.userId === this.selectedUserIdBySearchKeyword && auth.authority === type);
        this.detailTeamUserAuthority.splice(idx, 1);
    }
    changeDetailTeamName = (e) => {
        this.detailTeam.name = e.target.value;
    }
    changeDetailTeamComment = (e) => {
        if (e.target.value.length <= 1000) {
            this.detailTeam.comment = e.target.value;
        }
    }
    changeDetailTeamMaxUsers = (e) => {
        let maxUsers = e.target.value;
        if (maxUsers.length > 1 && maxUsers[0] === "0") {
            maxUsers = maxUsers.substring(1, 2);
        }
        if (parseInt(maxUsers) < 1) {
            return;
        }
        this.detailTeam.maxUsers = maxUsers;
    }
    changeDetailTeamParentTeam = (e) => {
        this.detailTeam.parentTeamId = e.target.value;
    }

    changeDetailTeamEnabled = (e) => {
        if (e.target.value === "true") {
            this.detailTeam.enabled = true;
        } else {
            this.detailTeam.enabled = false;
        }
    }

    modifyTeamCheck = () => {
        this.deleteDetailTeamUserList = this.orgDetailTeamUser.filter(org =>
            this.detailTeamUser.findIndex(newValue => newValue.userId === org.userId && newValue.type === org.type) === -1
        );
        this.insertDetailTeamUserList = this.detailTeamUser.filter(org =>
            this.orgDetailTeamUser.findIndex(newValue => newValue.userId === org.userId && newValue.type === org.type) === -1
        );
        this.deleteDetailTeamUserAuthorityList = this.orgDetailTeamUserAuthority.filter(org =>
            this.detailTeamUserAuthority.findIndex(newValue => newValue.userId === org.userId && newValue.authority === org.authority) === -1
        );
        this.insertDetailTeamUserAuthorityList = this.detailTeamUserAuthority.filter(org =>
            this.orgDetailTeamUserAuthority.findIndex(newValue => newValue.userId === org.userId && newValue.authority === org.authority) === -1
        );

        this.deleteDetailTeamUserList = this.deleteDetailTeamUserList.map(teamUser => {
            return {
                teamId: teamUser.teamId,
                userId: teamUser.userId,
                type: teamUser.type,
                enabled : teamUser.enabled
            };
        });
        this.insertDetailTeamUserList = this.insertDetailTeamUserList.map(teamUser => {
            return {
                teamId: teamUser.teamId,
                userId: teamUser.userId,
                type: teamUser.type,
                enabled : teamUser.enabled
            };
        });
        this.deleteDetailTeamUserAuthorityList = this.deleteDetailTeamUserAuthorityList.map(auth => {
            return {
                teamId: auth.teamId,
                userId: auth.userId,
                authority: auth.authority,
            };
        });
        this.insertDetailTeamUserAuthorityList = this.insertDetailTeamUserAuthorityList.map(auth => {
            return {
                teamId: auth.teamId,
                userId: auth.userId,
                authority: auth.authority,
            };
        });
    }
    modifyTeamCallback = (teamUserList) => {
        const detailTeam = teamUserList.find(teamUser => teamUser.team.id === this.detailTeam.id);
        this.setDetailTeam(detailTeam);
    }
    initModifyTeam = () => {
        this.detailTeam = {...EmptyTeam};
        this.detailTeamUser = [];
        this.detailTeamUserAuthority = [];
        this.selectedUserIdBySearchKeyword = 0;
        this.changeModifiedState(false);
    }
    modifyTeamCancelSimple = () => {
        // if(!this.teamModifyingState) return;

        this.teamModifyingState = false;
        this.modyTeam.isMody = false;
        this.modyTeam.name = this.modyTeam.lastName;
        this.modyTeam = {...EmptyTeam};
    }
    modifyTeamCancel = () => {
        this.teamModifyingState = false;
        this.detailTeam = toJS(this.orgDetailTeam);
        this.detailTeam.image = this.orgDetailTeamImage;
        this.detailTeamUser = toJS(this.orgDetailTeamUser);
        this.detailTeamUserAuthority = toJS(this.orgDetailTeamUserAuthority);
        this.selectedUserIdBySearchKeyword = 0;
        this.changeModifiedState(false);
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    changeDeleteTeamId = (deleteTeamId) => {
        this.deleteTeamId = deleteTeamId;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    searchIntervalState = undefined;
    searchKeyword = "";
    lastSearchKeyword = "";
    initSearchTeamList = () => {
        clearInterval(this.searchIntervalState);
        this.searchIntervalState = undefined;
        this.lastSearchKeyword = '';
        this.searchKeyword = '';
        this.teamTreeListForTeamMember = [];
        this.isProgress = false;
    }

    searchTeamList = (keyword) => {
        this.isProgress = true;
        this.teamTreeListForTeamMember = [];
        this.searchKeyword = keyword;
        if (this.searchIntervalState === undefined || this.searchIntervalState === null) {
            console.log(logPrefix, "Starting SearchInterval ...");
            this.searchIntervalState = setInterval(() => this.getTeamList(), searchingInterval);
        } else {
            console.log(logPrefix, "SearchInterval already started ...");
        }
    }

    getTeamList = function* getTeamList() {
        if (this.searchKeyword === '') {
            clearInterval(this.searchIntervalState);
            this.lastSearchKeyword = '';
            this.searchIntervalState = undefined;
            this.isProgress = false;
            console.log(logPrefix, "Clear SearchInterval ...");
        } else {
            if (this.searchKeyword !== this.lastSearchKeyword) {
                try {
                    this.lastSearchKeyword = this.searchKeyword;
                    const response = yield axios.get(
                        this.serverContextPath + `/api/v1/teams/list/${this.lastSearchKeyword}`);
                    console.log(logPrefix, "SearchMembers teamResults keyword : ", this.lastSearchKeyword, " >> ", response.data);
                    this.teamTreeListForTeamMember = response.data;
                    this.isProgress = false;
                } catch (error) {
                    console.log(logPrefix, "Cannot Search Teams ...", error);
                }
            } else {
                // console.log(logPrefix, "Clear SearchInterval ...");
                clearInterval(this.searchIntervalState);
                this.searchIntervalState = undefined;
                this.lastSearchKeyword = '';
                this.isProgress = false;
            }
        }
    }

    getTeamTreeList = function* getTeamTreeList() {
        this.teamLoadingState = State.Pending;
        console.log(logPrefix, "getTeamTreeList Start");

        try {
            const response = yield axios({
                method: "get",
                url: this.serverContextPath + "/api/v1/teams",
                params: {type: "tree"}
            });

            this.getTeamSort(response.data);

            this.teamLoadingState = State.Done;
            console.log(logPrefix, "getTeamTreeList tree Done");

        } catch (err) {
            this.teamLoadingState = State.Failed;
            console.log(logPrefix, "getTeamTreeList Failed msg=", err.response.data.msg);
            console.log(err);
        }
    }

    getTeamUser = function* getTeamUser() {
        console.log(logPrefix, "getTeamUser Start");

        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + "/api/v1/teams/teamUser"
            });
            this.teamUserListForUsersBelongedToTeam = response.data;
            console.log(logPrefix, "getTeamUser Done");

        } catch (err) {
            console.log(logPrefix, "getTeamUser Failed msg=", err.response.data.msg);
            console.log(err);
        }
    }

    getTeamTableList = function* getTeamTableList(teamId) {
        this.isProgress = true;
        this.teamTableList = [];
        this.teamLoadingState = State.Pending;
        console.log(logPrefix, "getTeamTableList Start teamId=", teamId);

        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + "/api/v1/teams",
                params: {
                    teamId: teamId,
                    type: "table"
                }
            });
            this.teamTableList = response.data;

            // const countResponse = yield axios({
            //     method: 'get',
            //     url: this.serverContextPath + "/api/v1/teams",
            //     params: {
            //         teamId: teamId,
            //         keyword : this.teamTablePage.keyword,
            //         type: "tableCount"
            //     }
            // });
            // this.teamTablePage.total = countResponse.data;

            this.teamLoadingState = State.Done;
            console.log(logPrefix, "getTeamTableList Done");
        } catch (err) {
            this.teamLoadingState = State.Failed;
            console.log(logPrefix, "getTeamTableList Failed msg=", err.response.data.msg);
            console.log(err);
        } finally {
            this.isProgress = false;
        }
    }

    getTeamDetail = function* getTeamDetail(intl, teamId) {
        this.isProgress = true;
        this.teamLoadingState = State.Pending;
        console.log(logPrefix, "getTeamDetail Start");
        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + "/api/v1/teams/" + teamId,
            });

            yield this.setDetailTeam(response.data);
            console.log(logPrefix, "getTeamDetail teams Done");

            const response2 = yield axios({
                method: 'get',
                url: this.serverContextPath + "/api/v1/avatars/team/" + teamId,
            });
            this.detailTeam.image = response2.data;
            this.orgDetailTeamImage = response2.data;

            this.changeTeamTreeExpendListByCustom("-1");
            if (response.data.parentTeamId && response.data.parentTeamId !== null) {
                this.changeTeamTreeExpendListByCustom(response.data.parentTeamId.toString());
            }
            this.changeTeamTreeSelectedTeamId(response.data.id.toString());

            this.teamLoadingState = State.Done;
            console.log(logPrefix, "getTeamDetail avatars Done");
        } catch (err) {
            const title = intl.formatMessage({id: "error_loading_team"});
            this.setTeamConfirmDialogByError(intl, err.response.data, title);
            this.teamLoadingState = State.Failed;
            console.log(logPrefix, "getTeamDetail Failed msg=", err.response.data.msg);
            console.log(err);
        } finally {
            this.isProgress = false;
        }
    }

    getParentTeam = function* getParentTeam(intl, teamId) {
        if (!teamId) {
            this.parentTeam = {...EmptyTeam}
            return;
        }
        this.teamLoadingState = State.Pending;
        console.log(logPrefix, "getParentTeam Start");
        try {
            const response = yield axios({
                method: 'get',
                url: this.serverContextPath + "/api/v1/teams/" + teamId,
            });
            this.parentTeam = response.data;
            this.teamLoadingState = State.Done;
            console.log(logPrefix, "getParentTeam Done");
        } catch (err) {
            const title = intl.formatMessage({id: "error_loading_team"});
            this.setTeamConfirmDialogByError(intl, err.response.data, title);
            this.teamLoadingState = State.Failed;
            console.log(logPrefix, "getParentTeam Failed msg=", err.response.data.msg);
            console.log(err);
        } finally {
            this.isProgress = false;
        }
    }

    getSelectedTeam = function* getSelectedTeam(selectedTeamId, callbackSelectedTeam){
        this.isProgress = true;
        console.log(logPrefix, "getSelectedTeam Start");
        try {
            const response = yield axios({
                method : 'get',
                url : this.serverContextPath + `/api/v1/teams/${selectedTeamId}`
            })
            callbackSelectedTeam(selectedTeamId, response.data);
        } catch(e) {
            console.log(e);
        }
    }

    checkTeamName = function* checkTeamName(teamName, handleCheckNameState){
        this.teamLoadingState = State.Pending;
        console.log(logPrefix, "checkTeamName Start");
        try {
            const response = yield axios({
                method : "get",
                url : this.serverContextPath + `/api/v1/teams/check/name`,
                params : {name : teamName}
            });
            console.log("response.data : ", response.data);
            if (response.data === 1) {
                // 이미 존재 합니다.
                handleCheckNameState(false, "이미 존재하는 팀명 입니다.");
            } else {
                // 사용 가능한 팀명입니다.
                handleCheckNameState(true, "사용 가능한 팀명입니다.");
            }
            console.log(logPrefix, "checkTeamName Done : status=", response.data );
        } catch (e) {
            console.log(e);
        }
    }


    createTeamSimple = function* createTeamSimple(intl) {
        this.teamLoadingState = State.Pending;
        console.log(logPrefix, "createTeamSimple Start");
        try {

            this.newTeamSimple.maxUsers = 20;

            const response = yield axios({
                method: 'post',
                url: this.serverContextPath + "/api/v1/teams",
                data: {
                    type : "simple",
                    team : this.newTeamSimple,
                },
            });

            if (this.newTeamSimple.type === TeamType.Team) {
                this.getTeamDetail(intl, response.data);
                this.changeUiState(UI.Information);
            } else if (this.modyTeam.type === TeamType.Group) {
                this.getTeamTableList(response.data);
            }

            this.teamLoadingState = State.Done;
            console.log(logPrefix, "createTeamSimple Done");

        } catch (err) {
            const title = intl.formatMessage({id: "error_loading_team"});
            this.setTeamConfirmDialogByError(intl, err.response.data, title);

            this.teamLoadingState = State.Failed;
            console.log(logPrefix, "createTeamSimple Failed msg=", err.response.data.msg);
            console.log(err);

            this.getTeamTableList();
        } finally {
            this.getTeamTreeList();
            this.addTeamCancelSimple();
        }
    }

    createTeam = function* createTeam(intl, user, createTeamCallback) {
        this.isProgress = true;
        console.log(logPrefix, "createTeam Start");
        try {
            this.teamLoadingState = State.Pending;
            this.newTeam.type = TeamType.Team;


            const response = yield axios({
                method: 'post',
                url: this.serverContextPath + "/api/v1/teams",
                data: {
                    type : "detail",
                    team : this.newTeam,
                    teamUserListToCreate : this.newMemberList,
                    teamUserAuthorityListToCreate : this.newAuthorityList,
                },
            });

            createTeamCallback(response.data);
            this.getTeamDetail(intl, response.data);
            this.changeUiState(UI.Information);
            this.addTeamCancel();
            this.getTeamTableList();
            this.getTeamTreeList();
            this.getTeamUser();

            this.teamLoadingState = State.Done;
            console.log(logPrefix, "createTeam Done");
        } catch (err) {
            const title = intl.formatMessage({id: "error_create_team"});
            this.setTeamConfirmDialogByError(intl, err.response.data, title);

            this.teamLoadingState = State.Failed;
            console.log(logPrefix, "createTeam Failed msg=", err.response.data.msg);
            console.log(err.response);
        } finally {
            this.isProgress = false;
        }
    }

    modifyTeamSimple = function* modifyTeamSimple(intl) {
        console.log(logPrefix, "modifyTeamSimple Start");
        try {
            this.teamLoadingState = State.Pending;

            if (this.modyTeam.type === TeamType.Group) {
                this.modyTeam.maxUsers = 1;
            }
            yield axios({
                method: 'put',
                url: this.serverContextPath + "/api/v1/teams",
                data: {
                    type : "simple",
                    team : this.modyTeam
                }
            });

            if (this.modyTeam.type === TeamType.Team) {
                this.getTeamDetail(intl, this.modyTeam.id);
                this.changeUiState(UI.Information);
            } else if (this.modyTeam.type === TeamType.Group) {
                this.getTeamTableList(this.modyTeam.id);
            }

            this.teamLoadingState = State.Done;
            console.log(logPrefix, "modifyTeamSimple Done");

        } catch (err) {
            const title = intl.formatMessage({id: "error_modify_team"});
            this.setTeamConfirmDialogByError(intl, err.response.data, title);
            this.getTeamTableList();

            this.teamLoadingState = State.Failed;
            console.log(logPrefix, "modifyTeamSimple Failed msg=", err.response.data.msg);
            console.log(err)
        } finally {
            this.getTeamTreeList();
            this.modifyTeamCancelSimple();
        }
    }


    modifyTeam = function* modifyTeam(intl) {
        this.isProgress = true;
        this.teamLoadingState = State.Pending;
        console.log(logPrefix, "modifyTeam Start");
        this.modifyTeamCheck();
        try {
            yield axios({
                method: 'put',
                url: this.serverContextPath + "/api/v1/teams",
                data: {
                    type : "detail",
                    team : this.detailTeam,
                    teamUserListToRemove : this.deleteDetailTeamUserList,
                    teamUserListToCreate : this.insertDetailTeamUserList,
                    teamUserAuthorityListToRemove : this.deleteDetailTeamUserAuthorityList,
                    teamUserAuthorityListToCreate : this.insertDetailTeamUserAuthorityList,
                },
            });
            this.getTeamDetail(intl, this.detailTeam.id);
            this.getTeamTableList();
            this.getTeamTreeList();
            this.insertDetailTeamUserList = [];
            this.deleteDetailTeamUserList = [];
            this.insertDetailTeamUserAuthorityList = [];
            this.deleteDetailTeamUserAuthorityList = [];
            this.changeUiState(UI.Information);
            this.getTeamUser();
            this.teamLoadingState = State.Done;
            console.log(logPrefix, "modifyTeam Done");
        } catch (err) {
            const title = intl.formatMessage({id: "error_modify_team"});
            this.setTeamConfirmDialogByError(intl, err.response.data, title);
            this.teamLoadingState = State.Failed;
            console.log(logPrefix, "modifyTeam Failed msg=", err.response.data.msg);
            console.log(err);
        } finally {
            this.isProgress = false;
        }
    }

    deleteTeam = function* deleteTeam(intl) {
        this.isProgress = true;
        console.log(logPrefix, "deleteTeam Start");
        try {
            this.teamLoadingState = State.Pending;
            yield axios({
                method: 'delete',
                url: this.serverContextPath + `/api/v1/teams/${this.deleteTeamId}`
            });
            this.teamLoadingState = State.Done;
            console.log(logPrefix, "deleteTeam Done");
        } catch (err) {
            const title = intl.formatMessage({id: "error_loading_team"});
            this.setTeamConfirmDialogByError(intl, err.response.data, title);
            this.teamLoadingState = State.Failed;
            console.log(logPrefix, "deleteTeam Failed msg=", err.response.data.msg);
            console.log(err);
        } finally {
            this.changeDeleteTeamId(0);
            this.teamDeleteDialog = {...EmptyDialog};
            this.detailTeam = {};
            this.getTeamTableList();
            this.getTeamTreeList();
            this.changeUiState(UI.TeamTable);
            this.isProgress = false;
            this.getTeamUser();
        }
    }
}