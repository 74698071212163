import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Popper,
    Paper,
    Typography,
    Box,
    ClickAwayListener,
    Dialog,
    IconButton,
    Slide,
    Grow,
    MenuList,
    MenuItem,
    Button, TextareaAutosize
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {AuthorityType} from "../../../stores/UserLoungeStore";
import {DATE_UTIL} from "../../../common/util/date.util";
import {ReactComponent as MobileClose} from "../../../common/images/MobileClose.svg";
import {ReactComponent as MoreIcon} from "../../../common/images/MoreIcon.svg";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root: {

    },
    titleBox:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        borderBottom:'1px solid rgba(136,136,157,0.3)',
        padding:'8px 10px 8px 20px',
        "& .MuiMenuItem-root":{
            minHeight:0
        }
    },
    titleText:{
        width:'100%',
        textAlign:'center',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight:600
    },
    iconBtn:{
        padding:5
    },
    buttonStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        "&:hover":{
            background:'transparent'
        }
    },
    teamBox:{
        borderRadius:10,
        padding:'19px 20px',
        background:'#f5f5f5',
        margin:'0 20px 20px'
    },
    teamText1:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#000',
        fontWeight:800,
        textAlign:'justify'
    },
    teamText2:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.3rem',
        color: '#b6b6b6',
        fontWeight:600,
        textAlign:'justify',
        margin:'0 0 5px',
    },
    teamText3:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.1rem',
        color: '#a3a8af',
        fontWeight:500,
    },
    noticeBox:{
        padding:20,
    },
    moreText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.1rem',
        color: '#0d0d0d',
        padding:'5px 25px'
    },
    modifyDialogTitleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#000',
        fontWeight: 500,
        textAlign:'justify',
        width: '100%',
        resize:'none',
        padding:'10px 8px',
        border:'1px solid #c0c2c3',
        borderRadius:5,
        "&::placeholder":{
            color:'#a3a8af',
            fontWeight: 500,
        },
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
        "&:focus":{
            outline:'none',
        }
    },
    modifyText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#000000',
        fontWeight:600,
        margin:'25px 0 10px'

    }
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref} />
))

@inject('authStore', 'userLoungeStore')
@observer
class MobileNoticeContents extends Component {
    state = {
        open: false,
        moreOpen: false,
        noticeModify: false
    };

    handleClickOpen = () => {
        const { userLoungeStore, notice } = this.props;
        userLoungeStore.changeSelectedNotice(notice);
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };

    handleToggle = () => {
        this.setState(state => ({ moreOpen: !state.moreOpen }));
    };

    handleMoreClose = () => {
        this.setState({ moreOpen: false });
    };

    handleClick = () => {
        this.setState({noticeModify: this.state.noticeModify ? false : true});
        this.setState({ moreOpen: false });
    };

    handleRemoveButton = () => {
        const {userLoungeStore} = this.props;
        userLoungeStore.removeTeamNotice();
        this.setState({ moreOpen: false });
        this.setState({ open: false });
    };

    handleSaveButton = () => {
        const {userLoungeStore} = this.props;
        userLoungeStore.modifyTeamNotice();
        this.setState({ moreOpen: false });
        this.setState({ open: false });
    };

    render() {
        const {classes, intl, notice, authStore, userLoungeStore} = this.props;
        const { moreOpen } = this.state;

        return (
            <div className={classes.root}>
                <Box onClick={this.handleClickOpen} className={classes.teamBox}>
                    <Typography noWrap className={classes.teamText1}>{notice.title}</Typography>
                    <Typography noWrap className={classes.teamText2}>
                        {notice.content}
                    </Typography>
                    <Typography className={classes.teamText3}>{DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(notice.updatedDatetime), `YYYY${intl.formatMessage({id: "calendar_year"})} MMM D${intl.formatMessage({id: "calendar_day"})}`)}</Typography>
                </Box>
                <Dialog
                    fullScreen
                    open={this.state.open}
                    onClose={this.handleClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.titleBox}>
                        <Box display='flex' alignItems='center'>
                            <IconButton onClick={this.handleClose} aria-label="Close" className={classes.iconBtn}>
                                <MobileClose/>
                            </IconButton>
                            <Typography className={classes.titleText}><FormattedMessage id="notice"/></Typography>
                        </Box>
                        {this.state.noticeModify === false ?
                            <IconButton
                                buttonRef={node => {
                                    this.anchorEl = node;
                                }}
                                aria-owns={moreOpen ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={this.handleToggle}
                                className={classes.iconBtn}
                            >
                                <MoreIcon/>
                            </IconButton>
                        :
                            <Button className={classes.buttonStyle} onClick={this.handleSaveButton}><FormattedMessage id="complete"/></Button>
                        }
                        {(userLoungeStore.userType === 'Leader' || ((notice.userId === authStore.loginUser.id) && userLoungeStore.authorityList.find(a => a.authority === AuthorityType.CreateNotice))) &&
                            <Popper open={moreOpen} anchorEl={this.anchorEl} transition disablePortal>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        id="menu-list-grow"
                                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={this.handleMoreClose}>
                                                <MenuList>
                                                    <MenuItem onClick={this.handleClick} className={classes.moreText}><FormattedMessage id="modify"/></MenuItem>
                                                    <MenuItem onClick={this.handleRemoveButton} className={classes.moreText}><FormattedMessage id="delete"/></MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        }
                    </Box>

                    <Box className={classes.noticeBox}>
                        {this.state.noticeModify === false ?
                            <Typography
                                className={classes.teamText1}
                                style={{borderBottom:'1px solid rgba(136,136,157,0.3)', paddingBottom:10, marginBottom:10}}
                            >
                                {notice.title}
                            </Typography>
                        :
                            <TextareaAutosize
                                aria-label="maximum height"
                                rows={8}
                                rowsMax={8}
                                value={userLoungeStore.noticeTitle}
                                onChange={(event) => userLoungeStore.changeNoticeTitle(event.target.value)}
                                placeholder={intl.formatMessage({id: "msg.insert_subject"})}
                                className={classes.modifyDialogTitleText}
                            />
                        }

                        {this.state.noticeModify === false ?
                            <Typography className={classes.teamText2}>
                                {notice.content}
                            </Typography>
                        :
                            <>
                                <Typography className={classes.modifyText}><FormattedMessage id="descriptions"/></Typography>
                                <TextareaAutosize
                                    aria-label="maximum height"
                                    rows={14}
                                    rowsMax={14}
                                    value={userLoungeStore.noticeContent}
                                    onChange={(event) => userLoungeStore.changeNoticeContent(event.target.value)}
                                    placeholder={intl.formatMessage({id: "msg.insert_contents"})}
                                    className={classes.modifyDialogTitleText}
                                />
                            </>
                        }
                        {this.state.noticeModify === false &&
                            <Box display='flex' justifyContent='flex-end' pt={3}>
                                <Typography className={classes.teamText3}>{DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(notice.updatedDatetime), `YYYY${intl.formatMessage({id: "calendar_year"})} MMM D${intl.formatMessage({id: "calendar_day"})}`)}</Typography>
                            </Box>
                        }
                    </Box>
                </Dialog>
            </div>
        );
    };
}

export default withStyles(style)(injectIntl(MobileNoticeContents));