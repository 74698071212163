import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {Link} from "react-router-dom";
import {
    Button,
    ClickAwayListener,
    Grow,
    Paper,
    Popper,
    Badge,
    Avatar,
    Box,
    MenuList,
    Typography,
    // Select, MenuItem
} from "@material-ui/core";
// import UserImg from '../../common/images/userImg.png'
import AwayIcon from '../../common/images/AwayIcon.png'
// import {MobXProviderContext, useObserver} from "mobx-react";
// import { ReactComponent as } from "../../common/images/AwayIcon.svg"
import {inject, observer} from "mobx-react";
import {UTIL} from "../../common/util/common.util";
import {UserType} from "../../stores/AuthStore";
import { useHistory } from "react-router";
import {ManagementUrlCategoryType} from "../../stores/OrgStore";
import {FormattedMessage} from "react-intl";


const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiBadge-colorPrimary":{
            border:'1px solid #fff',
            background:'#00c880',
            width:10,
            height:10,
            borderRadius:'50%'
        },
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
    paper: {
        marginRight: theme.spacing(2),
    },
    userButton:{
        minWidth:35,
        padding:0,
        "&:hover":{
            background:'transparent'
        },
    },
    paperBox:{
        padding:'10px 25px',
        boxShadow:'-4px 4px 4px 0 rgba(0, 0, 0, 0.25)',
        background:'#f2f2f2'
    },
    avatarStyle:{
        fontFamily: 'Montserrat',
        fontSize:22,
        fontWeight:800,
        width:50,
        height:50,
        background:() => theme.configs.SubColor,
        // background:() => theme.SubColor ? theme.SubColor : '#0097ff',
        // background:() => theme.SubColor ? theme.SubColor : '#0097ff',
        border:'1px solid #fff',
    },
    avatarStyle1:{
        fontFamily: 'Montserrat',
        fontSize:22,
        fontWeight:800,
        background:() => theme.configs.SubColor,
        // background:() => theme.SubColor ? theme.SubColor : '#0097ff',
        width:40,
        height:40,
        border:'1px solid #fff',
    },
    textStyle1:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:16,
        color:'#000',
        fontWeight:600,
    },
    textStyle2:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:12,
        color:'#a3a8af',
        fontWeight:600,
    },
    selectBox:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:12,
        color:'#000',
        border:'none',
        background:'transparent',
        padding:0,
        outline:'none',
        marginLeft:-5,
        "&:focus":{
            outline:'none',
        },
    },
    menuListBox:{
        "&:focus":{
            outline:'none',
        },
        "& .MuiBadge-anchorOriginBottomRightCircle":{
            bottom:'38%'
        },
        "& .MuiMenu-paper":{
            top:'85px !important',
            left:'100px !important'
        },
        "& .MuiInput-underline:before":{
            borderBottom:0
        },
        "& .MuiInput-underline:after":{
            borderBottom:0
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled):before":{
            borderBottom:0
        },
        "& .MuiSelect-select:focus":{
            background:'transparent',
        }
    },
    optionStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:12,
        color:'#000',
    },
    itemBox:{
        borderBottom:'1px solid #c0c2c3',
        "&:last-child":{
            borderBottom:0
        }
    },
    itemText:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:16,
        color:'#000',
        padding:'10px 150px 10px 5px',
        "&:hover":{
            background:'transparent'
        },
        "&:last-child":{
            paddingBottom:0
        }
    }
}));

const SmallAvatar = withStyles((theme) => ({
    root: {
        width:12,
        height:12,
        // border: `1px solid ${theme.palette.background.paper}`,
    },
}))(Avatar);

function UserInformation(props) {
    const { authStore } = props;
    const classes = useStyles();
    const history = useHistory();
    const [open, setOpen] = React.useState(false);
    // const [userState, setUserState] = React.useState('online');
    const anchorRef = React.useRef(null);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    function handleListKeyDown(event) {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    }

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const doLogout = () =>{
        props.authStore.doLogout(history);
        props.userLoungeStore.init();
        props.userLoungeStore.changeSimpleTeamList([]);
        props.timelineStore.init();
        props.teamStore.initSelectTeamByLeader();
    }
    return (
        <div className={classes.root}>
            <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                className={classes.userButton}
            >
                {/* 온라인일경우 */}
                {/*<Badge*/}
                {/*    color='primary'*/}
                {/*    overlap="circle"*/}
                {/*    badgeContent=" "*/}
                {/*    variant="dot"*/}
                {/*    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}*/}
                {/*>*/}
                {/*    <Avatar className={classes.avatarStyle}>N</Avatar>*/}
                {/*</Badge>*/}

                {/* 부재중일경우*/}
                <Badge
                    overlap="circle"
                    badgeContent={<SmallAvatar alt="부재중" src={AwayIcon} />}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                >
                    {
                        props.loadAvatarImage ?
                            <Avatar className={classes.avatarStyle1} >
                                <img
                                    id="userAvatar"
                                    style={{width: '100%', height: '100%'}}
                                    src={props.loadAvatarImage}
                                    alt={props.userInfo.name}
                                />
                            </Avatar>
                            :
                            <Avatar
                                className={classes.avatarStyle1}
                                // style={{background : configs.SubColor}}
                            >
                                {UTIL.getFirstCapitalLetter(props.userInfo.name)}
                            </Avatar>
                    }
                </Badge>

            </Button>
            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                        <Paper className={classes.paperBox}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}  className={classes.menuListBox}>
                                    <Box display='flex' className={classes.menuListBox}>
                                        {/* 온라인일경우 */}
                                        <Badge
                                            color='primary'
                                            overlap="circle"
                                            badgeContent=""
                                            variant="dot"
                                            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                        >
                                            {
                                                props.loadAvatarImage ?
                                                    <Avatar className={classes.avatarStyle} >
                                                        <img
                                                            id="userAvatar"
                                                            style={{width: '100%', height: '100%'}}
                                                            src={props.loadAvatarImage}
                                                            alt={props.userInfo.name}
                                                        />
                                                    </Avatar>
                                                    :
                                                    <Avatar
                                                        className={classes.avatarStyle}
                                                        // style={{background : configs.SubColor}}
                                                    >
                                                        {UTIL.getFirstCapitalLetter(props.userInfo.name)}
                                                    </Avatar>
                                            }
                                        </Badge>
                                        
                                        {/* 부재중일경우*/}
                                        {/*<Badge*/}
                                        {/*    overlap="circle"*/}
                                        {/*    badgeContent={<SmallAvatar alt="부재중" src={AwayIcon} />}*/}
                                        {/*    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}*/}
                                        {/*>*/}
                                        {/*    <Avatar className={classes.avatarStyle} alt="userImg" src={UserImg} />*/}
                                        {/*</Badge>*/}

                                        <Box pl={4}>
                                            <Typography className={classes.textStyle1}>{props.userInfo.name}</Typography>
                                            <Typography className={classes.textStyle2}>{props.userInfo.email}</Typography>
                                            <Typography className={classes.optionStyle}><FormattedMessage id="online"/></Typography>
                                            {/*<Select*/}
                                            {/*    name="userState"*/}
                                            {/*    className={classes.selectBox}*/}
                                            {/*    value={userState}*/}
                                            {/*    onChange={e => setUserState(e.target.value)}*/}
                                            {/*    MenuProps={{*/}
                                            {/*        // PaperProps: {*/}
                                            {/*        //     style: {*/}
                                            {/*        //         maxHeight: 48 * 4.5 + 8,*/}
                                            {/*        //         width: 250*/}
                                            {/*        //     }*/}
                                            {/*        // },*/}
                                            {/*        disablePortal: true*/}
                                            {/*    }}*/}
                                            {/*>*/}
                                            {/*    <MenuItem value="online" className={classes.optionStyle}>{UTIL.getIntlFormatMessageString("online")}</MenuItem>*/}
                                            {/*    <MenuItem value="notHere" className={classes.optionStyle}>{UTIL.getIntlFormatMessageString("offline")}</MenuItem>*/}
                                            {/*</Select>*/}
                                        </Box>
                                    </Box>
                                    <Box className={classes.itemBox}>
                                        <Link to={{ pathname : "/profilesettings",}} className={classes.link}>
                                            <Button className={classes.itemText} onClick={handleClose}><FormattedMessage id="profile_settings"/></Button>
                                        </Link>
                                    </Box>

                                    {/* 슈퍼관리자일경우*/}
                                    {authStore.loginUser.type === UserType.Admin &&
                                        <Box className={classes.itemBox}>
                                            <Link to={`/management/${ManagementUrlCategoryType.Organization}`} className={classes.link}>
                                                <Button className={classes.itemText} onClick={handleClose}><FormattedMessage id="organization_role"/></Button>
                                            </Link>
                                        </Box>
                                    }

                                    <Box className={classes.itemBox}>
                                        <Button className={classes.itemText} onClick={()=>doLogout()}><FormattedMessage id="logout"/></Button>
                                    </Box>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    );
}
export default inject('authStore', 'teamStore', 'userLoungeStore', 'timelineStore')(observer(UserInformation));