import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Button,
    Typography,
    Box,
    Avatar,
    FormControl,
    Select,
    InputBase,
    Input, IconButton, TextField
} from "@material-ui/core";
import {ReactComponent as WarningIcon} from "../../common/images/WarningIcon.svg"
import MobileChangePassword from "./MobileChangePassword";
import {inject, observer} from "mobx-react";
import MakeProfile from "./MakeProfile";
import timeZone from "../../common/timeZone/timeZone.json"
import * as validation from "../../common/Validation";
import CheckIcon from "@material-ui/icons/Check";
import {Languages} from "../../common/Languages";
import {UserConfigType} from "../../stores/UserStore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {PATH_UTIL} from "../../common/util/path.util";
import {withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root: {
        padding: '90px 20px 20px',
        width: '100%',
        '& .MuiInput-underline:after': {
            border: 'none'
        },
        "& .MuiInput-root:hover::before": {
            border: 'none'
        },
        '& .MuiInput-underline:before': {
            border: 'none'
        },
        "& .MuiButton-root.Mui-disabled": {
            background: '#fff',
            color: '#88889D',
            border: '1px solid #A3A8AF'
        },
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        fontWeight: 800,
        color: '#0d0d0d',
        borderBottom: '1px solid rgba(136,136,157,0.3)',
        paddingBottom: 10
    },
    avatarStyle: {
        width: 65,
        height: 65,
    },
    pictureBtn: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.0rem',
        color: '#0d0d0d',
        marginLeft: 14,
        padding: '7px 14px',
        background: '#ededed',
        "&:hover": {
            background: '#ededed'
        }
    },
    textStyle1: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        fontWeight: 600,
        width: 80,
    },
    textStyle2: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
    },
    btnStyle: {
        padding: '9px 44px',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#fff',
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle1: {
        padding: '9px 44px',
        background: '#a3a8af',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#fff',
        marginRight: 27,
        "&:hover": {
            background: '#a3a8af'
        }
    },
    inputStyle: {
        borderRadius: 3,
        padding: '2px 9px',
        border: '1px solid #c0c2c3',
        background: '#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
    },
    inputErrorStyle: {
        padding: '2px 9px',
        borderRadius: 3,
        border: '1px solid #ff0000',
        background: '#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
    },
    inputText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.8rem',
        color: '#a3a8af',
        marginTop: 5,
    },
    errorText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.8rem',
        color: '#ff0000',
        fontWeight: 600,
    },
    autocompleteBox: {
        width: 240,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        background: '#fff',
        border: 0,
        borderRadius: 3,
        "& .MuiAutocomplete-root": {
            padding: 0,
        },
        "& .MuiAutocomplete-input": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.4rem',
            color: '#0d0d0d',
        },
        "& .MuiChip-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.2rem',
            color: '#0d0d0d'
        },
        "& .MuiAutocomplete-popper": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.2rem',
            color: '#0d0d0d',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiSelect-select:focus": {
            background: 'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiInputBase-root ":{
            height:32.25,
        },
    },
    option: {
        fontSize: '1.2rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#303030'
    },
    formControlBox: {
        width:240,
        background: '#fff',
        border: 0,
        borderRadius: 3,
        "& .MuiSelect-select": {
            background: 'transparent',
            border: '1px solid #c0c2c3',
        },
        "& .MuiOutlinedInput-input": {
            padding: '8px 9px',
        },
    },
    selectBox: {
        width: '100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#303030'
    },
});
const BootstrapInput = withStyles((theme) => ({
    root: {},
    input: {
        background: 'transparent',
        border: '1px solid #c0c2c3',
        padding: '8px 9px',
        borderRadius: 3,
        '&:focus': {
            borderRadius: 3,
        },
    },
}))(InputBase);


@inject('userStore', 'authStore', 'avatarStore', 'progressStore', 'localeStore', 'orgStore', 'topBarStore')
@observer
class MobileProfileSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modified: false,
            timeZone: '',
            language: '',
            name: '',
            error: false,
            checkNameState: true,
        }
    }

    componentDidMount() {
        const { topBarStore } = this.props;

        if (PATH_UTIL.getPath(this.props.location.pathname) === 'profilesettings') {
            topBarStore.setIsSearchBarRender(false);
        }
        this.props.userStore.getUserInfo(this.props.authStore.loginUser.id);
        this.props.avatarStore.initialize(this.props.authStore.loginUser.id, 'standbyVideo', 'backgroundImage', 'captureCanvas', 'backgroundCanvas', 'maskedCanvas', 'avatarCanvas');
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {progressStore, userStore, avatarStore} = this.props;
        progressStore.setIsLoading(userStore.getIsLoading || avatarStore.getIsLoading)
    }

    handleCancelClick = () => {
        this.setState({
            modified: !this.state.modified,
            language: this.props.userStore.userInfo.userConfig.find((config => config.configKey === UserConfigType.Language)) !== undefined ? this.props.userStore.userInfo.userConfig.find((config => config.configKey === UserConfigType.Language)).value : "",
            timeZone: this.props.userStore.userInfo.userConfig.find((config => config.configKey === UserConfigType.Zone)) !== undefined ? this.props.userStore.userInfo.userConfig.find((config => config.configKey === UserConfigType.Zone)).value : "",
            name: this.props.userStore.userInfo.name,
            error: false,
            checkNameState: true,
        });
        this.props.userStore.errMsg = '';
    }

    handleChangeNickName = e => {
        const {intl} = this.props;

        this.handleChangeCheckNameState(false);
        this.setState({
            name: e.target.value
        }, () => this.setState({error: !validation.validateName(this.state.name)}, () => {
            this.state.error ? this.props.userStore.errMsg = intl.formatMessage({id: "msg.re_enter_nickname"}) : this.props.userStore.errMsg = intl.formatMessage({id: "msg.required_duplicate_button"})
        }))
    }

    handleTimeZoneSelect = value => {
        if (timeZone.includes(value) || value === null) {
            this.setState({timeZone: value})
        }
    };
    handleLanguageSelect = e => {
        this.setState({language: e.target.value})
    }
    handleCheckName = () => {
        const {intl} = this.props;

        if (this.state.name !== this.props.userStore.userInfo.name) {
            this.props.userStore.checkName(intl, this.state.name, (value) => {
                this.handleChangeCheckNameState(value)
            });
        } else {
            this.props.userStore.errMsg = intl.formatMessage({id: "msg.available_nickname"});
            this.handleChangeCheckNameState(true);
        }
    }
    handleChangeCheckNameState = (value) => {
        this.setState({checkNameState: value})
    }

    handleLanguageSet = newLanguage => {
        if (!newLanguage || newLanguage === "") {
            return;
        } else {
            this.props.localeStore.changeLocale(newLanguage);
        }
    }

    handleSave = (selectLanguage) => {
        const {intl} = this.props;
        this.props.userStore.changeProfile(intl, this.state, this.props.authStore.loginUser.id, () => {
            this.handleCancelClick();
            this.handleLanguageSet(selectLanguage);
        });
    }

    render() {
        const {classes, intl, userStore, orgStore} = this.props;
        const {locale} = this.props.localeStore;
        const {userInfo} = this.props.userStore;
        const {loadAvatarImage} = this.props.avatarStore;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleStyle}><FormattedMessage id="profile_settings"/></Typography>
                <Box style={{width: '100%'}}>
                    <Box display='flex' alignItems='center' pt={3} pb={4}>
                        <Avatar alt="userImg" src={loadAvatarImage} className={classes.avatarStyle}/>
                        {this.state.modified === true &&
                        <MakeProfile/>
                        }
                    </Box>
                    <Box display="flex" alignItems="center">
                        {this.state.modified === false ?
                            <Typography className={classes.textStyle1} style={{marginBottom: 30}}><FormattedMessage id="nickname"/></Typography>
                            :
                            <Typography className={classes.textStyle1} style={{marginBottom: 30}}><FormattedMessage id="nickname"/><span
                                style={{color: '#ff0000', marginLeft: 5}}>*</span></Typography>
                        }
                        {this.state.modified === false ?
                            <Typography className={classes.textStyle2} style={{marginBottom: 30}}>
                                {userInfo.name}
                            </Typography>
                            :
                            <Box display='flex' flexDirection='column'>
                                <Box display='flex' flexDirection='row'>
                                    <Input style={{height: '32.25px', marginTop: 10, width: 240}}
                                           variant="outlined"
                                           name="name"
                                           type="text"
                                           id="name"
                                           placeholder={intl.formatMessage({id: "msg.validation_nickname"})}
                                           value={this.state.name}
                                           className={this.state.error ? classes.inputErrorStyle : classes.inputStyle}
                                           onChange={e => this.handleChangeNickName(e)}
                                    />
                                    <IconButton onClick={this.handleCheckName} disabled={this.state.error}>
                                        <CheckIcon/>
                                    </IconButton>
                                </Box>
                                {userStore.errMsg === '' ?
                                    <Box display="flex" alignItems='center'>
                                        <Typography className={classes.inputText} style={{width: 240, marginBottom: 15}}><FormattedMessage id="msg.validation_nickname"/></Typography>
                                    </Box>
                                    :
                                    userStore.errMsg === intl.formatMessage({id: "msg.available_nickname"}) ?
                                        <Typography className={classes.inputText}
                                                    style={{marginBottom: 15, color: 'blue'}}>
                                            {userStore.errMsg}
                                        </Typography>
                                        :
                                        <Box display="flex" alignItems='center'>
                                            <WarningIcon
                                                style={{marginRight: 5, marginBottom: 15, width: 13, height: 13}}/>
                                            <Typography className={classes.inputText}
                                                        style={{marginBottom: 15, color: '#ff0000'}}>
                                                {userStore.errMsg}
                                            </Typography>
                                        </Box>
                                }
                            </Box>
                        }
                    </Box>
                    <Box display="flex" alignItems="center" pb={3}>
                        <Typography className={classes.textStyle1}><FormattedMessage id="company"/></Typography>
                        <Typography className={classes.textStyle2}>
                            {orgStore.orgConfigs.OrganizationName}
                        </Typography>
                    </Box>
                    <Box display="flex" alignItems="center" pb={3}>
                        <Typography className={classes.textStyle1}><FormattedMessage id="email"/></Typography>
                        <Typography className={classes.textStyle2}>
                            {userInfo.email}
                        </Typography>
                    </Box>
                    {this.state.modified === false &&
                    <Box display="flex" alignItems="center" pb={3}>
                        <Typography className={classes.textStyle1}><FormattedMessage id="password"/></Typography>
                        <MobileChangePassword locale={locale}/>
                    </Box>
                    }
                    <Box display="flex" alignItems="center" pb={3}>
                        <Typography className={classes.textStyle1}><FormattedMessage id="timezone"/></Typography>
                        {this.state.modified === false ?
                            <Typography
                                className={classes.textStyle2}>{userInfo.userConfig.filter((config => config.configKey === UserConfigType.Zone)).map((data => data.value))}</Typography>
                            :
                            <Box className={classes.autocompleteBox}>
                                <Autocomplete
                                    id="tags-outlined"
                                    classes={{
                                        option: classes.option,
                                    }}
                                    value={this.state.timeZone}
                                    options={timeZone}
                                    noOptionsText={intl.formatMessage({id: "no_search_result"})}
                                    loadingText={intl.formatMessage({id: "searching"})}
                                    getOptionLabel={(option) => {
                                        return option.substring(option.indexOf('/')+1)
                                    }}
                                    groupBy={(option) => option.substring(0,option.indexOf('/'))}
                                    size='small'
                                    disablePortal={true}
                                    onChange={(e, value) => {
                                            this.handleTimeZoneSelect(value)
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            label=""
                                            placeholder={intl.formatMessage({id: "msg.insert_search_city"})}
                                        />
                                    )}
                                />
                            </Box>
                        }
                    </Box>
                    <Box display="flex" alignItems="center" pb={2}>
                        <Typography className={classes.textStyle1}><FormattedMessage id="language"/></Typography>
                        {this.state.modified === false ?
                            <Typography className={classes.textStyle2}>
                                {userInfo.userConfig.find(config => config.configKey === UserConfigType.Language) &&
                                Languages[userInfo.userConfig.find(config => config.configKey === UserConfigType.Language).value]}
                            </Typography>
                            :
                            <FormControl variant={'outlined'} className={classes.formControlBox}>
                                <Select
                                    native
                                    value={this.state.language}
                                    onChange={(e) => this.handleLanguageSelect(e)}
                                    className={classes.selectBox}
                                    input={<BootstrapInput/>}
                                >
                                    <option value="" className={classes.optionStyle}>{intl.formatMessage({id: "language"})}</option>
                                    {
                                        Object.entries(Languages).map(([key, value]) =>
                                            <option key={key} value={key}
                                                    className={classes.optionStyle}>{value}</option>)
                                    }
                                </Select>
                            </FormControl>
                        }
                    </Box>

                    <Box display="flex" pt={2}
                         style={this.state.modified === false ? {justifyContent: 'flex-end'} : {justifyContent: 'center'}}>
                        {this.state.modified === false ?
                            <Button className={classes.btnStyle}
                                    onClick={this.handleCancelClick}
                                    // style={{background : MainBtnColor ? MainBtnColor : MainColor}}
                            >
                                <FormattedMessage id="edit"/>
                            </Button>
                            :
                            <Box display="flex">
                                <Button className={classes.btnStyle1} onClick={this.handleCancelClick}><FormattedMessage id="cancel"/></Button>
                                <Button className={classes.btnStyle}
                                        // style={{background : MainBtnColor ? MainBtnColor : MainColor}}
                                        disabled={this.state.error || !this.state.checkNameState || this.props.userStore.getIsLoading}
                                        onClick={() => this.handleSave(this.state.language)}><FormattedMessage id="save"/></Button>
                            </Box>
                        }
                    </Box>
                </Box>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(MobileProfileSettings)));