import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Button,
    Select,
    FormControl,
    TextareaAutosize,
    TextField, MenuItem, ClickAwayListener, FormControlLabel, Checkbox,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import MobileSelectSchedule from "../../components/Calendar/MobileSelectSchedule";
import MobileGuestParticipation from "./containers/MobileGuestParticipation";
import {inject, observer} from "mobx-react";
import MobileConfirmDialog from "../../common/MobileConfirmDialog";
import {withRouter} from "react-router-dom";
import {TeamType} from "../../stores/RoomStore";
import {FormattedMessage, injectIntl} from "react-intl";
import {ReactComponent as UnCheckedBox} from "../../common/images/UnCheckedBox.svg";
import {ReactComponent as CheckedBox} from "../../common/images/CheckedBox.svg";

const style = (theme) => ({
    root: {
        padding: '90px 20px 20px',
        width: '100%',
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #c0c2c3'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #c0c2c3',
        }
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        fontWeight: 800,
        color: '#0d0d0d',
        borderBottom: '1px solid rgba(136,136,157,0.3)',
        paddingBottom: 10,
        marginBottom: 40
    },
    labelText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        fontWeight: 600,
        color: '#000',
    },
    formControlBox: {
        width: '100%',
        "& .MuiOutlinedInput-input": {
            padding: '8px 10px'
        },
        "& .MuiSelect-select:focus": {
            background: 'transparent'
        },

    },
    selectBox: {
        width: '100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#000',
        background: 'transparent',
        padding: 0,
        outline: 'none',
        "&:focus": {
            outline: 'none',
        },
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.1rem',
        color: '#0d0d0d',
        padding: 5,
        outline: 0,
        minHeight: 0,
        "&:hover": {
            color: '#0d0d0d',
        }
    },
    textareaStyle: {
        width: "100%",
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        border: '1px solid #c0c2c3',
        padding: '10px',
        borderRadius: 5,
        overflowY: 'auto',
        resize: 'none',
        lineHeight: 1.5,
        marginTop: 7,
        "&::placeholder": {
            color: '#a3a8af'
        },
        "&:focus": {
            outline: 'none',
        },
        "&::-webkit-scrollbar": {
            width: '5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background: 'transparent',
            marginTop: 10,
        },
    },
    autocompleteBox: {
        width: '100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        marginTop: 7,
        "& .MuiAutocomplete-root": {
            padding: 0,
        },
        "& .MuiAutocomplete-input": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.2rem',
            color: '#0d0d0d',
        },
        "& .MuiAutocomplete-popper": {
            padding: 0
        },
        "& .MuiChip-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: '1.0rem',
            color: '#0d0d0d'
        },
    },
    option: {
        fontSize: '1.0rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#303030',
        minHeight: '0 !important',
        padding: '10px 0'
    },
    btnStyle: {
        padding: '9px 44px',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        fontWeight: 800,
        color: '#fff',
        marginBottom: 50,
        borderRadius: 7,
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle1: {
        padding: '9px 44px',
        background: '#a3a8af',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        fontWeight: 800,
        color: '#fff',
        marginBottom: 50,
        marginRight: 10,
        borderRadius: 7,
        "&:hover": {
            background: '#a3a8af'
        }
    },
    buttonStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        border: '1px solid #b7bcd6',
        marginTop: 9,
        padding: '8px 14px'
    },
    teamDropdown: {
        position: 'relative',
    },
    dropdown: {
        position: 'absolute',
        top: 50,
        right: 0,
        left: 0,
        zIndex: 1,
        border: '1px solid #b7bcd6',
        padding: '8px 0',
        background: '#fff'
    },
    dropdownText: {
        fontSize: '1.4rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#303030',
        cursor: 'pointer',
        padding: '5px 18px',
        "&:hover": {
            background: '#f5f5f5'
        }
    },
    selectedDropdownText: {
        fontSize: '1.4rem',
        fontFamily: 'NanumSquareRoundOTF',
        color: '#303030',
        cursor: 'pointer',
        padding: '5px 18px',
        opacity: 0.2,
    },
});

@inject('roomStore', 'authStore', 'userLoungeStore', 'teamStore', 'userStore', 'timelineStore', 'progressStore', 'topBarStore', 'orgStore')
@observer
class MobileModifyRoom extends Component {
    constructor(props) {
        super(props);
        this.state ={
            members:[],

        }

    }
    componentDidMount() {
        const {history, authStore, topBarStore, roomStore, timelineStore} = this.props;
        if (window.location.pathname === '/modifyroom') {
            topBarStore.setIsSearchBarRender(false);
        }
        if (timelineStore.roomListGroupByStartTime.length > 0) {
            this.props.roomStore.setRoomInfo(authStore.loginUser, this.props.location.roomId, this.props.location.teamId, () => {
                roomStore.roomInfo.members.length > 0 &&
                this.setState({members: roomStore.roomInfo.members})
            });
            roomStore.changeSendEmail(false);
        } else {
            history.push('/');
        }
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {roomStore, progressStore, userStore} = this.props;
        progressStore.setIsLoading(roomStore.getIsLoading || userStore.getIsLoading)
    }

    handleModifyRoom = () => {
        this.props.roomStore.modifyRoom(this.props.intl, this.props, this.props.timelineStore, this.props.userLoungeStore);
    };

    handleCancel = () => {
        const {userLoungeStore} = this.props;

        if (userLoungeStore.selectedTeamId !== 0) {
            this.props.history.push(`/teams/${userLoungeStore.selectedTeamId}`);
        } else {
            this.props.history.push(`/mytimeline`);
        }
    };

    handleSendEmailChecked = (checked) => {
        const {roomStore} = this.props;
        roomStore.changeSendEmail(checked);
    };

    render() {
        const {classes, intl, roomStore} = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleStyle}><FormattedMessage id="on_room_modify"/></Typography>
                <Box display='flex' alignItems='center' pb={3}>
                    <Typography className={classes.labelText} style={{width: 80,}}>
                        <FormattedMessage id="room_type"/><span style={{color: '#ff0000', marginLeft: 5}}>*</span>
                    </Typography>

                    <FormControl variant={'outlined'} className={classes.formControlBox}>
                        <Select
                            value={roomStore.roomInfo.type}
                            onChange={e => roomStore.changeRoomType(e.target.value)}
                            className={classes.selectBox}
                        >
                            <MenuItem value='Education' className={classes.optionStyle}><FormattedMessage id="education"/></MenuItem>
                            {/*<MenuItem value='Conference' className={classes.optionStyle}>{UTIL.getIntlFormatMessageString("conference")}</MenuItem>*/}

                        </Select>
                    </FormControl>
                </Box>

                <Box pb={3}>
                    <Typography className={classes.labelText}>
                        <FormattedMessage id="topics"/><span style={{color: '#ff0000', marginLeft: 5}}>*</span>
                    </Typography>
                    <TextareaAutosize
                        rows={4}
                        rowsMax={4}
                        aria-label="maximum height"
                        placeholder={intl.formatMessage({id: "msg.insert_room_topic"})}
                        value={roomStore.roomInfo.name}
                        onChange={(e) => roomStore.changeRoomName(e.target.value)}
                        className={classes.textareaStyle}
                    />
                </Box>

                <Box pb={3}>
                    <Typography className={classes.labelText}>
                        <FormattedMessage id="descriptions"/>
                    </Typography>
                    <TextareaAutosize
                        rows={9}
                        rowsMax={9}
                        aria-label="maximum height"
                        placeholder={intl.formatMessage({id: "msg.insert_room_description"})}
                        value={roomStore.roomInfo.comment}
                        onChange={e => roomStore.changeComment(e.target.value)}
                        className={classes.textareaStyle}
                    />
                </Box>

                <Box pb={3}>
                    <Typography className={classes.labelText}>
                        <FormattedMessage id="select_reservation_date"/><span style={{color: '#ff0000', marginLeft: 5}}>*</span>
                    </Typography>
                    <Box style={{width: '100%', marginTop: 7}}>
                        <MobileSelectSchedule roomInfo={roomStore.roomInfo}/>
                    </Box>
                </Box>

                {/* 보안 */}
                <MobileGuestParticipation didMountRoomInfo={roomStore.roomInfo}/>

                <Box pb={4}>
                    <Typography className={classes.labelText}>
                        <FormattedMessage id="attendees"/><span style={{color: '#ff0000', marginLeft: 5}}>*</span>
                        <br/>
                        {/*<span style={roomStore.calMaxUsers <= 0 ? {color: 'red'} : {color: 'black'}}>*/}
                        {/*        (참석가능 인원 : {roomStore.calMaxUsers} {UTIL.getIntlFormatMessageString("person")})*/}
                        {/*    </span>*/}
                    </Typography>
                    <Box display='flex' flexDirection='column' style={{width: '100%'}}>
                        <Box className={classes.autocompleteBox}>
                            <Autocomplete
                                multiple
                                id="tags-outlined"
                                classes={{
                                    option: classes.option,
                                }}
                                value={this.state.members}
                                loading={roomStore.isSearchLoading}
                                noOptionsText={intl.formatMessage({id: "no_search_result"})}
                                loadingText={intl.formatMessage({id: "searching"})}
                                options={roomStore.teamList}
                                getOptionDisabled={(option) => {
                                    if (option.type === TeamType.Team) {
                                        return roomStore.roomInfo.members.filter(value => value.id === option.id && value.type === TeamType.Team).length > 0
                                    } else {
                                        return roomStore.roomInfo.members.filter(value => value.id === option.id && value.type !== TeamType.Team).length > 0
                                    }
                                }
                                }
                                getOptionLabel={(option) => {
                                    if (option.type === TeamType.Team) {
                                        return `#${option.name}`
                                    } else {
                                        return `@${option.name}`
                                    }
                                }}
                                groupBy={(option) => option.type}
                                filterSelectedOptions
                                size='small'
                                disablePortal={true}
                                freeSolo
                                onChange={(e, value, option) => {
                                    if (option !== "create-option") {
                                        this.setState({members: value})
                                        roomStore.changeMembers(value)
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label=""
                                        placeholder={roomStore.roomInfo.members.length > 0 ? "" : intl.formatMessage({id: "msg.insert_room_attendees"})}
                                        onChange={(e) => {
                                            roomStore.searchTeamMembers(e.target.value);
                                        }}
                                    />
                                )}
                            />
                        </Box>
                        <ClickAwayListener onClickAway={roomStore.handleClickAway}>
                            <Box className={classes.teamDropdown}>
                                <Button className={classes.buttonStyle}
                                        onClick={roomStore.handleChangeDropDownButton}>
                                    <FormattedMessage id="import_from_registered_team"/>
                                </Button>
                                {roomStore.teamDropDownButtonOpen ? (
                                    <Box className={classes.dropdown}>
                                        {roomStore.myTeamList.map(option =>
                                            <Typography key={option.id}
                                                        onPointerDown={() => {
                                                            if (roomStore.roomInfo.members.find(data => (data.name === option.name) && (data.id === option.id)) === undefined) {
                                                                this.setState({members: this.state.members.concat(option)})
                                                                roomStore.changeMembersInMyTeams(option)
                                                            }
                                                        }
                                                        }
                                                        className={roomStore.roomInfo.members.find(data => (data.name === option.name) && (data.id === option.id)) === undefined ? classes.dropdownText : classes.selectedDropdownText}>#{option.name}</Typography>
                                        )}
                                    </Box>
                                ) : null}
                            </Box>
                        </ClickAwayListener>
                    </Box>
                </Box>
                <Box pb={4}>
                    <Typography className={classes.labelText}><FormattedMessage id="email"/></Typography>
                    <Box display='flex' flexDirection="column" className={classes.checkBox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={<UnCheckedBox/>}
                                    checkedIcon={<CheckedBox/>}
                                    checked={roomStore.isSendEmail}
                                    onChange={e => this.handleSendEmailChecked(e.target.checked)}
                                />
                            }
                            label={intl.formatMessage({id: "send_invitation_email"})}
                            style={{width: 200}}
                        />
                    </Box>
                </Box>
                <Box display="flex" justifyContent='center'>
                    <Button className={classes.btnStyle1} onClick={this.handleCancel}
                            disabled={roomStore.getIsLoading}><FormattedMessage id="cancel"/></Button>
                    <Button
                        className={classes.btnStyle}
                        // style={{background : MainBtnColor ? MainBtnColor : MainColor}}
                        disabled={roomStore.getIsLoading}
                        onClick={this.handleModifyRoom}
                    >
                        <FormattedMessage id="modify"/>
                    </Button>
                </Box>
                <MobileConfirmDialog open={roomStore.openConfirmDialog}
                                     title={intl.formatMessage({id: "msg.input_incorrect"})}
                                     msg={roomStore.errMsg}
                                     changeDialogOpen={roomStore.changeConfirmDialogOpen}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(MobileModifyRoom)));