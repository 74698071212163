import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Grid,
    Typography,
    Box,
    Select,
    FormControl,
    TextareaAutosize,
    MenuItem,
    MenuList,
    IconButton,
    FormGroup,
    FormControlLabel,
    Checkbox,
    RadioGroup,
    Radio, Button, ClickAwayListener,
} from "@material-ui/core";
import TeamDropzoneImage from "./TeamDropzoneImage";
import { ReactComponent as DeleteIcon} from "../../../common/images/DeleteIcon.svg"
import {ReactComponent as CheckedBox} from "../../../common/images/CheckedBox.svg";
import {ReactComponent as UnCheckedBox} from "../../../common/images/UnCheckedBox.svg";
import * as Store from "../../../stores/TeamStore";
import {TeamUserType} from "../../../stores/UserStore";
import {inject, observer} from "mobx-react";
import {ReactComponent as WarningIcon} from "../../../common/images/WarningIcon.svg";
import CheckIcon from "@material-ui/icons/Check";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        width:'100%',
        background:'#fff',
        padding:'50px 30px',
        "& .MuiOutlinedInput-input":{
            padding:'8px 12px'
        },
        "& .MuiListItem-root.Mui-selected":{
            background:'#d0d0d0',
        },
        "& :focus":{
            outline:'none',
        },
    },
    gridBox:{
        marginBottom:30,
        "& .MuiButton-root.Mui-disabled":{
            background:'#fff',
            color:'#88889D',
            border:'1px solid #A3A8AF'
        }
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#0d0d0d',
        fontWeight:600
    },
    textStyle2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#666666'
    },
    textStyle2_err:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'red'
    },
    inputStyle:{
        width:'80%',
        padding:'8px 10px',
        borderRadius:4,
        border:'1px solid #b7bcd6',
        background:'#fff',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#0d0d0d'
    },
    inputStyle1:{
        width:'70%',
        padding:10,
        borderRadius:6,
        background:'#f2f3f7',
        border:0,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#b6b6b6'
    },
    formControlBox:{
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
            border:'1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiSelect-select:focus":{
            background:'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
            border:'1px solid rgba(0, 0, 0, 0.23)',
        }
    },
    selectBox: {
        width: 240,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        marginBottom:10,
        color:'#0d0d0d',
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#0d0d0d',
    },
    textareaStyle:{
        width:"80%",
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#0d0d0d',
        border:'1px solid #b7bcd6',
        padding:'10px',
        borderRadius:4,
        resize:'none',
        "&::placeholder":{
            color:'#8d909e'
        },
        "&:focus":{
            outline:'none',
        },
        overflowY:'scroll',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    textStyle3:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#0d0d0d'
    },
    teamLeaderBox:{
        width:"50%",
        height:140,
        overflowY:'auto',
        border:'1px solid #b7bcd6',
        padding:'0 8px',
        marginBottom:14,
        marginTop:5,
        "& .MuiListItem-button":{
            background:'transparent'
        },
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    teamMembersBox:{
        width:"50%",
        height:140,
        border:'1px solid #b7bcd6',
        padding:'0 8px',
        marginBottom:14,
        marginTop:5,
        overflowY:'auto',
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    textStyle4:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#8d909e',
    },
    listStyle:{
        width:'100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#0d0d0d',
        padding:5
    },
    spanStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#7d86a9',
        marginLeft:5
    },
    checkboxStyle:{
        "& .MuiTypography-root":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 12,
            color:'#0d0d0d',
        },
        marginLeft:20,
    },
    checkboxStyle1:{
        "& .MuiTypography-root":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 12,
            color:'#0d0d0d',
            marginRight:50
        },
    },
    btnStyle:{
        width:135,
        padding:'8px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#fff',
        marginBottom:50,
        "&:hover":{
            background:() =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    btnStyle1:{
        width:135,
        padding:'8px 0',
        background:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#fff',
        marginBottom:50,
        marginRight:27,
        "&:hover":{
            background:'#a3a8af'
        }
    },
    teamDropdown:{
        position: 'relative',
    },
    dropdown: {
        width:'70%',
        position: 'absolute',
        top: 40,
        right: 0,
        left: 0,
        zIndex: 1,
        border:'1px solid #b7bcd6',
        padding:'8px 0',
        background:'#fff'
    },
    dropdownText:{
        fontSize: 14,
        fontFamily:'NanumSquareRoundOTF',
        color:'#0d0d0d',
        cursor:'pointer',
        padding:'5px 18px',
        "&:hover":{
            background:'#f5f5f5'
        }
    }, autocompleteBox:{
        width:'100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color:'#0d0d0d',
        "& .MuiAutocomplete-root":{
            padding:0,
        },
        "& .MuiAutocomplete-input":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color:'#0d0d0d',
        },
        "& .MuiChip-label":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 12,
            color:'#0d0d0d'
        },
        "& .MuiAutocomplete-popper":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 12,
            color:'#0d0d0d',

        }
    },
});

@inject("teamStore", "userStore", "authStore", "avatarStore", "progressStore")
@observer
class CreateTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            leaderSearchBar : false,
            memberSearchBar : false,
            CreateRoom: false,
            CreateNotice: false,
            UploadFile: false,
            userSelectIndex : 0,
            avatarState : false,

            teamNameCheckState : true,
            teamNameCheckMsg : "",
            teamMaxUsersCheckState : true,
            teamMaxUsersCheckMsg : "",

        }
    }

    componentDidMount() {
        const group = this.props.teamStore.parentTeamList.find(group => group.id.toString() === this.props.teamStore.teamTreeSelectedTeamId)
        if (group) {
            this.props.teamStore.changeNewTeamParentTeamId(group.id);
        }
    }
    componentWillUnmount() {
        this.props.teamStore.addTeamCancel();
        this.props.userStore.initSearchUserList(TeamUserType.Leader);
        this.props.userStore.initSearchUserList(TeamUserType.Member);
    }
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const { teamStore, progressStore } = this.props;
        progressStore.setIsLoading(teamStore.getIsLoading)
    }
    memberHandleListItemClick = (event, type) => {
        this.setState({
            CreateRoom :false,
            CreateNotice :false,
            UploadFile :false,
        })
        this.props.teamStore.changeSelectedUserIdBySearchKeyword(event.target.value);
        const authority = this.props.teamStore.newAuthorityList.filter( authority => authority.userId ===  event.target.value);
        if (authority) {
            authority.forEach( auth => {
                this.setState({ [auth.authority] : true});
            })
        }


    }
    handleChange = (event) => {
        if (event.target.checked) {
            this.props.teamStore.addNewAuthority(event.target.name);
        } else {
            this.props.teamStore.modifyTeamCancelSimple(event.target.name);
        }
        this.setState({ ...this.state, [event.target.name]: event.target.checked });
    };

    handleLeaderKey = (e) => {
        this.props.userStore.searchUserList(e.target.value, TeamUserType.Leader);
    }
    handleMemberKey = (e) => {
        this.props.userStore.searchUserList(e.target.value, TeamUserType.Member);
    }
    handleAddMember = (e, type, list) => {
        if (e.isComposing || e.keyCode === 229) { return; }
        if (e.keyCode  === 13) {
            const userSelectIndex = this.state.userSelectIndex;
            const newMember = list[userSelectIndex];
            if (newMember) {
                newMember.type = type;
                newMember.userId = newMember.id;
                this.props.teamStore.addNewMember(newMember);
            }
        } else if (e.keyCode  === 38 && this.state.userSelectIndex > 0) {
            const index = this.state.userSelectIndex - 1;
            this.setState({userSelectIndex : index});
        } else if (e.keyCode  === 40 && this.state.userSelectIndex < list.length-1) {
            const index = this.state.userSelectIndex + 1;
            this.setState({userSelectIndex : index});
        } else if (e.keyCode  === 27) {
            this.setState({
                leaderSearchBar : false,
                memberSearchBar : false,
            });
        }
    }
    hoverMemberLabel = (index) => {
        this.setState({userSelectIndex : index});
    }
    handleAddMemberByMember = (member, type) => {
        const {intl} = this.props;

        member.type = type;
        member.userId = member.id;
        member.enabled = true;

        if (this.props.teamStore.newTeam.maxUsers === "") {
            this.handleCheckMaxUsersState(false, intl.formatMessage({id: "msg.required_max_user_count"}));
        } else if(this.props.teamStore.newMemberList.length < parseInt(this.props.teamStore.newTeam.maxUsers)) {
            this.props.teamStore.addNewMember(member);
        } else {
            // this.props.teamStore.newTeam.maxUsers = (this.props.teamStore.newMemberList.length + 1).toString();
            // this.handleCheckMaxUsersState(true, "");
            this.props.teamStore.addNewMember(member);
        }
    }
    handleRemoveNewMember = (member) => {
        this.props.teamStore.removeNewMember(member);
        this.setState({
            CreateRoom :false,
            CreateNotice :false,
            UploadFile :false,
        })
    }
    handleSearchBar = (event, state) => {
        console.log(" SearchBar seleted ");
        this.setState({
            [event.target.name] : state,
            userSelectIndex : 0
        });
    }

    handleClickAway = () => {
        this.props.userStore.initSearchUserList(TeamUserType.Leader);
        this.setState({leaderSearchBar : false});
    };

    handleClickAwayMembers = () => {
        this.props.userStore.initSearchUserList(TeamUserType.Member);
        this.setState({memberSearchBar : false});
    };

    handleTeamInUserList = (userId) => {
        const {intl} = this.props;

        let teamUserList = this.props.teamStore.teamUserListForUsersBelongedToTeam.filter(e => e.userId === userId);
        const nameList = teamUserList.map( e => {
            return e.teamName;
        })

        if(nameList.length > 0) return nameList.join(", ");
        else return intl.formatMessage({id: "independent"});
    }
    createTeam = () => {
        const {intl} = this.props;

        const leader = this.props.teamStore.newMemberList.filter( leader => leader.type === Store.UserType.Leader);
        const member = this.props.teamStore.newMemberList.filter( member => member.type === Store.UserType.Member);
        if (leader.length === 0 && member.length > 0) {
            const msg = intl.formatMessage({id: "msg.required_team_leader"});
            const title = intl.formatMessage({id: "error_insert_member"});
            this.props.teamStore.setTeamConfirmDialog(msg, title);
            return;
        }

        if (this.props.teamStore.newTeam.maxUsers < this.props.teamStore.newMemberList.length) {
            const msg = intl.formatMessage({id: "msg.small_max_user_count"});
            const title = intl.formatMessage({id: "error_insert_member"});
            this.props.teamStore.setTeamConfirmDialog(msg, title);
            return;
        }
        this.props.teamStore.createTeam(intl, this.props.authStore.loginUser, this.createTeamCallback);
    }

    createTeamCallback = (teamId) => {
        if (this.state.avatarState) {
            this.props.avatarStore._uploadTeamAvatarImage(teamId);
        }
        this.props.teamStore.changeModifiedState(false);
        // this.props.createTeamCallback();
        this.setState({
            avatarState : false,
            CreateRoom :false,
            CreateNotice :false,
            UploadFile :false,
        });
    }

    changeNewTeamName = (e) => {
        const {intl} = this.props;

        if (e.target.value === "") {
            this.handleCheckNameState(false, intl.formatMessage({id: "msg.required_team_name"}));
        } else {
            this.handleCheckNameState(false,"팀 명에 대한 중복체크가 필요합니다.");
        }

        this.props.teamStore.changeNewTeamName(e);
    }
    handleCheckNameState= (value, msg) => {
        this.setState({
            teamNameCheckState : value,
            teamNameCheckMsg : msg,
        });
    }
    changeNewTeamMaxUsers = (e) => {
        const {intl} = this.props;

        if (e.target.value === "") {
            this.handleCheckMaxUsersState(false, intl.formatMessage({id: "msg.required_max_user_count"}));
        } else if (parseInt(e.target.value) < 1) {
            this.handleCheckMaxUsersState(false
                , intl.formatMessage({id: "msg.required_team_user"}));
        } else if (parseInt(e.target.value) < this.props.teamStore.newMemberList.length) {
            this.handleCheckMaxUsersState(false, intl.formatMessage({id: "msg.small_max_user_count"}));
        } else {
            this.handleCheckMaxUsersState(true, "");
        }
        this.props.teamStore.changeNewTeamMaxUsers(e);
    }
    handleCheckMaxUsersState= (value, msg) => {
        this.setState({
            teamMaxUsersCheckState : value,
            teamMaxUsersCheckMsg : msg,
        });
    }
    handleAddTeamCancel =() => {
        this.props.teamStore.initTeamTablePage();
        this.props.teamStore.getTeamTableList();
        this.props.teamStore.addTeamCancel();
        this.props.teamStore.changeUiState(Store.UI.TeamTable);
    }
    changeNewTeamParentTeamId = (e) => {
        this.props.teamStore.changeNewTeamParentTeamId(e.target.value);
    }
    handleCheckTeamName = () => {
        const { newTeam } = this.props.teamStore;
        this.props.teamStore.checkTeamName(newTeam.name, this.handleCheckNameState);
    }
    handleCheckTeamNameByKeyDown = (e) => {
        if (e.isComposing || e.keyCode === 229) { return; }
        if (e.keyCode  === 13) {
            this.handleCheckTeamName();
        }
    }

    render() {
        const { classes, intl } = this.props;
        const { parentTeamList, newTeam, newMemberList,selectedUserIdBySearchKeyword, changeNewTeamComment, changeNewTeamEnabled} = this.props.teamStore;
        const { userList, searchKeyword } = this.props.userStore;
        const leaders = newMemberList.filter( member => member.type === TeamUserType.Leader);
        const members = newMemberList.filter( member => member.type === TeamUserType.Member);
        const notLeaderList = userList[TeamUserType.Leader].filter(user => user.type !== TeamUserType.Leader);
        const notMemberList = userList[TeamUserType.Member].filter(user => user.type !== TeamUserType.Member);

        return (
            <Grid container className={classes.root}>
                <Grid item xs={2}>
                    <Typography className={classes.textStyle}><FormattedMessage id="team_image"/></Typography>
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    <TeamDropzoneImage
                        handleAvatarState={(value)=>this.setState({avatarState : value})}
                    />
                    <Typography className={classes.textStyle2} style={{marginTop:20}}>* <FormattedMessage id="msg.team_image_guide"/></Typography>
                    <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.logo_upload_guide"/></Typography>
                </Grid>

                <Grid item xs={2}>
                    <Typography className={classes.textStyle}><FormattedMessage id="team_name"/><span style={{color:'#ff0000',marginLeft:5}}>*</span></Typography>
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>

                    <FormControl variant={'outlined'} className={classes.formControlBox}>
                        <Select
                            native
                            value={newTeam.parentTeamId}
                            onChange={this.changeNewTeamParentTeamId}
                            className={classes.selectBox}>

                            <option value="" className={classes.optionStyle}>{intl.formatMessage({id: "msg.select_parent_group"})}</option>
                            {parentTeamList.length > 0 &&
                                parentTeamList.map( group => {
                                    return <option key={group.id.toString()} value={group.id} className={classes.optionStyle}>{group.name}</option>
                                })
                            }
                        </Select>
                    </FormControl>
                    <Box>
                        <input
                            value={newTeam.name}
                            onChange={this.changeNewTeamName}
                            onKeyDown={this.handleCheckTeamNameByKeyDown}
                            name="teamName"
                            type="text"
                            id="teamName"
                            placeholder={intl.formatMessage({id: "msg.insert_team_name"})}
                            className={classes.inputStyle}
                        />
                        <IconButton className={classes.iconBtn} onClick={this.handleCheckTeamName}>
                            <CheckIcon />
                        </IconButton>
                    </Box>
                    {!this.state.teamNameCheckState &&
                        <Box display="flex" alignItems="center" pt={1}>
                            <WarningIcon style={{width:13, height:13, marginRight:5}}/>
                            <Typography className={classes.textStyle2_err}>
                                {this.state.teamNameCheckMsg}
                            </Typography>
                        </Box>
                    }
                </Grid>

                <Grid item xs={2}>
                    <Typography className={classes.textStyle}><FormattedMessage id="descriptions"/></Typography>
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    <TextareaAutosize
                        value={newTeam.comment}
                        onChange={changeNewTeamComment}
                        rows={8}
                        rowsMax={8}
                        aria-label="maximum height"
                        placeholder={intl.formatMessage({id: "msg.insert_team_descriptions"})}
                        className={classes.textareaStyle}
                    />
                </Grid>

                <Grid item xs={2}>
                    <Typography className={classes.textStyle}><FormattedMessage id="max_user_count"/><span style={{color:'#ff0000',marginLeft:5}}>*</span></Typography>
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    <input
                        value={newTeam.maxUsers}
                        onChange={this.changeNewTeamMaxUsers}
                        name="maxUsers"
                        type="number"
                        id="maxUsers"
                        placeholder={intl.formatMessage({id: "msg.insert_max_user_count"})}
                        className={classes.inputStyle}
                    />
                    {!this.state.teamMaxUsersCheckState &&
                        <Box display="flex" alignItems="center" pt={1}>
                            <WarningIcon style={{width:13, height:13, marginRight:5}}/>
                            <Typography className={classes.textStyle2_err}>
                                {this.state.teamMaxUsersCheckMsg}
                            </Typography>
                        </Box>
                    }
                </Grid>

                <Grid item xs={2}>
                    <Typography className={classes.textStyle}><FormattedMessage id="team_leader"/></Typography>
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    <Typography className={classes.textStyle3}>{leaders.length} <FormattedMessage id="person"/></Typography>
                    <Box className={classes.teamLeaderBox}>
                        <Box display='flex' >
                            <MenuList style={{width:'100%'}}>
                                {leaders.map( (leader,index) => {
                                    return(
                                        <Box key={`${index.toString()}-${leader.userId}`} display='flex' justifyContent='space-between'>
                                            <MenuItem key={index.toString()} className={classes.listStyle}>
                                                {leader.name}
                                                <span className={classes.spanStyle}>
                                                ({this.handleTeamInUserList(leader.id)} / {leader.title? leader.title : "-"} )
                                            </span>
                                            </MenuItem>
                                            <IconButton onClick={()=>this.handleRemoveNewMember(leader)}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </Box>
                                        )
                                })}
                            </MenuList>
                        </Box>
                    </Box>
                    <ClickAwayListener onClickAway={this.handleClickAway}>
                        <Box className={classes.teamDropdown}>
                            <input
                                value={searchKeyword[TeamUserType.Leader]}
                                onChange={this.handleLeaderKey}
                                onKeyDown={(event) => this.handleAddMember(event, TeamUserType.Leader, notLeaderList)}
                                onSelect={(event) => this.handleSearchBar(event,true)}
                                name="leaderSearchBar"
                                type="text"
                                id="leaderSearchBar"
                                placeholder={intl.formatMessage({id: "msg.insert_or_select_nickname"})}
                                className={classes.inputStyle1}
                            />
                            {this.state.leaderSearchBar ? (
                                <Box className={classes.dropdown}>
                                    {notLeaderList.length > 0 ?
                                        notLeaderList.map((leader,index) => {
                                        if(index === this.state.userSelectIndex)
                                        return (
                                            <Typography key={index.toString()}
                                                        className={classes.dropdownText}
                                                        style={{ background:'#f5f5f5'}}
                                                        onMouseEnter={()=> this.hoverMemberLabel(index)}
                                                        onClick={() => this.handleAddMemberByMember(leader, TeamUserType.Leader)}>
                                                {leader.name}
                                                <span key={`${index.toString()}-${leader.id.toString()}`} className={classes.spanStyle}>
                                                    ({this.handleTeamInUserList(leader.id)} / {leader.title? leader.title : "-"} )
                                                </span>
                                            </Typography>
                                        )
                                        else return (
                                            <Typography key={index.toString()}
                                                        className={classes.dropdownText}
                                                        onMouseEnter={()=> this.hoverMemberLabel(index)}
                                                        onClick={() => this.handleAddMemberByMember(leader, TeamUserType.Leader)}>
                                                {leader.name}

                                                <span key={`${index.toString()}-${leader.id.toString()}`} className={classes.spanStyle}>
                                                    ({this.handleTeamInUserList(leader.id)} / {leader.title? leader.title : "-"} )
                                                </span>
                                            </Typography>
                                        )
                                        }): (
                                            <Typography key={"0"}
                                                        className={classes.dropdownText}>
                                                <FormattedMessage id="msg.no_addable_user"/>
                                            </Typography>
                                        )}
                                </Box>
                            ) : null}
                        </Box>
                    </ClickAwayListener>
                </Grid>

                <Grid item xs={2}>
                    <Typography className={classes.textStyle}><FormattedMessage id="team_member"/></Typography>
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    <Box display='flex'>
                        <Typography className={classes.textStyle3} style={{width:'50%'}}>{members.length}<FormattedMessage id="person"/></Typography>
                        <Typography className={classes.textStyle} style={{marginLeft:50}}><FormattedMessage id="authority"/></Typography>
                    </Box>
                    <Box display='flex'>
                        <Box className={classes.teamMembersBox}>
                            <Box display='flex' >
                                <MenuList style={{width:'100%'}}>
                                    {members.map( (member, index) => {
                                        return (<Box key={`${member.userId.toString()}-${index.toString()}`} display='flex' justifyContent='space-between'>
                                                    <MenuItem
                                                        key={index.toString()}
                                                        value={member.id}
                                                        selected={selectedUserIdBySearchKeyword === member.id}
                                                        onClick={(event) => this.memberHandleListItemClick(event, TeamUserType.Member)}
                                                        className={classes.listStyle}
                                                    >
                                                        {member.name}
                                                        <span key={`${index.toString()}-${member.userId.toString()}`} className={classes.spanStyle}>
                                                            ({this.handleTeamInUserList(member.id)} / {member.title? member.title : "-"} )
                                                        </span>
                                                    </MenuItem>
                                                    <IconButton onClick={()=>this.handleRemoveNewMember(member)}>
                                                        <DeleteIcon />
                                                    </IconButton>
                                                </Box>)
                                    })}
                                </MenuList>
                            </Box>
                        </Box>
                        <Box style={{width:'20%',textAlign:'center',marginTop:10}}>
                            <FormGroup className={classes.checkboxStyle}>
                                <FormControlLabel
                                    control={<Checkbox
                                        icon={<UnCheckedBox />}
                                        checkedIcon={<CheckedBox/>}
                                        checked={this.state.CreateRoom}
                                        onChange={this.handleChange}
                                        disabled={!selectedUserIdBySearchKeyword || selectedUserIdBySearchKeyword === 0 }
                                        name="CreateRoom"
                                    />}
                                    label={intl.formatMessage({id: "create_room"})}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        icon={<UnCheckedBox />}
                                        checkedIcon={<CheckedBox/>}
                                        checked={this.state.CreateNotice}
                                        onChange={this.handleChange}
                                        disabled={!selectedUserIdBySearchKeyword || selectedUserIdBySearchKeyword === 0}
                                        name="CreateNotice"
                                    />}
                                    label={intl.formatMessage({id: "notice"})}
                                />
                                <FormControlLabel
                                    control={<Checkbox
                                        icon={<UnCheckedBox />}
                                        checkedIcon={<CheckedBox/>}
                                        checked={this.state.UploadFile}
                                        onChange={this.handleChange}
                                        disabled={!selectedUserIdBySearchKeyword || selectedUserIdBySearchKeyword === 0}
                                        name="UploadFile"
                                    />}
                                    label={intl.formatMessage({id: "file_management"})}
                                />
                            </FormGroup>
                        </Box>
                    </Box>
                    <ClickAwayListener onClickAway={this.handleClickAwayMembers}>
                        <Box className={classes.teamDropdown} pb={2}>
                            <input
                                value={searchKeyword[TeamUserType.Member]}
                                onChange={this.handleMemberKey}
                                onKeyDown={(event) => this.handleAddMember(event, TeamUserType.Member , notMemberList)}
                                onSelect={(event) => this.handleSearchBar(event,true)}
                                name="memberSearchBar"
                                type="text"
                                id="memberSearchBar"
                                placeholder={intl.formatMessage({id: "msg.insert_or_select_nickname"})}
                                className={classes.inputStyle1}
                            />
                            {this.state.memberSearchBar ? (
                                <Box className={classes.dropdown}>
                                    {notMemberList.length > 0 ?
                                        notMemberList.map((member, index)=> {
                                        if(index === this.state.userSelectIndex)
                                        return (
                                            <Typography key={index.toString()}
                                                        className={classes.dropdownText}
                                                        style={{ background:'#f5f5f5'}}
                                                        onMouseEnter={()=> this.hoverMemberLabel(index)}
                                                        onClick={() => this.handleAddMemberByMember(member, TeamUserType.Member)}>
                                                {member.name}
                                                <span key={`${index.toString()}-${member.id.toString()}`} className={classes.spanStyle}>
                                                    ({this.handleTeamInUserList(member.id)} / {member.title? member.title : "-"} )
                                                </span>
                                            </Typography>
                                        )
                                        else return (
                                            <Typography key={index.toString()}
                                                        className={classes.dropdownText}
                                                        onMouseEnter={()=> this.hoverMemberLabel(index)}
                                                        onClick={() => this.handleAddMemberByMember(member, TeamUserType.Member)}>
                                                {member.name}
                                                <span key={`${index.toString()}-${member.id.toString()}`} className={classes.spanStyle}>
                                                    ({this.handleTeamInUserList(member.id)} / {member.title? member.title : "-"} )
                                                </span>
                                            </Typography>
                                        )
                                        }) : (
                                            <Typography key={"0"}
                                                        className={classes.dropdownText}>
                                                <FormattedMessage id="msg.no_addable_user"/>
                                            </Typography>
                                        )}
                                </Box>
                            ) : null}
                        </Box>
                    </ClickAwayListener>
                    <Typography className={classes.textStyle2}>* <FormattedMessage id="msg.team_member_authority"/> </Typography>
                </Grid>

                <Grid item xs={2}>
                    <Typography className={classes.textStyle}><FormattedMessage id="state"/><span style={{color:'#ff0000',marginLeft:5}}>*</span></Typography>
                </Grid>
                <Grid item xs={10} className={classes.gridBox}>
                    <RadioGroup row className={classes.checkboxStyle1} aria-label="useSelection" name="useSelection1" value={newTeam.enabled} onChange={changeNewTeamEnabled} >
                        <FormControlLabel
                            control={<Radio
                                icon={<UnCheckedBox />}
                                checkedIcon={<CheckedBox/>}
                            />}
                            value={true}
                            label={intl.formatMessage({id: "enabled"})}
                        />
                        <FormControlLabel
                            control={<Radio
                                icon={<UnCheckedBox />}
                                checkedIcon={<CheckedBox/>}
                            />}
                            value={false}
                            label={intl.formatMessage({id: "disabled"})}
                        />
                    </RadioGroup>

                    <Box display="flex" justifyContent='flex-end' pt={8} style={{width:"80%"}}>
                        <Button className={classes.btnStyle1} onClick={this.handleAddTeamCancel}><FormattedMessage id="cancel"/></Button>
                        <Button className={classes.btnStyle}
                                onClick={this.createTeam}
                                disabled={
                                    !this.state.teamNameCheckState
                                    || !this.state.teamMaxUsersCheckState
                                    || newTeam.name === ""
                                    || parseInt(newTeam.maxUsers) < 1
                                    || newTeam.maxUsers===""
                                }
                        ><FormattedMessage id="save"/></Button>
                    </Box>
                </Grid>

            </Grid>
        );
    }
}

export default withStyles(style)(injectIntl(CreateTeam));
