import {makeAutoObservable} from "mobx";
import axios from "axios";
import fileDownload from "js-file-download";

export default class DownloadStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    };

    downloadManual = function* downloadManual() {
        console.log("downloadManual Start");
        try {
            const response = yield axios.get(`/api/v1/download/manual`, {responseType: 'blob'});

            const file = response.data;
            fileDownload(file, '사용자 매뉴얼.pdf');
            console.log("downloadManual Done");
        } catch (err) {
            console.log("downloadManual Failed");
        }
    }
}