import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    AppBar,
    Box,
    LinearProgress,
    Toolbar,
    InputBase
} from "@material-ui/core";
import { ReactComponent as MobileSearchIcon } from "../../common/images/MobileSearchIcon.svg";
import { ReactComponent as MobileArrowLeft } from "../../common/images/MobileArrowLeft.svg";
import { ReactComponent as MobileXCircle } from "../../common/images/MobileXCircle.svg";
import MobileSideBar from "../../components/MobileSideBar";
import MobileNotice from "./MobileNotice";
import MobileUserInformation from "./MobileUserInformation";
import {inject, observer} from "mobx-react";
import {State, UserType} from "../../stores/AuthStore";
import {Request_Notice_IntervalTime} from "../../stores/NoticeStore";
import {withRouter} from "react-router-dom";
import {PATH_UTIL} from "../../common/util/path.util";
import {injectIntl} from "react-intl";

const style = theme => ({
    appBar: {
        width:'100%',
        boxShadow:'none',
        background: (props) =>  props.isTablet ? theme.configs.MainColor : "#fff",
        '& .MuiToolbar-gutters':{
            padding:0
        },
        '& .MuiToolbar-regular':{
            minHeight:0
        }
    },
    iconBtn:{
        padding:5
    },
    inputRoot: {
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        color: '#0d0d0d',
        width:'100%',
        paddingLeft:10,
        boxSizing:'border-box',
        "&::placeholder":{
            color: '#c8c8d0',
        }
    },
    tabletInputRoot:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        color: '#0d0d0d',
        width:'100%',
        paddingLeft:10,
        boxSizing:'border-box',
        "&::placeholder":{
            color: '#c8c8d0',
        }
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        width: '100%',
    },
});

@inject('avatarStore', 'authStore','userStore', 'noticeStore', 'progressStore', 'topBarStore', 'timelineStore', 'userLoungeStore', 'roomStore', 'orgStore', 'surveyStore', 'quizStore')
@observer
class MobileTopBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userConfigLanguage : false,
            isSearch: false,
        }
    }

    getRoomNoticeInterval = undefined;

    getRoomNotice = () => {
        console.log("Start Mobile getRoomNotice")
        const { noticeStore, authStore } = this.props;
        noticeStore.requestGetRoomNotice(authStore.loginUser.id);
    };

    setRoomNoticeInterval = () => {
        console.log("Start Mobile setRoomNoticeInterval")
        this.getRoomNoticeInterval = setInterval(this.getRoomNotice, Request_Notice_IntervalTime); //30초마다 알림조회 인터벌 설정
        this.getRoomNotice();
    }

    getTimelineData = () => {
        const { history, authStore, userLoungeStore, timelineStore, surveyStore, quizStore } = this.props;
        if (authStore.loginState === State.Authenticated) {
            const path = PATH_UTIL.getPath(this.props.location.pathname);
            const teamId = PATH_UTIL.getTeamId(this.props.location.pathname);

            if ((path === 'mytimeline') || (path === 'teams')) {
                if (userLoungeStore.selectedTeamId !== teamId) {
                    userLoungeStore.changeSelectedTeamId(teamId);
                }
                userLoungeStore.changeSelectedTeam(authStore.loginUser.id, teamId, timelineStore, history);
                surveyStore.getSurveyListByUserId(authStore.loginUser);
                quizStore.getQuizListByUserId(authStore.loginUser);
            }
        }
    }

    componentDidMount() {
        this.props.userStore.getUserInfo(this.props.authStore.loginUser.id,
                                (userConfigLanguage) => {this.props.userStore.checkConfigLanguage(userConfigLanguage);},
                                () => {this.getTimelineData()});
        this.props.avatarStore.getAvatar(this.props.authStore.loginUser.id);
        if(this.props.authStore.loginUser.type !== UserType.Admin && this.getRoomNoticeInterval === undefined) {
            this.setRoomNoticeInterval();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        if(this.getRoomNoticeInterval !== undefined) {
            console.log("Clear interval Mobile")
            clearInterval(this.getRoomNoticeInterval);
        }
        if (this.props.noticeStore.cancelTokenSource) {
            this.props.noticeStore.cancelTokenSource.cancel();
        }
    }

    handleSearchButton = () => {
        const {authStore, userLoungeStore, timelineStore} = this.props;

        userLoungeStore.resetData();
        timelineStore.resetData();

        if (timelineStore.timelineType === 0) {
            timelineStore.getRoomListGroupByStartTime(userLoungeStore.selectedTeamId, authStore.loginUser.id, 'now', 1);
        } else {
            timelineStore.onChangeCalendarDate(userLoungeStore.selectedTeamId, authStore.loginUser.id);
        }
    }

    handleChangeSearchKeyword = (value) => {
        const {userLoungeStore, timelineStore} = this.props;
        userLoungeStore.changeSearchKeyword(value);
        timelineStore.changeSearchKeyword(value);
    }

    handleKeyUpEnter = (e) => {
        if(e.key === 'Enter') {
            this.handleSearchButton();
        }
    }

    handleCloseSearch = () => {
        const {userLoungeStore, timelineStore} = this.props;
        userLoungeStore.changeSearchKeyword('');
        timelineStore.changeSearchKeyword('');
        this.setState({isSearch: false})
    }

    render() {
        const { classes, intl, noticeStore, authStore, timelineStore, configs, isTablet } = this.props;
        const { loginUser } = this.props.authStore;
        const { isLoading } = this.props.progressStore;
        const { isRemoveRoomLoading } = this.props.roomStore;
        const loading = isLoading || isRemoveRoomLoading;
        const { userInfo } = this.props.userStore;
        const { loadAvatarImage } = this.props.avatarStore;
        const { isSearchBarRender } = this.props.topBarStore;

        return (
            <AppBar
                position="fixed"
                className={classes.appBar}
                // style={{background : isTablet ? configs.MainColor : "#fff",  width:'100%', boxShadow:'none',}}
            >
                <Toolbar>
                    {this.state.isSearch ?
                        <Box display='flex' justifyContent='space-between' alignItems='center' style={{width:'100%', padding:'10px 16px', borderBottom:'1px solid #88889d'}}>
                            <MobileArrowLeft style={isTablet ? {width:28, height:28} : {width:22, height:17}} onClick={() => {this.handleCloseSearch()}}/>
                            <InputBase
                                value={timelineStore.searchKeyword}
                                placeholder={intl.formatMessage({id: "search"})}
                                classes={isTablet ?
                                    {root: classes.tabletInputRoot, input: classes.inputInput,}
                                :
                                    {root: classes.inputRoot, input: classes.inputInput,}
                                }
                                inputProps={{ 'aria-label': 'search' }}
                                onChange={(event) => this.handleChangeSearchKeyword(event.target.value)}
                                onKeyPress={this.handleKeyUpEnter}
                            />
                            <Box display='flex' justifyContent='center' alignItems='center' onClick={() => this.handleChangeSearchKeyword('')}>
                                <MobileXCircle style={isTablet ? {width:25, height:25, margin:'0 10px'} : {width:22, height:17,margin:'0 5px'}}/>
                            </Box>

                            <MobileSearchIcon
                                style={isTablet ? {width:28, height:28} : {width:22, height:17}}
                                onClick={() => this.handleSearchButton()}
                            />

                        </Box>
                        :
                        <Box display='flex' justifyContent='space-between' alignItems='center' style={isTablet ? {width:'100%',padding:'10px 16px'} : {width:'100%',padding:'5px 16px'} }>
                            <MobileSideBar
                                configs={configs}
                                isMobile={this.props.isMobile}
                                isTablet={this.props.isTablet}
                            />
                            <Box display='flex' alignItems='center'>
                                {
                                    loginUser.type === UserType.Normal && isSearchBarRender &&
                                    <MobileSearchIcon style={isTablet ? {width:28, height:28} : {marginRight:3}} onClick={() => this.setState({isSearch: true})}/>
                                }
                                {
                                    loginUser.type === UserType.Normal &&
                                    <MobileNotice
                                        roomNoticeList={noticeStore.roomNoticeList || []}
                                        noticeStateUpdate={noticeStore.requestUpdateRoomNotifyState}
                                        userId={authStore.loginUser.id}
                                        isMobile={this.props.isMobile}
                                        isTablet={this.props.isTablet}
                                    />
                                }
                                <MobileUserInformation loadAvatarImage={loadAvatarImage} userInfo={userInfo} isMobile={this.props.isMobile} isTablet={this.props.isTablet} userType={loginUser.type}/>
                            </Box>
                        </Box>
                    }
                </Toolbar>
                {loading && <LinearProgress />}
            </AppBar>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(MobileTopBar)));
