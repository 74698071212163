import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Box, Button, ClickAwayListener, Paper, Popper, Typography} from "@material-ui/core";
import {ReactComponent as CalendarBlank} from "../../../../common/images/CalendarBlank.svg";
import {ReactComponent as AlarmIcon} from "../../../../common/images/AlarmIcon.svg";
import {ReactComponent as ArrowBottomIcon} from "../../../../common/images/ArrowBottomIcon.svg";
import CalendarBox from "./CalendarBox";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root: {},
    calendarBox: {
        width: '100%',
    },
    calendarBoxIn: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #d3d7db',
        paddingBottom: 5,
        marginRight: 22
    },
    calendarBtn: {
        "&:hover": {
            background: 'transparent'
        },
    },
    calenderText1: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 10,
        color: '#404040',
        marginBottom: 12,
        textAlign: 'left'
    },
    calenderText2: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        marginLeft: 5,
    },
    popper: {
        zIndex: 1,
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.common.white} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.common.white} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.common.white} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.common.white}`,
            },
        },
    },
    arrow: {
        position: 'absolute',
        fontSize: 7,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },
    paper: {
        padding: 0,
        maxWidth: 500,
        overflow: 'auto',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
    },
});

@inject('adminRoomStore')
@observer
class SelectSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDate: '',
            StartTime: '',
            MeetingTime: '',
            arrow: true,
            arrowRef: null,
            disablePortal: false,
            flip: true,
            open: false,
            placement: 'right',
        }
    }

    handleClickButton = () => {
        this.setState(state => ({
            open: !state.open,
        }));
    };

    handleArrowRef = node => {
        this.setState({
            arrowRef: node,
        });
    };

    handleClose = () => {
        this.setState({
            open: false,
        });
    };

    render() {
        const {classes, adminRoomStore} = this.props;
        const {open, placement, disablePortal, arrow, arrowRef} = this.state;
        return (
            <div>
                <Box display='flex' className={classes.calendarBox}>
                    <Button buttonRef={node => {
                        this.anchorEl = node;
                    }} onClick={this.handleClickButton} className={classes.calendarBtn} disableRipple>
                        <Box className={classes.calendarBoxIn}>
                            <Typography className={classes.calenderText1}><FormattedMessage id="date_setting"/></Typography>
                            <Box display='flex' alignItems='center'>
                                <CalendarBlank/>
                                {adminRoomStore.roomInfo.startDatetime !== "" ?
                                    <Typography
                                        className={classes.calenderText2}>{dayjs(adminRoomStore.roomInfo.startDatetime).format("YYYY-MM-DD")}</Typography>
                                    :
                                    <Typography className={classes.calenderText2}><FormattedMessage id="select_date"/></Typography>
                                }
                            </Box>
                        </Box>
                        <Box className={classes.calendarBoxIn}>
                            <Typography className={classes.calenderText1}><FormattedMessage id="start_time"/></Typography>
                            <Box display='flex' alignItems='center'>
                                <AlarmIcon/>
                                {adminRoomStore.roomInfo.startDatetime !== "" ?
                                    <Typography
                                        className={classes.calenderText2}>{dayjs(adminRoomStore.roomInfo.startDatetime).format("HH:mm").substring(0, 2) < 13 ? dayjs(adminRoomStore.roomInfo.startDatetime).format("HH:mm") : dayjs(adminRoomStore.roomInfo.startDatetime).add(-12, 'hours').format("HH:mm")} {dayjs(adminRoomStore.roomInfo.startDatetime).format("HH") < 12 ? "AM" : "PM"}</Typography>
                                    :
                                    <Typography className={classes.calenderText2}><FormattedMessage id="select_time"/></Typography>
                                }
                            </Box>
                        </Box>
                        <Box className={classes.calendarBoxIn}>
                            <Typography className={classes.calenderText1}><FormattedMessage id="meeting_time"/></Typography>
                            <Box display='flex' alignItems='center'>
                                {adminRoomStore.roomInfo.meetingTime !== "" ?
                                    <Typography className={classes.calenderText2} style={{
                                        marginRight: 10,
                                        marginLeft: 0
                                    }}>{adminRoomStore.roomInfo.meetingTime}</Typography>
                                    :
                                    <Typography className={classes.calenderText2} style={{
                                        marginRight: 10,
                                        marginLeft: 0
                                    }}><FormattedMessage id="select_time"/></Typography>
                                }
                                <ArrowBottomIcon/>
                            </Box>
                        </Box>
                    </Button>
                    <Popper
                        open={open}
                        anchorEl={this.anchorEl}
                        placement={placement}
                        disablePortal={disablePortal}
                        className={classes.popper}
                        modifiers={{
                            flip: {
                                enabled: true,
                            },
                            preventOverflow: {
                                enabled: true,
                                boundariesElement: 'scrollParent',
                            },
                            arrow: {
                                enabled: true,
                                element: arrowRef,
                            },
                        }}

                    >
                        {arrow ? <span className={classes.arrow} ref={this.handleArrowRef}/> : null}
                        <Paper className={classes.paper}>
                            <ClickAwayListener onClickAway={this.handleClose}>
                                <>
                                    {open &&
                                    <CalendarBox onClickSave={this.handleClose}/>
                                    }
                                </>
                            </ClickAwayListener>
                        </Paper>
                    </Popper>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(SelectSchedule);