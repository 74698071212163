import React, {useEffect} from 'react';
import {
    Typography,
    Box,
    Select,
    FormControl,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles({
    root: {
        marginTop: 20
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        fontSize: 14,
        fontWeight: 600,
    },
    formControlBox: {
        width: '100%',
        "& .MuiOutlinedInput-input": {
            padding: '12px 14px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        "& .MuiSelect-select:focus": {
            background: 'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        }
    },
    selectBox: {
        width: "100%",
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        borderRadius: 4,
        "& :focus": {
            outline: 'none',
        },
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#303030',
    },
});


export default function MeetingTime(props) {
    const intl = useIntl();
    const classes = useStyles();
    const [time, setTime] = React.useState('01');
    const [minute, setMinute] = React.useState('00');

    const onChangeEndTime = props.onChangeEndTime;

    useEffect(() => {
        if(time === '04'){
            onChangeEndTime(time, '00');
        }else{
            onChangeEndTime(time, minute);
        }
    }, [time, minute, onChangeEndTime]);

    return (
        <div className={classes.root}>
            <Typography className={classes.titleStyle}><FormattedMessage id="meeting_time"/></Typography>

            <Box display='flex' alignItems='center' pt={1} pb={1}>
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select
                        native
                        value={time}
                        onChange={(event) => setTime(event.target.value)}
                        className={classes.selectBox}
                    >
                        <option value="" className={classes.optionStyle}>{intl.formatMessage({id: "hour"})}</option>
                        <option value="00" className={classes.optionStyle}>0</option>
                        <option value="01" className={classes.optionStyle}>1</option>
                        <option value="02" className={classes.optionStyle}>2</option>
                        <option value="03" className={classes.optionStyle}>3</option>
                        <option value="04" className={classes.optionStyle}>4</option>
                        {/*<option value="05" className={classes.optionStyle}>5</option>*/}
                        {/*<option value="06" className={classes.optionStyle}>6</option>*/}
                        {/*<option value="07" className={classes.optionStyle}>7</option>*/}
                        {/*<option value="08" className={classes.optionStyle}>8</option>*/}
                        {/*<option value="09" className={classes.optionStyle}>9</option>*/}
                        {/*<option value="10" className={classes.optionStyle}>10</option>*/}
                        {/*<option value="11" className={classes.optionStyle}>11</option>*/}
                        {/*<option value="12" className={classes.optionStyle}>12</option>*/}
                    </Select>
                </FormControl>
                <Typography style={{paddingLeft: 10, paddingRight: 10}}> : </Typography>
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select
                        native
                        value={minute}
                        onChange={(event) => setMinute(event.target.value)}
                        className={classes.selectBox}
                    >
                        {time !== '04' ?
                            <>
                                <option value="" className={classes.optionStyle}>{intl.formatMessage({id: "minute"})}</option>
                                <option value="00" className={classes.optionStyle}>00</option>
                                <option value="05" className={classes.optionStyle}>05</option>
                                <option value="10" className={classes.optionStyle}>10</option>
                                <option value="15" className={classes.optionStyle}>15</option>
                                <option value="20" className={classes.optionStyle}>20</option>
                                <option value="25" className={classes.optionStyle}>25</option>
                                <option value="30" className={classes.optionStyle}>30</option>
                                <option value="35" className={classes.optionStyle}>35</option>
                                <option value="40" className={classes.optionStyle}>40</option>
                                <option value="45" className={classes.optionStyle}>45</option>
                                <option value="50" className={classes.optionStyle}>50</option>
                                <option value="55" className={classes.optionStyle}>55</option>
                            </>
                            :
                            <>
                                <option value="00" className={classes.optionStyle}>00</option>
                            </>
                        }
                    </Select>
                </FormControl>
            </Box>
        </div>
    );
}