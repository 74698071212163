import React, {useCallback, useEffect, useMemo} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {Drawer, Badge, Box, Typography, IconButton, Tooltip} from "@material-ui/core";
import { ReactComponent as Bell } from "../../common/images/Bell.svg";
import {ReactComponent as Close} from "../../common/images/Close.svg";
import {ROOM_TYPE, ToolTip_Close_Time} from "../../stores/NoticeStore";
import {DATE_UTIL} from "../../common/util/date.util";
import { _ } from "../../stores/NoticeStore";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles(theme => ({
    root: {
    },
    drawerBox:{
        zIndex:'9999 !important',
        "& .MuiBackdrop-root": {
            background:'transparent'
        },
        "& .MuiDrawer-paper":{
            marginTop:65,
            boxShadow:'0 4px 7px 0 rgba(0, 0, 0, 0.25)',
        }
    },
    bellBox:{
        margin:'0 23px',

        //아이콘(svg) 색상 변경
        "& path":{
            fill: () =>
                theme.configs.SubColor
                ? theme.configs.SubColor
                : '#0097ff',
        }
    },
    list: {
        width: 264,
        height:'100%',
        background:'#fff',
    },
    fullList: {
        width: 'auto',
        height:'100%',
        background:'#fff'
    },
    iconStyle:{
        cursor:'pointer'
    },
    titleBox:{
        background:'#f0f1f1',
        padding:'10px 16px'
    },
    titleText:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:15,
        fontWeight:800,
        marginLeft:5
    },
    listBox:{
        paddingTop:30
    },
    listText:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        borderBottom:'1px dashed #a3a8af',
        paddingBottom:10,
        marginBottom:10,
        textAlign:'center'
    },
    listText1:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        textAlign:'center'
    },
    listText2:{
        color:'#0d0d0d',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:800,
        margin:'0 5px'
    }
}));

const usePrevious = (value) => {
    const ref = React.useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export default function Notice(props) {
    const classes = useStyles();
    const intl = useIntl();

    const {roomNoticeList, noticeStateUpdate, userId} = props;

    const [state, setState] = React.useState({
        right: false,
        readNoticeList: [],
    });

    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const prevRoomNoticeList = usePrevious(roomNoticeList);

    const noticeList = useMemo(() => {
        if (roomNoticeList) {
            return roomNoticeList.map(notice => notice.roomId);
        } else {
            return [];
        }
    }, [roomNoticeList]);

    const latestNoticeOwner = useMemo(() => {
        if (roomNoticeList && roomNoticeList.length > 0) {
            return roomNoticeList[0].ownerName;
        } else {
            return "";
        }
    }, [roomNoticeList]);

    const latestNoticeRoomType = useMemo(() => {
        if (roomNoticeList && roomNoticeList.length > 0) {
            return intl.formatMessage({id: ROOM_TYPE[roomNoticeList[0].roomType]});
        } else {
            return "";
        }
    }, [roomNoticeList, intl]);

    const insertReadNotice = useCallback((isRead, roomIdList) => {
        if (isRead && roomIdList.length > 0) {
            setState({right: isRead, readNoticeList: roomIdList});
        } else {
            return;
        }
    }, []);

    const updateReadNoticeState = useCallback((isRead, readNoticeList) => {
        if (!isRead && readNoticeList.length > 0) {
            noticeStateUpdate(userId, readNoticeList);
        } else {
            return;
        }
    }, [noticeStateUpdate, userId]);

    const handleTooltipClose = () => {
        setTooltipOpen(false);
    };

    const toggleDrawer = (anchor, open, noticeList, callback) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        if (open) {
            setTooltipOpen(false);
        }
        setState({...state, [anchor]: open});
        callback(open, noticeList);
    };

    const handleClose = (anchor, open, noticeList, callback) => (event) => {
        setState({...state, [anchor]: open});
        //callback
        callback(open, noticeList);
    };

    const printDatetime = (newDate) => {
        const datetime = DATE_UTIL.convertTimeZoneToUTC(newDate);
        return DATE_UTIL.getTimeZoneDate(datetime, 'YYYY-M-DD h:mm A');
    }

    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === 'top' || anchor === 'bottom',
            })}
            role="presentation"
        >
            <Box display='flex' alignItems='center' justifyContent='space-between' className={classes.titleBox}>
                <Typography className={classes.titleText}>
                    <FormattedMessage id={"notification"} />
                </Typography>
                <IconButton onClick={handleClose(anchor, false, state.readNoticeList, updateReadNoticeState)}>
                    <Close style={{width: 20, height: 20}}/>
                </IconButton>
            </Box>
            {
                roomNoticeList.length > 0
                    ? roomNoticeList.map((obj, index) => {
                        return (
                            <Box className={classes.listBox} key={index}>
                                <Typography className={classes.listText}>{printDatetime(obj.createdDatetime)}</Typography>
                                <Typography className={classes.listText1}>
                                    {
                                        intl.formatMessage({id:'msg.has_new_notification'}, {
                                            name: obj.ownerName,
                                            type: <span className={classes.listText2}>{intl.formatMessage({id:ROOM_TYPE[obj.roomType]})}</span>
                                        })
                                    }
                                </Typography>
                            </Box>

                        )
                    })
                    : <Box className={classes.listBox}>
                        <Typography className={classes.listText1}>
                            <FormattedMessage id={"msg.no_new_notification"} />
                        </Typography>
                    </Box>
            }

        </div>
    );

    useEffect(() => {

        const checkedFirst = (prev, next) => {
            if (!prev || !next) {
                return true;
            } else if (next.length === 0) {
                return true;
            } else {
                return;
            }
        }

        const action = () => {
            if (!_.isEqualWith(prevRoomNoticeList, roomNoticeList, checkedFirst)) {
                if (!state.right) {
                    setTooltipOpen(true);
                    setTimeout(handleTooltipClose, ToolTip_Close_Time);
                }
                insertReadNotice(state.right, noticeList);
            } else {
                return;
            }
        }

        action();

        return function cleanUp() {
            clearTimeout(handleTooltipClose);
        }

    }, [roomNoticeList, insertReadNotice, noticeList, prevRoomNoticeList, state.right]);

    return (
        <div className={classes.root}>

            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Tooltip
                        PopperProps={{disablePortal: true}}
                        open={tooltipOpen}
                        enterNextDelay={1000}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={intl.formatMessage({id: 'msg.has_new_notification'},{
                            name: latestNoticeOwner,
                            type: latestNoticeRoomType
                        })}
                    >
                        <Badge
                            color="secondary"
                            overlap="circle"
                            badgeContent=" "
                            variant="dot"
                            onClick={toggleDrawer(anchor, true, noticeList, insertReadNotice)}
                            className={classes.bellBox}
                            invisible={roomNoticeList.length === 0}
                        >
                            <Bell className={classes.iconStyle}/>
                        </Badge>
                    </Tooltip>

                    <Drawer anchor={anchor} open={state[anchor]}
                            onClose={toggleDrawer(anchor, false, state.readNoticeList, updateReadNoticeState)}
                            className={classes.drawerBox}>
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
