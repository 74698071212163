import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Paper,
    Typography,
    Box,
    Button,
    Drawer
} from "@material-ui/core";
import { ReactComponent as MobileArrowLineConference} from "../../../common/images/MobileArrowLineConference.svg";
import { ReactComponent as ConferenceIcon} from "../../../common/images/ConferenceIcon.svg";
import { ReactComponent as MobileArrowLineLearning} from "../../../common/images/MobileArrowLineLearning.svg";
import { ReactComponent as LearningIcon} from "../../../common/images/LearningIcon.svg";
import { ReactComponent as SmileyIcon} from "../../../common/images/SmileyIcon.svg";
import { ReactComponent as ClockIcon} from "../../../common/images/ClockIcon.svg";
import { ReactComponent as Enter} from "../../../common/images/Enter.svg";
import MobileLinkView from "./MobileLinkView";
import {ReactComponent as MobileMeetingLogIcon} from "../../../common/images/MobileMeetingLogIcon.svg";
import {Link, withRouter} from "react-router-dom";
import {ReactComponent as MobileStatisticsIcon} from "../../../common/images/MobileStatisticsIcon.svg";
import MobileRoomInformation from "./MobileRoomInformation";
import {inject, observer} from "mobx-react";
import {DATE_UTIL} from "../../../common/util/date.util";
import dayjs from "dayjs";
import {UTIL} from "../../../common/util/common.util";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{

    },
    buttonBox:{
        "&:hover":{
            background:'transparent'
        }
    },
    lineContentBox:{
        position:'relative',
        left:-20
    },
    lineContentBoxOpacity:{
        position:'relative',
        opacity:0.5,
        left:-20
    },
    ArrowLineBox:{
        position:'relative',
        zIndex:20
    },
    // 회의일경우
    lineContentBoxIn:{
        position:'relative',
        // width:250,
        width:window.innerWidth > 350 ? 250 : 200,
        boxSizing:'border-box',
        background:'#dff8ed',
        borderRadius:10,
        padding:'18px 14px 20px',
        boxShadow:'none',
        marginTop:-20,
        zIndex:10
    },
    // 학습일경우
    lineContentBoxIn1:{
        position:'relative',
        // width:250,
        width:window.innerWidth > 350 ? 250 : 200,
        boxSizing:'border-box',
        background:'#eae4ff',
        borderRadius:10,
        padding:'18px 14px 20px',
        boxShadow:'none',
        marginTop:-18,
        zIndex:10
    },
    titleText1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:800,
        color:'#0d0d0d',
        marginBottom:9,
        textAlign:'left',
        textTransform:'none',
    },
    titleText2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:600,
        color:'#0d0d0d',
        textAlign:'left',
        textTransform:'none',
    },
    titleText3:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:800,
        color:'#4f5660',
    },
    conferenceIcon:{
        width:15,
        height:15,
        marginRight:4
    },
    learningIcon:{
        width:15,
        height:15,
        marginRight:4,
        "& path":{
            fill:'#664cff',
        },
    },
    iconButton:{
        padding:0,
    },
    drawerBox:{
        "& .MuiDrawer-paper":{
            borderRadius:'15px 15px 0 0',
            padding:'10px 20px'
        }
    },
    imageText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:700,
        color:'#fff',
        padding:'2px 4px'
    },
    imageTextBox:{
        position:'absolute',
        alignItems:'center',
        padding:'0 2px',
        top:'2%',
        left:40,
        display:'flex',
        border:'2px solid #fff',
        borderRadius:8,
        marginLeft:12
    }
});

@inject('authStore', 'timelineStore', 'userLoungeStore')
@observer
class MobileTimeLineItemContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bottom: false,
        }
    }

    componentDidMount() {
        const {timelineStore} = this.props;

        if (timelineStore.isFirstLoad && timelineStore.roomListGroupByStartTime.length > 0) {
            const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.FULL_FORMAT);

            let targetElementById = '';
            let el = undefined;

            if (timelineStore.roomListGroupByStartTime.length === 0) {
                targetElementById = "0";
                el = document.getElementById(targetElementById);
            } else if (timelineStore.roomListGroupByStartTime.length === 1) {
                targetElementById = timelineStore.roomListGroupByStartTime[0].roomList[0].id;
                el = document.getElementById(targetElementById);
            } else {
                for(let i = 0; i < timelineStore.roomListGroupByStartTime.length; i++) {
                    if (dayjs(currentDatetime).isSameOrBefore(timelineStore.roomListGroupByStartTime[i].startDatetime)) {
                        if (i === 0) {
                            targetElementById = timelineStore.roomListGroupByStartTime[i].roomList[0].id;
                        } else {
                            targetElementById = timelineStore.roomListGroupByStartTime[i - 1].roomList[0].id;
                        }
                        el = document.getElementById(targetElementById);
                        break;
                    }
                }
            }

            if (el) {
                if (targetElementById === "0" || timelineStore.roomListGroupByStartTime.length === 1) {
                    el.scrollIntoView({block: "start", behavior: 'smooth'});
                } else {
                    el.scrollIntoView({block: "start", behavior: 'smooth'});
                    const targetElement = document.getElementById('timelineList');
                    if (targetElement) {
                        const scrollTop = targetElement.scrollTop;
                        targetElement.scrollTo({top: scrollTop + 200, behavior: 'smooth'})
                    }
                }
                timelineStore.changeIsFirstLoad(false);
            }
        } else if (timelineStore.scrollTop !== 0) {
            const targetElement = document.getElementById('timelineList');
            if (targetElement) {
                targetElement.scrollTo(0, timelineStore.scrollTop);
            }
        }
    }

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    handleClickEnterance = (event, url) => {
        event.stopPropagation();
        url = url + '&tz=' + DATE_UTIL.getTimeZone() + '&local=' + DATE_UTIL.getLanguage();
        window.open(url);
    };

    closeBottom = () => {
        this.setState({bottom: false});
    };

    handleStatisticsButton = (event) => {
        const {room, history} = this.props;

        event.stopPropagation();
        history.push(`/rooms/${room.id}/statistics`);
    };

    render() {
        const { classes, history, authStore, userLoungeStore, room, isMulti } = this.props;

        const roomState = UTIL.getRoomState(authStore.loginUser.id, room);
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);

        const team = userLoungeStore.selectedTeamId !== 0 ? (userLoungeStore.selectedTeam.id !== undefined ? userLoungeStore.selectedTeam : undefined) : userLoungeStore.teamList.find(t => t.id === room.teamId);
        const member = team !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
        const isLeader = (member !== undefined) && (member.type === 'Leader');

        return (
            <div className={classes.root}>
                <Button onClick={this.toggleDrawer('bottom', true)} disableRipple className={classes.buttonBox}>
                    <Box className={dayjs(room.endDatetime).isBefore(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT)) ? classes.lineContentBoxOpacity : classes.lineContentBox}>
                        {isMulti ?
                            <Box style={{width:80, height:33}}/>
                            :
                            <Box className={classes.ArrowLineBox}>
                                {
                                    roomState.isConference ?
                                        <div>
                                            <MobileArrowLineConference style={window.innerWidth > 350 ? {width:260} : {width:210}}/>
                                            <div className={classes.imageTextBox} style={{background:'#00bc6a'}}>
                                                <ConferenceIcon/>
                                                <Typography className={classes.imageText}><FormattedMessage id="conference"/></Typography>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <MobileArrowLineLearning style={window.innerWidth > 350 ? {width:260} : {width:210}}/>
                                            <div className={classes.imageTextBox} style={{background:'#664cff'}}>
                                                <LearningIcon/>
                                                <Typography className={classes.imageText}><FormattedMessage id="education"/></Typography>
                                            </div>
                                        </div>
                                }
                            </Box>
                        }
                        <Paper className={roomState.isConference ? classes.lineContentBoxIn : classes.lineContentBoxIn1} style={ isMulti ? {marginLeft:28} : {marginLeft:40}} >
                            <Typography noWrap className={classes.titleText1}>{room.name}</Typography>
                            <Typography noWrap className={classes.titleText2}>{room.comment}</Typography>
                            <Box display='flex' alignItems='center' pt={1}>
                                <SmileyIcon className={roomState.isConference ? classes.conferenceIcon : classes.learningIcon}/>
                                <Typography noWrap className={classes.titleText2} style={{fontWeight:300}}>{room.teamName} | {room.userName} {room.userTitle}</Typography>
                            </Box>
                            <Box display='flex' alignItems='center' pt={1} pb={1}>
                                <ClockIcon className={roomState.isConference ? classes.conferenceIcon : classes.learningIcon } />
                                <Typography noWrap className={classes.titleText3}>{DATE_UTIL.getTimeZoneLanguage(room.startDatetime).format('A')} {dayjs(room.startDatetime).format('HH:mm')} ~ {DATE_UTIL.getTimeZoneLanguage(room.endDatetime).format('A')} {dayjs(room.endDatetime).format('HH:mm')}</Typography>
                            </Box>
                            <Box display='flex' alignItems='center' justifyContent='flex-end'>
                                {/* do not delete this */}
                                <div className={classes.iconButton} style={{visibility: 'hidden'}}>
                                    <MobileStatisticsIcon style={{visibility: 'hidden'}}/>
                                </div>
                                {(roomState.isMade || isLeader) && room.guests.length > 0 &&
                                    <div className={classes.iconButton} style={{marginRight:6}} onClick={event => event.stopPropagation()}>
                                        <MobileLinkView guests={room.guests}/>
                                    </div>
                                }
                                {roomState.isEnter &&
                                    <div className={classes.iconButton} style={{marginRight:6}} onClick={event => this.handleClickEnterance(event, room.members.find(m => m.userId === authStore.loginUser.id).entranceUrl)}>
                                        <Enter style={{width:26, verticalAlign:'middle'}}/>
                                    </div>
                                }
                                {/*시간이 지난뒤 회의일경우*/}
                                {(dayjs(room.endDatetime).isBefore(currentDatetime) && roomState.isConference) &&
                                    <Link to="/MeetingMinutes" className={classes.link}>
                                        <div className={classes.iconButton}>
                                            <MobileMeetingLogIcon style={{verticalAlign:'middle'}}/>
                                        </div>
                                    </Link>
                                }
                                {/*시간이 지난뒤 학습일경우*/}
                                {(dayjs(room.endDatetime).isBefore(currentDatetime) && !roomState.isConference && room.statistics && (isLeader || (room.userId === authStore.loginUser.id))) &&
                                    <div className={classes.iconButton} onClick={this.handleStatisticsButton}>
                                        <MobileStatisticsIcon style={{verticalAlign:'middle'}}/>
                                    </div>
                                }
                            </Box>
                        </Paper>
                    </Box>
                </Button>

                <Drawer
                    anchor="bottom"
                    open={this.state.bottom}
                    onClose={this.toggleDrawer('bottom', false)}
                    className={classes.drawerBox}
                >
                    <MobileRoomInformation history={history} room={room} closeBottom={this.closeBottom}/>
                </Drawer>
            </div>
        );
    }
}
export default withRouter(withStyles(style)(MobileTimeLineItemContent));
