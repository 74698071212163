import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import {
    Paper,
    Typography,
    Box,
    Button,
    Grid,
    Tab,
    Tabs,
} from "@material-ui/core";
import { ReactComponent as TimeLineIcon } from "../../../common/images/TimeLineIcon.svg";
import { ReactComponent as CalendarBlankIcon } from "../../../common/images/CalendarBlankIcon.svg";
import TimeLine from "./TimeLine";
import {inject, observer} from "mobx-react";
import {DATE_UTIL} from "../../../common/util/date.util";
import TimeLineCalendar from "./TimeLineCalendar";
import {AuthorityType} from "../../../stores/UserLoungeStore";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
        "& .MuiTab-root":{
          minWidth:0
        },
        "& .Mui-selected":{
            background:'#3B3B3B',
            "& path":{
                fill:'#fff'
            }
        },
        "& .MuiTabs-indicator":{
            background:'transparent'
        }
    },
    tabButton:{
        border:'1px solid #d3d7db',
        borderRadius:'5px 0 0 5px',
    },
    tabButton1:{
        border:'1px solid #d3d7db',
        borderLeft:0,
        borderRadius:'0 5px 5px 0',

    },
    paperBox:{
        padding:'30px 20px',
    },
    titleStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:18,
        fontWeight:600,
        color:'#0d0d0d',
        marginBottom:20
    },
    dataText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:800,
        color:'#0097ff',
        marginRight:10
    },
    arrowBtn:{
        padding:5
    },
    btnStyle:{
        padding:'10px 33px',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#fff',
        "&:hover":{
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',
    },
});


@inject('authStore', 'userLoungeStore', 'timelineStore', 'progressStore')
@observer
class TimeLineTab extends Component {
    handleChange = (event, value) => {
        const {authStore, timelineStore, userLoungeStore} = this.props;

        timelineStore.changeTimelineType(value);
        if (timelineStore.timelineType !== value) {
            if (value === 0) {
                timelineStore.changeScrollTop(0);
                timelineStore.closeAllDateTimelinePanel();
            } else {
                timelineStore.closeAllTimelinePanel();
            }

            if (value === 0) {
                timelineStore.getRoomListGroupByStartTime(userLoungeStore.selectedTeamId, authStore.loginUser.id, 'now', 1);
            }
        }
    };

    render() {
        const { classes, history, authStore, timelineStore, userLoungeStore } = this.props;

        const date = DATE_UTIL.getTimeZoneLanguage(timelineStore.timelineDate).format('YYYY.MM.DD(ddd)') !== 'Invalid Date' ? DATE_UTIL.getTimeZoneLanguage(timelineStore.timelineDate).format('YYYY.MM.DD(ddd)') : '';

        const team = userLoungeStore.selectedTeam;
        const member = team.id !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
        const isLeader = (member !== undefined) && (member.type === 'Leader');
        const isCreateRoom = (member !== undefined) && (member.authorities.find(a => a.authority === AuthorityType.CreateRoom));

        return (
            <div className={classes.root}>
                <Paper elevation={0} className={classes.paperBox}>
                    <Typography className={classes.titleStyle}><FormattedMessage id="my_timeline"/></Typography>
                    <Grid item xs={11} style={{paddingLeft:40,}}>
                        <Box display='flex' justifyContent='space-between' alignItems='center'>
                            <Grid item xs={3} style={{display:'flex',alignItems:'center'}}>
                                {timelineStore.timelineType === 0 &&
                                    <>
                                        <Typography className={classes.dataText}>{date}</Typography>
                                    </>
                                }
                            </Grid>

                            <Grid item xs={3} style={{display:'flex',justifyContent:'center'}}>
                                {(isLeader || isCreateRoom) ?
                                    <Button className={classes.btnStyle} onClick={() => history.push("/createroom")}>+ <FormattedMessage id="make_room"/></Button>
                                    :
                                    null
                                }

                            </Grid>

                            <Grid item xs={3} style={{display:'flex',justifyContent:'flex-end'}}>
                                <Tabs value={timelineStore.timelineType} onChange={this.handleChange}>
                                    <Tab
                                        label={
                                            <TimeLineIcon style={{marginLeft:2,marginRight:2}}/>
                                        }
                                        className={classes.tabButton}
                                    />
                                    <Tab
                                        label={
                                            <CalendarBlankIcon />
                                        }
                                        className={classes.tabButton1}
                                    />
                                </Tabs>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={11}>
                        {timelineStore.timelineType === 0 && <TimeLine history={history}/>}
                        {timelineStore.timelineType === 1 && <TimeLineCalendar/>}
                    </Grid>
                </Paper>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(TimeLineTab));