import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {Badge} from "@material-ui/core";
import { ReactComponent as Bell } from "../../../../common/images/Bell.svg";


const useStyles = makeStyles(theme => ({
    root: {
        "& .MuiBadge-dot":{
            minWidth:5,
            height:5
        },
        "& .MuiBadge-anchorOriginTopRightCircle":{
            right:'23%'
        }
    },
    bellBox:{
        margin:'0 5px',
        // 아이콘(svg) 색상 변경
        "& path":{
            fill: (props) => props.subColor ? props.subColor : '#0097ff',
        }
    },
    iconStyle:{
        width:15,
        height:15,
        cursor:'pointer'
    }
}));

export default function TabletPreviewNotice(props) {
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
                <React.Fragment>
                    <Badge
                        color="secondary"
                        overlap="circle"
                        badgeContent=" "
                        variant="dot"
                        className={classes.bellBox}
                    >
                        <Bell className={classes.iconStyle}/>
                    </Badge>
                </React.Fragment>
        </div>
    );
}