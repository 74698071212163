import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    Button,
    // Dialog,
    DialogTitle,
    DialogContent,
    Box,
    FormControlLabel,
    Checkbox,
    Typography,
    // IconButton
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {ReactComponent as WarningIcon} from "../../../common/images/WarningIcon.svg"
// import {ReactComponent as Close} from "../common/images/Close.svg"
import LogoSamsung from "../../../common/images/logo_samsung.png";
import LogoSamsungSub from "../../../common/images/logo_samsung_sub.png";
import GoogleBnr from "../../../common/images/banner_googleDw.png";
import {inject, observer} from "mobx-react";
import {State} from "../../../stores/AuthStore";
import PasswordChangeDialog from "../PasswordChangeDialog";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        position: 'fixed',
        right:0,
        top:'0',
        width:424,
        height:'100vh',
        backgroundColor:'rgb(255 255 255 / 90%)',
    },
    loginBtn: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 17,
        fontWeight: 800,
        color: '#0d0d0d'
    },
    loginBox: {

        "& .MuiDialog-paper": {
            borderRadius: 30,
            padding: '15px 40px 70px'
        },
        "& .MuiDialogContent-root": {
            padding: 0
        },
        "& .MuiDialogTitle-root": {
            padding: '0 24px'
        }
    },
    inputBoxWrap:{
        width:'100%',
        "& div input":{
            width:'100%'
        },
        "& > div:first-child input":{
            borderRadius:'5px 5px 0 0',
            borderBottomWidth:'0!important',
        },
        "& > div:last-child input":{
            borderRadius:'0 0 5px 5px',
        }
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
    },
    logoBox:{
        margin:'50px 0 70px',
    },
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 27,
        fontWeight: 500,
        color: '#0d0d0d',
        textAlign: 'center',
        marginBottom: 20,
        marginTop:10,
    },
    lblStyle: {
        width: 80,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 16,
        fontWeight: 300,
        color: '#0d0d0d',
    },
    textStyle: {
        width: 300,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        fontWeight: 300,
        padding: '14px 15px',
        borderRadius: 5,
        background: '#e8edfa',
        color: '#2d3e6f',
        "&::placeholder": {
            color: '#475c98',
            textAlign: 'center',
            fontWeight: 400,
        },
        "&:focus": {
            outline: 'none !important',
        }
    },
    buttonStyle: {
        width:'100%',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 21,
        color: '#fff',
        fontWeight: 700,
        borderRadius: 5,
        padding: '8px 45px',
        background:'#5e2eba',
        "&.Mui-disabled":{
            background:'#5e2eba !important',
            color:'#fff'
        },
        "&:focus, &:hover": {
            background: '#5e2eba'
        }
    },
    checkboxTextWrap:{
        width:'100%',
        marginBottom:20,
    },
    checkboxText: {
        "& .MuiTypography-root": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 13,
            color: '#475c98',
        }
    },
    pwFindText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        textDecoration: 'none'
    },
    errorBox: {
        margin: '5px 0 10px',
    },
    errorText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#ff0000',
        fontWeight: 600,
    },
    imgBanner:{
        position: 'absolute',
        bottom:'40px',
        left:'calc(50% - 101px)'
    }
});

@inject('authStore')
@observer
class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userNick: '',
            rememberNickState: '',
        }
    };

    componentDidMount() {
        this.props.authStore.setLoginModal();
    };

    handleClickOpen = () => {
        this.props.authStore.setLoginModal();
    };

    handleClickClose = () => {
        this.alertReset();
        this.props.authStore.changeLoginModal();
    };

    handleKeyPressLogin = (e) => {
        e.preventDefault();
        this.alertReset();
        if (e.keyCode === 13) this.handleLogin();
    };

    alertReset = () => {
        this.props.authStore.loginState = State.Pending;
    };

    handleLogin = () => {
        const {intl} = this.props;
        this.props.authStore.doLogin(intl);
    };

    render() {
        const { classes, authStore, intl } = this.props;

        return (
            <div className={classes.root}>
                <DialogTitle id="form-dialog-title">
                    <Box className={classes.titleBox}>
                        <Box className={classes.logoBox}>
                            <img src={LogoSamsung} alt={''}/>
                        </Box>
                        <img src={LogoSamsungSub} alt={''}/>
                        <Typography className={classes.titleText}>
                            <FormattedMessage id={"sign_in"} />
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Box display='flex' alignItems='center' flexDirection='column'>
                        <Box display='flex' flexDirection='column' className={classes.inputBoxWrap}>
                            <Box display='flex' flexDirection='column' justifyContent='space-between'
                                 alignItems='center'>
                                <input
                                    autoFocus={true}
                                    name="id"
                                    type="email"
                                    id="id"
                                    tabIndex={1}
                                    placeholder={intl.formatMessage({id : "msg.insert_email"})}
                                    className={classes.textStyle}
                                    style={authStore.loginState !== State.Failed ? {border: '1px solid #c0c2c3'} : {
                                        border: '1px solid #ff0000',
                                        background: '#fff'
                                    }}
                                    value={authStore.login.email}
                                    onKeyUp={(e) => this.handleKeyPressLogin(e)}
                                    onChange={(event) => authStore.changeLoginEmail(event.target.value)}
                                />
                            </Box>
                            <Box display='flex' flexDirection='row' justifyContent='space-between'
                                 alignItems='center'>
                                <input
                                    name="password"
                                    type="password"
                                    id="password"
                                    tabIndex={2}
                                    placeholder={intl.formatMessage({id : "msg.validation_password"})}
                                    style={authStore.loginState !== State.Failed ? {border:'1px solid #c0c2c3'} : {border: '1px solid #ff0000', background:'#fff'}}
                                    className={classes.textStyle}
                                    onKeyUp={(e) => this.handleKeyPressLogin(e)}
                                    onChange={(event) => authStore.changeLoginPassword(event.target.value)}
                                />
                            </Box>
                        </Box>
                        <Box className={classes.checkboxTextWrap}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                        checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                        name="checkedId"
                                        color="primary"
                                        // tabIndex={3}
                                        // defaultChecked={authStore.rememberNickState}
                                        checked={authStore.isRememberNickState}
                                        // checked={(isBoolean === authStore.getCookie('rememberNickState'))}
                                        // value={authStore.rememberNickState}
                                    />
                                }
                                label={intl.formatMessage({id : "save_email"})}
                                className={classes.checkboxText}
                                onChange={() => authStore.changeRememberNickState()}
                            />
                        </Box>
                        <Button
                            disabled={authStore.login.email === '' && authStore.login.password === ''}
                            className={classes.buttonStyle}
                            tabIndex={4}
                            onClick={this.handleLogin}
                        >
                            <FormattedMessage id={"start"} />
                        </Button>
                    </Box>

                    {/* 사용자 정보가 없을경우 */}
                    {authStore.loginState === State.Failed &&
                    <Box display="flex" className={classes.errorBox}>
                        <WarningIcon/>
                        <Typography className={classes.errorText}>{authStore.errorMsg}</Typography>
                    </Box>
                    }



                </DialogContent>

                <img src={GoogleBnr} className={classes.imgBanner} alt={''}/>


                {authStore.loginState === State.Progress && <PasswordChangeDialog/>}


            </div>
        );
    }
};

export default withStyles(style)(injectIntl(SignIn));