import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Button,
    Typography,
    Grid,
    Box,
    TextareaAutosize,
} from "@material-ui/core";
import MeetingMinutesChange from "./containers/MeetingMinutesChange";
import {ReactComponent as WarningIcon} from "../../common/images/WarningIcon.svg";
import SelectSchedule from "../../components/Calendar/SelectSchedule";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
        flexGrow: 1,
        width:'100%',
        padding:'100px 70px 0',
        marginLeft:90,
    },
    titleStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:24,
        fontWeight:800,
        color:'#0d0d0d',
        marginBottom:30
    },
    tableBox:{
        width:'100%',
        borderTop:'1px solid #a3a8af',
        borderCollapse:'collapse',
        "& th":{
            background:'#f5f5f5',
            fontFamily:'NanumSquareRoundOTF',
            fontSize:14,
            fontWeight:600,
            color:'#0d0d0d',
            width:150,
            padding:'11px 18px',
            textAlign:'left'
        },
        "& td":{
            paddingLeft:10,
            fontFamily:'NanumSquareRoundOTF',
            fontSize:14,
            color:'#0d0d0d',
            marginLeft:14,
        },
        "& tr":{
            borderBottom:'1px solid #a3a8af',
        },
    },
    inputStyle:{
        width:'80%',
        border:0,
        "&:focus":{
            outline:'none',
        },
    },
    textareaStyle:{
        width:'100%',
        height:350,
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#303030',
        resize:'none',
        border:0,
        paddingTop:11,
        "&::placeholder":{
            color:'#303030'
        },
        "&:focus":{
            outline:'none',
        },
        "&::-webkit-scrollbar": {
            width:'8px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    contentsBox1:{
        height:300,
        verticalAlign:'top',
        padding:11,
        borderBox:'boxSizing',
    },
    contentsBox2:{
        height:150,
        verticalAlign:'top',
        padding:11,
        borderBox:'boxSizing',
    },
    btnStyle:{
        padding:'9px 38px',
        background:'#0097ff',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:800,
        color:'#fff',
        marginBottom:50,
        borderRadius:7,
        "&:hover":{
            background:'#0097ff'
        }
    },
    btnStyle1:{
        padding:'9px 38px',
        background:'#a3a8af',
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:800,
        color:'#fff',
        marginBottom:50,
        marginRight:10,
        borderRadius:7,
        "&:hover":{
            background:'#a3a8af'
        }
    },
    warningText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:800,
        color:'#ff3654',
        marginLeft:5
    }
});

class MeetingMinutes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modified:false,
        }
    }

    handleClick = () => {
        this.setState({ modified: true });
    }

    render() {
        const { classes } = this.props;
        // const { open, placement, disablePortal, flip, arrow, arrowRef } = this.state;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleStyle}>회의록</Typography>
                <Grid item xs={10}>
                    <Box display='flex' justifyContent='flex-end' pb={1}>
                        <MeetingMinutesChange/>
                    </Box>
                    <table className={classes.tableBox}>
                        <tbody>
                            <tr>
                                <th >회의일시</th>
                                {this.state.modified === false ?
                                    <td>2021년 2월 25일 10:15 AM</td>
                                    :
                                    <td>
                                        <input
                                            name="meetingDate"
                                            type="text"
                                            id="meetingDate"
                                            placeholder=""
                                            className={classes.inputStyle}
                                        />
                                    </td>
                                }
                                <th className={classes.textStyle1}>회의록 작성자</th>

                                {this.state.modified === false?
                                    <td>서비스기획팀 한수연 대리</td>
                                    :
                                    <td>
                                        <input
                                            name="writer"
                                            type="text"
                                            id="writer"
                                            placeholder=""
                                            className={classes.inputStyle}
                                        />
                                    </td>
                                }
                            </tr>
                            <tr>
                                <th>참석자</th>
                                {this.state.modified === false?
                                    <td colSpan={3}>김병건 대리, 윤세라 부장, 박소은 주임, 길영수 차장, 임유진 주임, 한수연 대리</td>
                                    :
                                    <td colSpan={3}>
                                        <input
                                            name="members"
                                            type="text"
                                            id="members"
                                            placeholder=""
                                            className={classes.inputStyle}
                                        />
                                    </td>
                                }
                            </tr>
                            <tr>
                                {this.state.modified === false ?
                                    <th>회의 안건</th>
                                    :
                                    <th>회의 안건<span style={{color:'#ff0000',marginLeft:5}}>*</span></th>
                                }

                                {this.state.modified === false?
                                    <td colSpan={3}>00사이트 리뉴얼 기획 회의</td>
                                    :
                                    <td colSpan={3}>
                                        <input
                                            name="meetingTitle"
                                            type="text"
                                            id="meetingTitle"
                                            placeholder=""
                                            className={classes.inputStyle}
                                        />
                                    </td>
                                }
                            </tr>

                            <tr>
                                {this.state.modified === false ?
                                    <th>회의 내용</th>
                                    :
                                    <th>회의 내용<span style={{color:'#ff0000',marginLeft:5}}>*</span></th>
                                }

                                {this.state.modified === false?
                                    <td colSpan={3} className={classes.contentsBox1}>
                                        1. 회의 안건<br/>
                                        - 내용
                                    </td>
                                    :
                                    <td colSpan={3} className={classes.contentsBox1}>
                                        <TextareaAutosize
                                            rows={16}
                                            rowsMax={16}
                                            aria-label="maximum height"
                                            placeholder=" "
                                            className={classes.textareaStyle}
                                        />
                                    </td>
                                }
                            </tr>

                            <tr>
                                {this.state.modified === false ?
                                    <th>확정 내용</th>
                                    :
                                    <th>확정 내용<span style={{color:'#ff0000',marginLeft:5}}>*</span></th>
                                }

                                {this.state.modified === false?
                                    <td colSpan={3} className={classes.contentsBox2}>
                                        - 내용
                                    </td>
                                    :
                                    <td colSpan={3} className={classes.contentsBox2}>
                                        <TextareaAutosize
                                            rows={8}
                                            rowsMax={8}
                                            aria-label="maximum height"
                                            placeholder=" "
                                            className={classes.textareaStyle}
                                        />
                                    </td>
                                }
                            </tr>

                            <tr>
                                {this.state.modified === false ?
                                    <th>다음 회의 일정</th>
                                    :
                                    <th>다음 회의 일정<span style={{color:'#ff0000',marginLeft:5}}>*</span></th>
                                }

                                {this.state.modified === false?
                                    <td colSpan={3}>2021년 2월 27일 03:00 PM</td>
                                    :
                                    <td colSpan={3} style={{paddingTop:20,paddingBottom:20}}>
                                        <SelectSchedule/>
                                    </td>
                                }
                            </tr>
                        </tbody>
                    </table>

                    {/* 필수입력란에 입력 안했을때*/}
                    {this.state.modified === true &&
                        <Box display='flex' pt={2}>
                            <WarningIcon/>
                            <Typography className={classes.warningText}>회의 안건을 입력해주세요</Typography>
                        </Box>
                    }

                    <Box display="flex" justifyContent='flex-end' pt={8} pb={8}>
                        {this.state.modified === false?

                            <Box display="flex">
                                {/* 팀원들만*/}
                                {/*<Button className={classes.btnStyle}>확인</Button>*/}

                                {/* 회의록 작성자만 */}
                                <Button className={classes.btnStyle1} ><FormattedMessage id="cancel"/></Button>
                                <Button className={classes.btnStyle} onClick={this.handleClick}><FormattedMessage id="modify"/></Button>
                            </Box>
                            :
                            <Box display="flex">
                                <Button className={classes.btnStyle1} ><FormattedMessage id="cancel"/></Button>
                                <Button className={classes.btnStyle} ><FormattedMessage id="modify"/></Button>
                            </Box>
                        }
                    </Box>
                </Grid>
            </div>
        );
    }
}

export default withStyles(style)(MeetingMinutes);
