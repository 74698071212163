import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Box} from "@material-ui/core";
import {TimelineItem} from "@material-ui/lab";
import TimeLineItemContent from "./TimelineItemContent";
import {inject, observer} from "mobx-react";
import TimelineItemOppositeContent from "./TimelineItemOppositeContent";
import TimeLineNoSchedule from "./TimeLineNoSchedule";
import {DATE_UTIL} from "../../../common/util/date.util";
import * as dayjs from "dayjs";
import {withRouter} from "react-router-dom";

const style = theme => ({
    root:{
        marginTop:50,
        "& .MuiTimeline-root":{
            paddingLeft:40,
            paddingRight:0
        },
        "& .MuiTimelineOppositeContent-root":{
            flex:0,
            padding:0,
            margin:0,
        },
        "& .MuiTimelineContent-root":{
            paddingTop:0,
            paddingLeft:0
        },
    },
});

@inject('timelineStore')
@observer
class TimeLineItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isExpand: false,
        }
    };

    handleClickExpand = event => {
        const {timelineStore} = this.props;

        timelineStore.closeAllTimelinePanel();
        event.stopPropagation();
        this.setState({isExpand: !this.state.isExpand});
    };

    componentDidMount() {
        const {timelineStore} = this.props;

        if (timelineStore.isFirstLoad && timelineStore.roomListGroupByStartTime.length > 0) {
            const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.FULL_FORMAT);

            let targetElementById = '';
            let el = undefined;

            if (timelineStore.roomListGroupByStartTime.length === 0) {
                targetElementById = "0";
                el = document.getElementById(targetElementById);
            } else if (timelineStore.roomListGroupByStartTime.length === 1) {
                targetElementById = timelineStore.roomListGroupByStartTime[0].roomList[0].id;
                el = document.getElementById(targetElementById);
            } else {
                for(let i = 0; i < timelineStore.roomListGroupByStartTime.length; i++) {
                    if (dayjs(currentDatetime).isSameOrBefore(timelineStore.roomListGroupByStartTime[i].startDatetime)) {
                        if (i === 0) {
                            targetElementById = timelineStore.roomListGroupByStartTime[i].roomList[0].id;
                        } else {
                            targetElementById = timelineStore.roomListGroupByStartTime[i - 1].roomList[0].id;
                        }
                        el = document.getElementById(targetElementById);
                        break;
                    }
                }
            }

            if (el) {
                if (targetElementById === "0" || timelineStore.roomListGroupByStartTime.length === 1) {
                    el.scrollIntoView({block: "start", behavior: 'smooth'});
                } else {
                    el.scrollIntoView({block: "start", behavior: 'smooth'});
                    const targetElement = document.getElementById('timelineList');
                    if (targetElement) {
                        const scrollTop = targetElement.scrollTop;
                        targetElement.scrollTo({top: scrollTop + 200, behavior: 'smooth'})
                    }
                }
                timelineStore.changeIsFirstLoad(false);
            }
        } else if (timelineStore.scrollTop !== 0) {
            const targetElement = document.getElementById('timelineList');
            if (targetElement) {
                targetElement.scrollTo(0, timelineStore.scrollTop);
            }
        }
    };

    render() {
        const { isFirst, history, roomData, isFirstByDate, isLast } = this.props;

        return (
            <TimelineItem style={isLast ? {marginBottom:40, paddingBottom: (window.innerHeight - 350) - 210} : {marginBottom:40}}>
                <Box display='flex' flexDirection='column' justifyalign={'flex-start'} aligntext={'flex-start'} mb={2} style={{width: '100%'}}>
                    {roomData.roomList.length > 0 ?
                        this.state.isExpand ?
                                roomData.roomList.map((r, i) =>
                                    <div id={r.id} key={i}>
                                        <Box display='flex' flexDirection='row' justifyalign={'flex-start'} aligntext={'flex-start'} style={{width: '100%'}} mt={2} mb={2}>
                                            <TimelineItemOppositeContent
                                                room={r}
                                                isFirst={i === 0}
                                                isFirstByDate={isFirstByDate}
                                            />
                                            <TimeLineItemContent
                                                key={i}
                                                room={r}
                                                isFirst={i === 0}
                                                isExpand={this.state.isExpand}
                                                isMulti={false}
                                                handleClickExpand={this.handleClickExpand}
                                            />
                                        </Box>
                                    </div>
                                )
                            :
                            roomData.roomList[0].id !== 0 ?
                                <div id={roomData.roomList[0].id}>
                                    <Box display='flex' flexDirection='row' justifyalign={'flex-start'} aligntext={'flex-start'} style={{width: '100%'}} mt={2} mb={2}>
                                        <TimelineItemOppositeContent
                                            history={history}
                                            room={roomData.roomList[0]}
                                            isFirst={isFirst}
                                            isFirstByDate={isFirstByDate}
                                        />
                                        <TimeLineItemContent
                                            room={roomData.roomList[0]}
                                            isFirst={isFirst}
                                            isExpand={this.state.isExpand}
                                            isMulti={roomData.roomList.length > 1}
                                            roomCount={roomData.roomList.length}
                                            handleClickExpand={this.handleClickExpand}
                                        />
                                    </Box>
                                </div>
                                :
                                <TimeLineNoSchedule room={roomData.roomList[0]}/>
                        :
                        null
                    }
                </Box>
            </TimelineItem>
        );
    }
}

export default withRouter(withStyles(style)(TimeLineItem));
