import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {
    Typography,
    Box,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import {ReactComponent as UnCheckedBox} from "../../../common/images/UnCheckedBox.svg";
import {ReactComponent as CheckedBox} from "../../../common/images/CheckedBox.svg";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root: {},
    labelText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        fontWeight: 600,
        color: '#0d0d0d',
        width: 180,
    },
    checkBox: {
        width: '100%',
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#0d0d0d',
            fontWeight: 600
        }
    },
    guestCheckText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#333',
    },
    inputStyle1: {
        width: '100%',
        padding: 10,
        borderRadius: 6,
        background: '#f2f3f7',
        border: 0,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        margin: '22px 0 9px',
        "&:focus": {
            outline: 'none !important'
        }
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 12,
        color: '#666666'
    },
});

@inject('roomStore')
@observer
class GuestParticipation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            guestParticipation: false,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.didMountRoomInfo !== undefined) {
            if ((this.props.didMountRoomInfo !== prevProps.didMountRoomInfo) && (this.props.roomStore.roomInfo.guestNum !== 0)) {
                this.setState({guestParticipation: true})
            }
        }
    }

    componentWillUnmount() {
        this.setState({guestParticipation: false})
    }

    handleChecked = (e) => {
        this.setState({
            guestParticipation: e.target.checked,
        });
        this.props.roomStore.changeGuestNum("")
    };

    changeGuestNum = (value) => {
        if (value <= this.props.roomStore.roomInfo.maxUsers || this.props.roomStore.roomInfo.maxUsers === 0) {
            this.props.roomStore.changeGuestNum(value)
        } else {
            this.props.roomStore.changeGuestNum("")
        }
    }

    render() {
        const {classes, intl, roomStore} = this.props;
        return (
            <div>
                <Box display='flex' alignItems='center' pb={4}>
                    <Typography className={classes.labelText}><FormattedMessage id="security"/></Typography>
                    <Box display='flex' flexDirection="column" className={classes.checkBox}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    icon={<UnCheckedBox/>}
                                    checkedIcon={<CheckedBox/>}
                                    checked={this.state.guestParticipation}
                                    onChange={this.handleChecked}
                                />
                            }
                            label={intl.formatMessage({id: "allow_guest"})}
                            style={{width: 200}}
                        />
                    </Box>
                </Box>
                {this.state.guestParticipation &&
                <Box style={{paddingLeft: 150, width: '100%'}} pb={4}>
                    <Typography className={classes.guestCheckText}><FormattedMessage id="guest_number"/><span
                        style={{color: '#ff0000', marginLeft: 5}}>*</span></Typography>

                    <input
                        name="Numbers"
                        type="text"
                        id="Numbers"
                        value={roomStore.roomInfo.guestNum}
                        placeholder={
                            roomStore.roomInfo.maxUsers > 0 ?
                            intl.formatMessage({id: "msg.insert_room_guest_number"})+ " ("+ intl.formatMessage({id: "max_user_count"}) + roomStore.roomInfo.maxUsers + intl.formatMessage({id: "person"})+")"
                            :
                            intl.formatMessage({id: "msg.insert_room_guest_number"})}
                        className={classes.inputStyle1}
                        onChange={(e) => this.changeGuestNum(e.target.value)}
                    />
                </Box>
                }
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(GuestParticipation));