import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Grid,
    Button,
    Typography,
    MenuItem, Select, FormControl
} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import SurveySingleChoice from "./SurveySingleChoice";
import SurveyParagraph from "./SurveyParagraph";
import SurveyMultipleChoice from "./SurveyMultipleChoice";
import SurveyShortAnswer from "./SurveyShortAnswer";
import SurveyRating from "./SurveyRating";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {DATE_UTIL} from "../../../common/util/date.util";

const style = theme => ({
    root:{
        width:'100%',
        paddingTop: 100,
        paddingLeft: 160,
    },
    textStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 12,
        color: '#0d0d0d',
        fontWeight: 600,
    },
    titleStyle: {
        fontSize: 24,
        fontWeight: 800,
    },
    formControlBox: {
        width: '100%',
        background: '#fff',
        border: 0,
        borderRadius: 5,

        "& .MuiSelect-select": {
            background: 'transparent',
        },
        "& .MuiOutlinedInput-input": {
            padding: '11px 6px',
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #b7bcd6',
        },
        "& .MuiSelect-select:focus": {
            background: 'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid #b7bcd6',
        }
    },
    selectBox: {
        width: 185,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#000',
        fontWeight: 500,
        paddingRight:25,
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#404040',
        fontWeight: 500,
        "&:hover":{
            background:'#e6e7e8'
        }
    },
    menuList: {
        padding:0,
    },
    btnStyle: {
        padding: '9px 38px',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        fontWeight: 800,
        color: '#fff',
        marginRight: 10,
        borderRadius: 7,
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    typoStyle1: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        fontWeight: 600,
    },
    typoStyle2: {
        fontSize: 12,
        color: '#a3a8af'
    },
});

@inject('topBarStore', 'statisticsStore', 'progressStore')
@observer
class UserSurveyDetail extends Component {
    componentDidMount() {
        const { topBarStore, statisticsStore, progressStore } = this.props;

        statisticsStore.getUserSurveyStatistics(this.props.match.params.roomId, this.props.match.params.userId);
        if (window.location.pathname === `/rooms/${this.props.match.params.roomId}/users/${this.props.match.params.userId}/statistics`) {
            topBarStore.setIsSearchBarRender(false);
        }
        progressStore.setIsLoading(statisticsStore.getIsLoading);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {statisticsStore, progressStore} = this.props;
        progressStore.setIsLoading(statisticsStore.getIsLoading);
    };

    getItem = (item) => {
        switch (item.type) {
            case 'SingleChoice' :
                return (
                    <SurveySingleChoice item={item}/>
                )
            case 'MultipleChoice' :
                return (
                    <SurveyMultipleChoice item={item}/>
                )
            case 'ShortAnswer' :
                return (
                    <SurveyShortAnswer item={item}/>
                )
            case 'Rating' :
                return (
                    <SurveyRating item={item} />
                )
            default :
                return (
                    <SurveyParagraph item={item} />
                )
        }
    };

    render() {
        const { classes, history, intl, statisticsStore } = this.props;
        const { selectedUserSurvey } = this.props.statisticsStore;

        return (
            <div className={classes.root}>
                <Grid item sm={11} md={10} lg={7} xl={5}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' style={{margin:'60px 0 20px'}}>
                        <Typography className={classes.titleStyle}><FormattedMessage id="survey"/></Typography>
                        {(Object.keys(statisticsStore.userSurveyStatistics).length !== 0 && statisticsStore.userSurveyStatistics.userSurveyList.length > 0 && !statisticsStore.getIsLoading) &&
                            <Box display='flex' justifyContent='space-between' alignItems='center'>
                                <FormControl variant={'outlined'} className={classes.formControlBox}>
                                    <Select
                                        value={selectedUserSurvey}
                                        onChange={(event) => statisticsStore.changeSelectedUserSurvey(event.target.value)}
                                        className={clsx(classes.textStyle, classes.selectBox)}
                                        MenuProps={{
                                            classes: {
                                                list: classes.menuList
                                            }
                                        }}
                                    >
                                        {
                                            statisticsStore.userSurveyStatistics.userSurveyList.map((survey, i) => (
                                                <MenuItem key={i} value={survey} className={clsx(classes.textStyle, classes.optionStyle)}>{survey.survey.title}</MenuItem >
                                            ))
                                        }
                                    </Select>
                                </FormControl>
                            </Box>
                        }
                    </Box>
                    {(Object.keys(statisticsStore.userSurveyStatistics).length !== 0 && statisticsStore.userSurveyStatistics.userSurveyList.length > 0 && !statisticsStore.getIsLoading) &&
                        <>
                            <Box display="flex" alignItems='center'>
                                <Typography className={classes.textStyle}>
                                    <span style={{color:'#a3a8af', marginRight: 8}}><FormattedMessage id="respondent"/></span>{statisticsStore.userSurveyStatistics.userName}
                                </Typography>

                                <span style={{color:'#a3a8af', margin:'0 8px'}}>|</span>

                                <Typography className={classes.textStyle}>
                                    <span style={{color:'#a3a8af', marginRight: 8}}><FormattedMessage id="submitted_time"/></span>{DATE_UTIL.getTimeZoneDate(selectedUserSurvey.submittedDatetime, `YYYY${intl.formatMessage({id: "calendar_year"})} MMM D${intl.formatMessage({id: "calendar_day"})} hh:mm A`)}
                                </Typography>
                            </Box>
                            <Box mt={8}>
                                <Box display='flex' justifyContent='space-between' alignItems='center'>
                                    <Typography className={classes.textStyle} style={{fontSize:18}}>{selectedUserSurvey.survey.title}</Typography>
                                    <Typography className={clsx(classes.typoStyle1, classes.typoStyle2)} style={{color: '#ff0000'}}>* <FormattedMessage id="required_items"/></Typography>
                                </Box>
                                <Typography className={classes.textStyle} style={{fontSize:14, marginTop: 50}}> {selectedUserSurvey.survey.descriptions}</Typography>
                            </Box>
                            <Box mt={8}>
                                {selectedUserSurvey.survey.items.length > 0 &&
                                    statisticsStore.getSortedUserSurveyItems.map((item, i) => (
                                        <div key={i}>
                                            {this.getItem(item)}
                                        </div>
                                    ))
                                }
                            </Box>
                        </>
                    }
                    <Box display='flex' justifyContent='flex-end' mb={2}>
                        <Button className={classes.btnStyle} onClick={() => history.push(`/rooms/${this.props.match.params.roomId}/statistics`)}>
                            <FormattedMessage id="ok"/>
                        </Button>
                    </Box>
                </Grid>

            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(UserSurveyDetail)));