import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Tooltip,
    Paper,
    Typography,
    Box,
    IconButton
} from "@material-ui/core";
import { ReactComponent as LeaderIcon} from "../../../common/images/LeaderIcon.svg"
import TeamMembersSideBar from "../../../components/TeamMembersSideBar";
import {inject, observer} from "mobx-react";
import {ReactComponent as MyTeamSettingIcon} from "../../../common/images/MyTeamSettingIcon.svg";
import {withRouter} from "react-router-dom";
import {ManagementUrlCategoryType} from "../../../stores/OrgStore";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        marginTop:40,
        paddingBottom:20,
        marginBottom:30,

    },
    paperBox:{
        position:'relative',
        borderRadius:16,
        padding:20,
        height: '100%',
        border:'1px solid #c0c2c3',
        overflow:'hidden',
    },
    titleStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:18,
        fontWeight:600,
        color:'#0d0d0d',
        textIndent:20,
        borderBottom:'1px solid #c0c2c3',
        paddingBottom:10,
        marginBottom:20
    },
    paperInBox:{
        background:'#f5f5f5',
        borderRadius:16,
        padding:'21px 13px 53px'
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#0d0d0d',
        textAlign:'left',
    },
    paperBoxScroll:{
        overflowY:'scroll',
        paddingRight:10,
        height:'93%',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    paperInBox1:{
        background:'#f5f5f5',
        borderRadius:16,
        padding:20,
        marginBottom: 20,
    },
    textStyle1:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#0d0d0d',
    },
    iconBtn:{
        padding:0,
        "&:hover":{
            background:'transparent'
        }
    },
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        padding:'4px 4px',
        border:'0.3px solid #000000',
        background:'#fffff5',
        fontFamily:'NanumSquareRoundOTF',
        fontSize: 11,
        color:'#000',
        borderRadius:'0',
        marginLeft:5,
        marginTop:5
    },
}))(Tooltip);

@inject('authStore', 'userLoungeStore', 'timelineStore', 'teamStore', 'progressStore', 'orgStore')
@observer
class Team extends Component {
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userLoungeStore, timelineStore, progressStore} = this.props;
        progressStore.setIsLoading(userLoungeStore.getIsLoading || timelineStore.getIsLoading)
    };

    getTeamLeaderList = (team) => {
        let leaderNameList = [];
        const leaderList = team.members.filter(m => (m.type === 'Leader'));
        leaderList.forEach(l => {
            leaderNameList.push(l.name + (l.title !== null ? (' ' + l.title) : ''));
        });
        return leaderNameList.join(', ');
    };

    getIsLeader = (team) => {
        const {authStore} = this.props;

        const leaderList = team.members.filter(m => m.type === 'Leader');
        return !!leaderList.find(l => l.userId === authStore.loginUser.id);
    };

    moveManagement = (e, team) => {
        e.stopPropagation();
        this.props.teamStore.changeSelectTeamByLeader(team);
        this.props.history.push(`/management/${ManagementUrlCategoryType.Team}`);
    };

    handleTeamClick = (teamId) => {
        const {history} = this.props;
        history.push(`/teams/${teamId}`);
    };

    render() {
        const { classes, intl, userLoungeStore, orgStore } = this.props;

        return (
            <div className={classes.root} style={this.props.authority ? {height : (window.innerHeight - 175) / 2} : {height : window.innerHeight - 175}}>
                {/*<Paper elevation={0} className={classes.paperBox} style={{height: window.innerHeight - 160}}>*/}
                <Paper elevation={0} className={classes.paperBox}>
                    <Typography className={classes.titleStyle}>
                        <FormattedMessage id="affiliation_team"/>
                    </Typography>
                    <Box className={classes.paperBoxScroll}>

                    {
                        userLoungeStore.teamList.length > 0 ?
                            userLoungeStore.teamList.map((team) => {
                                return (
                                    <div key={team.id} style={{cursor: 'pointer'}} onClick={() => {this.handleTeamClick(team.id);}}>
                                        <Paper elevation={0} className={classes.paperInBox1} style={{overflowX: 'auto'}}>
                                            <Typography noWrap className={classes.textStyle1} style={{fontSize:14,fontWeight:800, maxWidth: 800}}>{team.name}</Typography>
                                            <Typography noWrap className={classes.textStyle1} style={{fontSize:12, margin:'10px 0 5px',fontWeight:600, maxWidth: 800}}>{team.comment}</Typography>
                                            <Box display="flex" alignItems="center" pb={2}>
                                                <LeaderIcon style={{marginRight:5,width:12,height:12}}/>
                                                <Typography noWrap className={classes.textStyle1} style={{fontSize:11}}><FormattedMessage id="team_leader"/> : {this.getTeamLeaderList(team)}</Typography>
                                            </Box>
                                            <Box display="flex" flexDirection={"row"} justifyContent="space-between" alignItems='center'>
                                                <Box display="flex" flexBasis={0}>
                                                    <TeamMembersSideBar members={team.members} isTeam={true} configs={orgStore.orgConfigs}/>
                                                </Box>
                                                {
                                                    this.getIsLeader(team) &&
                                                    <Box display="flex" flexBasis={1}>
                                                        <LightTooltip title={intl.formatMessage({id: "team_management"})} placement="bottom">
                                                            <IconButton onClick={(e)=> this.moveManagement(e, team)} className={classes.iconBtn}>
                                                                <MyTeamSettingIcon/>
                                                            </IconButton>
                                                        </LightTooltip>
                                                    </Box>
                                                }
                                            </Box>
                                        </Paper>
                                    </div>
                                );
                            })
                            :
                            <Paper elevation={0} className={classes.paperInBox}>
                                <Typography className={classes.textStyle}><FormattedMessage id="msg.no_team"/></Typography>
                            </Paper>
                    }
                    </Box>
                    <Box style={{width:'100%', height:30, position:'absolute', zIndex:100,background:'linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff)',bottom:0}} />
                </Paper>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(Team)));