import React, {useEffect} from 'react';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import {
    Typography,
    Box,
    Button
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {MobXProviderContext} from "mobx-react";
import ServiceCenterDialog from "./ServiceCenterDialog";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
    root:{
        background:'#fff',
        paddingTop:70,
        marginLeft:90,
        width:'100%',
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:42,
        textAlign:'center',
        paddingTop:50,
        marginBottom:50,
    },
    moreIcon:{
        color:'#c0c2c3'
    },
    accrdionText1:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:16,
        fontWeight:600,
    },
    accrdionText2:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:15,
        color:'#4f5660'
    },
    buttonBox:{
        width:'80%',
        margin:'0 auto 30px',
        display:'flex',
        justifyContent:'flex-end',
    },
    buttonStyle:{
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        borderRadius:7,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#fff',
        padding:'13px 25px',
        "&:hover":{
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    textBox:{
        background:'#fbfbfb',
        textAlign:'center',
        marginTop:100,
        padding:'80px 0',
    },
    textStyle1:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:32,
        fontWeight:'bold',
    },
    textStyle2:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:'bold',
        margin:'25px 0'
    }
}));

const Accordion = withStyles({
    root: {
        width:'80%',
        borderTop: '1px solid rgba(0, 0, 0, .125)',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        background:'#fbfbfb',
        margin:'0 auto',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
    root: {
        marginBottom: -1,
        background:'#fff',
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
            paddingBottom:35,
        },
    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiAccordionDetails);

export default function ServiceCenter() {
    const classes = useStyles();
    const intl = useIntl();
    const [expanded, setExpanded] = React.useState('panel0');
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const { topBarStore } = React.useContext(MobXProviderContext);

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const handleDownloadClick = () => setDialogOpen(prevState => !prevState);

    useEffect(() => {
        if (window.location.pathname === '/servicecenter')
            topBarStore.setIsSearchBarRender(false);
    });

    return (
        <div className={classes.root}>
            <Typography className={classes.titleText}>
                <FormattedMessage id={"service_center"} />
            </Typography>
            <Box display='flex' justifyContent='space-between' mt={2} className={classes.buttonBox}>
                <Button className={classes.buttonStyle} onClick={handleDownloadClick}>
                    <FormattedMessage id={"download_manual"} />
                </Button>
            </Box>
            <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')} style={{borderTop: '3px solid #000'}}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>}>
                    <Typography className={classes.accrdionText1}>
                        {`Q. ${intl.formatMessage({id: "msg.how_do_sign_up"})}`}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.accrdionText2}>
                        <FormattedMessage id={"msg.answer_example_one"} /><br />
                        <FormattedMessage id={"msg.answer_example_two"} /><br />
                        <FormattedMessage id={"msg.answer_example_three"} />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon} />}>
                    <Typography className={classes.accrdionText1}>
                        {`Q. ${intl.formatMessage({id: "msg.how_do_free_trial"})}`}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.accrdionText2}>
                        <FormattedMessage id={"msg.answer_example_one"} /><br />
                        <FormattedMessage id={"msg.answer_example_two"} /><br />
                        <FormattedMessage id={"msg.answer_example_three"} />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>}>
                    <Typography className={classes.accrdionText1}>
                        {`Q. ${intl.formatMessage({id: "msg.how_much_enterprise"})}`}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.accrdionText2}>
                        <FormattedMessage id={"msg.answer_example_one"} /><br />
                        <FormattedMessage id={"msg.answer_example_two"} /><br />
                        <FormattedMessage id={"msg.answer_example_three"} />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                <AccordionSummary aria-controls="panel4d-content" id="panel4d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>}>
                    <Typography className={classes.accrdionText1}>
                        {`Q. ${intl.formatMessage({id: "msg.how_do_subscription"})}`}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.accrdionText2}>
                        <FormattedMessage id={"msg.answer_example_one"} /><br />
                        <FormattedMessage id={"msg.answer_example_two"} /><br />
                        <FormattedMessage id={"msg.answer_example_three"} />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                <AccordionSummary aria-controls="panel5d-content" id="panel5d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>}>
                    <Typography className={classes.accrdionText1}>
                        {`Q. ${intl.formatMessage({id: "msg.what_is_difference_service_grade"})}`}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.accrdionText2}>
                        <FormattedMessage id={"msg.answer_example_one"} /><br />
                        <FormattedMessage id={"msg.answer_example_two"} /><br />
                        <FormattedMessage id={"msg.answer_example_three"} />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
                <AccordionSummary aria-controls="panel6d-content" id="panel6d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon} />}>
                    <Typography className={classes.accrdionText1}>
                        {`Q. ${intl.formatMessage({id: "msg.how_do_change_my_plan"})}`}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.accrdionText2}>
                        <FormattedMessage id={"msg.answer_example_one"} /><br />
                        <FormattedMessage id={"msg.answer_example_two"} /><br />
                        <FormattedMessage id={"msg.answer_example_three"} />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
                <AccordionSummary aria-controls="panel7d-content" id="panel7d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>}>
                    <Typography className={classes.accrdionText1}>
                        {`Q. ${intl.formatMessage({id: "msg.how_do_cancel_subscription"})}`}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.accrdionText2}>
                        <FormattedMessage id={"msg.answer_example_one"} /><br />
                        <FormattedMessage id={"msg.answer_example_two"} /><br />
                        <FormattedMessage id={"msg.answer_example_three"} />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion square expanded={expanded === 'panel8'} onChange={handleChange('panel8')}>
                <AccordionSummary aria-controls="panel8d-content" id="panel8d-header" expandIcon={<ExpandMoreIcon className={classes.moreIcon}/>}>
                    <Typography className={classes.accrdionText1}>
                        {`Q. ${intl.formatMessage({id: "msg.how_do_cancel_membership"})}`}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography className={classes.accrdionText2}>
                        <FormattedMessage id={"msg.answer_example_one"} /><br />
                        <FormattedMessage id={"msg.answer_example_two"} /><br />
                        <FormattedMessage id={"msg.answer_example_three"} />
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Box className={classes.textBox}>
                <Typography className={classes.textStyle1}>
                    <FormattedMessage id={"msg.have_more_question"} />
                </Typography>
                <Typography className={classes.textStyle2}>
                    <FormattedMessage id={"msg.contact_us"} />
                </Typography>
                <Button className={classes.buttonStyle} style={{padding:'13px 70px'}}>
                    <FormattedMessage id={"get_support"} />
                </Button>
            </Box>
            <ServiceCenterDialog
                open={dialogOpen}
                title={intl.formatMessage({id: 'notification'})}
                msg={intl.formatMessage({id: 'msg.coming_up_service'})}
                changeDialogOpen={setDialogOpen}
            />
        </div>
    );
}