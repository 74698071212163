import {makeAutoObservable} from "mobx";
import axios from "axios";
import * as dayjs from "dayjs";
import {DATE_UTIL} from "../common/util/date.util";

export default class TimelineStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    };

    cancelTokenSource = null;
    isRoomListLoading = false;
    isSendMailLoading = false;
    isFirstLoad = true;
    roomListGroupByStartTime = [];
    downPage = 1;
    upPage = 0;
    timelineDate = '';
    startDatetimeList = [];
    timelineOpenList = []
    timelineLinkViewOpenList = [];
    dateTimelineOpenList = []
    dateTimelineLinkViewOpenList = [];
    roomListResult = [];
    calendarData = [];
    timelineType = 0;
    openDeleteDialog = false;
    openMoreDeleteDialog = false;
    searchKeyword = '';
    scrollTop = 0;
    calendarSelectedDay = '';
    calendarWeekOfStartDay = '';
    calendarWeekOfEndDay = '';
    topRoomId = undefined;

    init = () => {
        this.isRoomListLoading = false;
        this.isSendMailLoading = false;
        this.isFirstLoad = true;
        this.roomListGroupByStartTime = [];
        this.downPage = 1;
        this.upPage = 0;
        this.timelineDate = '';
        this.startDatetimeList = [];
        this.timelineOpenList = []
        this.timelineLinkViewOpenList = [];
        this.dateTimelineOpenList = []
        this.dateTimelineLinkViewOpenList = [];
        this.roomListResult = [];
        this.calendarData = [];
        this.timelineType = 0;
        this.openDeleteDialog = false;
        this.openMoreDeleteDialog = false;
        this.searchKeyword = '';
        this.scrollTop = 0;

        const selectedWeek = DATE_UTIL.getWeek();
        this.calendarSelectedDay = selectedWeek.selectedDay;
        this.calendarWeekOfStartDay = selectedWeek.weekOfStartDay;
        this.calendarWeekOfEndDay = selectedWeek.weekOfEndDay;
        this.topRoomId = undefined;
    };

    resetData = () => {
        this.isFirstLoad = true;
        this.roomListGroupByStartTime = [];
        this.downPage = 1;
        this.upPage = 0;
        this.timelineDate = '';
        this.startDatetimeList = [];
        this.timelineOpenList = []
        this.timelineLinkViewOpenList = [];
        this.dateTimelineOpenList = []
        this.dateTimelineLinkViewOpenList = [];
        this.roomListResult = [];
        this.calendarData = [];
        this.openDeleteDialog = false;
        this.openMoreDeleteDialog = false;
        this.scrollTop = 0;

        const selectedWeek = DATE_UTIL.getWeek();
        this.calendarSelectedDay = selectedWeek.selectedDay;
        this.calendarWeekOfStartDay = selectedWeek.weekOfStartDay;
        this.calendarWeekOfEndDay = selectedWeek.weekOfEndDay;
        this.topRoomId = undefined;
    };

    get getIsLoading() {
        return this.isRoomListLoading || this.isSendMailLoading;
    };

    setCancelTokenSource = cancelTokenSource => this.cancelTokenSource = cancelTokenSource;

    requestCancel() {
        if(this.cancelTokenSource !== null) {
            this.cancelTokenSource.cancel();
            this.setCancelTokenSource(null);
        }
    };

    resetPage = () => {
        this.downPage = 1;
        this.upPage = 0;
    };

    onChangeCalendarDate = (teamId, userId, value) => {
        const selectedWeek = DATE_UTIL.getWeek(value);
        this.getRoomListByCalendar(teamId, userId,
                                   dayjs(selectedWeek.weekOfStartDay).tz(DATE_UTIL.getTimeZone(), true).tz('UTC').format(DATE_UTIL.FORMAT.FULL_FORMAT),
                                   dayjs(selectedWeek.weekOfEndDay).tz(DATE_UTIL.getTimeZone(), true).tz('UTC').format(DATE_UTIL.FORMAT.FULL_FORMAT));
        this.calendarSelectedDay = selectedWeek.selectedDay;
        this.calendarWeekOfStartDay = selectedWeek.weekOfStartDay;
        this.calendarWeekOfEndDay = selectedWeek.weekOfEndDay;
    };

    changeTopRoomId = (topRoomId) => {
        this.topRoomId = topRoomId;
    };

    changeCalendarSelectedDay = calendarSelectedDay => {
        this.calendarSelectedDay = calendarSelectedDay;
    };

    changeCalendarWeekOfStartDay = calendarWeekOfStartDay => {
        this.calendarWeekOfStartDay = calendarWeekOfStartDay;
    };

    changeCalendarWeekOfEndDay = calendarWeekOfEndDay => {
        this.calendarWeekOfEndDay = calendarWeekOfEndDay;
    };

    changeScrollTop = (scrollTop) => {
        this.scrollTop = scrollTop;
    };

    changeSearchKeyword = (keyword) => {
        this.searchKeyword = keyword;
    };

    changeOpenDeleteDialog = (open) => {
        this.openDeleteDialog = open;
    };

    changeOpenMoreDeleteDialog = (open) => {
        this.openMoreDeleteDialog = open;
    };

    changeTimelineType = (type) => {
        this.timelineType = type;
    };

    changeRoomListGroupByStartTime = (roomListGroupByStartTime) => {
        this.roomListGroupByStartTime = roomListGroupByStartTime;
    };

    changeCalendarData = (calendarData) => {
        this.calendarData = calendarData;
    };

    changeStartDatetimeList = (startDatetimeList) => {
        this.startDatetimeList = startDatetimeList;
    };

    changeIsFirstLoad = (isFirstLoad) => {
        this.isFirstLoad = isFirstLoad;
    };

    changeTimelineDate = (date) => {
        this.timelineDate = date;
    };

    getTimelineOpen = (roomId) => {
        const timelineOpen = this.timelineOpenList.find(t => t.id === roomId);
        return timelineOpen ? timelineOpen.value : false;
    };

    getTimelineLinkViewOpen = (roomId) => {
        const timelineLinkViewOpen = this.timelineLinkViewOpenList.find(t => t.id === roomId);
        return timelineLinkViewOpen ? timelineLinkViewOpen.value : false;
    };

    getDateTimelineOpen = (roomId) => {
        const dateTimelineOpen = this.dateTimelineOpenList.find(t => t.id === roomId);
        return dateTimelineOpen ? dateTimelineOpen.value : false;
    };

    getDateTimelineLinkViewOpen = (roomId) => {
        const dateTimelineLinkViewOpen = this.dateTimelineLinkViewOpenList.find(t => t.id === roomId);
        return dateTimelineLinkViewOpen ? dateTimelineLinkViewOpen.value : false;
    };

    openTimelineDetail = (roomId) => {
        this.closeAllTimelinePanel();
        const target = this.timelineOpenList.find(t => t.id === roomId);
        if (target) {
            target.value = true;
        }
    };

    openTimelineLinkView = (roomId) => {
        this.closeAllTimelinePanel();
        const target = this.timelineLinkViewOpenList.find(t => t.id === roomId);
        if (target) {
            target.value = true;
        }
    };

    openDateTimelineDetail = (roomId) => {
        this.closeAllDateTimelinePanel();
        const target = this.dateTimelineOpenList.find(t => t.id === roomId);
        if (target) {
            target.value = true;
        }
    };

    openDateTimelineLinkView = (roomId) => {
        this.closeAllDateTimelinePanel();
        const target = this.dateTimelineLinkViewOpenList.find(t => t.id === roomId);
        if (target) {
            target.value = true;
        }
    };

    closeTimelinePanel = (roomId) => {
        this.closeTimelineDetail(roomId);
        this.closeTimelineLinkView(roomId);
    };

    closeDateTimelinePanel = (roomId) => {
        this.closeDateTimelineDetail(roomId);
        this.closeDateTimelineLinkView(roomId);
    };

    closeTimelineDetail = (roomId) => {
        this.timelineOpenList.find(t => t.id === roomId).value = false;
    };

    closeTimelineLinkView = (roomId) => {
        this.timelineLinkViewOpenList.find(t => t.id === roomId).value = false;
    };

    closeDateTimelineDetail = (roomId) => {
        this.dateTimelineOpenList.find(t => t.id === roomId).value = false;
    };

    closeDateTimelineLinkView = (roomId) => {
        this.dateTimelineLinkViewOpenList.find(t => t.id === roomId).value = false;
    };

    closeAllTimelinePanel = () => {
        this.closeAllTimelineDetail();
        this.closeAllTimelineLinkView();
    };

    closeAllDateTimelinePanel = () => {
        this.closeAllDateTimelineDetail();
        this.closeAllDateTimelineLinkView();
    };

    closeAllTimelineDetail = () => {
        this.timelineOpenList = this.timelineOpenList.map(t => ({
            id: t.id,
            value: false
        }));
    };

    closeAllTimelineLinkView = () => {
        this.timelineLinkViewOpenList = this.timelineLinkViewOpenList.map(t => ({
            id: t.id,
            value: false
        }));
    };

    closeAllDateTimelineDetail = () => {
        this.dateTimelineOpenList = this.dateTimelineOpenList.map(t => ({
            id: t.id,
            value: false
        }));
    };

    closeAllDateTimelineLinkView = () => {
        this.dateTimelineLinkViewOpenList = this.dateTimelineLinkViewOpenList.map(t => ({
            id: t.id,
            value: false
        }));
    };


    getRoomListGroupByStartTime = function* getRoomListGroupByStartTime(teamId, userId, direction, page, topRoomId) {
        console.log("getRoomListGroupByStartTime Start");
        this.isRoomListLoading = true;
        this.roomListResult = [];
        try {
            this.requestCancel();
            this.setCancelTokenSource(axios.CancelToken.source());
            let url = this.serverContextPath + `/api/v1/rooms/timeline?team-id=${teamId !== undefined ? teamId : 0}&user-id=${userId}&direction=${direction}`;
            if(page !== 0) {
                url = url + `&page=${page}`;
            } else {
                if (direction === 'future') {
                    this.downPage = this.downPage + 1;
                    url = url + `&page=${this.downPage}`;
                } else {
                    this.upPage = this.upPage + 1;
                    url = url + `&page=${this.upPage}`;
                }
            }
            if (this.searchKeyword !== '') {
                url = url + `&search-keyword=${this.searchKeyword}`;
            }

            const response = yield axios.get(url, {cancelToken: this.cancelTokenSource.token});

            this.getTimelineDataSuccessAction(page, response.data);
            if ((topRoomId !== undefined) && (response.data.length > 0)) {
                this.topRoomId = topRoomId;
            }

            console.log("getRoomListGroupByStartTime Done");
        } catch(e) {
            console.log("getRoomListGroupByStartTime Fail : >> ", e);
        } finally {
            this.isRoomListLoading = false;
            this.roomListResult.forEach(roomData => {
                roomData.roomList.forEach(room => {
                    this.getRoomMemberAvatarList(room, 'timeline');
                })
            });
        }
    };

    getRoomListByCalendar = function* getRoomListByCalendar(teamId, userId, startDatetime, endDatetime) {
        console.log("getRoomListByCalendar Start");
        this.isRoomListLoading = true;
        this.calendarData = [];
        try {
            this.requestCancel();
            this.setCancelTokenSource(axios.CancelToken.source());
            let url = this.serverContextPath + `/api/v1/rooms/calendar?team-id=${teamId !== undefined ? teamId : 0}&user-id=${userId}&start-datetime=${startDatetime}&end-datetime=${endDatetime}`;
            if (this.searchKeyword !== '') {
                url = url + `&search-keyword=${this.searchKeyword}`;
            }
            const response = yield axios.get(url, {cancelToken: this.cancelTokenSource.token});

            response.data.sort((a, b) => a.startDatetime < b.startDatetime ? -1 : a.startDatetime > b.startDatetime ? 1 : 0);
            this.getCalendarDataSuccessAction(startDatetime, response.data);

            console.log("getRoomListByCalendar Done");
        } catch(e) {
            console.log("getRoomListByCalendar Fail : >> ", e);
        } finally {
            this.isRoomListLoading = false;
            this.calendarData.forEach(roomData => {
                roomData.roomList.forEach(room => {
                    this.getRoomMemberAvatarList(room, 'calendar');
                });
            });
        }
    };

    getRoomMemberAvatarList = function* getRoomMemberAvatarList(room, type) {
        try {
            const targetData = type === 'timeline' ? this.roomListGroupByStartTime : this.calendarData;
            const response = yield axios.get(this.serverContextPath + `/api/v1/rooms/${room.id}/members/avatars`);

            const roomMembers = room.members.map(m => ({...m}));
            for(let i = 0; i < roomMembers.length; i++) {
                for (let j = 0; j < response.data.length; j++) {
                    if(roomMembers[i].userId === response.data[j].userId) {
                        roomMembers[i].image = response.data[j].image;
                        break;
                    }
                }
            }
            for(let i = 0; i < targetData.length; i++) {
                for (let j = 0; j < targetData[i].roomList.length; j++) {
                    if(targetData[i].roomList[j].id === room.id) {
                        targetData[i].roomList[j].members = roomMembers;
                        break;
                    }
                }
            }
        } catch(e) {
            console.log("getRoomMemberAvatarList Fail : >> ", e);
        }
    };

    sendRoomEmail = function* sendRoomEmail(intl, roomId, userId, callback) {
        console.log("sendRoomEmail Start");
        this.isSendMailLoading = true;
        try {
            const param = {
                id : userId
            };

            const response = yield axios.post(this.serverContextPath + `/api/v1/rooms/${roomId}/email`, param);

            callback && callback(intl.formatMessage({id: "msg.success_send_email"}));

            if (this.roomListGroupByStartTime.length > 0) {
                for(let i = 0; i < this.roomListGroupByStartTime.length; i++) {
                    const targetRoom = this.roomListGroupByStartTime[i].roomList.find(r => r.id === roomId);
                    if (targetRoom) {
                        targetRoom.sendMailDatetime = response.data;
                        break;
                    }
                }
            }

            if (this.calendarData.length > 0) {
                for(let i = 0; i < this.calendarData.length; i++) {
                    const targetRoom = this.calendarData[i].roomList.find(r => r.id === roomId);
                    if (targetRoom) {
                        targetRoom.sendMailDatetime = response.data;
                        break;
                    }
                }
            }
            console.log("sendRoomEmail Done");
        } catch(e) {
            callback && callback(intl.formatMessage({id: "msg.cannot_send_email"}));
            console.log("sendRoomEmail Fail : >> ", e);
        } finally {
            this.isSendMailLoading = false;
        }
    };

    getTimelineDataSuccessAction = (page, data) => {
        this.convertTimelineDatetimeForTimezone(data);
        this.roomListResult = data.map(r => ({...r}));

        if (page !== 0) {
            this.roomListGroupByStartTime = data;
        } else {
            this.addRoomListGroupByStartTime(data);
        }

        if (this.roomListGroupByStartTime.length === 0) {
            this.addEmptyData();
        }

        this.setIsLastRoom();

        data.forEach(roomData => {
            roomData.roomList.forEach(r => {
                this.timelineOpenList.push({id: r.id, value: false})
                this.timelineLinkViewOpenList.push({id: r.id, value: false})
            })
        });
    };

    getCalendarDataSuccessAction = (startDatetime, data) => {
        const timeZoneHourDiff = dayjs(dayjs().tz('UTC', true).format('YYYY-MM-DD HH')).diff(dayjs(dayjs().tz(DATE_UTIL.getTimeZone()).format('YYYY-MM-DD HH')), 'hour');
        const timeZoneDayDiff = dayjs(dayjs(dayjs(startDatetime).format('YYYY-MM-DD')).tz('UTC', true).subtract(dayjs.duration({'hour' : timeZoneHourDiff})).format('YYYY-MM-DD')).diff(dayjs(dayjs(dayjs(startDatetime).format('YYYY-MM-DD')).tz('UTC', true).format('YYYY-MM-DD')), 'day');

        let dataListGroupByDate = [];
        for (let i = timeZoneDayDiff; i < timeZoneDayDiff + 7; i++) {
            dataListGroupByDate.push({date: dayjs(startDatetime).add(dayjs.duration({'days' : i})).format(DATE_UTIL.FORMAT.DATE_FORMAT), roomList: [], count: 0});
        }

        this.convertCalendarDatetimeForTimezone(data);

        data.forEach(roomData => {
            const targetData = dataListGroupByDate.find(d => d.date === dayjs(roomData.startDatetime).format(DATE_UTIL.FORMAT.DATE_FORMAT))
            if (targetData) {
                targetData.roomList.push(roomData);
                targetData.count = targetData.count + 1;
            }
        });

        this.calendarData = dataListGroupByDate;
        this.calendarData.forEach(roomData => {
            roomData.roomList.forEach(r => {
                this.dateTimelineOpenList.push({id: r.id, value: false})
                this.dateTimelineLinkViewOpenList.push({id: r.id, value: false})
            });
        });
    };

    convertTimelineDatetimeForTimezone = (data) => {
        data.map(d => {
            d.startDatetime = DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(d.startDatetime), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
            return d;
        });

        data.forEach(d => {
            if (d.roomList.length > 0) {
                d.roomList.map(r => {
                    r.startDatetime = DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(r.startDatetime), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
                    r.endDatetime = DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(r.endDatetime), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
                    return r;
                });
            }
        });
    };

    convertCalendarDatetimeForTimezone = (data) => {
        data.map(d => {
            d.startDatetime = DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(d.startDatetime), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
            d.endDatetime = DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(d.endDatetime), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
            return d;
        });
    };

    addRoomListGroupByStartTime = (data) => {
        data.forEach(d => {
            const startTime = this.roomListGroupByStartTime.find(t => t.startDatetime === d.startDatetime);

            if(startTime) {
                d.roomList.forEach(r => startTime.roomList.push(r));
                startTime.count = startTime.count + d.count;
            } else {
                this.roomListGroupByStartTime.push(d);
            }
            d.roomList.forEach(r => {
                this.timelineOpenList.push({id: r.id, value: false})
                this.timelineLinkViewOpenList.push({id: r.id, value: false})
            });
        });

        this.roomListGroupByStartTime.sort((a, b) => a.startDatetime < b.startDatetime ? -1 : a.startDatetime > b.startDatetime ? 1 : 0);
    };

    addEmptyData = () => {
        const today = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT);
        this.roomListGroupByStartTime.push({count: 0, startDatetime: today + ' 00:00', roomList: [{id: 0, startDatetime: today + ' 00:00'}]})
    };

    setIsLastRoom = () => {
        for (let i = 0; i < this.roomListGroupByStartTime.length; i++) {
            if (i === this.roomListGroupByStartTime.length - 1) {
                this.roomListGroupByStartTime[i].isLast = true;
            } else {
                this.roomListGroupByStartTime[i].isLast = false;
            }
        }
    };
}