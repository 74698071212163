import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography, Box, Grid} from "@material-ui/core"
import {observer} from "mobx-react";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
        padding:'17px 20px 0'
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'0.8rem',
        color:'#0d0d0d',
        marginBottom:20
    }
});

@observer
class MobileQuiz extends Component {
    render() {
        const { classes, type, data, index } = this.props;
        const isChoice = type === 'SingleChoice' || type === 'MultipleChoice';
        const answer = isChoice ? data.quizItemList.filter(qi => qi.correct).map(qi => qi.itemId) : [];

        return (
            <div className={classes.root}>
                <Box>
                    <Typography className={classes.textStyle}>
                        <span style={{color:'#0047ac', fontWeight:800}}>퀴즈 {index + 1})</span> {data.title}
                    </Typography>
                    {
                        data.quizImageList.map(image => {
                            return (
                                <img key={image.imageId} src={'data:' + image.type + ';base64,' + image.data} alt={image.name} style={{width: "30%", height: "30%"}}/>
                            )
                        })
                    }
                    {
                        isChoice &&
                        <React.Fragment>
                            <Grid container className={classes.borderBottomStyle} style={{display:'flex', justifyContent:'space-between'}}>
                                {
                                    data.quizItemList.map(item => {
                                        return (
                                            <Grid key={item.itemId} item xs={6}>
                                                <Typography className={classes.gridTextStyle}>
                                                    {item.itemId}) {item.content}
                                                </Typography>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                            <Typography className={classes.textStyle} style={{marginTop:18}}>
                                <span style={{color:'#0047ac', fontWeight:800}}><FormattedMessage id="correct_answer"/>)</span> {answer.join()}
                            </Typography>
                        </React.Fragment>
                    }
                </Box>
            </div>
        );
    };
}

export default withStyles(style)(MobileQuiz);