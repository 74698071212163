import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Box,
    Typography,
    FormControl,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Input
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {inject, observer} from "mobx-react";
import {injectIntl} from "react-intl";

const style = theme => ({
    root: {
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width: '100%',
        border: '1px solid #c4c9de',
        borderRadius: 8,
        marginBottom: 40,
        padding: '30px 20px 25px'
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 18,
        color: '#a3a8af',
        fontWeight: 600,
    },
    explanationText: {
        fontSize: 12,
        fontWeight: 400,
        marginTop: 12
    },
    formControl: {
        width: '100%',
        "& .MuiInput-underline:after, .MuiInput-underline:before, .MuiInput-underline:hover:not(.Mui-disabled):before ": {
            borderBottom: 0
        },
        "& label + .MuiInput-formControl": {
            marginTop: 0
        }
    },
    formControlLabelDefault: {
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#a3a8af',
            fontWeight: 400,
        }
    },
    formControlLabel: {
        "& .MuiFormControlLabel-label": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 14,
            color: '#0d0d0d',
            fontWeight: 400,
        }
    },
    radioIcon: {
        fontSize: 18,
        color: '#0097ff'
    },
    inputBoxIn: {
        fontSize: 14,
        color: '#0d0d0d',
        fontWeight: 500,
        borderBottom: '1px solid #979797',
        marginLeft: 25,
    },
    titleImgBox: {
        width: 241,
        height: 181,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
    },
    imgBox: {
        width: 192,
        height: 144,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        marginLeft: 25,
    },
});

@inject('statisticsStore')
@observer
class SurveyMultipleChoice extends Component {
    render() {
        const { classes, intl, statisticsStore, item } = this.props;
        const sortedOptions = item.options.map(o => ({...o})).sort((a, b) => a.number < b.number ? -1 : a.number > b.number ? 1 : 0);
        const itemAnswerList = statisticsStore.getUserSurveyItemAnswerList(item);
        const etcAnswer = statisticsStore.getUserSurveyShortAnswer(item);

        return (
            <div className={classes.root}>
                {item.title !== "" &&
                    <>
                        {item.required ?
                            <Typography className={classes.textStyle} style={{color: '#0d0d0d'}}>{item.title}<span style={{color: '#FF0000'}}>*</span></Typography>
                            :
                            <Typography className={classes.textStyle} style={{color: '#0d0d0d'}}>{item.title}</Typography>
                        }
                    </>
                }
                {item.contents !== "" &&
                    <>
                        <Typography className={clsx(classes.textStyle, classes.explanationText)} style={{color: '#0d0d0d'}}>{item.contents}</Typography>
                    </>
                }
                {item.images.length > 0 &&
                    item.images.map((images, i) => (
                        <Box key={item.surveyItemId + i} className={classes.titleImgBox} mb={2}>
                            <img src={images.image} alt="" style={{width: '100%'}}/>
                        </Box>
                    ))
                }
                <Box style={{marginTop: 28}}>
                    <FormControl component="fieldset" className={classes.formControl}>
                        <FormGroup
                            row
                            className={classes.group}
                        >
                            {sortedOptions.map((option, index) => {
                                return (
                                    option.etc ?
                                        <Box display='flex' flexDirection='column' style={option.images.length > 0 ? {width: '50%'} : {width: '100%'}} key={option.surveyItemOptionId}>
                                            <FormControlLabel value={option.surveyItemOptionId}
                                                              control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    icon={<CheckBoxOutlineBlankIcon className={classes.radioIcon} style={{color: '#979797'}}/>}
                                                                    checkedIcon={<CheckBoxIcon className={classes.radioIcon}/>}
                                                                    disabled={true}
                                                                    checked={!!(itemAnswerList.find(a => a.optionId === option.surveyItemOptionId))}
                                                                />
                                                             }
                                                             label={option.contents === "" ? intl.formatMessage({id: "etc"}) : option.contents}
                                                             className={classes.formControlLabel}
                                            />
                                            {option.images.length > 0 &&
                                                option.images.map((image, i) => (
                                                    <Box key={option.surveyItemOptionId + i} className={classes.imgBox} mt={1}>
                                                        <img src={image.image} alt="" style={{width: '100%'}}/>
                                                    </Box>
                                                ))
                                            }
                                            <Input
                                                defaultValue={etcAnswer}
                                                className={clsx(classes.textStyle, classes.inputBoxIn)}
                                                inputProps={{
                                                    'aria-label': 'Description',
                                                }}
                                                readOnly={true}
                                            />
                                        </Box>
                                        :
                                        <Box style={option.images.length > 0 ? {width: '50%'} : {width: '100%'}} key={option.surveyItemOptionId}>
                                            <FormControlLabel value={option.surveyItemOptionId}
                                                              control={
                                                                <Checkbox
                                                                    color="primary"
                                                                    icon={<CheckBoxOutlineBlankIcon className={classes.radioIcon} style={{color: '#979797'}}/>}
                                                                    checkedIcon={<CheckBoxIcon className={classes.radioIcon}/>}
                                                                    disabled={true}
                                                                    checked={!!(itemAnswerList.find(a => a.optionId === option.surveyItemOptionId))}
                                                                />
                                                             }
                                                             label={option.contents}
                                                             className={classes.formControlLabel}
                                            />
                                            {option.images.length > 0 &&
                                                option.images.map((image, i) => (
                                                    <Box key={option.surveyItemOptionId + i} className={classes.imgBox} mt={1}>
                                                        <img src={image.image} alt="" style={{width: '100%'}}/>
                                                    </Box>
                                                ))
                                            }
                                        </Box>
                                )
                            })}
                        </FormGroup>
                    </FormControl>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(SurveyMultipleChoice));