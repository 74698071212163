import React, {Component} from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import {withStyles} from "@material-ui/core/styles";
import { ReactComponent as DropzoneIcon } from "../../../common/images/DropzoneIcon.svg";
import {inject, observer} from "mobx-react";
import {injectIntl} from "react-intl";

const style = theme => ({
    root: {
        "& .MuiDropzoneArea-root":{
            width:111,
            minHeight:111,
            border:'2px dashed #dbdbdb',
            marginBottom:20
        },
        "& .MuiDropzoneArea-text":{
            position :  "absolute",
            width : "100%",
            top:'48%',
            textAlign :"center",
            fontFamily:'NanumSquareRoundOTF',
            fontSize:10,
            color:'#303030'
        },
        "& .MuiDropzoneArea-icon": {
            position :  "absolute",
            top: "13%",
            marginLeft:-25,
        },
        "& :focus":{
            outline:'none',
        },
    },
});

@inject('orgStore')
@observer
class DropzoneImage extends Component {
    constructor(props){
        super(props);
        this.state = {
            files: []
        };
    }
    handleChange = (files) => {
        this.setState({
            files: files
        });
        this.props.orgStore.changeMainImgData(this.state.files);
    }

    render() {
        const { classes, intl, orgStore } = this.props;

        return (
            <div className={classes.root}>
                <DropzoneArea
                    onDrop={this.handleChange}
                    acceptedFiles={['image/jpeg', 'image/png']}
                    maxFileSize={1000000}
                    showPreviewsInDropzone={false}
                    filesLimit={1}
                    dropzoneText={intl.formatMessage({id: "msg.click_upload"})}
                    Icon={DropzoneIcon}
                    showAlerts={false}
                    onAlert={orgStore.handleError}
                    getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => orgStore.handleDropRejectMessage(intl, rejectedFile, acceptedFiles, maxFileSize)}
                    getFileLimitExceedMessage={(filesLimit) => orgStore.handleFileLimitExceedMessage(intl, filesLimit)}
                />
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(DropzoneImage));