import React, {Component} from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    AppBar,
    Box,
    Toolbar,
    Typography,
    LinearProgress,
} from "@material-ui/core";
import TopSearch from "./TopSearch";
import Notice from "./Notice";
import { ReactComponent as Question } from "../../common/images/Question.svg";
import UserInformation from "./UserInformation";
import {Link, withRouter} from "react-router-dom";
import {inject, observer} from "mobx-react";
import {State, UserType} from "../../stores/AuthStore";
import {Request_Notice_IntervalTime} from "../../stores/NoticeStore";
import {PATH_UTIL} from "../../common/util/path.util";

const style = theme => ({
    appBar: {
        width: `calc(100% - 85px)`,
        background:()=> theme.configs.MainColor,
        borderBottom:'1px solid #c0c2c3',
        boxShadow:'none',
    },
    title: {
        padding:'5px 20px',
        background:'#f5f5f5',
        borderRadius:22,
        fontFamily:'Montserrat',
        fontSize:19,
        color:'#4f5660',
    },
    link: {
        textDecoration: 'none',
        color: 'inherit',

        // 아이콘(svg) 색상 변경
        "& path":{
            fill: () => theme.configs.SubColor,
        },
    },
});

@inject('avatarStore', 'authStore','userStore', 'orgStore', 'timelineStore', 'noticeStore', 'progressStore', 'topBarStore', 'userLoungeStore', 'roomStore', 'surveyStore', 'quizStore')
@observer
class TopBar extends Component {

    constructor(props) {
        super(props);
        this.state = { userConfigLanguage : false }
    };

    getRoomNoticeInterval = undefined;

    getRoomNotice = () => {
        const { noticeStore, authStore } = this.props;
        noticeStore.requestGetRoomNotice(authStore.loginUser.id);
    };

    setRoomNoticeInterval = () => {
        console.log("Start setRoomNoticeInterval")
        this.getRoomNoticeInterval = setInterval(this.getRoomNotice, Request_Notice_IntervalTime); //30초마다 알림조회 인터벌 설정
        this.getRoomNotice();
    }

    getTimelineData = () => {
        const { history, authStore, userLoungeStore, timelineStore, surveyStore, quizStore} = this.props;

        if (authStore.loginState === State.Authenticated) {
            const path = PATH_UTIL.getPath(this.props.location.pathname);
            const teamId = PATH_UTIL.getTeamId(this.props.location.pathname);

            if ((path === 'mytimeline') || (path === 'teams')) {
                if (userLoungeStore.selectedTeamId !== teamId) {
                    userLoungeStore.changeSelectedTeamId(teamId);
                }
                userLoungeStore.changeSelectedTeam(authStore.loginUser.id, teamId, timelineStore, history);
                surveyStore.getSurveyListByUserId(authStore.loginUser);
                quizStore.getQuizListByUserId(authStore.loginUser);

            }
        }
    };

    componentDidMount() {
        this.props.userStore.getUserInfo(this.props.authStore.loginUser.id,
                                (userConfigLanguage) => {this.props.userStore.checkConfigLanguage(userConfigLanguage)},
                                () => {this.getTimelineData()});
        this.props.avatarStore.getAvatar(this.props.authStore.loginUser.id);
        if (this.props.authStore.loginUser.type !== UserType.Admin && this.getRoomNoticeInterval === undefined) {
            this.setRoomNoticeInterval();
        }
    }

    componentDidUpdate(prevProps, prevState, snapShot) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            window.scrollTo(0, 0);
        }
    }

    componentWillUnmount() {
        if (this.getRoomNoticeInterval !== undefined) {
            console.log("Clear interval")
            clearInterval(this.getRoomNoticeInterval);
        }
        if (this.props.noticeStore.cancelTokenSource) {
            console.log("Request Notice Component WillUnmount Cancel");
            this.props.noticeStore.cancelTokenSource.cancel();
        }

    }

    render() {
        const { classes } = this.props;
        const { loginUser } = this.props.authStore;
        const { userInfo } = this.props.userStore;
        const { loadAvatarImage } = this.props.avatarStore;
        const { isSearchBarRender } = this.props.topBarStore;
        const { roomNoticeList, requestUpdateRoomNotifyState } = this.props.noticeStore;
        const { isLoading } = this.props.progressStore;
        const { isRemoveRoomLoading } = this.props.roomStore;
        const loading = isLoading || isRemoveRoomLoading;
        const orgConfigs = {...this.props.orgStore.orgConfigs};

        return (
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Box display='flex' justifyContent='space-between' alignItems='center' style={{width:'100%'}}>
                        <Box>
                            <Typography noWrap className={classes.title}>
                                # {userInfo.name}
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' >
                            {
                                loginUser.type === UserType.Normal && isSearchBarRender &&
                                <TopSearch />
                            }
                            {
                                loginUser.type === UserType.Normal &&
                                <Notice
                                    roomNoticeList={roomNoticeList || []}
                                    noticeStateUpdate={requestUpdateRoomNotifyState}
                                    userId={loginUser.id}
                                />
                            }
                            {/*<Link to="/servicecenter" className={classes.link} >*/}
                            {/*    <Question style={{cursor:'pointer', marginRight:23, marginTop:5}}/>*/}
                            {/*</Link>*/}
                            <UserInformation
                                loadAvatarImage={loadAvatarImage}
                                userInfo={userInfo}
                                configs={orgConfigs}
                            />
                        </Box>
                    </Box>
                </Toolbar>
                {loading && <LinearProgress />}
            </AppBar>
        );
    }
}

export default withRouter(withStyles(style)(TopBar));