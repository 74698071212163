import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {
    Box,
    Typography,
} from "@material-ui/core";
import { ReactComponent as Question } from "../../../../common/images/Question.svg";
import PreviewTopSearch from "./PreviewTopSearch";
import PreviewNotice from "./PreviewNotice";
import PreviewUserInformation from "./PreviewUserInfomation";


const useStyles = makeStyles((theme) => ({
    appBar: {
        width: 'auto',
        height : 'auto',
        minHeight : 38,
        paddingRight:20,
        background: (props) => props.mainColor ? props.mainColor : "#e0e0e0",
        borderBottom:'1px solid #c0c2c3',
        boxShadow:'none',
        "& .MuiPaper-root.MuiAppBar-root.MuiAppBar-positionStatic.MuiAppBar-colorPrimary.MuiPaper-elevation4" : {
            background : (props) => props.mainColor ? props.mainColor : "#e0e0e0",
        }
    },
    title: {
        padding:'3px 10px',
        background:'#f5f5f5',
        borderRadius:22,
        fontFamily:'Montserrat',
        fontSize:10,
        color:'#4f5660',
    },
    link: {
        marginTop:5,
        //아이콘(svg) 색상 변경
        "& path":{
            fill: (props) => props.subColor ? props.subColor : '#0097ff',
        }
    },
}))

function PreviewTopBar(props) {
        const classes = useStyles(props);

        return (
           <>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        alignItems='center'
                        className={classes.appBar}
                    >
                        <Box style={{width : '20%', marginRight : 10, marginLeft : 10}}>
                            <Typography noWrap className={classes.title}>
                                # nickname
                            </Typography>
                        </Box>
                        <Box display='flex' alignItems='center' justifyContent='flex-end'>
                            <PreviewTopSearch />
                            <PreviewNotice subColor={props.subColor}/>
                            <div className={classes.link}>
                                <Question style={{cursor:'pointer', marginRight:5, width:15, height:15}}/>
                            </div>
                            <PreviewUserInformation subColor={props.subColor}/>
                        </Box>
                    </Box>
           </>
        );
}

export default PreviewTopBar;
