import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import ConnectChart from "./ConnectChart";
import {inject, observer} from "mobx-react";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:16,
        fontWeight:600,
        color:'#0d0d0d',
    },
});

@inject('statisticsStore')
@observer
class ConnectStatus extends Component {
    render() {
        const { classes } = this.props;
        const { userHistoryList } = this.props.statisticsStore;

        const chartData = [{"id": "history", "data": []}];
        userHistoryList.forEach((uh) => {
            chartData[0].data.push({"x": uh.historyCount, "y": uh.userCount});
        })
        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}><FormattedMessage id="access_status"/></Typography>
                <ConnectChart data={chartData} />
            </div>
        );
    }
}

export default withStyles(style)(ConnectStatus);