import React, {useEffect} from 'react';
import {
    Typography,
    Box,
    Select,
    FormControl,
    MenuItem
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {FormattedMessage} from "react-intl";

const useStyles = makeStyles({
    root: {
        marginTop: 20
    },
    titleStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        color: '#000',
        fontSize: '1.2rem',
        fontWeight: 600,
        marginTop:20,
        paddingBottom:7
    },
    formControlBox: {
        width:65,
        "& .MuiOutlinedInput-input": {
            padding: '8px 10px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":{
            border:'1px solid #c0c2c3'
        },
        "& .MuiSelect-select:focus":{
            background:'transparent'
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
            border:'1px solid #c0c2c3'
        },
    },
    selectBox: {
        width: "100%",
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        borderRadius: 4,
        background:'transparent',
        padding:0,
        outline:'none',
        "& :focus": {
            outline: 'none',
        },
    },
    optionStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.0rem',
        color: '#303030',
        padding:5,
        outline:0,
        minHeight:0,
        "&:hover":{
            color:'#303030',
        }
    },
});


export default function MobileMeetingTime(props) {
    const classes = useStyles();
    const [time, setTime] = React.useState('01');
    const [minute, setMinute] = React.useState('00');

    const onChangeEndTime = props.onChangeEndTime;
    useEffect(() => {
        onChangeEndTime(time, minute);
    }, [time, minute, onChangeEndTime]);

    return (
        <div className={classes.root}>
            <Typography className={classes.titleStyle}><FormattedMessage id="meeting_time"/></Typography>

            <Box display='flex' alignItems='center' pt={1} pb={1}>
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select
                        value={time}
                        onChange={(event) => setTime(event.target.value)}
                        className={classes.selectBox}
                    >
                        <MenuItem value="시간" className={classes.optionStyle}><FormattedMessage id="hour"/></MenuItem>
                        {Array.from({length: 5}, (_, i) => (<MenuItem key={`0${i}`} value={`0${i}`} className={classes.optionStyle}>{i}</MenuItem>))}
                    </Select>
                </FormControl>
                <Typography style={{paddingLeft: 10, paddingRight: 10}}> : </Typography>
                <FormControl variant={'outlined'} className={classes.formControlBox}>
                    <Select
                        value={minute}
                        onChange={(event) => setMinute(event.target.value)}
                        className={classes.selectBox}
                    >
                        <MenuItem value="분" className={classes.optionStyle}><FormattedMessage id="minute"/></MenuItem>
                        {Array.from({length: time !== '04' ? 12 : 1}, (_, i) => {
                            const value = i * 5 >= 10 ? i * 5 : '0' + (i * 5);
                            const label = i * 5 >= 10 ? i * 5 : '0' + (i * 5);
                            return (<MenuItem key={value} value={value} className={classes.optionStyle}>{label}</MenuItem>);
                        })}
                    </Select>
                </FormControl>
            </Box>
        </div>
    );
}