import {makeAutoObservable} from "mobx";
import axios from "axios";

export default class StatisticsStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    };

    isStatisticsLoading = false;
    attendanceResultList = [];
    quizItemAnswerList = [];
    quizShortAnswerList = [];
    userActivityList = [];
    userDeviceList = [];
    userHistoryList = [];
    userSummaryList = [];
    quizList = [];
    surveyList = [];
    surveyItemAnswerList = [];
    surveyShortAnswerList = [];
    isQuizDetailDialogOpen = false;
    detailQuizResults = [];
    isShowImagePopupModal = false;
    popupTargetImage = {};
    selectedSurvey = {};
    contextFilteredItemList = [];
    selectedSurveyItemIndex = 0;
    selectedSurveyItem = {};
    userSurveyStatistics = {};
    selectedUserSurvey = {};

    init = () => {
        this.attendanceResultList = [];
        this.quizItemAnswerList = [];
        this.quizShortAnswerList = [];
        this.userActivityList = [];
        this.userDeviceList = [];
        this.userHistoryList = [];
        this.userSummaryList = [];
        this.quizList = [];
        this.surveyList = [];
        this.surveyItemAnswerList = [];
        this.surveyShortAnswerList = [];
        this.isQuizDetailDialogOpen = false;
        this.detailQuizResults = [];
        this.isShowImagePopupModal = false;
        this.popupTargetImage = {};
        this.selectedSurvey = {};
        this.contextFilteredItemList = [];
        this.selectedSurveyItemIndex = 0;
        this.selectedSurveyItem = {};
        this.userSurveyStatistics = {};
        this.selectedUserSurvey = {};
    }

    get getIsLoading() {
        return this.isStatisticsLoading;
    };

    setQuizDetailDialogOpen = (isQuizDetailDialogOpen, quizResults) => {
        this.isQuizDetailDialogOpen = isQuizDetailDialogOpen;
        if (quizResults) this.detailQuizResults = quizResults;
    };

    setShowImagePopupModal = isShowImagePopupModal => this.isShowImagePopupModal = isShowImagePopupModal;

    changeSelectedSurvey = survey => {
        this.selectedSurvey = survey;
        if (this.selectedSurvey && this.selectedSurvey.items) {
            this.contextFilteredItemList = this.selectedSurvey.items.sort((a, b) => a.number < b.number ? -1 : a.number > b.number ? 1 : 0).filter(i => i.type !== "Context");
            this.changeSelectedSurveyItemIndex(0);
        }
    };

    changeSelectedUserSurvey = userSurvey => {
        this.selectedUserSurvey = userSurvey;
    };

    onImageDoubleClick(image) {
        this.setShowImagePopupModal(true);
        this.popupTargetImage = image;
    };

    onCloseImagePopupModal() {
        this.setShowImagePopupModal(false);
        this.popupTargetImage = {};
    };

    nextSelectedSurveyItemIndex() {
        this.selectedSurveyItemIndex = this.selectedSurveyItemIndex + 1;
        this.changeSelectedSurveyItem();
    };

    backSelectedSurveyItemIndex() {
        this.selectedSurveyItemIndex = this.selectedSurveyItemIndex - 1;
        this.changeSelectedSurveyItem();
    };

    changeSelectedSurveyItemIndex(itemIndex) {
        this.selectedSurveyItemIndex = itemIndex;
        this.changeSelectedSurveyItem();
    };

    changeSelectedSurveyItem() {
        this.selectedSurveyItem = this.contextFilteredItemList[this.selectedSurveyItemIndex];
    };

    getUserSurveyItemAnswerList(item) {
        return this.selectedUserSurvey.itemAnswerList.filter(a => a.surveyItemId === item.surveyItemId);
    };

    getUserSurveyShortAnswer(item) {
        const shortAnswer = this.selectedUserSurvey.shortAnswerList.find(a => a.surveyItemId === item.surveyItemId);
        if (shortAnswer) {
            return shortAnswer.answer;
        } else {
            return "";
        }
    };

    getSurveyItemAnswerList(intl) {
        return this.surveyItemAnswerList.filter(sia => sia.activationId === this.selectedSurveyItem.activationId && sia.surveyId === this.selectedSurveyItem.surveyId && sia.surveyItemId === this.selectedSurveyItem.surveyItemId)
            .sort((a, b) => a.number < b.number ? -1 : a.number > b.number ? 1 : 0)
            .map(sia => {
                return {name: this.selectedSurveyItem.type !== 'Rating' ? intl.formatMessage({id: "survey_option_number"}, {"number": sia.number}) : sia.number , color: "#b7defe", count: sia.responseCount, users: sia.users, contents: sia.contents}
            });
    };

    get getSurveyShortAnswerList() {
        return this.surveyShortAnswerList.filter(ssa => ssa.activationId === this.selectedSurveyItem.activationId && ssa.surveyId === this.selectedSurveyItem.surveyId && ssa.surveyItemId === this.selectedSurveyItem.surveyItemId)
            .sort((a, b) => a.userName < b.userName ? -1 : a.userName > b.userName ? 1 : 0)
            .map(sia => {
                return {userName: sia.userName, answer: sia.answer}
            });
    };

    getStatistics = function* getStatistics(roomId) {
        console.log("getStatistics Start");
        this.init();
        this.isStatisticsLoading = true;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/statistics/rooms/${roomId}`);

            this.attendanceResultList = response.data.attendanceResultList;
            this.quizItemAnswerList = response.data.quizItemAnswerList;
            this.quizShortAnswerList = response.data.quizShortAnswerList;
            this.userActivityList = response.data.userActivityList;
            this.userDeviceList = response.data.userDeviceList;
            this.userHistoryList = response.data.userHistoryList;
            this.userSummaryList = response.data.userSummaryList;
            this.quizList = response.data.quizList;
            this.surveyList = response.data.surveyList
            this.surveyItemAnswerList = response.data.surveyItemAnswerList;
            this.surveyShortAnswerList = response.data.surveyShortAnswerList;

            if (this.surveyList.length > 0) {
                this.changeSelectedSurvey(this.surveyList[0]);
            }
            console.log("getStatistics Done");
        } catch(e) {
            console.log("getStatistics Fail : >> ", e);
        } finally {
            this.isStatisticsLoading = false;
        }
    };

    getUserSurveyStatistics = function* getUserSurveyStatistics(roomId, userId) {
        console.log("getUserSurveyStatistics Start");
        this.userSurveyStatistics = {};
        this.isStatisticsLoading = true;
        try {
            const response = yield axios.get(this.serverContextPath + `/api/v1/statistics/rooms/${roomId}/users/${userId}/statistics`);
            this.userSurveyStatistics = response.data;
            if (this.userSurveyStatistics && this.userSurveyStatistics.userSurveyList.length > 0) {
                this.changeSelectedUserSurvey(this.userSurveyStatistics.userSurveyList[0]);
            }
        } catch(e) {
            console.log("getUserSurveyStatistics Fail : >> ", e);
        } finally {
            this.isStatisticsLoading = false;
        }
    };

    get getQuizData() {
        const quizData = [];
        this.quizList.forEach(q => {
            const quizType = q.quizType;
            quizData.push({
                "data": q,
                "type": q.quizType,
                "chartData": (quizType === 'SingleChoice' || quizType === 'MultipleChoice') ?
                                this.quizItemAnswerList.filter(qia => qia.activationId === q.activationId && qia.quizId === q.quizId) :
                                this.quizShortAnswerList.filter(qia => qia.activationId === q.activationId && qia.quizId === q.quizId)
            })
        })
        return quizData;
    };

    get getSortedUserSurveyItems() {
        const items = this.selectedUserSurvey.survey.items.map(i => ({...i}));
        return items.sort((a, b) => a.number < b.number ? -1 : a.number > b.number ? 1 : 0);
    };
}