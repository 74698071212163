import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Paper,
    Typography,
    Box,
} from "@material-ui/core";
import Announcement from "./Announcement";
import NoticeContents from "./NoticeContents";
import {inject, observer} from "mobx-react";
import {withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
        marginBottom:30,
        height: (window.innerHeight - 175) / 2
    },
    paperBox:{
        position:'relative',
        borderRadius:16,
        padding:20,
        border:'1px solid #c0c2c3',
        overflow:'hidden',
        height: '100%'
    },
    paperBoxScroll:{
        height: '100%',
        overflowY:'scroll',
        paddingRight:10,
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    titleStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:18,
        fontWeight:800,
        color:'#0d0d0d',
        textIndent:20,
    },
    paperInBox:{
        background:'#f5f5f5',
        borderRadius:16,
        padding:'21px 13px 53px',
        marginTop:19
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#0d0d0d',
        textAlign:'left',
    },
});

@inject('userLoungeStore', 'timelineStore', 'progressStore')
@observer
class TeamRoomNotice extends Component {
    componentDidMount() {
        document.getElementById('noticeList').addEventListener("scroll", () => this.infiniteScroll(), true);
    };

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userLoungeStore, timelineStore, progressStore} = this.props;
        progressStore.setIsLoading(userLoungeStore.getIsLoading || timelineStore.getIsLoading)
    };

    infiniteScroll = () => {
        const { userLoungeStore, progressStore } = this.props;

        let scrollTop = document.getElementById('noticeList').scrollTop;
        let clientHeight = document.getElementById('noticeList').clientHeight;
        let scrollHeight = document.getElementById('noticeList').scrollHeight;

        if (scrollTop + clientHeight >= scrollHeight) {
            progressStore.setIsLoading(true);
            userLoungeStore.getTeamNoticeList(userLoungeStore.selectedTeamId);
        }
    };

    render() {
        const { classes } = this.props;
        const { teamNoticeList } = this.props.userLoungeStore;

        return (
            <div className={classes.root}>
                <Paper elevation={0} className={classes.paperBox}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' style={{borderBottom:'1px solid #c0c2c3',paddingBottom:10,marginRight:10}}>
                        <Typography className={classes.titleStyle}>
                            <FormattedMessage id="notice"/>
                        </Typography>
                        <Box>
                            <Announcement/>
                        </Box>
                    </Box>
                    <Box className={classes.paperBoxScroll} id='noticeList'>
                        {
                            teamNoticeList.length > 0 ?
                                teamNoticeList.map((notice, index) => {
                                    return (
                                        <NoticeContents key={index} notice={notice}/>
                                    );
                                })
                            :
                            <Paper elevation={0} className={classes.paperInBox}>
                                <Typography className={classes.textStyle}><FormattedMessage id="msg.no_notice"/></Typography>
                            </Paper>
                        }
                    </Box>
                    <Box style={{width:'100%', height:30, position:'absolute', zIndex:100,background:'linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff)',bottom:0}} />
                </Paper>
            </div>
        );
    }
}

export default withRouter(withStyles(style)(TeamRoomNotice));