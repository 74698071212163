import React from "react";
import {withStyles} from "@material-ui/core/styles";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    Box,
    FormControlLabel,
    Checkbox,
    Typography, IconButton, Grid
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {ReactComponent as WarningIcon} from "../../common/images/WarningIcon.svg"
import {ReactComponent as Close} from "../../common/images/Close.svg"
import {inject, observer} from "mobx-react";
import {State} from "../../stores/AuthStore";
import PasswordChangeDialog from "./PasswordChangeDialog";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{

    },
    loginBtn: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 17,
        fontWeight: 800,
        color: '#0d0d0d'
    },
    loginBox: {
        "& .MuiDialog-paper": {
            borderRadius: 30,
            padding: '15px 45px 70px'
        },
        "& .MuiDialogContent-root": {
            padding: 0
        },
        "& .MuiDialogTitle-root": {
            padding: '0 24px'
        }
    },
    titleBox: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
    },
    titleText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 27,
        fontWeight: 300,
        color: '#0d0d0d',
        textAlign: 'center',
        marginBottom: 20
    },
    lblStyle: {
        width: 80,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 16,
        fontWeight: 300,
        color: '#0d0d0d',
    },
    textStyle: {
        width: 300,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        fontWeight: 300,
        padding: '13px 15px',
        borderRadius: 10,
        background: '#ececec',
        color: '#0d0d0d',
        "&::placeholder": {
            color: '#6c6969'
        },
        "&:focus": {
            outline: 'none !important',
        }
    },
    buttonStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 20,
        color: '#fff',
        fontWeight: 800,
        borderRadius: 10,
        background: () =>
            theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor,
        padding: '40px 45px',
        "&:hover": {
            background: () =>
                theme.configs.SignInBtnColor ? theme.configs.SignInBtnColor : theme.configs.MainColor,
        },
        "&.Mui-disabled":{
            background:'#a3a8af !important',
            color:'#fff'
        }
    },
    checkboxText: {
        paddingLeft: 83,
        "& .MuiTypography-root": {
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 13,
            color: '#0d0d0d',
        }
    },
    pwFindText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#0d0d0d',
        textDecoration: 'none'
    },
    errorBox: {
        margin: '5px 0 10px 80px',
    },
    errorText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        color: '#ff0000',
        fontWeight: 600,
    }
});

@inject('authStore', 'orgStore')
@observer
class SignIn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userNick: '',
            rememberNickState: '',
        }
    }

    handleClickOpen = () => {
        this.props.authStore.setLoginModal();
    };
    handleClickClose = () => {
        this.alertReset();
        this.props.authStore.changeLoginModal();
    }
    handleKeyPressLogin = (e) => {
        e.preventDefault();
        this.alertReset();
        if (e.keyCode === 13) this.handleLogin();
    }
    alertReset = () => {
        this.props.authStore.loginState = State.Pending;
    }
    handleLogin = () => {
        const {intl} = this.props;
        this.props.authStore.doLogin(intl);
    }

    render() {
        const { classes, authStore, orgStore, intl } = this.props;

        return (
            <div className={classes.root}>
                <Button onClick={this.handleClickOpen} className={classes.loginBtn}>
                    <FormattedMessage id={"sign_in"} />
                </Button>
                <Dialog maxWidth='md' open={authStore.loginModal} onClose={this.handleClickOpen}
                        aria-labelledby="form-dialog-title"
                        className={classes.loginBox}
                        onKeyUp={(e) => this.handleKeyPressLogin(e)}>
                    <Box display='flex' justifyContent='flex-end'>
                        <IconButton onClick={this.handleClickClose} style={{marginRight: -30}}>
                            <Close/>
                        </IconButton>
                    </Box>
                    <DialogTitle id="form-dialog-title">
                        <Box className={classes.titleBox}>
                            {
                                orgStore.orgImages.Logo
                                    ? <img
                                        // src={LoginLogo}
                                        src={orgStore.orgImages.Logo}
                                        alt="Organization Logo"
                                        style={{width: 'auto', height: 'auto', maxWidth: 174, maxHeight: 80}}
                                    />
                                    : <Box><Typography>Organization Logo</Typography></Box>

                            }
                            <Typography className={classes.titleText}>
                                <FormattedMessage id={"sign_in"} />
                            </Typography>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <Box display='flex' alignItems='center'>
                            <Box display='flex' flexDirection='column' pr={2}>
                                <Box display='flex' flexDirection='row' justifyContent='space-between'
                                     alignItems='center' pb={2}>
                                    <label form="id" className={classes.lblStyle}>
                                        {authStore.isLoginByNickname ?
                                            <FormattedMessage id={"nickname"} />
                                            :
                                            <FormattedMessage id={"email"} />
                                        }
                                    </label>
                                    <input
                                        autoFocus={true}
                                        name="id"
                                        type="email"
                                        id="id"
                                        tabIndex={1}
                                        placeholder={authStore.isLoginByNickname ? intl.formatMessage({id : "msg.insert_nickname"}) : intl.formatMessage({id : "msg.insert_email"})}
                                        className={classes.textStyle}
                                        style={authStore.loginState !== State.Failed ? {border: '1px solid #c0c2c3'} : {
                                            border: '1px solid #ff0000',
                                            background: '#fff'
                                        }}
                                        value={authStore.login.email}
                                        onChange={(event) => authStore.changeLoginEmail(event.target.value)}
                                    />
                                </Box>
                                <Box display='flex' flexDirection='row' justifyContent='space-between'
                                     alignItems='center'>
                                    <label form="password" className={classes.lblStyle}>
                                        <FormattedMessage id={"password"} />
                                    </label>
                                    <input
                                        name="password"
                                        type="password"
                                        id="password"
                                        tabIndex={2}
                                        placeholder={intl.formatMessage({id : "msg.validation_password"})}
                                        style={authStore.loginState !== State.Failed ? {border:'1px solid #c0c2c3'} : {border: '1px solid #ff0000', background:'#fff'}}
                                        className={classes.textStyle}
                                        onChange={(event) => authStore.changeLoginPassword(event.target.value)}
                                    />
                                </Box>
                            </Box>
                            <Button
                                disabled={authStore.login.email === '' && authStore.login.password === ''}
                                className={classes.buttonStyle}
                                tabIndex={4}
                                onClick={this.handleLogin}
                            >
                                <FormattedMessage id={"start"} />
                            </Button>
                        </Box>

                        {/* 사용자 정보가 없을경우 */}
                        {authStore.loginState === State.Failed &&
                        <Box display="flex" alignItems="center" className={classes.errorBox}>
                            <WarningIcon/>
                            <Typography className={classes.errorText}>{authStore.errorMsg}</Typography>
                        </Box>
                        }

                        <Grid container>
                            <Grid item xs={5}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                            checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                            name="checkedId"
                                            color="primary"
                                            tabIndex={3}
                                            // defaultChecked={authStore.rememberNickState}
                                            checked={authStore.isRememberNickState}
                                            // checked={(isBoolean === authStore.getCookie('rememberNickState'))}
                                            // value={authStore.rememberNickState}
                                        />
                                    }
                                    label={intl.formatMessage({id : "save_id"})}
                                    className={classes.checkboxText}
                                    onChange={() => authStore.changeRememberNickState()}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={<CheckBoxOutlineBlankIcon fontSize="small"/>}
                                            checkedIcon={<CheckBoxIcon fontSize="small"/>}
                                            name="checkedId"
                                            color="primary"
                                            tabIndex={3}
                                            // defaultChecked={authStore.rememberNickState}
                                            checked={authStore.isLoginByNickname}
                                            // checked={(isBoolean === authStore.getCookie('rememberNickState'))}
                                            // value={authStore.rememberNickState}
                                        />
                                    }
                                    label={intl.formatMessage({id : "sign_in_by_nickname"})}
                                    className={classes.checkboxText}
                                    style={{paddingLeft: 10}}
                                    onChange={() => authStore.changeIsLoginByNickname()}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                {authStore.loginState === State.Progress && <PasswordChangeDialog/>}
            </div>
        );
    }
};

export default withStyles(style)(injectIntl(SignIn));