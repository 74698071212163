import {makeAutoObservable} from "mobx";
import axios from "axios";

export const State = {
    Authenticated: 'Authenticated',
    NotAuthenticated: 'NotAuthenticated',
    Pending: 'Pending',
    Progress: 'Progress',
    Failed: 'Failed',
};

export const UserType = {
    Admin: 'Admin',
    Normal: 'Normal'
};
export const LocalStorageTokenKey = '_BASKITOP_AUTHENTICATION_TOKEN_';
export const LocalStorageSaveNameKey = '_OBZ_AUTHENTICATION_SAVE_NAME_';
export const LocalStorageLoginByNicknameKey = '_OBZ_AUTHENTICATION_LOGIN_BY_NICKNAME_';

const EmptyLogin = {
    email: '',
    password: '',
    type: 'Email'
};

const EmptyUser = {
    id: '',
    name: '',
    type: '',
    avatar: '',
    title: '',
    email: '',
    isEnabled: '',
    shouldChangePassword: '',
    createdDatetime: '',
    updatedDatetime: '',
};
const EmptyDialog = {
    open : false,
    title : "",
    msg : "",
};
export default class AuthStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }

    login = Object.assign({}, EmptyLogin);
    loginState = State.NotAuthenticated;
    loginToken = '';
    loginUser = Object.assign({}, EmptyUser);
    loginModal = false;
    isRememberNickState = false;
    isLoginByNickname = false;
    errorMsg = '';
    shouldChangePassword = false;
    confirmDialog={...EmptyDialog};


    changeConfirmDialogOpen = (open) =>{
        if(open){
            this.confirmDialog.open = open;
        }else{
            this.confirmDialog = {...EmptyDialog}
        }
    }
    changeConfirmDialogTitle = (title) =>{
        this.confirmDialog.title = title;
    }
    changeConfirmDialogMsg = (msg) =>{
        this.confirmDialog.msg = msg;
    }
    setConfirmDialog =(msg, title) =>{
        this.changeConfirmDialogOpen(true);
        this.changeConfirmDialogTitle(title);
        this.changeConfirmDialogMsg(msg);
    }

    changeLoginState = (state) => {
        this.loginState = state;
    }
    setLoginModal = () => {
        const userEmail = localStorage.getItem(LocalStorageSaveNameKey);
        const isLoginByNickname = localStorage.getItem(LocalStorageLoginByNicknameKey);
        if (userEmail) {
            this.changeLoginEmail(userEmail);
            this.isRememberNickState = true;
        }
        if (isLoginByNickname) {
            this.isLoginByNickname = true;
        }
        this.changeLoginModal();
    }

    changeLoginEmail = (email) => {
        this.login.email = email;
    };
    changeLoginPassword = (password) => {
        this.login.password = password;
    };
    changeLoginModal = () => {
        this.loginModal = !this.loginModal
    }
    invalidateLogin = () => {
        this.login = Object.assign({}, EmptyLogin);
        this.loginState = State.NotAuthenticated;
        this.loginToken = '';
        this.loginUser = Object.assign({}, EmptyUser);
        localStorage.removeItem(LocalStorageTokenKey)
    };

    changeRememberNickState = () => {
        this.isRememberNickState = !this.isRememberNickState;
    };

    changeIsLoginByNickname = () => {
        this.isLoginByNickname = !this.isLoginByNickname;
        if (this.isLoginByNickname) {
            localStorage.setItem(LocalStorageLoginByNicknameKey, new Boolean(true).toString());
        } else {
            localStorage.removeItem(LocalStorageLoginByNicknameKey);
        }
    }

    loginSuccessAction(response) {
        this.isRememberNickState ? localStorage.setItem(LocalStorageSaveNameKey, this.isLoginByNickname ? response.data.user.name : response.data.user.email) : localStorage.removeItem(LocalStorageSaveNameKey);
        if (response.data.user.shouldChangePassword) {
            this.loginState = State.Progress;
            this.shouldChangePassword = response.data.user.shouldChangePassword;
        } else {
            this.loginState = State.Authenticated;
        }
    }

    doLogin = function* doLogin(intl, isMobile, callback) {
        const ErrorCode = {
            CanNotFoundUser: intl.formatMessage({id: "msg.not_found_user"}),
            UserIsNotEnabled: intl.formatMessage({id: "msg.user_is_not_enabled"}),
            BadCredentials: intl.formatMessage({id: "msg.bad_credentials"})
        };

        this.loginState = State.Pending;

        try {
            // const path = this.isLoginByNickname ? '/api/v1/authentications/signin/name' : '/api/v1/authentications/signin/email';
            const path = '/api/v1/authentications/signin';
            const param = this.login;
            if (this.isLoginByNickname) {
                param.type = 'Name';
            } else {
                param.type = 'Email';
            }

            const response = yield axios.post(this.serverContextPath + path, param);

            if(isMobile && response.data.user.type === "Admin"){
                this.setConfirmDialog(intl.formatMessage({id: "msg.administrator_login_device"}), intl.formatMessage({id: "sign_in"}));
                return ;
            }

            const token = response.data.token;
            const user = response.data.user;

            localStorage.setItem(LocalStorageTokenKey, token);

            this.loginToken = token;
            this.loginUser = user;
            this.loginSuccessAction(response);
            this.loginModal = false;
            callback && callback();
        } catch (e) {
            this.errorMsg = ErrorCode[e.response.data.message];
            this.loginState = State.Failed;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);
        }
    };

    checkLogin = function* checkLogin(callback) {
        const token = localStorage.getItem(LocalStorageTokenKey);

        if (token) {
            try {
                const response = yield axios.get(this.serverContextPath + '/api/v1/authentications/signcheck');
                const user = response.data;
                this.loginState = State.Authenticated;
                this.loginUser = user;
                this.loginToken = token;
                callback && callback();
            } catch (e) {
                this.loginState = State.NotAuthenticated;
                this.loginToken = '';
                this.loginUser = Object.assign({}, EmptyUser);
                localStorage.removeItem(LocalStorageTokenKey);
            }
        }
    };

    doLogout = function* doLogout() {
        localStorage.removeItem(LocalStorageTokenKey);

        if(window.flutter_inappwebview !== undefined) {
            window.flutter_inappwebview.callHandler("logout");
        }

        try {
            yield axios.post(this.serverContextPath + '/api/v1/authentications/signout');

            this.login = Object.assign({}, EmptyLogin);
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);
        } catch (e) {
            this.login = Object.assign({}, EmptyLogin);
            this.loginState = State.NotAuthenticated;
            this.loginToken = '';
            this.loginUser = Object.assign({}, EmptyUser);
        }
    };
}