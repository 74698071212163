import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import clsx from "clsx";
import {Box, Button, Dialog, Slide, Typography} from "@material-ui/core";
import {FormattedMessage, injectIntl} from "react-intl";
import {ReactComponent as TopIcon} from "../../../../common/images/TopIcon.svg";
import MobilePreviewParagraph from "./Preview/MobilePreviewParagraph";
import MobilePreviewSingleChoice from "./Preview/MobilePreviewSingleChoice";
import MobilePreviewMultipleChoice from "./Preview/MobilePreviewMultipleChoice";
import MobilePreviewShortAnswer from "./Preview/MobilePreviewShortAnswer";
import MobilePreviewParagraphAdd from "./Preview/MobilePreviewParagraphAdd";
import MobilePreviewRating from "./Preview/MobilePreviewRating";
import {inject, observer} from "mobx-react";
import {SurveyItemType} from "../../../../stores/SurveyStore";


const style = theme => ({
    root: {},
    buttonStyle: {
        width: 110,
        padding: '5px 0',
        background: () =>
            theme.configs.MainBtnColor
                ? theme.configs.MainBtnColor
                : theme.configs.MainColor,
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.15rem',
        fontWeight: 300,
        color: '#fff',
        borderRadius: 7,
        "&:hover": {
            background: () =>
                theme.configs.MainBtnColor
                    ? theme.configs.MainBtnColor
                    : theme.configs.MainColor,
        }
    },
    titleBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        borderBottom: '1px solid rgba(136,136,157,0.3)',
        padding: '8px 10px 8px 20px',
        "& .MuiMenuItem-root": {
            minHeight: 0
        }
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight: 600
    },
    textStyle1: {
        fontSize: '1rem',
        color: '#ff0000',
        textAlign: 'right',
        fontWeight: 500
    },
    cancelBtn: {
        background: '#a3a8af !important',
        marginRight: 13,
        "&:hover": {
            background: '#a3a8af !important'
        }
    },
    topButtonStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.15rem',
        color: '#000',
        marginTop: 15
    }
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref}/>
));

@inject('surveyStore', 'progressStore')
@observer
class MobileSurveyPreview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            anchorEl: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {surveyStore, progressStore} = this.props;
        progressStore.setIsLoading(surveyStore.getIsLoading)
    };

    handleClickOpen = () => {
        this.setState({dialogOpen: true});
    };
    handleClickClose = () => {
        this.setState({dialogOpen: false});
    };
    handlePopoverClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };

    handlePopoverClose = () => {
        this.setState({
            anchorEl: null,
        });
    };

    handleInsertSurvey = () => {
        const {surveyStore, history, intl} = this.props;
        if (!this.props.surveyStore.survey.surveyId) {
            surveyStore.insertSurvey(intl, history);
        } else {
            surveyStore.updateSurvey(intl, history, this.props.surveyStore.survey.surveyId);
        }
    };

    render() {
        const {classes, surveyStore} = this.props;
        return (
            <div className={classes.root}>
                <Button
                    onClick={this.handleClickOpen}
                    className={classes.buttonStyle}
                    disableRipple
                >
                    <FormattedMessage id="preview"/>
                </Button>
                <Dialog
                    fullScreen
                    open={this.state.dialogOpen}
                    onClose={this.handlePopoverClose}
                    TransitionComponent={Transition}
                >
                    <Box className={classes.titleBox} id="mobileSurveyPreviewTop">
                        <Typography className={classes.textStyle}><FormattedMessage
                            id="preview_survey"/></Typography>
                    </Box>

                    <Box pl={3} pr={3} pt={2} pb={3}>
                        <Typography className={clsx(classes.textStyle, classes.textStyle1)}>* <FormattedMessage
                            id="required_items"/></Typography>
                        <MobilePreviewParagraph/>
                        {surveyStore.surveyItemDataList.length > 0 &&
                        surveyStore.surveyItemDataList.map((surveyQuestion, i) => {
                            if (surveyQuestion.type === SurveyItemType.SingleChoice) {
                                return <MobilePreviewSingleChoice key={i} index={i} surveyData={surveyQuestion}/>
                            } else if (surveyQuestion.type === SurveyItemType.MultipleChoice) {
                                return <MobilePreviewMultipleChoice key={i} index={i} surveyData={surveyQuestion}/>
                            } else if (surveyQuestion.type === SurveyItemType.ShortAnswer) {
                                return <MobilePreviewShortAnswer key={i} index={i} surveyData={surveyQuestion}/>
                            } else if (surveyQuestion.type === SurveyItemType.Rating) {
                                return <MobilePreviewRating key={i} index={i} surveyData={surveyQuestion}/>
                            } else {
                                return <MobilePreviewParagraphAdd key={i} index={i} surveyData={surveyQuestion}/>
                            }
                        })
                        }

                        <Box display="flex" justifyContent='center' alignItems='center' mt={5}>
                            <Button className={clsx(classes.buttonStyle, classes.cancelBtn)}
                                    onClick={this.handleClickClose} disabled={surveyStore.isSurveyLoading}><FormattedMessage id="rewrite"/></Button>
                            <Button className={classes.buttonStyle} onClick={this.handleInsertSurvey} disabled={surveyStore.isSurveyLoading}><FormattedMessage id="save"/></Button>
                        </Box>
                        <Box display="flex" justifyContent='center'>
                            <Button startIcon={<TopIcon/>}
                                    className={classes.topButtonStyle}
                                    onClick={() => {
                                        const topEl = document.getElementById("mobileSurveyPreviewTop");
                                        topEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                                    }}
                            >
                                <FormattedMessage id="go_to_the_top"/>
                            </Button>
                        </Box>
                    </Box>
                </Dialog>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(MobileSurveyPreview));