import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    Paper,
    Typography,
    Box,
    Button, MenuList, MenuItem, Popover
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {FormattedMessage, injectIntl} from "react-intl";
import {DATE_UTIL} from "../../../common/util/date.util";
import DeleteDialog from "../../../common/DeleteDialog";
import SurveyMoreButton from "./SurveyMoreButton";
import ConfirmDialog from "../../../common/ConfirmDialog";
import {ReactComponent as NotePencilIcon} from "../../../common/images/NotePencil.svg";
import {ReactComponent as Quiz} from "../../../common/images/Quiz.svg";

const style = theme => ({
    root: {
        height: (window.innerHeight - 175) / 2
    },
    paperBox: {
        position: 'relative',
        borderRadius: 16,
        padding: 20,
        border: '1px solid #c0c2c3',
        overflow: 'hidden',
        height: '100%'
    },
    paperBoxScroll: {
        height: '80%',
        overflowY: 'scroll',
        paddingRight: 10,
        "&::-webkit-scrollbar": {
            width: '5px',
            marginLeft: 10
        },
        "&::-webkit-scrollbar-thumb": {
            background: '#dbdbdb',
            borderRadius: '10px',
            backgroundClip: 'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background: 'transparent',
            marginTop: 10
        },
    },
    textStyle: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 14,
        fontWeight: 600,
        color: '#0d0d0d',
    },
    titleStyle: {
        fontSize: 18,
        textIndent: 20,
        textAlign: 'left'
    },
    textStyle1: {
        fontWeight: 800,
        color: '#000',
        maxWidth: 800
    },
    textStyle2: {
        fontSize: 12,
        color: '#b6b6b6',
        maxWidth: 800,
        marginTop: 3
    },
    textStyle3: {
        fontSize: 10,
        fontWeight: 500,
        color: '#a3a8af',
    },
    buttonText: {
        border: '1px solid #bfbfbf',
        color: '#1f1f1f',
        fontWeight: 500,
        borderRadius: 4,
        padding: '5px 12px',
        "&:hover": {
            background: 'transparent'
        }
    },
    paperInBox: {
        background: '#f5f5f5',
        borderRadius: 16,
        marginBottom: 20,
        padding: '20px 20px 10px',
        "& .MuiPopover-paper": {
            borderRadius: 7,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)'
        }
    },
    paperInBoxLabel: {
        background: '#f5f5f5',
        borderRadius: 16,
        marginBottom: 20,
        marginTop: 12,
        padding: '20px 20px 10px',
        display: 'inline-block',
        width: 'calc(100% - 10px)',
        "& .MuiPopover-paper": {
            borderRadius: 7,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)'
        },
        "&:after": {
            visibility: 'hidden',
            display: 'block',
            fontSize: 0,
            content: '" "',
            clear: 'both',
            height: 0,
        }
    },
    popoverBox: {
        "& .MuiPopover-paper": {
            borderRadius: 7,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25)'
        }
    },
    menuText: {
        fontSize: 12,
        color: '#000',
        fontWeight: 300,
        textAlign: 'center',
        display: 'block',
    },
    circle: {
        width: 16,
        height: 16,
        borderRadius: 50,
        background: '#dbdbdb',
        textAlign: 'center',
        fontSize: 10,
        color: '#666',
        marginLeft: 8,
        paddingTop: 1,
        boxSizing: 'border-box'
    },
    imageText: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 12,
        fontWeight: 700,
        color: '#fff',
        padding: '2px 4px',
        height: 22,
    },
    imageTextBox: {
        position: 'absolute',
        alignItems: 'center',
        padding: '0 4px',
        top: '-11px',
        left: 12,
        display: 'flex',
        border: '2px solid #fff',
        borderRadius: 8,
    }
});

@inject('surveyStore', 'authStore', 'progressStore', 'quizStore')
@observer
class Survey extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {surveyStore, quizStore, progressStore} = this.props;
        progressStore.setIsLoading(surveyStore.getIsLoading || quizStore.getIsLoading)
    };

    handleRemoveSurveyButton = () => {
        const {authStore, intl, surveyStore} = this.props;
        surveyStore.deleteSurvey(intl, authStore.loginUser);
    };

    handleRemoveQuizButton = () => {
        const {authStore, intl, quizStore} = this.props;
        quizStore.deleteQuiz(intl, authStore.loginUser);
    };

    handleItemClick = (item) => {
        const {history} = this.props;
        if (item.surveyId) {
            history.push(`/survey/modify/${item.surveyId}`);
        } else {
            history.push(`/quiz/modify/${item.quizId}`);
        }
    };

    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget,
        });
    };
    handleClose = event => {
        this.setState({
            anchorEl: null,
        });
    };

    render() {
        const {classes, history, intl, surveyStore, quizStore} = this.props;
        const {anchorEl} = this.state;
        const open = Boolean(anchorEl);
        const liveFormList = surveyStore.surveyList.concat(quizStore.quizList);
        const sortedLiveFormList = liveFormList.sort((a, b) => b.updatedDatetime.localeCompare(a.updatedDatetime));

        return (
            <div className={classes.root}>
                <Paper elevation={0} className={classes.paperBox}>
                    <Box display='flex' justifyContent='space-between' alignItems='center'
                         style={{borderBottom: '1px solid #c0c2c3', paddingBottom: 10, marginBottom: 20}}>
                        <Typography className={clsx(classes.textStyle, classes.titleStyle)}>
                            {/*라이브 폼*/}
                            <FormattedMessage id="live_form"/>
                        </Typography>
                        {/*<Button className={clsx(classes.textStyle,classes.buttonText)} onClick={() => history.push("/survey/create")}>*/}
                        <Button className={clsx(classes.textStyle, classes.buttonText)} onClick={this.handleClick}>
                            <FormattedMessage id="registration"/>
                        </Button>
                        <Popover
                            id={'simple-popper'}
                            open={open}
                            anchorEl={anchorEl}
                            onClose={this.handleClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            className={classes.popoverBox}
                        >
                            <MenuList>
                                <MenuItem className={clsx(classes.textStyle, classes.menuText)}
                                          onClick={() => history.push("/survey/create")}>
                                    <FormattedMessage id="survey"/>
                                </MenuItem>
                                <MenuItem className={clsx(classes.textStyle, classes.menuText)}
                                          onClick={() => history.push("/quiz/create")}>
                                    <FormattedMessage id="quiz"/>
                                </MenuItem>
                            </MenuList>
                        </Popover>
                    </Box>
                    <Box className={classes.paperBoxScroll}>
                        {sortedLiveFormList.length > 0 ?
                            sortedLiveFormList.map((item, index) => {
                                return (
                                    item.surveyId !== undefined ?
                                        <Paper elevation={0} className={classes.paperInBoxLabel} key={index}
                                               style={{cursor: 'pointer', position: 'relative'}}
                                               onClick={() => this.handleItemClick(item)}
                                        >
                                            <div className={classes.imageTextBox} style={{background: '#7d86a9'}}>
                                                <NotePencilIcon/>
                                                <Typography className={classes.imageText}><FormattedMessage id="survey"/></Typography>
                                            </div>
                                            <Typography noWrap
                                                        className={clsx(classes.textStyle, classes.textStyle1)}>{item.title}</Typography>
                                            <Typography noWrap
                                                        className={clsx(classes.textStyle, classes.textStyle2)}>{item.descriptions}</Typography>
                                            <Box display='flex' justifyContent='space-between' alignItems='center'
                                                 mt={1}>
                                                <Typography noWrap
                                                            className={clsx(classes.textStyle, classes.textStyle3)}>{DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(item.updatedDatetime), DATE_UTIL.FORMAT.AMPM_FORMAT)}</Typography>
                                                <SurveyMoreButton item={item}
                                                                  changeOpeDeleteDialog={surveyStore.changeOpeDeleteDialog}
                                                                  changeConfirmDialogOpen={surveyStore.changeConfirmDialogOpen}/>
                                            </Box>

                                        </Paper>
                                        :
                                        <Paper elevation={0} className={classes.paperInBoxLabel} key={index}
                                               style={{cursor: 'pointer', position: 'relative'}}
                                               onClick={() => this.handleItemClick(item)}
                                        >
                                            <div className={classes.imageTextBox} style={{background: '#7d86a9'}}>
                                                {/* 퀴즈일경우*/}
                                                <Quiz/>
                                                <Typography className={classes.imageText}><FormattedMessage id="quiz"/></Typography>
                                            </div>
                                            <Typography noWrap
                                                        className={clsx(classes.textStyle, classes.textStyle1)}>{item.title}</Typography>
                                            <Box display='flex' justifyContent='space-between' alignItems='center'
                                                 mt={1}>
                                                <Typography noWrap
                                                            className={clsx(classes.textStyle, classes.textStyle3)}>{DATE_UTIL.getTimeZoneDate(DATE_UTIL.convertTimeZoneToUTC(item.updatedDatetime), DATE_UTIL.FORMAT.AMPM_FORMAT)}</Typography>
                                                <SurveyMoreButton item={item}
                                                                  changeOpeDeleteDialog={quizStore.changeOpeDeleteDialog}
                                                                  changeConfirmDialogOpen={surveyStore.changeConfirmDialogOpen}/>
                                            </Box>

                                        </Paper>
                                )
                            })
                            :
                            <Paper elevation={0} className={classes.paperInBox} style={{paddingBottom: 50}}>
                                <Typography
                                    className={classes.textStyle}><FormattedMessage id="msg.no_survey"/></Typography>
                            </Paper>
                        }
                    </Box>
                    <Box style={{
                        width: '100%',
                        height: 30,
                        position: 'absolute',
                        zIndex: 100,
                        background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff)',
                        bottom: 0
                    }}/>
                </Paper>
                <ConfirmDialog open={surveyStore.openConfirmDialog}
                               title={intl.formatMessage({id: "delete"})}
                               msg={intl.formatMessage({id: "msg.check_confirm_survey"})}
                               changeDialogOpen={surveyStore.changeConfirmDialogOpen}
                />
                <DeleteDialog open={surveyStore.openDeleteDialog}
                              title={intl.formatMessage({id: "delete"})}
                              msg={intl.formatMessage({id: "msg.check_delete_survey"})}
                              changeDialogOpen={surveyStore.changeOpeDeleteDialog}
                              submit={() => this.handleRemoveSurveyButton()}
                />
                <DeleteDialog open={quizStore.openDeleteDialog}
                              title={intl.formatMessage({id: "delete"})}
                              msg={intl.formatMessage({id: "msg.check_delete_survey"})}
                              changeDialogOpen={quizStore.changeOpeDeleteDialog}
                              submit={() => this.handleRemoveQuizButton()}
                />
            </div>
        );
    }
}

export default withRouter(withStyles(style)(injectIntl(Survey)));