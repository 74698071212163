import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
    Tabs,
    Tab,
    Box,
    Typography,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@material-ui/core";
import SimpleColor from "./SimpleColor";
import { ReactComponent as UnCheckedBox } from "../../../common/images/UnCheckedBox.svg";
import { ReactComponent as CheckedBox } from "../../../common/images/CheckedBox.svg";
import DirectSelectionColor from "./DirectSelectionColor";
import {CONFIG_SELECTIONS, DEFAULT_COLORS} from "../../../stores/OrgStore";
import {FormattedMessage, useIntl} from "react-intl";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiTab-textColorInherit.Mui-selected":{
            color:'#202020',
            fontWeight:'bold'
        },
        "& .MuiTab-root":{
            minWidth:69,
            minHeight:10
        },
        "& .MuiTabs-root":{
            minHeight:10
        },
        "&  .MuiTabs-indicator":{
            height:3,
        }
    },
    textareaStyle:{
        width:"90%",
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#8d909e',
        border:'1px solid #b7bcd6',
        padding:'11px 5px',
        borderRadius:4,
        resize:'none',
        marginTop:24,
        "&::placeholder":{
            color:'#8d909e'
        }
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#666666'
    },
    tabletTextStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:10,
        color:'#666666'
    },
    radioBox:{
        marginTop:16,
        "& .MuiFormControlLabel-label":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:12,
            color:'#0d0d0d',
        }
    },
    formLabelStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        color:'#0d0d0d',
    },
    fontRadioBox:{
        marginLeft:15,
        "& .MuiFormGroup-root":{
            marginTop:0
        },
        "& .MuiIconButton-root":{
            "&:hover":{
                background:'transparent'
            }
        },
        "& .MuiIconButton-root:first-child":{
            paddingLeft:9,
        },
    }
}));

const StyledTabs = withStyles({
    indicator: {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        '& > span': {
            maxWidth:'100%',
            width:'100%',
            backgroundColor:'#1676ff',
        },
    },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTab = withStyles((theme) => ({
    root: {
        textTransform: 'none',
        color: '#7d86a9',
        fontSize:12,
        fontFamily:'NanumSquareRoundOTF',
        '&:focus': {
            opacity: 1,
        },
    },
}))((props) => <Tab disableRipple {...props} />);

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Box
            role="tabpanel"
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            <Box>
                {children}
            </Box>
        </Box>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

export default function TabsColor(props) {
    const intl = useIntl();
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const { isTablet } = props;
    const [selection, setSelection] = React.useState(CONFIG_SELECTIONS.Simple);
    const [type, setType] = React.useState(CONFIG_SELECTIONS.Main);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if(newValue === 0) setType(CONFIG_SELECTIONS.Main);
        if(newValue === 1) setType(CONFIG_SELECTIONS.Sub);
    };

    const handleChangeChecked  = (event) => {
        setSelection(event.target.value);
    };

    useEffect(() => {
        const changeSelection = (type, mainColorSelection, subColorSelection) => {
            if(type === CONFIG_SELECTIONS.Main) {
                mainColorSelection ? setSelection(CONFIG_SELECTIONS.Simple) : setSelection(CONFIG_SELECTIONS.Direct)
            } else if (type === CONFIG_SELECTIONS.Sub) {
                subColorSelection ? setSelection(CONFIG_SELECTIONS.Simple) : setSelection(CONFIG_SELECTIONS.Direct);
            }
        }
        changeSelection(type, props.mainColorSelection, props.subColorSelection);
    }, [type, props.mainColorSelection, props.subColorSelection])

    return (
        <div className={classes.root} style={{marginTop:isTablet ? 20 : 35 }}>
            <StyledTabs value={value} onChange={handleChange} aria-label="styled tabs example" style={{borderBottom: '1px solid #dddddd',width:138}}>
                <StyledTab label={intl.formatMessage({id: "main_color"})} />
                <StyledTab label={intl.formatMessage({id: "sub_color"})} />}
            </StyledTabs>

            <TabPanel value={value} index={0}>
                <RadioGroup row aria-label="color" name="color1" value={selection} onChange={handleChangeChecked} className={classes.radioBox}>
                    <FormControlLabel
                        value={CONFIG_SELECTIONS.Simple}
                        control={<Radio checkedIcon={<CheckedBox/>} icon={<UnCheckedBox />} />}
                        label={intl.formatMessage({id: "easy_selection"})}
                    />
                    <FormControlLabel
                        value={CONFIG_SELECTIONS.Direct}
                        control={<Radio checkedIcon={<CheckedBox/>} icon={<UnCheckedBox />} />}
                        label={intl.formatMessage({id: "direct_selection"})}
                    />
                </RadioGroup>

                {selection === CONFIG_SELECTIONS.Simple ?
                    <SimpleColor {...props} type={type} defaultColors={DEFAULT_COLORS}/>
                    :
                    <Box display='flex'>
                        <DirectSelectionColor {...props} type={type}/>
                    </Box>
                }
                <Typography className={classes.textStyle}>
                    * <FormattedMessage id="msg.color_guide_1"/><br/>
                    * <FormattedMessage id="msg.color_guide_2"/>
                </Typography>
            </TabPanel>
        </div>
    );
}

