import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
    Tooltip,
    Typography
} from "@material-ui/core";
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { ReactComponent as FolderPlus } from "../../../../common/images/FolderPlus.svg";
import { ReactComponent as FolderMinus } from "../../../../common/images/FolderMinus.svg";
import { ReactComponent as SmileyMeh } from "../../../../common/images/SmileyMeh.svg";
import {inject, MobXProviderContext, observer} from "mobx-react";
import * as TStore from "../../../../stores/TeamStore";
import {UI} from "../../../../stores/UserStore";
import {useIntl} from "react-intl";
const useTreeItemStyles = makeStyles((theme) => ({
    root: {
        "& .MuiPaper-root":{
            background:'#474747',
            boxShadow:'0 4px 4px 0 rgba(0, 0, 0, 0.25)',
            borderRadius:7
        },
        "& .MuiMenuItem-root":{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:10,
            color:'#fff',
            fontWeight:300
        }
    },
    content: {
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        color:'#0d0d0d',
        // '$expanded > &': {
        // },
    },
    group: {
        '& $content': {
            paddingLeft:0,
        },
    },
    label: {
        fontWeight: 'inherit',
        color: 'inherit',
    },
    labelRoot: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'space-between',
        paddingTop:8,
        paddingBottom:8
    },
    labelBox:{
        display: "flex",
        alignItems: "center",
    },
    labelIcon: {
        marginRight:5,
    },
    rootLabelText: {
        [theme.breakpoints.down('lg')]: {
            maxWidth:70,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth:50,
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        fontWeight:500,
        flexGrow: 1,
        color:'#0d0d0d',
        maxWidth:120,
        textOverflow: "ellipsis",
        overflow:"hidden",
        whiteSpace: 'nowrap',
    },
    labelText: {
        [theme.breakpoints.down('lg')]: {
            maxWidth:140,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth:50,
        },
        fontFamily:'NanumSquareRoundOTF',
        fontSize:12,
        fontWeight:500,
        flexGrow: 1,
        color:'#0d0d0d',
        marginLeft:5,
        maxWidth:120,
        textOverflow: "ellipsis",
        overflow:"hidden",
        whiteSpace: 'nowrap',
    },
    labelNumber:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:10,
        color:'#7d86a9',
        marginLeft:5
    },
}));

function StyledTreeItem(props) {
    const intl = useIntl();
    const classes = useTreeItemStyles();
    const { selectTeam, labelText, labelIcon: LabelIcon,labelNumber,labelType, ...other } = props;
    const {userStore} = React.useContext(MobXProviderContext);
    const [open] = React.useState(false);
    const anchorRef = React.useRef(null);

    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const onLabelClick = async (event) =>{

        const team = props.value;
        event.preventDefault();
        if (userStore.memberTreeSelectedTeamId !== props.nodeId) {
            selectTeam(0);
            if (team === -1) {
                userStore.initUserTablePage();
                userStore.changeMemberTreeExpandedListByCustom(props.nodeId);
                userStore.getUserTableListByAll();
                userStore.changeUiState(UI.MemberTable);
            } else if (team === -2) {
                userStore.initUserTablePage();
                userStore.getUserTableListByNotTeam();
                userStore.changeUiState(UI.MemberTable);
            } else if (team.type === TStore.TeamType.Team) {
                userStore.initUserTablePage();
                userStore.getUserTableListByTeam(intl, team.id);
                userStore.changeUiState(UI.MemberTable);
                selectTeam(team.id);
            } else if (team.type === TStore.TeamType.Group) {
                userStore.changeMemberTreeExpandedListByCustom(team.id.toString());
                userStore.initUserTablePage();
                userStore.getUserTableListByTeam(intl, team.id);
                userStore.changeUiState(UI.MemberTable);
            }
        }
    }
    return (
        <TreeItem
            label={
                <div className={classes.labelRoot}>
                    <Tooltip title={labelText}>
                        <div className={classes.labelBox}>
                            <LabelIcon color="inherit" className={classes.labelIcon}/>
                            <Typography variant="body2" className={labelType === TStore.TeamType.Root ? classes.rootLabelText : classes.labelText }>
                                {labelText}
                            </Typography>
                            {labelType === TStore.TeamType.Team ?
                                null
                            :
                                <>
                                    {labelType === "independent" ?
                                        null
                                    :
                                        <Typography className={classes.labelNumber}>({labelNumber})</Typography>
                                    }
                                </>
                            }
                        </div>
                    </Tooltip>
                </div>
            }
            classes={{
                root: classes.root,
                content: classes.content,
                expanded: classes.expanded,
                selected: classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            onLabelClick={onLabelClick}
            {...other}
        />
    );
}

StyledTreeItem.propTypes = {
    labelIcon: PropTypes.elementType.isRequired,
    labelNumber: PropTypes.number,
    labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
    root: {
        marginTop:45,
        flexGrow: 1,
        maxWidth:'100%',
        overflowY:'scroll',
        overflowX:'hidden',
        maxHeight:650,
        minHeight:100,
        marginBottom:20,
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
});

function TeamMemberTreeView(props) {
    const intl = useIntl();
    const classes = useStyles();
    const {selectTeam} = props;

    const handleLabelIcon = (team) =>{
        if(!team.childTeam) return <FolderMinus/>;
        else if(team.childTeam.length > 0) return <FolderPlus/>;
        else return <FolderMinus/>;
    }
    const {teamTreeList} = props.teamStore;
    const {memberTreeExpandedList, changeMemberTreeExpandedList} = props.userStore;
    const {orgConfigs} = props.orgStore;

    const handleChangeSelected = (e, node) =>{
        props.userStore.changeSelectedTeamIdByMemberTree(node)
    }

    return (
        <TreeView
            className={classes.root}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            expanded={memberTreeExpandedList}
            onNodeToggle={changeMemberTreeExpandedList}
            selected={props.userStore.memberTreeSelectedTeamId}
            onNodeSelect={handleChangeSelected}
        >
            <StyledTreeItem key={-1}
                            value={-1}
                            nodeId={"-1"}
                            labelNumber={teamTreeList.length}
                            labelType={TStore.TeamType.Root}
                            labelText={orgConfigs.OrganizationName ?
                                            orgConfigs.OrganizationName === ''
                                                ? intl.formatMessage({id: "my_organization"})
                                                : orgConfigs.OrganizationName
                                        : intl.formatMessage({id: "my_organization"})}
                            labelIcon={FolderPlus}
                            selectTeam={selectTeam}
            >
                <StyledTreeItem
                    key={-2}
                    value={-2}
                    nodeId={"-2"}
                    labelText={intl.formatMessage({id: "independent"})}
                    labelType={"independent"}
                    labelIcon={SmileyMeh}
                    selectTeam={selectTeam}
                />
                {teamTreeList.map((team, index) => {
                    return (
                        <StyledTreeItem
                            key={team.id}
                            value={team}
                            nodeId={team.id.toString()}
                            labelText={team.name}
                            labelType={team.type}
                            labelNumber={team.childTeam.length}
                            labelIcon={()=>handleLabelIcon(team)}
                            selectTeam={selectTeam}
                        >
                            {team.childTeam.map((child, index) => {
                                return(
                                    <StyledTreeItem
                                        key={child.id}
                                        value={child}
                                        nodeId={child.id.toString()}
                                        labelText={child.name}
                                        labelType={child.type}
                                        labelNumber={0}
                                        labelIcon={()=>handleLabelIcon(child)}
                                        selectTeam={selectTeam}
                                    />
                                )
                            })}
                        </StyledTreeItem>
                    )
                })}
            </StyledTreeItem>
        </TreeView>
    );
}

export default inject('teamStore', 'userStore', 'orgStore')(observer(TeamMemberTreeView));


