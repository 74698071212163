import React, {Component} from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Box, Button, Dialog, IconButton, Slide, Typography} from "@material-ui/core";
import {ReactComponent as CalendarBlank} from "../../common/images/CalendarBlank.svg";
import {ReactComponent as AlarmIcon} from "../../common/images/AlarmIcon.svg";
import {ReactComponent as ArrowBottomIcon} from "../../common/images/ArrowBottomIcon.svg";
import MobileCalendarBox from "./MobileCalendarBox";
import {inject, observer} from "mobx-react";
import dayjs from "dayjs";
import {ReactComponent as MobileClose} from "../../common/images/MobileClose.svg";
import {DATE_UTIL} from "../../common/util/date.util";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root: {},
    calendarBox: {
        width: '100%',
    },
    calendarBoxIn: {
        display: 'flex',
        flexDirection: 'column',
        borderBottom: '1px solid #d3d7db',
        paddingBottom: 5,
        marginRight: 10
    },
    calendarBtn: {
        display: 'flex',

    },
    calenderText1: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '0.8rem',
        color: '#404040',
        marginBottom: 5,
        textAlign: 'left'
    },
    calenderText2: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.0rem',
        color: '#0d0d0d',
        marginLeft: 5,
        paddingRight: 10
    },
    titleBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid rgba(136,136,157,0.3)',
        padding: '8px 10px 8px 20px',
        marginBottom: 20
    },
    iconBtn: {
        padding: 5
    },
    titleText: {
        width: '100%',
        textAlign: 'center',
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.4rem',
        color: '#0d0d0d',
        fontWeight: 600
    },
    saveBtn: {
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: '1.2rem',
        color: '#0d0d0d',
        "&:hover": {
            background: 'transparent'
        }
    },
});

const Transition = React.forwardRef((props, ref) => (
    <Slide direction="up" {...props} ref={ref}/>
));

@inject('roomStore')
@observer
class MobileSelectSchedule extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            startDatetime: {
                date: dayjs(new Date(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT)).setHours(0, 0, 0)).tz(DATE_UTIL.getTimeZone(), true),
                hour: '',
                min: '',
                am: "AM"
            },
            endDatetime: {hour: '', min: ''},
            checkNull: false,
        }
    }

    handleClose = () => {
        this.setState({
            open: false,
            startDatetime: {
                date: dayjs(new Date(DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DATE_FORMAT)).setHours(0, 0, 0)).tz(DATE_UTIL.getTimeZone(), true),
                hour: '',
                min: '',
                am: "AM"
            },
            endDatetime: {hour: '', min: ''}
        });
    };

    onChangeDate = (props) => {
        this.setState({startDatetime: {...this.state.startDatetime, date: props}})
    }
    onChangeStartTime = (time, minute, radio) => {
        this.setState({startDatetime: {...this.state.startDatetime, hour: time, min : minute, am : radio}}, this.InvalidationDate)
    }

    onChangeStartMin = (minute) => {
        this.setState({startDatetime: {...this.state.startDatetime, min: minute}}, this.InvalidationDate)
    }

    onChangeAm = (radio) => {
        this.setState({startDatetime: {...this.state.startDatetime, am: radio}})
    }

    onChangeEndTime = (time, minute) => {
        this.setState({endDatetime: {hour: time, min : minute}},this.InvalidationDate)
    }

    onChangeEndMin = (props) => {
        this.setState({endDatetime: {...this.state.endDatetime, min: props}}, this.InvalidationDate)
    }
    InvalidationDate = () => {
        let checkNull = false;
        if (this.state.startDatetime.hour === "시간" || this.state.startDatetime.hour === "") {
            checkNull = true;
        } else if (this.state.startDatetime.min === "분" || this.state.startDatetime.min === "") {
            checkNull = true;
        } else if (this.state.endDatetime.hour === "시간" || this.state.endDatetime.hour === "") {
            checkNull = true;
        } else if (this.state.endDatetime.min === "분" || (this.state.endDatetime.hour === "00" && this.state.endDatetime.min === "00")) {
            checkNull = true;
        }
        this.setState({checkNull: checkNull})
    }
    handleClickOpen = () => {
        this.setState({open: true});
    };
    handleSave = () => {
        this.props.roomStore.onChangeDate(this.state);
        this.handleClose();
    }

    render() {
        const {classes, roomStore} = this.props;
        return (
            <div>
                <Box display='flex' className={classes.calendarBox}>
                    <Box onClick={this.handleClickOpen} className={classes.calendarBtn}>
                        <Box className={classes.calendarBoxIn}>
                            <Typography className={classes.calenderText1}><FormattedMessage id="date_setting"/></Typography>
                            <Box display='flex' alignItems='center'>
                                <CalendarBlank/>
                                {roomStore.roomInfo.startDatetime !== "" ?
                                    <Typography
                                        className={classes.calenderText2}>{dayjs(roomStore.roomInfo.startDatetime).format("YYYY-MM-DD")}</Typography>
                                    :
                                    <Typography className={classes.calenderText2}><FormattedMessage id="select_date"/></Typography>
                                }
                            </Box>
                        </Box>
                        <Box className={classes.calendarBoxIn}>
                            <Typography className={classes.calenderText1}><FormattedMessage id="start_time"/></Typography>
                            <Box display='flex' alignItems='center'>
                                <AlarmIcon/>
                                {roomStore.roomInfo.startDatetime !== "" ?
                                    <Typography
                                        className={classes.calenderText2}>{dayjs(roomStore.roomInfo.startDatetime).format("HH:mm").substring(0, 2) < 13 ? dayjs(roomStore.roomInfo.startDatetime).format("HH:mm") : dayjs(roomStore.roomInfo.startDatetime).add(-12, 'hours').format("HH:mm")} {dayjs(roomStore.roomInfo.startDatetime).format("HH") < 12 ? "AM" : "PM"}</Typography>
                                    :
                                    <Typography className={classes.calenderText2}><FormattedMessage id="select_time"/></Typography>
                                }
                            </Box>
                        </Box>
                        <Box className={classes.calendarBoxIn}>
                            <Typography className={classes.calenderText1}><FormattedMessage id="meeting_time"/></Typography>
                            <Box display='flex' alignItems='center'>
                                {roomStore.roomInfo.meetingTime !== "" ?
                                    <Typography className={classes.calenderText2} style={{
                                        marginRight: 10,
                                        marginLeft: 0,
                                        paddingRight: 0,
                                        paddingLeft: 10
                                    }}>{roomStore.roomInfo.meetingTime}</Typography>
                                    :
                                    <Typography className={classes.calenderText2} style={{
                                        marginRight: 10,
                                        marginLeft: 0,
                                        paddingRight: 0,
                                        paddingLeft: 10
                                    }}><FormattedMessage id="select_time"/></Typography>
                                }
                                <ArrowBottomIcon/>
                            </Box>
                        </Box>
                    </Box>
                    <Dialog
                        fullScreen
                        open={this.state.open}
                        onClose={this.handleClose}
                        TransitionComponent={Transition}
                    >
                        <Box className={classes.titleBox}>
                            <Box display='flex' alignItems='center'>
                                <IconButton onClick={this.handleClose} aria-label="Close" className={classes.iconBtn}>
                                    <MobileClose/>
                                </IconButton>
                                {/*<Typography className={classes.titleText}>일시예약</Typography>*/}
                            </Box>
                            <Button className={classes.saveBtn} onClick={this.handleSave}
                                    disabled={this.state.checkNull}><FormattedMessage id="complete"/></Button>
                        </Box>

                        <MobileCalendarBox onChangeDate={this.onChangeDate} onChangeStartTime={this.onChangeStartTime}
                                           onChangeStartMin={this.onChangeStartMin} onChangeAm={this.onChangeAm}
                                           onChangeEndTime={this.onChangeEndTime} onChangeEndMin={this.onChangeEndMin}
                                           onClickSave={this.handleSave}/>

                    </Dialog>
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(MobileSelectSchedule);