import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography, Box} from "@material-ui/core";
import DataTimeLineItem from "./DataTimeLineItem";
import {inject, observer} from "mobx-react";
import {DATE_UTIL} from "../../../common/util/date.util";
import {ReactComponent as VectorIcon} from "../../../common/images/VectorIcon.svg";
import {FormattedMessage, injectIntl} from "react-intl";

const style = theme => ({
    root:{
        paddingRight:20,
        overflowX:'hidden',
        "&::-webkit-scrollbar": {
            width:'5px',
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    dataBox:{
        width:'100%',
        display:'flex',
        justifyContent:'space-between',
        borderBottom:'1px solid #c2c2c2',
        paddingBottom:10,
        marginBottom:17
    },
    dataText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:13,
        fontWeight:600,
        color:'#212525',
    },
    timeLineBox:{
        height:93,
        padding:'11px 11px 14px',
        borderRadius:8,
        background:'#f5f5f5',
        marginBottom:10,
        display:'flex',
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:300,
        color:'#3b3b3b',
        marginLeft:5
    }
});

@inject('userLoungeStore', 'timelineStore', 'progressStore')
@observer
class DataTimeLine extends Component {
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userLoungeStore, timelineStore, progressStore} = this.props;
        progressStore.setIsLoading(userLoungeStore.getIsLoading || timelineStore.getIsLoading)
    };

    render() {
        const { classes, intl, timelineStore } = this.props;

        return (
            <div className={classes.root} style={{overflowY: 'auto', height: window.innerHeight - 350}}>
                {
                    timelineStore.calendarData.length > 0 &&
                    timelineStore.calendarData.map((data, index) => {
                        return (
                            data.count > 0 ?
                                <Box style={{marginBottom:45}} key={index}>
                                    <Box className={classes.dataBox}>
                                        <Typography className={classes.dataText}>{DATE_UTIL.getTimeZoneLanguage(data.date).format(`MMM D${intl.formatMessage({id: "calendar_day"})} dddd`)}</Typography>
                                        <Typography className={classes.dataText}><FormattedMessage id="total"/> {data.count}<FormattedMessage id="case"/> </Typography>
                                    </Box>
                                    {
                                        data.roomList.map((room, index) => {
                                            return (
                                                <DataTimeLineItem
                                                    key={index}
                                                    room={room}
                                                />
                                            )})
                                    }
                                </Box>
                                :
                                <Box style={{marginBottom:45}} key={index}>
                                    <Box className={classes.dataBox}>
                                        <Typography className={classes.dataText}>{DATE_UTIL.getTimeZoneLanguage(data.date).format(`MMM D${intl.formatMessage({id: "calendar_day"})} dddd`)}</Typography>
                                        <Typography className={classes.dataText}><FormattedMessage id="total"/> {data.count}<FormattedMessage id="case"/> </Typography>
                                    </Box>
                                    <div className={classes.timeLineBox}>
                                        <VectorIcon/>
                                        <Typography className={classes.textStyle}><FormattedMessage id="msg.no_schedule"/></Typography>
                                    </div>
                                </Box>
                        );
                    })
                }
            </div>
        );
    };
}

export default withStyles(style)(injectIntl(DataTimeLine));