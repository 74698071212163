import React, {Component} from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import {withStyles} from "@material-ui/core/styles";
import { ReactComponent as DropzoneIcon } from "../../../common/images/DropzoneIcon.svg";
import {inject, observer} from "mobx-react";
import {CONFIG_SELECTIONS} from "../../../stores/OrgStore";
import {injectIntl} from "react-intl";

const style = theme => ({
    root: {
        marginTop:30,
        "& .MuiDropzoneArea-root":{
            width:120,
            minHeight:120,
            border:'2px dashed #dbdbdb',
            marginBottom:20,
            borderRadius:'50%',
        },
        "& .MuiDropzoneArea-text":{
            position :  "absolute",
            width : "100%",
            top:'48%',
            textAlign :"center",
            fontFamily:'NanumSquareRoundOTF',
            fontSize:10,
            color:'#303030'
        },
        "& .MuiDropzoneArea-icon": {
            position :  "absolute",
            top: "13%",
            marginLeft:-25,
        },
        "& :focus":{
            outline:'none',
        },
    },
});

@inject('orgStore')
@observer
class DropzoneLogo extends Component {
    constructor(props){
        super(props);
        this.state = {
            files: []
        };
    }

    handleChange(files){
        const type = this.props.type;
        const {orgStore} = this.props;
        this.setState({
            files: files
        });
        if(type === CONFIG_SELECTIONS.Logo) orgStore.changeLogoImgData(this.state.files);
        if(type === CONFIG_SELECTIONS.Favicon) orgStore.changeFaviconImgData(this.state.files);
    }

    render() {
        const { classes, intl, orgStore, type } = this.props;
        return (
            <div className={classes.root}>
                <DropzoneArea
                    onDrop={this.handleChange.bind(this)}
                    acceptedFiles={type === CONFIG_SELECTIONS.Logo ? ['image/jpeg', 'image/png'] : ['image/*']}
                    showPreviewsInDropzone={false}
                    filesLimit={1}
                    dropzoneText={intl.formatMessage({id: "msg.click_upload"})}
                    Icon={DropzoneIcon}
                    showAlerts={false}
                    onAlert={orgStore.handleError}
                    getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => orgStore.handleDropRejectMessage(intl, rejectedFile, acceptedFiles, maxFileSize)}
                    getFileLimitExceedMessage={(filesLimit) => orgStore.handleFileLimitExceedMessage(intl, filesLimit)}
                />
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(DropzoneLogo));