import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {
    BottomNavigation, BottomNavigationAction,
    Box,
    Typography
} from "@material-ui/core";
import {inject, observer} from "mobx-react";
import {injectIntl} from "react-intl";

const style = theme => ({
    root:{
        [theme.breakpoints.up('xl')]: {
            width: '80%',
        },
        width:'100%',
        border: '1px solid #c4c9de',
        borderRadius: 8,
        marginBottom: 40,
        padding:'30px 20px 25px',
    },
    textStyle:{
        fontFamily: 'NanumSquareRoundOTF',
        fontSize: 18,
        color: '#a3a8af',
        fontWeight: 600,
    },
    explanationText:{
        fontSize: 12,
        fontWeight: 400,
        marginTop:12
    },
    titleImgBox:{
        width: 241,
        height: 181,
        overflow:'hidden',
        display:'flex',
        alignItems:'center'
    },
    buttonBox:{
        "& .MuiBottomNavigation-root":{
            justifyContent:'flex-start',
            flexWrap:'wrap',
            height:'inherit'
        },
        "& .MuiBottomNavigationAction-root.Mui-selected":{
            border:'1px solid #0097ff',
            background:'#fff',
            paddingTop:0
        },
        "& .MuiBottomNavigationAction-label.Mui-selected":{
            fontFamily: 'NanumSquareRoundOTF',
            color:'#0097ff',
            fontWeight: 600,
            fontSize: 18,
        },
        "& .MuiBottomNavigationAction-label":{
            fontFamily: 'NanumSquareRoundOTF',
            fontSize: 18,
            transaction:'none',
            transitionDelay:0,
        },
        "& .MuiBottomNavigation-root button:last-child":{
            marginRight:0
        }
    },
    toggleButtonStyle:{
        maxWidth:31,
        minWidth:31,
        height:31,
        padding:0,
        background:'rgba(163, 168, 175, 0.2)',
        color:'#a3a8af',
        marginRight:6,
        marginTop:6,
        borderRadius: 4,
        border:'1px solid rgba(163, 168, 175, 0.2)',
        "&:hover":{
            border:'1px solid #0097ff',
            background:'#fff',
            color:'#0097ff',
        }
    },
    actionText:{
        fontSize: 10,
        color: '#000',
        fontWeight: 500,
    }
});

@inject('statisticsStore')
@observer
class SurveyRating extends Component {
    render() {
        const { classes, intl, statisticsStore, item } = this.props;
        const sortedOptions = item.options.map(o => ({...o})).sort((a, b) => a.number < b.number ? -1 : a.number > b.number ? 1 : 0);
        const itemAnswerList = statisticsStore.getUserSurveyItemAnswerList(item);
        const itemAnswer = itemAnswerList.length > 0 ? statisticsStore.getUserSurveyItemAnswerList(item)[0].optionId : 0;

        const labelDefaultWidth = (37 * ((sortedOptions.length)));
        const maxSelect = (11 - Math.ceil((1535 - window.innerWidth)/37) - sortedOptions[0].number);
        const labelWidth = window.innerWidth >= 1535 ? labelDefaultWidth : item.options.length >= maxSelect ? 31 * maxSelect : labelDefaultWidth;

        return (
            <div className={classes.root}>
                {item.title !== "" &&
                    <>
                        {item.required ?
                            <Typography className={classes.textStyle} style={{color: '#0d0d0d'}}>{item.title}<span style={{color: '#FF0000'}}>*</span></Typography>
                            :
                            <Typography className={classes.textStyle} style={{color: '#0d0d0d'}}>{item.title}</Typography>
                        }
                    </>
                }
                {item.contents !== "" &&
                    <>
                        <Typography className={clsx(classes.textStyle, classes.explanationText)} style={{color:'#0d0d0d'}}>{item.contents}</Typography>
                    </>
                }
                {item.images.length > 0 &&
                    item.images.map((images, i) => (
                        <Box key={item.surveyItemId + i} className={classes.titleImgBox} mb={2}>
                            <img src={images.image} alt="" style={{width: '100%'}}/>
                        </Box>
                    ))
                }
                <Box className={classes.buttonBox} mt={3}>
                    <BottomNavigation value={itemAnswer} showLabels>
                        {(sortedOptions.length > 0) &&
                            sortedOptions.map((option) => (
                                <BottomNavigationAction  key={option.surveyItemOptionId} value={option.surveyItemOptionId} label={option.number} className={clsx(classes.textStyle, classes.toggleButtonStyle)}/>
                            ))
                        }
                    </BottomNavigation>
                    {(sortedOptions.length > 0) &&
                        <Box display='flex' justifyContent='space-between' alignItems='center' style={{marginTop:20, minWidth: '84px', width: labelWidth}}>
                            <Typography className={clsx(classes.textStyle, classes.actionText)}>{sortedOptions[0].contents !== "" ? sortedOptions[0].contents : intl.formatMessage({id: "highly_dissatisfied"})}</Typography>
                            <Typography className={clsx(classes.textStyle, classes.actionText)}>{sortedOptions[0].contents !== "" ? sortedOptions[0].contents : intl.formatMessage({id: "highly_satisfied"})}</Typography>
                        </Box>
                    }
                </Box>
            </div>
        );
    }
}

export default withStyles(style)(injectIntl(SurveyRating));