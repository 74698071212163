import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Paper,
    Typography,
    Box,
    Button,
    Tooltip,
    Grid
} from "@material-ui/core";
import { ReactComponent as FileArrowDown } from "../../../common/images/FileArrowDown.svg";
import FileUpload from "./FileUpload";
import {AuthorityType} from "../../../stores/UserLoungeStore";
import {inject, observer} from "mobx-react";
import {UploadState} from "../../../stores/UserLoungeStore";
import MoreButton from "./FileMoreButton";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
        height: (window.innerHeight - 175) / 2
    },
    paperBox:{
        borderRadius:16,
        padding:20,
        border:'1px solid #c0c2c3',
        overflow:'hidden',
        height: '100%'
    },
    paperBoxScroll:{
        height: '80%',
        overflowY:'scroll',
        paddingRight:10,
        "&::-webkit-scrollbar": {
            width:'5px',
            marginLeft:10
        },
        "&::-webkit-scrollbar-thumb": {
            background:'#dbdbdb',
            borderRadius:'10px',
            backgroundClip:'padding-box',
        },
        "&::-webkit-scrollbar-track": {
            background:'transparent',
            marginTop:10
        },
    },
    titleStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:18,
        fontWeight:800,
        color:'#0d0d0d',
        textIndent:20,
    },
    buttonStyle:{
        border:'1px solid #bfbfbf',
        fontFamily:'NanumSquareRoundOTF',
        color:'#1f1f1f',
        fontSize:14,
        borderRadius:4,
        padding:'5px 12px',
        "&:hover":{
            background:'transparent'
        }
    },
    input: {
        display: 'none',
    },
    paperInBox:{

    },
    fileUploadBox:{
        padding:'15px 0 15px 10px',
        borderBottom:'1px solid #eee',
        "&:hover":{
            background:'#f2f7fc',
            border:'1px solid #dbeaf9',
            borderRadius:4
        },
    },
    fileNameText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#4d5a69',
        fontSize:14,
        fontWeight:600,
        paddingLeft:8,
        cursor:'default'
    },
    downloadBtn: {
        display: 'flex',
        alignItems: 'center',
        justifyContent:'flex-end',
        "&:hover":{
            background:'transparent'
        }
    },
    downloadText:{
        fontFamily:'NanumSquareRoundOTF',
        color:'#567fbd',
        fontSize:14,
        marginLeft:5,
        textAlign:'right'
    },
    paperInBox1:{
        background:'#f5f5f5',
        borderRadius:16,
        padding:'21px 13px 53px',
        marginTop:19
    },
    textStyle:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:14,
        fontWeight:600,
        color:'#0d0d0d',
        textAlign:'left',
    },
});

const LightTooltip = withStyles((theme) => ({
    tooltip: {
        padding:'4px 4px',
        border:'0.3px solid #000000',
        background:'#fffff5',
        fontFamily:'NanumSquareRoundOTF',
        fontSize: 11,
        color:'#000',
        borderRadius:'0'
    },
}))(Tooltip);

@inject('authStore', 'userLoungeStore', 'timelineStore', 'progressStore')
@observer
class FileSharing extends Component {
    state = {
        moreButtonOpen: false
    };

    componentDidMount() {
        document.getElementById('fileList').addEventListener("scroll", () => this.infiniteScroll(), true);
    }

    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        const {userLoungeStore, timelineStore, progressStore} = this.props;
        progressStore.setIsLoading(userLoungeStore.getIsLoading || timelineStore.getIsLoading)
    };

    infiniteScroll = () => {
        const { userLoungeStore,  progressStore} = this.props
        let scrollTop = document.getElementById('fileList').scrollTop;
        let clientHeight = document.getElementById('fileList').clientHeight;
        let scrollHeight = document.getElementById('fileList').scrollHeight;

        if (scrollTop + clientHeight >= scrollHeight) {
            progressStore.setIsLoading(true);
            userLoungeStore.getTeamFileList(userLoungeStore.selectedTeamId);
        }
    };

    handleMultiple = event => {
        const { authStore, userLoungeStore } = this.props;
        userLoungeStore.addTeamFile(authStore.loginUser.id, {file: event.target.files[0], teamId: userLoungeStore.selectedTeamId, state: UploadState.Pending});
    };

    handleClose = () => {
        this.setState({
            moreButtonOpen: false,
        });
    };

    render() {
        const { classes, userLoungeStore } = this.props;

        return (
            <div className={classes.root}>
                <Paper elevation={0} className={classes.paperBox} style={{position:'relative'}}>
                    <Box display='flex' justifyContent='space-between' alignItems='center' style={{borderBottom:'1px solid #c0c2c3',paddingBottom:10,marginBottom:10,marginRight:10}}>
                        <Typography className={classes.titleStyle}>
                            <FormattedMessage id="sharing_file"/>
                        </Typography>
                        <Box>
                            <input
                                accept="*"
                                className={classes.input}
                                onChange={this.handleMultiple}
                                id="contained-button-file"
                                multiple
                                type="file"
                                disabled={!userLoungeStore.authorityList.find(a => a.authority === AuthorityType.UploadFile) && userLoungeStore.userType !== 'Leader'}
                            />
                            <label htmlFor="contained-button-file" >
                                <Button
                                    className={classes.buttonStyle}
                                    component="span"
                                    disabled={!userLoungeStore.authorityList.find(a => a.authority === AuthorityType.UploadFile) && userLoungeStore.userType !== 'Leader'}
                                >
                                    <FormattedMessage id="file_selection"/>...
                                </Button>
                            </label>
                        </Box>
                    </Box>

                    {
                        userLoungeStore.uploadFileList.length > 0 &&
                        <FileUpload uploadFileList={userLoungeStore.uploadFileList}/>
                    }

                    <Box className={classes.paperBoxScroll} id='fileList'>
                        {
                            userLoungeStore.teamFileList.length > 0 ?
                                userLoungeStore.teamFileList.map((file, index) => {
                                    return (
                                        <Box display='flex' justifyContent='space-between' alignItems='center' className={classes.fileUploadBox} key={index}>
                                            <Grid item xs={8}>
                                                <Box display='flex' alignItems='center'>
                                                    <Grid item xs={1}>
                                                        <FileArrowDown style={{marginTop:4}}/>
                                                    </Grid>
                                                    <Grid item xs={11}>
                                                        <LightTooltip title={file.title} placement="bottom">
                                                            <Typography noWrap className={classes.fileNameText} style={{maxWidth: 800}}>{file.title}</Typography>
                                                        </LightTooltip>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box display='flex' justifyContent='flex-end'>
                                                    <MoreButton contentClose={this.handleClose} file={file}/>
                                                </Box>
                                            </Grid>
                                        </Box>
                                    );
                                })
                                :
                                <Paper elevation={0} className={classes.paperInBox1}>
                                    <Typography className={classes.textStyle}><FormattedMessage id="msg.no_file"/></Typography>
                                </Paper>
                        }
                    </Box>
                    <Box style={{width:'100%', height:30, position:'absolute', zIndex:100,background:'linear-gradient(to bottom, rgba(255, 255, 255, 0), #ffffff)',bottom:0}} />
                </Paper>
            </div>
        );
    }
}

export default withStyles(style)(FileSharing);