import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {Typography} from "@material-ui/core";
import MobileQuestionsChart from "./MobileQuestionsChart";
import {FormattedMessage} from "react-intl";

const style = theme => ({
    root:{
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.1rem',
        fontWeight:600,
        color:'#0d0d0d',
        padding:'0 20px'
    },
});

class MobileQuestions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        }
    };

    render() {
        const { classes } = this.props;

        return (
            <div className={classes.root}>
                <Typography className={classes.titleText}><FormattedMessage id="presentation_and_question_count"/></Typography>
                <MobileQuestionsChart />
            </div>
        );
    };
}

export default withStyles(style)(MobileQuestions);