import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Typography,
    Box,
    Drawer,
    Button
} from "@material-ui/core";
import { ReactComponent as LiveIcon} from "../../../common/images/LiveIcon.svg";
import { ReactComponent as EndIcon} from "../../../common/images/EndIcon.svg";
import {ReactComponent as Enter} from "../../../common/images/Enter.svg";
import {inject, observer} from "mobx-react";
import MobileLinkView from "./MobileLinkView";
import {ReactComponent as MobileMeetingLogIcon} from "../../../common/images/MobileMeetingLogIcon.svg";
import {Link, withRouter} from "react-router-dom";
import {ReactComponent as MobileStatisticsIcon} from "../../../common/images/MobileStatisticsIcon.svg";
import MobileRoomInformation from "./MobileRoomInformation";
import {DATE_UTIL} from "../../../common/util/date.util";
import * as dayjs from "dayjs";
import {UTIL} from "../../../common/util/common.util";

const style = theme => ({
    root:{
        "& .MuiButton-root":{
            boxSizing:'inherit'
        },
        "& .MuiButton-text":{
            padding:0
        },
    },
    //회의box
    conferenceBox:{
        width:230,
        background:'#dff8ed',
        padding:12,
        borderRadius:10,
    },
    conferenceBoxOpacity:{
        width:230,
        background:'#dff8ed',
        padding:12,
        borderRadius:10,
        opacity: 0.5
    },
    //학습box
    learningBox:{
        width:230,
        background:'#eae4ff',
        padding:12,
        borderRadius:10,
    },
    learningBoxOpacity:{
        width:230,
        background:'#eae4ff',
        padding:12,
        borderRadius:10,
        opacity: 0.5
    },
    timeText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        color:'#4f5660',
        fontWeight:800,
        marginRight:10,
        textAlign:'left'
    },
    titleText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.4rem',
        fontWeight:800,
        color:'#0d0d0d',
        textAlign:'left',
        margin:'7px 0'
    },
    teamNameText:{
        fontFamily:'NanumSquareRoundOTF',
        fontSize:'1.2rem',
        fontWeight:300,
        color:'#0d0d0d',
    },
    iconButton:{
        padding:0,
        marginRight:9,
        "&:hover":{
            background:'transparent'
        }
    },
    drawerBox:{
        "& .MuiDrawer-paper":{
            borderRadius:'15px 15px 0 0',
            padding:'10px 20px'
        }
    }
});

@inject('authStore', 'userLoungeStore')
@observer
class MobileDataTimeLineItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bottom: false,
        }
    }
    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    handleClickEnterance = (event, url) => {
        event.stopPropagation();
        url = url + '&tz=' + DATE_UTIL.getTimeZone() + '&local=' + DATE_UTIL.getLanguage();
        window.open(url);
    };

    render() {
        const { classes, authStore, userLoungeStore, room, history} = this.props;

        const roomState = UTIL.getRoomState(authStore.loginUser.id, room);
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);

        const team = userLoungeStore.selectedTeamId !== 0 ? (userLoungeStore.selectedTeam.id !== undefined ? userLoungeStore.selectedTeam : undefined) : userLoungeStore.teamList.find(t => t.id === room.teamId);
        const member = team !== undefined ? team.members.find(m => m.userId === authStore.loginUser.id) : undefined;
        const isLeader = (member !== undefined) && (member.type === 'Leader');

        const closeBottom = () => {
            this.setState({bottom: false});
        }

        const boxStyle = () => {
            if (roomState.isConference) {
                if (roomState.isEnded)
                    return classes.conferenceBoxOpacity;
                else
                    return classes.conferenceBox;
            } else {
                if (roomState.isEnded)
                    return classes.learningBoxOpacity;
                else
                    return classes.learningBox;
            }
        }

        return (
                <div className={classes.root}>
                    <Button onClick={this.toggleDrawer('bottom', true)} disableRipple>
                        <Box className={boxStyle()}>
                            <Box display='flex' alignItems='center'>
                                <Typography className={classes.timeText}>{DATE_UTIL.getTimeZoneLanguage(room.startDatetime).format('hh:mm A')} ~ {DATE_UTIL.getTimeZoneLanguage(room.endDatetime).format('hh:mm A')}</Typography>
                                {/* 현재 */}
                                {roomState.isCurrent &&
                                    <Box>
                                        <LiveIcon/>
                                    </Box>
                                }
                                {/* 종료 */}
                                {roomState.isEnded &&
                                    <Box>
                                        <EndIcon/>
                                    </Box>
                                }
                            </Box>
                            <Typography className={classes.titleText}>{room.name}</Typography>

                            <Box display='flex' justifyContent='space-between'>
                                <Typography className={classes.teamNameText}>{room.teamName} | {room.userName} {room.userTitle}</Typography>
                                <Box display='flex'>
                                    {(roomState.isMade || isLeader) && room.guests.length > 0 &&
                                        <div className={classes.iconButton} style={{marginRight: 6}} onClick={event => event.stopPropagation()}>
                                            <MobileLinkView guests={room.guests}/>
                                        </div>
                                    }
                                    {roomState.isEnter &&
                                        <div className={classes.iconButton} onClick={event => this.handleClickEnterance(event, room.members.find(m => m.userId === authStore.loginUser.id).entranceUrl)}>
                                            <Enter style={{width:26, verticalAlign:'middle'}}/>
                                        </div>
                                    }

                                    {/*시간이 지난뒤 회의일경우*/}
                                    {(dayjs(room.endDatetime).isBefore(currentDatetime) && roomState.isConference) &&
                                    <Link to="/MeetingMinutes" className={classes.link}>
                                        <div className={classes.iconButton}>
                                            <MobileMeetingLogIcon style={{verticalAlign:'middle'}}/>
                                        </div>
                                    </Link>
                                    }
                                    {/*시간이 지난뒤 학습일경우*/}
                                    {(dayjs(room.endDatetime).isBefore(currentDatetime) && !roomState.isConference && room.statistics && (isLeader || (room.userId === authStore.loginUser.id))) &&
                                        <div className={classes.iconButton} onClick={() => history.push(`/rooms/${room.id}/statistics`)}>
                                            <MobileStatisticsIcon style={{verticalAlign:'middle'}}/>
                                        </div>
                                    }
                                </Box>
                            </Box>
                        </Box>
                    </Button>

                    <Drawer
                        anchor="bottom"
                        open={this.state.bottom}
                        onClose={this.toggleDrawer('bottom', false)}
                        className={classes.drawerBox}
                    >
                        <MobileRoomInformation history={history} room={room} closeBottom={closeBottom}/>
                    </Drawer>
                </div>
        );
    }
}

export default withRouter(withStyles(style)(MobileDataTimeLineItem));
