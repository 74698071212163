import {DATE_UTIL} from "./date.util";
import * as dayjs from "dayjs";

class CommonUtil {
    getFirstCapitalLetter(name) {
        return name.substring(0, 1).toUpperCase();
    };

    getRoomState(userId, room) {
        const currentDatetime = DATE_UTIL.getTimeZoneDate(dayjs(), DATE_UTIL.FORMAT.DEFAULT_FORMAT);
        const isMade = userId === room.userId;

        return {
            isConference: room.type === 'Conference',
            isLive: dayjs(currentDatetime).isBetween(room.startDatetime, room.endDatetime) || dayjs(currentDatetime).isSame(room.startDatetime) || dayjs(currentDatetime).isSame(room.endDatetime),
            isCurrent: (dayjs(currentDatetime).isBetween(room.startDatetime, room.endDatetime) || dayjs(currentDatetime).isSame(room.startDatetime) || dayjs(currentDatetime).isSame(room.endDatetime)),
            isEnded: dayjs(room.endDatetime).isBefore(currentDatetime),
            isMade: isMade,
            isEnter: isMade ? dayjs(currentDatetime).isSameOrBefore(room.endDatetime) : dayjs(currentDatetime).isBetween(dayjs(room.startDatetime).subtract(30, 'minute'), room.endDatetime)
        };
    };
}

export const UTIL = new CommonUtil();