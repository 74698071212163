import {makeAutoObservable} from "mobx";
import axios from "axios";
import {validateEmail, validateName, validatePassword} from "../common/Validation";

const logPrefix = ' [ ExcelStore ] ' ;

export const TeamType = {
    Group: 'Group',
    Team: 'Team',
}

export const TeamUserType = {
    Leader: 'Leader',
    Member: 'Member',
};

export const UserType = {
    Admin: 'Admin',
    Normal: 'Normal',
};

export const RoomType = {
    Education : "Education",
    Conference : "Conference",
    Seminar : "Seminar",
}
export const State = {
    Init: 'Init',
    Pending: 'Pending',
    Failed: 'Failed',
    Done: 'Done',
};
const EmptyDialog = {
    open: false,
    title: "",
    msg: "",
};
export default class ExcelStore {
    constructor(serverContextPath) {
        this.serverContextPath = serverContextPath;
        makeAutoObservable(this);
    }
    excelConfirmDialog = {...EmptyDialog}

    createExcelState = State.Init;
    validationState = State.Init;
    isTeamCreate = true;
    teamValidationState = true;
    memberValidationState = true;

    memberJsonByExcel = [];
    teamJsonByExcel = {};


    teamForView = {};
    memberForView = [];

    teamTransfer = {};
    newMemberTransferList = [];
    teamUserTransferList = [];

    memberExcelModal = false;
    teamLeaderCount = 0;


    get getIsLoading() {
        return (this.createExcelState === State.Pending) || (this.validationState === State.Pending);
    }
    ////////////////////////////////////////////////////////////////
    //common

    initExcelStore = () => {
        this.createExcelState = State.Init;
        this.validationState = State.Init;
        this.isTeamCreate = true;
        this.teamValidationState = true;
        this.memberValidationState = true;

        this.memberJsonByExcel = [];
        this.teamJsonByExcel = {};

        this.teamForView = {};
        this.memberForView = [];

        this.teamTransfer = {};
        this.newMemberTransferList = [];
        this.teamUserTransferList = [];

        this.teamLeaderCount = 0;
    };

    changeExcelConfirmDialogOpen = (open) => {
        if (open) {
            this.excelConfirmDialog.open = open;
        } else {
            this.excelConfirmDialog = {...EmptyDialog};
        }
    }
    changeExcelConfirmDialogTitle = (title) => {
        this.excelConfirmDialog.title = title;
    }
    changeExcelConfirmDialogMsg = (msg) => {
        this.excelConfirmDialog.msg = msg;
    }

    setExcelConfirmDialog = (msg, title) => {
        this.changeExcelConfirmDialogOpen(true);
        this.changeExcelConfirmDialogTitle(title);
        this.changeExcelConfirmDialogMsg(msg);
    }

    setMemberExcelModal = (modal) => {
        this.memberExcelModal = modal;
    }

    ////////////////////////////////////////////////////////////////
    //team

    checkTeamValidation = (intl) => {
        this.teamValidationState = true
        const team = this.teamJsonByExcel;
        team.validationState = true;
        team.msg = [];

        this.checkTeamNameValidation(intl);
        this.checkTeamCommentValidation(intl);
        this.checkTeamMaxUsersValidation(intl);
    }

    checkTeamNameValidation = function* checkTeamNameValidation(intl) {
        console.log(logPrefix, "checkTeamNameValidation Start...");
        const team = this.teamJsonByExcel;

        if (team.name) {
            if (team.name.toString().length <= 20) {
                try {
                    const response = yield axios({
                        method : "get",
                        url : this.serverContextPath + `/api/v1/teams/check/name`,
                        params : {name : team.name}
                    });
                    if (response.data === 0) {
                        // team.validationState = true;
                    } else {
                        const msg = intl.formatMessage({ id : "msg.already_using_team_name"});
                        team.msg.push(msg);
                        this.teamValidationState = false;
                        team.validationState = false;
                    }
                } catch (e) {
                    console.log(e);
                }
            } else {
                const msg = intl.formatMessage({ id : "msg.limit_team_name"});
                team.msg.push(msg);
                this.teamValidationState = false;
                team.validationState = false;
            }
        } else {
            const msg = intl.formatMessage({ id : "msg.required_team_name"});
            team.msg.push(msg);
            this.teamValidationState = false;
            team.validationState = false;
        }
    }

    checkTeamCommentValidation = (intl) => {
        const team = this.teamJsonByExcel;

        if (team.comment && 200 < team.comment.length) {
            const msg = intl.formatMessage({ id : "msg.insert_room_description"});
            team.msg.push(msg);
            this.teamValidationState = false;
            team.validationState = false;
        }
    }

    checkTeamMaxUsersValidation = (intl) => {
        const team = this.teamJsonByExcel;
        const memberCount = this.memberJsonByExcel.length;

        if (team.maxUsers || team.maxUsers < 1) {
            if (memberCount <= team.maxUsers) {
                // pass
                // team.validationState = true;
            } else {
                const msg = intl.formatMessage({ id : "msg.error_too_many_members"});
                team.msg.push(msg);
                this.teamValidationState = false;
                team.validationState = false;
            }
        } else {
            const msg = intl.formatMessage({ id : "msg.required_max_user_count"});
            team.msg.push(msg);
            this.teamValidationState = false;
            team.validationState = false;
        }
    }

    ////////////////////////////////////////////////////////////////
    //member

    checkMemberValidation = async (intl) => {
        this.memberValidationState = true;
        for (let index in this.memberJsonByExcel) {
            this.memberJsonByExcel[index].validationState = true;
            this.memberJsonByExcel[index].msg = [];

            const member = this.memberJsonByExcel[index];
            const count = this.memberJsonByExcel.filter( item => item.name === member.name || item.email === member.email ).length;
            if ( 1 < count ) {
                const msg = intl.formatMessage({ id : "msg.overlap_member"});
                member.msg.push(msg);
                this.memberValidationState = false;
                member.validationState = false;
            } else {
                await this.getMemberByName(index, intl);

                if (!this.memberJsonByExcel[index].isExist) {
                    this.checkMemberTypeValidation(index, intl);
                    this.checkMemberEmailValidation(index, intl);
                    this.checkMemberNameValidation(index, intl);
                    this.checkMemberPasswordValidation(index, intl);
                    this.checkMemberTitleValidation(index, intl);
                }
            }
        }
    }

    getMemberByName = function* getMemberByName(index, intl) {
        let member = this.memberJsonByExcel[index];

        try {
            const response = yield axios({
                method : "get",
                url : this.serverContextPath + "/api/v1/users/name",
                params : {
                    userName : member.name,
                }
            });
            const selectedMember = response.data;
            if (selectedMember) {
                if (selectedMember.email === member.email && selectedMember.name === member.name && selectedMember.enabled) {
                    member.id = selectedMember.id
                    member.isExist = true;
                    member.password = "****";
                    member.title = selectedMember.title;
                } else {
                    const msg = intl.formatMessage({ id : "msg.already_using_nickname"});
                    member.msg.push(msg);
                    this.memberValidationState = false;
                    member.validationState = false;
                }
            } else {
                this.getMemberByEmail(intl, index);
            }
        } catch (e) {
            console.log(e);
        }
    }

    getMemberByEmail = function* getMamberByEmail(intl, index) {
        let member = this.memberJsonByExcel[index];

        try {
            const response = yield axios({
                method : "get",
                url : this.serverContextPath + "/api/v1/users/email",
                params : {
                    email : member.email,
                }
            });

            const selectedMember = response.data;
            if (selectedMember) {
                const msg = intl.formatMessage({ id : "msg.already_using_email"});
                member.msg.push(msg);
                this.memberValidationState = false;
                member.validationState = false;
            } else {
                // new
                // 사용 가능
            }
        } catch (e) {
            console.log(e);
        }
    }

    checkMemberTypeValidation = (index, intl) => {
        const member = this.memberJsonByExcel[index];

        if (member.type) {
            if (TeamUserType[member.type]) {
                // pass
                // member.validationState = true;
            } else {
                const msg = intl.formatMessage({ id : "msg.violation_member_role"});
                member.msg.push(msg);
                this.memberValidationState = false;
                member.validationState = false;
            }
        } else {
            const msg = intl.formatMessage({ id : "msg.required_member_role"});
            member.msg.push(msg);
            this.memberValidationState = false;
            member.validationState = false;
        }
        //TeamUserType과 대소문자 다를 때 처리 방법 생각하기
    }

    checkMemberEmailValidation = (index, intl) => {
        const member = this.memberJsonByExcel[index];

        if (member.email) {
            if (validateEmail(member.email)) {
                // pass
            } else {
                const msg = intl.formatMessage({ id : "msg.email_incorrect"});
                member.msg.push(msg);
                this.memberValidationState = false;
                member.validationState = false;
            }
        } else {
            const msg = intl.formatMessage({ id : "msg.required_email"});
            member.msg.push(msg);
            this.memberValidationState = false;
            member.validationState = false;
        }
    }

    checkMemberNameValidation = (index, intl) => {
        const member = this.memberJsonByExcel[index];

        if (member.name) {
            if (validateName(member.name)) {
                // pass
            } else {
                const msg = intl.formatMessage({ id : "msg.re_enter_nickname"});
                member.msg.push(msg);
                this.memberValidationState = false;
                member.validationState = false;
            }
        } else {
            const msg = intl.formatMessage({ id : "msg.required_nickname"});
            member.msg.push(msg);
            this.memberValidationState = false;
            member.validationState = false;
        }
    }

    checkMemberPasswordValidation = (index, intl) => {
        const member = this.memberJsonByExcel[index];

        if (member.password) {
            if (8 <= member.password.length) {
                if (validatePassword(member.password)) {
                    // pass
                    // member.validationState = true;
                } else {
                    const msg = intl.formatMessage({ id : "msg.password_incorrect"});
                    member.msg.push(msg);
                    this.memberValidationState = false;
                    member.validationState = false;
                }
            } else {
                const msg = intl.formatMessage({ id : "msg.password_policy"});
                member.msg.push(msg);
                this.memberValidationState = false;
                member.validationState = false;
            }
        } else {
            const msg = intl.formatMessage({ id : "msg.required_password"});
            member.msg.push(msg);
            this.memberValidationState = false;
            member.validationState = false;
        }
    }

    checkMemberTitleValidation = (index, intl) => {
        const member = this.memberJsonByExcel[index];

        if (member.title && !validateName(member.title)){
            const msg = intl.formatMessage({ id : "msg.violation_member_title"});
            member.msg.push(msg);
            this.memberValidationState = false;
            member.validationState = false;
        }
    }

    ////////////////////////////////////////////////////////////////
    //request

    convertingTeamTransfer = () => {
        const team = this.teamJsonByExcel;
        this.teamTransfer = {
            name : team.name,
            comment : team.comment,
            maxUsers : team.maxUsers,
            type : TeamType.Team,
            enabled : true,
        };
    }

    convertingMemberTransfer = () => {
        this.newMemberTransferList = this.memberJsonByExcel.map(member => {
            return {
                user: {
                    id: member.id ? member.id : 0,
                    email: member.email,
                    name: member.name,
                    password: member.password,
                    title: member.title,
                    type: UserType.Normal,
                    enabled: true,
                    teamUserType: member.type,
                },
                teamUserListToCreate: [{
                    teamId: 0,
                    userId: member.id ? member.id : 0,
                    type: member.type,
                    enabled: true,
                }],

            };
        });
    }

    createTeamByExcel = function* createTeam(intl) {
        this.createExcelState = State.Pending;
        try {

            yield this.convertingTeamTransfer();
            yield this.convertingMemberTransfer();

            yield axios({
               method : "post",
               url : "/api/v1/excel/team",
               data : {
                   team : this.teamTransfer,
                   newDetailUserTransfer : this.newMemberTransferList,
               }
            });
            const msg = intl.formatMessage({ id : "msg.registration_success"});
            const title = intl.formatMessage({ id : "excel_create_team_and_member"});
            this.setExcelConfirmDialog(msg, title);
            this.createExcelState = State.Done;
        } catch (e) {
            console.log(e);
            this.createExcelState = State.Failed;
        }
    }

    createTeamMemberByExcel = function* createTeamMemberByExcel(intl, teamId, userStore) {
        this.createExcelState = State.Pending;
        try {
            yield this.convertingMemberTransfer();

            yield axios({
                method : "post",
                url : "/api/v1/excel/member",
                data : {
                    teamId : teamId,
                    newDetailUserTransfer : this.newMemberTransferList,
                }
            });
            const msg = intl.formatMessage({ id : "msg.registration_success"});
            const title = intl.formatMessage({ id : "excel_add_team_member"});

            this.setExcelConfirmDialog(msg, title);
            this.createExcelState = State.Done;

            this.initExcelStore();

            userStore.initUserTablePage();
            userStore.getUserTableListByTeam(intl, teamId);
        } catch (e) {
            if (e.response.data.code === 'CanNotInsertUserByMaxUsers') {
                const msg = intl.formatMessage({ id : "msg.error_too_many_members"});
                const title = intl.formatMessage({ id : "excel_add_team_member"});
                this.setExcelConfirmDialog(msg, title);
            } else {
                const msg = intl.formatMessage({ id : "msg.error_unknown"});
                const title = intl.formatMessage({ id : "excel_add_team_member"});
                this.setExcelConfirmDialog(msg, title);
            }

            this.createExcelState = State.Failed;
        }
    }

    exportLmsExcelSample = function* exportLmsExcelSample(language){
        try{
            const response = yield axios({
                method : "get",
                url : "/api/v1/excel/team",
                params : {
                    language : language
                },
                responseType :'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "LmsExcelForm_"+language+".xlsx");
            document.body.appendChild(link);
            link.click();
        } catch(e){
            console.log(e);
        }
    };

    exportLmsMemberExcelSample = function* exportLmsMemberExcelSample(language){
        try{
            const response = yield axios({
                method : "get",
                url : "/api/v1/excel/member",
                params : {
                    language : language
                },
                responseType :'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', "LmsMemberExcelForm_"+language+".xlsx");
            document.body.appendChild(link);
            link.click();
        } catch(e){
            console.log(e);
        }
    };

    getTeamLeaderCount = function* getTeamLeaderCount(teamId){
        console.log(logPrefix, "getTeamLeaderCount Start");
        try{
            const response = yield axios({
                method : "get",
                url : `/api/v1/teams/${teamId}/leader-count`
            });

            this.teamLeaderCount = response.data;

            console.log(logPrefix, "getTeamLeaderCount Done");
        } catch(err){
            console.log(logPrefix, "getTeamLeaderCount Failed msg=", err.response.data.msg);
            console.log(err);
        }
    };
}