import React, {Component} from 'react';
import {withStyles} from "@material-ui/core/styles";
import {
    Grid,
    Box, Paper, Typography, makeStyles, Button,
    List, ListItem, ListItemIcon, ListItemText, Avatar, ButtonGroup
} from "@material-ui/core";
import StopIcon from '@material-ui/icons/Stop';
import CodeOutlinedIcon from '@material-ui/icons/CodeOutlined';
import {Skeleton} from "@material-ui/lab";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import UserImg from "../../../../common/images/userImg.png";
import { ReactComponent as TimeLineIcon } from "../../../../common/images/TimeLineIcon.svg";
import { ReactComponent as CalendarBlankIcon } from "../../../../common/images/CalendarBlankIcon.svg";
import {ReactComponent as Enter} from "../../../../common/images/Enter.svg";
import {ReactComponent as InvitationLinkIcon} from "../../../../common/images/InvitationLinkIcon.svg";
import {ReactComponent as SmileyIcon} from "../../../../common/images/SmileyIcon.svg";
import {ReactComponent as ClockIcon} from "../../../../common/images/ClockIcon.svg";
import {ReactComponent as TabletArrowLineConference} from "../../../../common/images/TabletArrowLineConference.svg";
import {ReactComponent as TabletArrowLineLearning} from "../../../../common/images/TabletArrowLineLearning.svg";
import {ReactComponent as LiveIcon} from "../../../../common/images/LiveIcon.svg";

const style = theme => ({
    root:{
        width:'auto',
        height: 'auto',
        paddingTop:10,
        paddingLeft:10,
        marginRight:30,
        background:'#fff',
        flexGrow: 1,
    },
    buttonTabBox:{
        "& .MuiButton-startIcon":{
            marginLeft:0,
            marginRight:0
        },
        "& .MuiButtonGroup-grouped":{
            minWidth:30
        }
    }
});

function SkeletonTypes(props) {
    const useStyles = makeStyles({
        root:{
            marginBottom:5,
            "& .MuiListItemIcon-root":{
                minWidth:0
            },
            "& .MuiListItem-gutters":{
                padding:0
            },
            "& .MuiListItem-root":{
                padding:0
            },
            "& .MuiList-padding":{
                padding:0
            },
            "& .MuiListItemText-root":{
                margin:0
            }
        },
    });
    const classes = useStyles();
    const { num } = props;
    const type1 = () => {
        return (
            <Paper style={{background:'#F5F5F5', padding:5, marginBottom:5}}>
                <Skeleton style={{backgroundColor : '#696060', width : '100%', height:13}} animation={false}/>
                <Skeleton style={{backgroundColor : '#B7B1B1', width : '90%', height:13}} animation={false} />
                <Skeleton style={{backgroundColor : '#B7B1B1', width : '30%', height:13}} animation={false}/>
            </Paper>
        )
    };

    const type2 = () => {
        return (
            <>
                <Box display={'flex'} alignItems={'center'}>
                    <StopIcon style={{color:'#696060', fontSize:15}}/>
                    <Skeleton style={{backgroundColor : '#B7B1B1', width: '100%', height: 8}} animation={false}/>
                </Box>
            </>
        )
    };

    const type3 = () => {
        return (
            <>
                <Skeleton style={{backgroundColor : '#696060', width : '100%', height:13}} animation={false}/>
                <Skeleton style={{backgroundColor : '#B7B1B1', width : '90%', height:13}} animation={false} />
                <List className={classes.root}>
                    <ListItem>
                        <ListItemIcon>
                            <SmileyIcon style={{width:10, height: 10}} />
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'caption'}>
                                <Skeleton style={{backgroundColor : '#B7B1B1', width : '60%', height:8}} animation={false}/>
                            </Typography>
                        </ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <ClockIcon style={{width:10, height: 10}}/>
                        </ListItemIcon>
                        <ListItemText>
                            <Typography variant={'caption'}>
                                <Skeleton style={{backgroundColor : '#B7B1B1', width : '100%', height:8}} animation={false}/>
                            </Typography>
                        </ListItemText>
                    </ListItem>
                </List>
            </>
        )
    };

    const types = [type1(), type2(), type3()];

    return types[num];
}

function Avatars(props) {
    const { subColor } = props;
    const useStyles = makeStyles({
        root:{
        },
        avatarStyle1:{
            display : 'flex',
            "& .MuiAvatar-circle":{
                width:15,
                height:15,
                background:'#fff',
                fontFamily:'NanumSquareRoundOTF',
                fontSize:8,
                color:'#0d0d0d',
                fontWeight:800,
            },
        },
    });
    const classes = useStyles();

    const members = [
        UserImg,
        UserImg,
        UserImg,
        UserImg,
        UserImg,
        UserImg,
        UserImg,
        UserImg,
    ]

    return (
        <Box className={classes.avatarStyle1}>
            <AvatarGroup
                max={6}
                spacing={6}
            >
                {members.map((src, index) => <Avatar key={index} style={{background : subColor}} />)}
            </AvatarGroup>
        </Box>
    )

}

function Notice(props) {
    const {i} = props;
    const useStyle = makeStyles((theme) => ({
        paperBox:{
            position:'relative',
            borderRadius:10,
            padding:8,
            marginLeft:10,
            border:'1px solid #c0c2c3',
            overflow:'hidden',
            '& .MuiPaper-elevation1' : {
                boxShadow : 'none'
            },
            "& .MuiButton-root":{
                minWidth:30
            }
        },
        paperBoxScroll:{
            height:80,
            overflow:'auto',
            paddingRight:10,
            marginTop:10,
            "&::-webkit-scrollbar": {
                width:'5px',
            },
            "&::-webkit-scrollbar-thumb": {
                background:'#dbdbdb',
                borderRadius:'10px',
                backgroundClip:'padding-box',
            },
            "&::-webkit-scrollbar-track": {
                background:'transparent',
                marginTop:10
            },
        },
        buttonText:{
            height : 15,
            fontFamily:'NanumSquareRoundOTF',
            backgroundColor:'#0dc268',
            fontSize:25,
            borderRadius:2,
            "&:hover":{
                background:'#0dc268'
            }
        },
    }));

    const classes = useStyle();
    const numbers = [i,i,i];
    return (
        <Paper elevation={0} className={classes.paperBox}>
            <Box display='flex' justifyContent='space-between' alignItems='center' style={{borderBottom:'1px solid #c0c2c3',paddingBottom:5}}>
                <Skeleton style={{backgroundColor : '#E9BEBE', width : '45%', height : 8}} animation={false}/>
                <Button className={classes.buttonText} />
            </Box>
            <Box className={classes.paperBoxScroll}>
                {
                    numbers.map((num, key) => {
                        return (
                            <div key={key}>
                                <SkeletonTypes num={num} />
                            </div>
                        );
                    })
                }
            </Box>
        </Paper>
        )
}

function TimeLines(props) {
    const { subColor } = props;
    const useStyle = makeStyles((theme) => ({
        root:{
            marginTop:15
        },
        paperBox:{
            position:'relative',
            top:-13,
            left:15,
            borderTop:'2px solid #00bc6a',
            borderRadius:5,
            padding:10,
            background:'#DFF8ED',
            overflow:'hidden',
            display:'flex',
            justifyContent : 'space-between'
        },
        skeletonBox : {
            width: '80%',
            height: '100%',
        },
        timeText:{
            fontFamily:'NanumSquareRoundOTF',
            fontSize:5,
            fontWeight:800,
            color:'#a3a8af',
            textAlign:'left'
        },
    }));

    const classes = useStyle();
    return (
        <div className={classes.root}>
            <Box display={'flex'}>
                <Box style={{marginRight:5}}>
                    <Typography className={classes.timeText}>9:00 AM</Typography>
                    <LiveIcon/>
                </Box>
                <Box>
                    <Box style={{position:'relative', zIndex:10}}>
                        <TabletArrowLineConference/>
                    </Box>

                    <Paper elevation={0} className={classes.paperBox}>
                        <Box className={classes.skeletonBox}>
                            <SkeletonTypes num={2} />
                            <Avatars subColor={subColor}/>
                        </Box>
                        <Box style={{display:'flex', flexDirection:'column', alignContent:'flex-end', marginLeft:20}}>
                            <Enter style={{width:15, marginBottom:5}}/>
                            <InvitationLinkIcon style={{width:15}}/>
                        </Box>
                    </Paper>
                </Box>
            </Box>

            <Box display={'flex'}>
                <Box style={{marginRight:5}}>
                    <Typography className={classes.timeText}>9:30 AM</Typography>
                    <LiveIcon/>
                </Box>
                <Box>
                    <Box style={{position:'relative', zIndex:10}}>
                        <TabletArrowLineLearning/>
                    </Box>

                    <Paper elevation={0} className={classes.paperBox} style={{background:'#eae4ff', borderTop:'2px solid #664CFF',}}>
                        <Box className={classes.skeletonBox}>
                            <SkeletonTypes num={2} />
                            <Avatars subColor={subColor}/>
                        </Box>
                        <Box style={{display:'flex', flexDirection:'column', alignContent:'flex-end', marginLeft:20}}>
                            <Enter style={{width:15, marginBottom:5}}/>
                            <InvitationLinkIcon style={{width:15}}/>
                        </Box>
                    </Paper>
                </Box>
            </Box>

        </div>
    );
};



class PreviewTimeline extends Component {
    render() {
        const { classes, subColor, mainBtnColor, mainColor } = this.props;

        return (
                <Grid container spacing={1} flexdirection={'row'} style={{marginBottom:10}}>
                    <Grid item xs={12}>
                        <Box display={'flex'} style={{padding:'10px 20px 0', flexDirection : "row-reverse"}}>
                            <Avatars subColor={subColor}/>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} flexdirection={'column'}>
                            <Grid item xs={4}>
                                <Grid container flexdirection={'row'} spacing={1}>
                                    <Grid item xs={12}>
                                        <Notice i={0} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Notice i={1} />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={8}>
                                <Grid container flexdirection={'row'} spacing={1}>
                                    <Grid container flexdirection={'column'} alignItems={'center'} spacing={1}>
                                            <Grid item xs={4}>
                                                <Grid container flexdirection={'row'} spacing={1}>
                                                    <Grid item xs={10}>
                                                        <Skeleton style={{backgroundColor : '#E9BEBE', width : '100%', height:13}} animation={false}/>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Box display={'flex'} alignItems={'center'}>
                                                            <Skeleton style={{width : '100%', height:8, marginRight:5, marginLeft:5}} animation={false}/>
                                                            <CodeOutlinedIcon style={{color : '#dbdbdb', fontSize:15}}/>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Button variant={'contained'} style={{height : 20, background : mainBtnColor ? mainColor === '#fff' ? mainBtnColor : mainColor : mainColor, width:'80%'}}></Button>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Box className={classes.buttonTabBox}>
                                                    <ButtonGroup size={'small'} stlye={{display:'flex', justifyContent:'flex-end'}}>
                                                        <Button startIcon={<TimeLineIcon style={{width:13, height:13}}/>} style={{background:'#3B3B3B'}}/>
                                                        <Button startIcon={<CalendarBlankIcon style={{width:13, height:13}}/>} />
                                                    </ButtonGroup>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    <Grid item>
                                        <TimeLines subColor={subColor}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
        );
    }
}

export default withStyles(style)(PreviewTimeline);